import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getListingPartners } from '../../api/listing';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const listingPartnersLocation = 'listingPartners';

/**
 * Requests that the given page of the given query is made present.
 */
export const listingPartnersRequestPage = pagingRequestPage(
  listingPartnersLocation,
  getListingPartners
);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectListingPartners = pagingSelectItems(listingPartnersLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectListingPartnersMode = pagingSelectMode(listingPartnersLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectListingPartnersError = pagingSelectError(listingPartnersLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingPartnersQuery = pagingSelectQuery(listingPartnersLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingPartnersTotal = pagingSelectTotal(listingPartnersLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingPartnersTotalItems = pagingSelectTotalItems(listingPartnersLocation);

/**
 * Reducer for partners listing.
 */
export const listingPartnersReducer = pagingReducer(listingPartnersLocation);
