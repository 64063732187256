import React from 'react';
import PropTypes from 'prop-types';
import SingleImage from '../atoms/imageManager/SingleImage';
import './SingleImageManager.scss';
import ImageUpload from '../atoms/imageManager/ImageUpload';

const SingleImageManager = ({ circle, cover, height, image, onUpdateImage, width }) => {
  return (
    <div className='SingleImageManager'>
      {image && (
        <SingleImage
          index={0}
          location={image.location}
          {...{ circle, cover, height, width }}
          onRemove={() => onUpdateImage(null)}
        />
      )}
      <ImageUpload
        label={image ? 'Bild ersetzen' : 'Bild hochladen'}
        {...{ circle, height, width }}
        onUploadedImage={onUpdateImage}
      />
    </div>
  );
};

SingleImageManager.propTypes = {
  circle: PropTypes.bool,
  cover: PropTypes.bool,
  height: PropTypes.number,
  image: PropTypes.shape({
    location: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    alias: PropTypes.string
  }),
  onUpdateImage: PropTypes.func.isRequired,
  width: PropTypes.number
};

export default SingleImageManager;
