import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-dates/initialize';
import './index.scss';
import App from './components/App';
import { version } from './config';
import { addExceptionFilters, appInsights } from './ai';

/* eslint-disable no-console */
version
  ? console.log(`%cVersion ${version}`, 'color:gray')
  : console.log('%cUnknown version', 'color:gray');
/* eslint-enable no-console */

// Boot App Insights.
appInsights.loadAppInsights();

// Add standard filters.
addExceptionFilters(appInsights);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
