import React from 'react';
import PropTypes from 'prop-types';
import './NumericPicker.scss';
import { Plus, Minus } from '../../../images/icons';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Colors from '../../../definitions/colors';

const NumericPicker = ({
  disabled,
  max,
  min,
  onUpdateValue,
  showLabel,
  title,
  tooltip,
  value,
  valueDisplay
}) => {
  const canIncrease = () => {
    return value < max || !max;
  };
  const increase = () => {
    if (canIncrease()) {
      onUpdateValue(value + 1);
    }
  };
  const canDecrease = () => {
    return value > min;
  };
  const decrease = () => {
    if (canDecrease()) {
      onUpdateValue(value - 1);
    }
  };

  return (
    <div
      className={`NumericPicker
      ${disabled ? 'NumericPicker--Disabled' : ''}
      `}
    >
      {showLabel && (
        <div className='NumericPicker__Label'>
          <span>{title}</span>
          {tooltip && <Tooltip content={tooltip} />}
        </div>
      )}
      <div className='NumericPicker__Value'>{valueDisplay(value)}</div>
      <div className='NumericPicker__Buttons'>
        <div className='NumericPicker__Button NumericPicker__Button--Minus'>
          <IconButton
            title='Verringern'
            iconCircle={{ icon: <Minus size={16} fill={Colors.White} />, size: 28 }}
            disabled={!canDecrease() || disabled}
            onClick={decrease}
          />
        </div>
        <div className='NumericPicker__Button NumericPicker__Button--Plus'>
          <IconButton
            title='Erhöhen'
            iconCircle={{ icon: <Plus size={16} fill={Colors.White} />, size: 28 }}
            disabled={!canIncrease() || disabled}
            onClick={increase}
          />
        </div>
      </div>
    </div>
  );
};

NumericPicker.propTypes = {
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.number.isRequired,
  valueDisplay: PropTypes.func.isRequired
};

NumericPicker.defaultProps = {
  min: 0,
  showLabel: true,
  value: 0,
  valueDisplay: value => value
};

export default NumericPicker;
