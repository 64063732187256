import React, { useMemo, useState } from 'react';
import Container from '../layout/Container';
import './ValueDashboard.scss';
import LoadingOverlay from '../atoms/LoadingOverlay';

import PageTitle from '../../helpers/pageTitle';
import { useAbortEffect } from '../../hooks/useAbortEffect';
import moment from 'moment';
import { analyzeValuesSummary } from '../../api/customerValue';
import DateLineChart from '../atoms/DateLineChart';
import DateStackChart from '../atoms/DateStackChart';
import { rgbaToStyle, styleToRgba, rgbaSegBiLerp } from '../../util/color';
import Colors from '../../definitions/colors';
import Toolbar from '../modules/Toolbar';
import { displayPercentage, formatChange, naturalize } from '../../helpers/formatters';
import PropTypes from 'prop-types';
import { useSessionState } from '../../hooks/useSessionState';
import { Paths } from '../../definitions/paths';

const weeks = 8;

const colorFrom = styleToRgba(Colors.GrayLight);
const colorVia = styleToRgba(Colors.Orange);
const colorTo = styleToRgba(Colors.Green);

const splitName = split => `${displayPercentage(split.lower)}-${displayPercentage(split.upper)}`;
const splitColor = split =>
  rgbaToStyle(rgbaSegBiLerp((split.lower + split.upper) / 2.0, colorFrom, colorVia, colorTo));

const ColorBox = ({ color }) => (
  <div className='ValueDashboard__ColorBox' style={{ backgroundColor: color }}></div>
);

ColorBox.propTypes = {
  color: PropTypes.string.isRequired
};

const ValueDashboard = () => {
  // Value summaries (memorized in session).
  const [valueSummaries, setValueSummaries] = useSessionState('valueDashboard/all', []);
  const [loading, setLoading] = useState(false);

  // Load all values.
  useAbortEffect(signal => {
    const start = moment().startOf('day');
    const times = [...Array(weeks).keys()].map(diff => start.clone().subtract(diff * 7, 'days'));

    (async () => {
      setLoading(true);
      const all = await Promise.all(
        times.map(time =>
          analyzeValuesSummary(time.toISOString(), signal).then(result => ({
            time: time.toISOString(),
            ...result
          }))
        )
      );
      setValueSummaries(all);
    })()
      .catch(() => undefined)
      .finally(() => setLoading(false));
  }, []);

  // Determine splits from values.
  const splits = useMemo(() => {
    if (!valueSummaries?.length) return null;
    const splits = Object.keys(valueSummaries?.[0]?.splits || {});
    return splits.map((split, i) => ({
      key: split,
      lower: Number(split),
      upper: i + 1 < splits.length ? Number(splits[i + 1]) : 1
    }));
  }, [valueSummaries]);

  // Get changes per split to last week.
  const changes = useMemo(() => {
    if (!splits) return null;
    if (!valueSummaries?.length || valueSummaries.length < 2) return null;

    const current = valueSummaries.at(-1);
    const previous = valueSummaries.at(-2);
    return splits
      .map(split => ({
        ...split,
        change: (current.splits?.[split.key] ?? 0) - (previous.splits?.[split.key] ?? 0)
      }))
      .reverse();
  }, [valueSummaries, splits]);

  // Compute means series.
  const mean = useMemo(() => {
    if (!valueSummaries?.length) return null;
    return [
      {
        name: 'Durchschnitt',
        data: valueSummaries.map(entry => ({ x: entry.time, y: entry.mean }))
      }
    ];
  }, [valueSummaries]);

  // Compute stacked parts series.
  const parts = useMemo(() => {
    if (!splits) return null;
    if (!valueSummaries?.length) return null;

    return splits.map(split => ({
      name: splitName(split),
      color: splitColor(split),
      data: valueSummaries.map(entry => ({ x: entry.time, y: entry.splits?.[split.key] }))
    }));
  }, [valueSummaries, splits]);

  return (
    <main className='ValueDashboard'>
      <PageTitle title='Entdecker-Wert' />
      <LoadingOverlay show={loading} clear={!!valueSummaries?.length} />
      <Container>
        <Toolbar
          title={'Gesamtauswertung Entdecker-Wert'}
          backButton={{
            to: Paths.Dashboard(),
            label: 'Dashboard'
          }}
        />
        {!changes ? null : (
          <>
            <h2 className='ValueDashboard__Section'>Veränderungen zur letzten Woche</h2>
            <div className='ValueDashboard__Dual'>
              <div className='ValueDashboard__Dual--Item'>
                <div className='ValueDashboard__Legend'>
                  {changes?.map(split => (
                    <div className='ValueDashboard__Legend__Item' key={split.key}>
                      <span className='ValueDashboard__Legend__Item--Category'>
                        {splitName(split)}
                      </span>
                      <ColorBox color={splitColor(split)} />
                      <span className='ValueDashboard__Legend__Item--Value'>
                        {formatChange(split.change)}
                      </span>
                    </div>
                  )) ?? null}
                </div>
              </div>
              <div className='ValueDashboard__Dual--Item ValueDashboard__Dual--Fill'>
                <p className='ValueDashboard__MediumWidth'>
                  Zur Sichtbarmachung von Gesamtentwicklungen werden alle Kunden in die
                  <strong> {naturalize(splits?.length)} </strong>
                  nebenstehenden Kategorien eingeteilt, basierend auf ihrem jeweiligen
                  Entdecker-Wert.
                </p>
              </div>
            </div>
          </>
        )}

        {!parts ? null : (
          <>
            <h2 className='ValueDashboard__Section'>Entwicklung der Werteverteilung</h2>
            <DateStackChart series={parts} xTitle='Datum' yTitle='Anzahl Kunden' />
          </>
        )}

        {!mean ? null : (
          <>
            <h2 className='ValueDashboard__Section'>Durchschnittlicher Entdecker-Wert</h2>
            <DateLineChart series={mean} xTitle='Datum' yTitle='Entdeckerwert' percentage={true} />
          </>
        )}
      </Container>
    </main>
  );
};

export default ValueDashboard;
