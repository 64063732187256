import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import './AutoEllipsis.scss';
import IconButton from '../atoms/IconButton';
import { Down, Up } from '../../images/icons';
import Colors from '../../definitions/colors';

const AutoEllipsis = ({ children, concealColor, maxHeight }) => {
  const content = useRef(null);
  const ellipsis = content?.current?.offsetHeight > maxHeight;
  const [open, setOpen] = useState(false);

  const toggle = event => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className='AutoEllipsis'>
      <AnimateHeight
        height={open || !ellipsis ? 'auto' : maxHeight}
        className={ellipsis ? `AutoEllipsis__Collapse AutoEllipsis__Collapse--${concealColor}` : ''}
      >
        <div ref={content} className='AutoEllipsis__Content'>
          {children}
        </div>
      </AnimateHeight>
      {ellipsis && (
        <IconButton
          title={open ? 'Einklappen' : 'Alles anzeigen'}
          showLabel
          onClick={toggle}
          iconCircle={{
            icon: open ? (
              <Up size={14} fill={Colors.White} />
            ) : (
              <Down size={14} fill={Colors.White} />
            ),
            size: 28
          }}
        />
      )}
    </div>
  );
};

AutoEllipsis.propTypes = {
  children: PropTypes.any,
  concealColor: PropTypes.oneOf(['WHITE', 'GRAY']),
  maxHeight: PropTypes.number
};

AutoEllipsis.defaultProps = {
  concealColor: 'WHITE',
  maxHeight: 200
};

export default AutoEllipsis;
