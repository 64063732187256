import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getListingCampaignsOffers } from '../../api/listing';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const listingCampaignsOffersLocation = 'listingCampaignsOffers';

/**
 * Requests that the given page of the given query is made present.
 */
export const listingCampaignsOffersRequestPage = pagingRequestPage(
  listingCampaignsOffersLocation,
  getListingCampaignsOffers
);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectListingCampaignsOffers = pagingSelectItems(listingCampaignsOffersLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectListingCampaignsOffersMode = pagingSelectMode(listingCampaignsOffersLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectListingCampaignsOffersError = pagingSelectError(listingCampaignsOffersLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingCampaignsOffersQuery = pagingSelectQuery(listingCampaignsOffersLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingCampaignsOffersTotal = pagingSelectTotal(listingCampaignsOffersLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingCampaignsOffersTotalItems = pagingSelectTotalItems(
  listingCampaignsOffersLocation
);

/**
 * Reducer for partners listing.
 */
export const listingCampaignsOffersReducer = pagingReducer(listingCampaignsOffersLocation);
