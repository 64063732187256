import React, { useMemo, useState } from 'react';
import Container from '../layout/Container';
import Toolbar from '../modules/Toolbar';
import { Paths } from '../../definitions/paths';
import './CampaignStatistics.scss';
import PageTitle from '../../helpers/pageTitle';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Table from '../atoms/table/Table';
import TableHead, { fromApiSort } from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';
import { displayDateBoundaries } from '../../helpers/formatters';
import SingleBarChart from '../atoms/SingleBarChart';
import DateBarChart from '../atoms/DateBarChart';
import useIdParam from '../../hooks/useIdParam';
import { useAbortEffect } from '../../hooks/useAbortEffect';
import {
  statsCampaignActivations,
  statsCampaignAliases,
  statsCampaignUsages
} from '../../api/stats';
import DayHourHeatChart from '../atoms/DayHourHeatChart';
import Colors from '../../definitions/colors';
import PropTypes from 'prop-types';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import Pagination from '../atoms/Pagination';
import { useResolveName } from '../../hooks/useResolveName';
import { resolveCampaignName } from '../../api/campaigns';
import TextLink from '../atoms/TextLink';

const formatDe = new Intl.NumberFormat('de-DE');
const format = value => formatDe.format(value);

const CampaignStatisticsGraphs = ({ id }) => {
  const [[activations, aliases], setStats] = useState(() => [null, null]);
  useAbortEffect(
    signal => {
      if (!id) return;
      (async () => {
        setStats(
          await Promise.all([
            statsCampaignActivations(id, signal),
            statsCampaignAliases(id, signal)
          ])
        );
      })().catch(() => undefined);
    },
    [id]
  );

  const formatHeadActivations = activations?.total && format(activations.total);
  const formatHeadAliases = aliases?.total && format(aliases.total);

  return (
    <>
      {!activations?.total && !aliases?.total ? null : (
        <>
          <div className='CampaignStatistics__Screen'>
            <div className='CampaignStatistics__ScreenItem CampaignStatistics__ScreenItem--Orange'>
              <div className='CampaignStatistics__ScreenValue'>{formatHeadActivations || 0}</div>
              <div className='CampaignStatistics__ScreenLabel'>durchgeführte Einlösungen</div>
            </div>
            <div className='CampaignStatistics__ScreenItem CampaignStatistics__ScreenItem--Gray'>
              <div className='CampaignStatistics__ScreenValue'>{formatHeadAliases || 0}</div>
              <div className='CampaignStatistics__ScreenLabel'>ausstehende Einlösungen</div>
            </div>
          </div>
        </>
      )}
      {!activations?.total ? null : (
        <>
          <h2 className='CampaignStatistics__Section'>Einlösungen nach Wochentag und Uhrzeit</h2>
          <DayHourHeatChart data={activations?.combined} />
        </>
      )}

      {!activations?.total && !aliases?.total ? null : (
        <>
          <h2 className='CampaignStatistics__Section'>Einlösungen nach Datum</h2>
          <DateBarChart
            series={[
              {
                name: 'Durchgeführt',
                data: activations.days.map(item => ({
                  x: item.date,
                  y: item.count
                }))
              },
              {
                name: 'Ausstehend',
                color: Colors.GrayLight,
                data: aliases.days.map(item => ({
                  x: item.date,
                  y: item.count
                }))
              }
            ]}
          />
        </>
      )}
    </>
  );
};

CampaignStatisticsGraphs.propTypes = {
  id: PropTypes.string.isRequired
};

const None = () => <span className='CampaignStatistics__Weak'>–</span>;
const CampaignStatisticsUsage = ({ id }) => {
  const [usages, setUsages] = useState(() => null);
  const [page, setPage] = useState(() => 0);
  const [total, setTotal] = useState(() => 0);
  const [search] = useState('');
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  usePaginationGuard(page, total, setPage);

  useAbortEffect(
    signal => {
      if (!id) return;
      (async () => {
        setLoading(true);
        const result = await statsCampaignUsages(id, { page, search, order }, signal);

        setUsages(result.data);
        setPage(result.pagesAt);
        setTotal(result.pagesCount);
      })()
        .catch(() => undefined)
        .finally(() => setLoading(false));
    },
    [id, page, search, order]
  );

  const maxCount = useMemo(() => usages && Math.max(...usages.map(usage => usage.count)), [usages]);

  return (
    <>
      {!usages?.length ? null : (
        <div>
          <h2 className='CampaignStatistics__Section'>Einlösungsverlauf</h2>
          <LoadingOverlay show={loading} />
          <Table
            headings={[
              <TableHead
                key={1}
                label='Partner'
                currentSorting={fromApiSort('partnerName', order)}
                onSortAsc={() => setOrder('+partnerName')}
                onSortDesc={() => setOrder('-partnerName')}
                onSortReset={() => setOrder(null)}
              />,
              <TableHead
                key={2}
                label='Vorteil'
                width={30}
                currentSorting={fromApiSort('offerName', order)}
                onSortAsc={() => setOrder('+offerName')}
                onSortDesc={() => setOrder('-offerName')}
                onSortReset={() => setOrder(null)}
              />,
              <TableHead key={3} label='Vorteilsart' />,
              <TableHead key={4} label='Zeitraum' />,
              <TableHead key={5} label='Status' />,
              <TableHead
                key={6}
                width={20}
                label='Einlösungen'
                currentSorting={fromApiSort('count', order)}
                onSortAsc={() => setOrder('+count')}
                onSortDesc={() => setOrder('-count')}
                onSortReset={() => setOrder(null)}
              />
            ]}
          >
            {usages.map((usage, index) => (
              <TableRow key={index}>
                <TableCell>
                  {!usage.partnerName ? (
                    <None />
                  ) : (
                    <TextLink title={usage.partnerName} to={Paths.PartnerDetails(usage.partner)} />
                  )}
                </TableCell>
                <TableCell>
                  {!usage.offerName ? (
                    <None />
                  ) : (
                    <TextLink title={usage.offerName} to={Paths.OfferDetails(usage.offer)} />
                  )}
                </TableCell>
                <TableCell>
                  {(usage.type === 'qrCode' && 'QR-Code') ||
                    (usage.type === 'shopify' && 'Shopify') ||
                    (usage.type === 'article' && 'Info-Artikel') ||
                    (usage.type === 'form' && 'Web-Formular') || <None />}
                </TableCell>
                <TableCell>
                  {displayDateBoundaries(usage.validFrom, usage.validUntil) || <None />}
                </TableCell>
                <TableCell>
                  {'Aktiv'}
                  {/* When in draft mode: <span className='CampaignStatistics__Weak'>Entwurf</span> */}
                  {/* When planned for future: <span className='CampaignStatistics__Weak'>Geplant</span> */}
                  {/* When expired: <span className='CampaignStatistics__Weak'>Abgelaufen</span> */}
                </TableCell>
                <TableCell>
                  <SingleBarChart
                    background={index % 2 === 0 ? 'WHITE' : 'GRAY'}
                    max={maxCount}
                    value={usage.count}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
        </div>
      )}
    </>
  );
};
CampaignStatisticsUsage.propTypes = {
  id: PropTypes.string.isRequired
};

const CampaignStatistics = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Get name of Campaign.
  const name = useResolveName(resolveCampaignName, id);

  return (
    <main className='CampaignStatistics'>
      <PageTitle title={`Kampagne auswerten: ${name}`} />

      <Container className='CampaignStatistics__ExtraGap'>
        <Toolbar
          title={name ? `„${name}“ auswerten` : null}
          backButton={{
            to: Paths.CampaignDetails(id),
            label: 'Kampagne'
          }}
        />

        <CampaignStatisticsGraphs id={id} />
        <CampaignStatisticsUsage id={id} />
      </Container>
    </main>
  );
};

export default CampaignStatistics;
