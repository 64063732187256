import { apiFetch, completeJson, completeJsonPage, getStats, pathManage } from './common';

/**
 * Gets the campaign statistics.
 * @param id The ID of the campaign.
 * @return {*} Returns a promise on the campaign statistics. Faults to retrieve are marked as errors.
 */
export const getCampaignStats = id => getStats(`${pathManage}/stats/campaign/${id}`);

/**
 * Gets the offer statistics.
 * @param id The ID of the campaign.
 * @return {*} Returns a promise on the offer statistics. Faults to retrieve are marked as errors.
 */
export const getOfferStats = id => getStats(`${pathManage}/stats/offer/${id}`);

export const statsOfferActivations = (id, signal) => {
  if (typeof id !== 'string') return null;
  return apiFetch(`${pathManage}/stats/offer/${id}/activations`, { signal })
    .then(response => completeJson(response))
    .catch(() => null);
};

export const statsOfferAliases = (id, signal) => {
  if (typeof id !== 'string') return null;
  return apiFetch(`${pathManage}/stats/offer/${id}/aliases`, { signal })
    .then(response => completeJson(response))
    .catch(() => null);
};

export const statsOfferUsages = (id, query, signal) => {
  if (typeof id !== 'string') return null;
  return apiFetch(`${pathManage}/stats/offer/${id}/usage`, { signal, query })
    .then(response => completeJsonPage(response))
    .catch(() => null);
};

export const statsCampaignActivations = (id, signal) => {
  if (typeof id !== 'string') return null;
  return apiFetch(`${pathManage}/stats/campaign/${id}/activations`, { signal })
    .then(response => completeJson(response))
    .catch(() => null);
};

export const statsCampaignAliases = (id, signal) => {
  if (typeof id !== 'string') return null;
  return apiFetch(`${pathManage}/stats/campaign/${id}/aliases`, { signal })
    .then(response => completeJson(response))
    .catch(() => null);
};

export const statsCampaignUsages = (id, query, signal) => {
  if (typeof id !== 'string') return null;
  return apiFetch(`${pathManage}/stats/campaign/${id}/usage`, { signal, query })
    .then(response => completeJsonPage(response))
    .catch(() => null);
};
