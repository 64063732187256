import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getListingCustomers } from '../../api/listing';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const listingCustomersLocation = 'listingCustomers';

/**
 * Requests that the given page of the given query is made present.
 */
export const listingCustomersRequestPage = pagingRequestPage(
  listingCustomersLocation,
  getListingCustomers
);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectListingCustomers = pagingSelectItems(listingCustomersLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectListingCustomersMode = pagingSelectMode(listingCustomersLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectListingCustomersError = pagingSelectError(listingCustomersLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingCustomersQuery = pagingSelectQuery(listingCustomersLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingCustomersTotal = pagingSelectTotal(listingCustomersLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectListingCustomersTotalItems = pagingSelectTotalItems(listingCustomersLocation);

/**
 * Reducer for customers listing.
 */
export const listingCustomersReducer = pagingReducer(listingCustomersLocation);
