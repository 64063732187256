import {
  apiFetch,
  completeJson,
  deleteEntity,
  getEntity,
  getPage,
  getResolution,
  pathManage,
  postCall,
  postEntity,
  postResolution,
  putEntity
} from './common';

/**
 * Retrieves the specific offer with the given ID.
 * @param id The ID of the offer.
 * @return {*} Returns a promise with the offer. Failures to get are treated as errors.
 */
export const getOffer = id => getEntity(`${pathManage}/offers/${id}`);

/**
 * Creates the given offer.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param offer The offer to add.
 * @return {*} Returns a promise with the created offer. Failures to create are treated as errors.
 */
export const createOffer = offer => postEntity(`${pathManage}/offers`, null, offer);

/**
 * Updates the given offer. The offer should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param offer The offer to update.
 * @return {*} Returns a promise with the updated offer. Failures to create are treated as errors.
 */
export const updateOffer = offer => putEntity(`${pathManage}/offers`, null, offer);

/**
 * Updates the stage of the offer.
 * @param id The ID of the offer.
 * @param stage The new stage value.
 * @return {*} Returns a promise with the updated object. Failures to update are treated as errors.
 */
export const updateOfferStage = (id, stage) =>
  putEntity(`${pathManage}/offers/${id}/stage`, [['stage', stage]], null);

/**
 * Swaps the order of the offer with the order of the other given offer.
 * @param src The ID of the offer.
 * @param dst The ID of the offer to swap the order with.
 * @return {*} Returns a promise with the updated object. Failures to update are treated as errors.
 */
export const swapOfferOrder = (src, dst) =>
  postCall(`${pathManage}/offers/${src}/swap-order`, [['with', dst]], null);

/**
 * Deletes the offer with the given ID.
 * @param id The ID of the offer.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deleteOffer = id => deleteEntity(`${pathManage}/offers/${id}`);

/**
 * Gets the raw offers in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getOffers = (page, query) =>
  getPage(`${pathManage}/offers`, [['page', page], ...query]);

/**
 * Gets all offers as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allOffers = () => getResolution(`${pathManage}/resolve/offers`);

/**
 * Gets offers for the given IDs.
 * @param ids The IDs to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolveOffers = ids => postResolution(`${pathManage}/resolve/offers`, null, ids || []);

/**
 * Resolves a single offer name.
 * @param id The ID of the offer to resolve for.
 * @param signal Abort signal.
 * @returns {Promise<string|null>} Returns the name or null.
 */
export const resolveOfferName = (id, signal) => {
  if (typeof id !== 'string') return null;
  const body = JSON.stringify([id]);
  return apiFetch(`${pathManage}/resolve/offers`, { method: 'POST', signal, body })
    .then(response => completeJson(response))
    .then(elements => elements?.[0]?.name || null)
    .catch(() => null);
};

/**
 * Gets all internal categories as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allInternalCategories = () =>
  getResolution(`${pathManage}/resolve/internal-categories`);

/**
 * Gets internal categories for the given names.
 * @param names The names to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolveInternalCategories = names =>
  postResolution(`${pathManage}/resolve/internal-categories`, null, names || []);

/**
 * Gets all shops as a resolution list with objects giving URI and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allShops = () => getResolution(`${pathManage}/resolve/shops`);

/**
 * Gets internal categories for the given URIs.
 * @param uris The URIs to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolveShops = uris => postResolution(`${pathManage}/resolve/shops`, null, uris || []);
