/**
 * Value for stage when entity is draft.
 * @type {number}
 */
export const stageDraft = 0;

/**
 * Value for stage when entity is approval.
 * @type {number}
 */
export const stageApproval = 1;

/**
 * Value for stage when entity is released.
 * @type {number}
 */
export const stageReleased = 2;

/**
 * Value for stage when entity is archived.
 * @type {number}
 */
export const stageArchived = 3;

/**
 * True if entity's stage is draft.
 */
export const isDraft = entity => stageDraft === entity?.stage;

/**
 * True if entity's stage is approval.
 */
export const isApproval = entity => stageApproval === entity?.stage;

/**
 * True if entity's stage is released.
 */
export const isReleased = entity => stageReleased === entity?.stage;

/**
 * True if entity's stage is archived.
 */
export const isArchived = entity => stageArchived === entity?.stage;

/**
 * True if entity's stage is either draft, approval, or released.
 */
export const isNotArchived = entity => isDraft(entity) || isApproval(entity) || isReleased(entity);

/**
 * True if entity's stage is either draft or approval.
 */
export const isPreRelease = entity => isDraft(entity) || isApproval(entity);
