import React from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

const Table = ({ children, headings }) => {
  return (
    <table className='Table'>
      <thead>
        <tr>{headings}</tr>
      </thead>
      <tbody>
        {!children
          ? null
          : children.map((row, index) => React.cloneElement(row, { alternate: index % 2 === 0 }))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.any,
  headings: PropTypes.any
};

export default Table;
