import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import './Login.scss';
import PageTitle from '../../helpers/pageTitle';
import { notifySuccess } from '../../helpers/notifications';
import { msalOpenLogin } from '../../msal';

const Login = () => {
  // Use login. Connect to perform login by invoking and notifying success.
  const performLogin = async () => {
    await msalOpenLogin();
    notifySuccess('Sie wurden angemeldet.');
  };

  return (
    <main className='Login'>
      <PageTitle title={'Anmelden'} />
      <Container>
        <div className='Login__Wrapper'>
          <div className='Login__Header'>Anmelden</div>
          <div className='Login__Content'>
            <p> Nutzen Sie Ihren Microsoft-Account, um sich am CMS anzumelden.</p>
          </div>
          <div className='Login__Footer'>
            <Button title='Über Microsoft anmelden' onClick={performLogin} />
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Login;
