import { getListing, getPage, pathManage } from './common';

/**
 * Retrieves the related campaign objects for the campaign of the given ID.
 * @param id The ID of the campaign.
 * @return {*} Returns a promise with the related campaign objects.
 */
export const getListingCampaignRelated = id =>
  getListing(`${pathManage}/listing/campaign/${id}/related`);

/**
 * Retrieves the related partner objects for the partner of the given ID.
 * @param id The ID of the partner.
 * @return {*} Returns a promise with the related partner objects.
 */
export const getListingPartnerRelated = id =>
  getListing(`${pathManage}/listing/partner/${id}/related`);

/**
 * Gets the campaigns/offers listing in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getListingCampaignsOffers = (page, query) =>
  getPage(`${pathManage}/listing/campaigns-offers`, [['page', page], ...query]);

/**
 * Gets the listing partners in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getListingPartners = (page, query) =>
  getPage(`${pathManage}/listing/partners`, [['page', page], ...query]);

/**
 * Gets the listing customers in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getListingCustomers = (page, query) =>
  getPage(`${pathManage}/listing/customers`, [['page', page], ...query]);

/**
 * Retrieves the preview listing objects for the offer of the given ID.
 * @param id The ID of the offer.
 * @return {*} Returns a promise with the preview listing objects.
 */
export const getListingOfferPreviews = id =>
  getListing(`${pathManage}/listing/offers/${id}/previews`);

/**
 * Retrieves the inventory listing objects for the offer of the given ID.
 * @param id The ID of the offer.
 * @return {*} Returns a promise with the inventory listing objects.
 */
export const getListingOfferInventory = id =>
  getListing(`${pathManage}/listing/offers/${id}/inventory`);
