import React, { useState } from 'react';
import Container from '../layout/Container';
import Toolbar from '../modules/Toolbar';
import { Paths } from '../../definitions/paths';
import './OfferStatistics.scss';
import PageTitle from '../../helpers/pageTitle';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Table from '../atoms/table/Table';
import TableHead, { fromApiSort } from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';
import { displayTimestamp } from '../../helpers/formatters';
import DateBarChart from '../atoms/DateBarChart';
import TextLink from '../atoms/TextLink';
import useIdParam from '../../hooks/useIdParam';
import { useAbortEffect } from '../../hooks/useAbortEffect';
import { statsOfferActivations, statsOfferAliases, statsOfferUsages } from '../../api/stats';
import PropTypes from 'prop-types';
import Colors from '../../definitions/colors';
import Pagination from '../atoms/Pagination';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import { resolveOfferName } from '../../api/offers';
import { useResolveName } from '../../hooks/useResolveName';
import DayHourHeatChart from '../atoms/DayHourHeatChart';

const formatDe = new Intl.NumberFormat('de-DE');
const format = value => formatDe.format(value);

const OfferStatisticsGraphs = ({ id }) => {
  const [[activations, aliases], setStats] = useState(() => [null, null]);

  useAbortEffect(
    signal => {
      if (!id) return;
      (async () => {
        setStats(
          await Promise.all([statsOfferActivations(id, signal), statsOfferAliases(id, signal)])
        );
      })().catch(() => undefined);
    },
    [id]
  );

  const formatHeadActivations = activations?.total && format(activations.total);
  const formatHeadAliases = aliases?.total && format(aliases.total);

  return (
    <>
      <>
        <div className='OfferStatistics__Screen'>
          <div className='OfferStatistics__ScreenItem OfferStatistics__ScreenItem--Orange'>
            <div className='OfferStatistics__ScreenValue'>{formatHeadActivations || 0}</div>
            <div className='OfferStatistics__ScreenLabel'>durchgeführte Einlösungen</div>
          </div>
          <div className='OfferStatistics__ScreenItem OfferStatistics__ScreenItem--Gray'>
            <div className='OfferStatistics__ScreenValue'>{formatHeadAliases || 0}</div>
            <div className='OfferStatistics__ScreenLabel'>ausstehende Einlösungen</div>
          </div>
        </div>
      </>
      {!activations ? null : (
        <>
          <h2 className='OfferStatistics__Section'>Einlösungen nach Wochentag und Uhrzeit</h2>
          <DayHourHeatChart empty={!activations.total} data={activations?.combined} />
        </>
      )}

      {!activations && !aliases ? null : (
        <>
          <h2 className='OfferStatistics__Section'>Einlösungen nach Datum</h2>
          <DateBarChart
            empty={!activations.total}
            series={[
              {
                name: 'Durchgeführt',
                data: activations.days.map(item => ({
                  x: item.date,
                  y: item.count
                }))
              },
              {
                name: 'Ausstehend',
                color: Colors.GrayLight,
                data: aliases.days.map(item => ({
                  x: item.date,
                  y: item.count
                }))
              }
            ]}
          />
        </>
      )}
    </>
  );
};

OfferStatisticsGraphs.propTypes = {
  id: PropTypes.string.isRequired
};

const OfferStatisticsUsage = ({ id }) => {
  const [usages, setUsages] = useState(() => null);
  const [page, setPage] = useState(() => 0);
  const [total, setTotal] = useState(() => 0);
  const [search] = useState('');
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  usePaginationGuard(page, total, setPage);

  useAbortEffect(
    signal => {
      if (!id) return;
      (async () => {
        setLoading(true);
        const result = await statsOfferUsages(id, { page, search, order }, signal);

        setUsages(result.data);
        setPage(result.pagesAt);
        setTotal(result.pagesCount);
      })()
        .catch(() => undefined)
        .finally(() => setLoading(false));
    },
    [id, page, search, order]
  );

  return (
    <>
      {!usages?.length ? null : (
        <div>
          <h2 className='OfferStatistics__Section'>Einlösungsverlauf</h2>
          <LoadingOverlay show={loading} />
          <Table
            headings={[
              <TableHead
                key={1}
                label='Kunde'
                currentSorting={fromApiSort('customerName', order)}
                onSortAsc={() => setOrder('+customerName')}
                onSortDesc={() => setOrder('-customerName')}
                onSortReset={() => setOrder(null)}
              />,
              <TableHead
                key={2}
                label='Zeitpunkt'
                width={30}
                currentSorting={fromApiSort('at', order)}
                onSortAsc={() => setOrder('+at')}
                onSortDesc={() => setOrder('-at')}
                onSortReset={() => setOrder(null)}
              />
            ]}
          >
            {usages.map((usage, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextLink title={usage.customerName} to={Paths.CustomerDetails(usage.customer)} />
                </TableCell>
                <TableCell>{displayTimestamp(usage.at)}</TableCell>
              </TableRow>
            ))}
          </Table>
          <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
        </div>
      )}
    </>
  );
};
OfferStatisticsUsage.propTypes = {
  id: PropTypes.string.isRequired
};

const OfferStatistics = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Get name of offer.
  const name = useResolveName(resolveOfferName, id);

  return (
    <main className='OfferStatistics'>
      <PageTitle title={`Vorteil auswerten: ${name}`} />

      <Container className='OfferStatistics__ExtraGap'>
        <Toolbar
          title={name ? `„${name}“ auswerten` : null}
          backButton={{
            to: Paths.OfferDetails(id),
            label: 'Vorteil'
          }}
        />

        <OfferStatisticsGraphs id={id} />
        <OfferStatisticsUsage id={id} />
      </Container>
    </main>
  );
};

export default OfferStatistics;
