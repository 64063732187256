import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import Colors from '../../definitions/colors';
import { Article, Euro, Form, Qr } from '../../images/icons';
import './OfferTypePicker.scss';

const OfferTypePicker = ({ value, onUpdateValue }) => {
  const makeButton = (label, iconElement, iconSize, itemValue) => {
    const isActive = value === itemValue;
    return (
      <button
        className={`OfferTypePicker__Button ${isActive ? 'OfferTypePicker__Button--Active' : ''}`}
        onClick={() => onUpdateValue(itemValue)}
        title={label}
      >
        {createElement(iconElement, {
          size: iconSize,
          fill: isActive ? Colors.White : Colors.Orange
        })}
        <div className='OfferTypePicker__ButtonLabel'>{label}</div>
      </button>
    );
  };
  return (
    <div className='OfferTypePicker'>
      {makeButton('QR-Code', Qr, 24, 'qrCode')}
      {makeButton('Shopify-Direktkauf', Euro, 22, 'shopify')}
      {makeButton('Info-Artikel', Article, 22, 'article')}
      {makeButton('Web-Formular', Form, 28, 'form')}
    </div>
  );
};

OfferTypePicker.propTypes = {
  value: PropTypes.oneOf(['qrCode', 'shopify', 'article', 'form']).isRequired,
  onUpdateValue: PropTypes.func.isRequired
};

export default OfferTypePicker;
