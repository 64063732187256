import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Edit } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './HighlightDetails.scss';
import EditsProtocol from '../modules/EditsProtocol';
import PageTitle from '../../helpers/pageTitle';
import DisplayGroup from '../atoms/DisplayGroup';
import TextLink from '../atoms/TextLink';
import HighlightStatus from '../atoms/HighlightStatus';
import FormRow from '../atoms/FormRow';
import ToggleSwitch from '../atoms/input/ToggleSwitch';
import ImagePreview from '../modules/ImagePreview';
import { remoteAction, useEntityView } from './common';
import { useEdits, useHighlight } from '../../hooks/useRemoteValue';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { deleteHighlight, updateHighlightStage } from '../../api/highlights';
import { isPreRelease, isReleased, stageDraft, stageReleased } from '../../helpers/entities';

const HighlightDetails = () => {
  // Select state values
  const id = useIdParam();

  // Use highlight of ID.
  const { entity, loadingEntity, reload } = useEntityView(useHighlight, id);
  const [edits, , loadingEdits, , , reloadEdits] = useEdits(id);

  // Loading state is combination of associated loading states.
  const loading = loadingEntity || loadingEdits;

  const onMode = entity?.on?.campaign?.target ? 'campaign' : 'offer';
  const onElement =
    entity && (onMode === 'campaign' ? entity.on.campaign?.target : entity.on.offer?.target);

  // Deletes the currently viewed partner.
  const handleDelete = remoteAction({
    action: async () => deleteHighlight(id),
    navigateSuccess: Paths.Highlights(),
    messageConfirm: 'Bitte bestätigen Sie das Löschen des Highlights.',
    messageSuccess: 'Highlight gelöscht.',
    messageFail: 'Fehler beim Löschen.'
  });

  // Changes the stage of the highlight.
  const handleChangeMode = remoteAction({
    action: async () => {
      if (isPreRelease(entity)) {
        await updateHighlightStage(id, stageReleased);
        reload();
        reloadEdits();
      } else if (isReleased(entity)) {
        await updateHighlightStage(id, stageDraft);
        reload();
        reloadEdits();
      }
    }
  });

  return (
    <main className='HighlightDetails'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Highlight: ${onElement?.title}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title={onElement?.title}
            backButton={{ to: Paths.Highlights(), label: 'Highlights' }}
            right={[
              <Button
                key={1}
                title='Bearbeiten'
                icon={Edit}
                iconSize={17}
                to={Paths.HighlightEdit(entity?.id)}
              />,
              <Button
                key={2}
                title='Löschen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                onClick={handleDelete}
              />
            ]}
          />

          {!onElement ? null : (
            <DisplayGroup label='Verknüpfter Inhalt'>
              {onMode === 'campaign' && (
                <>
                  Kampagne:{' '}
                  <TextLink title={onElement.title} to={Paths.CampaignDetails(onElement.id)} />
                </>
              )}
              {onMode === 'offer' && (
                <>
                  Vorteil:{' '}
                  <TextLink title={onElement.title} to={Paths.OfferDetails(onElement.id)} />
                </>
              )}
            </DisplayGroup>
          )}
          <DisplayGroup label='Status'>
            <FormRow spaceBetween>
              <HighlightStatus highlight={entity} />
              <ToggleSwitch
                onUpdateValue={handleChangeMode}
                value={isReleased(entity)}
                labelOff='Entwurf'
                labelOn='Aktiv'
              />
            </FormRow>
          </DisplayGroup>
          <DisplayGroup label='Vorschau'>
            <div className='HighlightDetails__Preview'>
              <ImagePreview width={120} height={120} circle images={[entity?.preview]} cover />
              <div className='HighlightDetails__PreviewTitle'>{entity?.previewTitle}</div>
            </div>
          </DisplayGroup>
          <DisplayGroup label='Inhalt'>
            <div className='HighlightDetails__ContentWrapper'>
              <div className='HighlightDetails__Image'>
                <ImagePreview width={135} height={240} images={[entity?.portrait]} cover />
              </div>
              <div className='HighlightDetails__Content'>
                {entity?.badge && <div className='HighlightDetails__Badge'>{entity.badge}</div>}
                <div className='HighlightDetails__Title'>{entity?.title}</div>
                <div className='HighlightDetails__Excerpt'>{entity?.excerpt}</div>
              </div>
            </div>
          </DisplayGroup>

          <EditsProtocol edits={edits} />
        </Container>
      )}
    </main>
  );
};

export default HighlightDetails;
