import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './TextLink.scss';

const TextLink = ({ href, inheritSize, onClick, target, title, to }) => {
  const commonProps = {
    className: `TextLink ${inheritSize ? 'TextLink--InheritSize' : ''}`,
    title
  };

  const props = {
    ...(href && { href, target }),
    ...(onClick && { onClick }),
    ...(to && { to }),
    ...commonProps
  };

  const element = (to && Link) || (href && 'a') || 'button';
  return React.createElement(element, props, title);
};

TextLink.propTypes = {
  href: PropTypes.string,
  inheritSize: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank']),
  title: PropTypes.string.isRequired,
  to: PropTypes.any
};

export default TextLink;
