import {
  deleteEntity,
  getEntity,
  getPage,
  getResolution,
  pathManage,
  postEntity,
  postResolution,
  putEntity
} from './common';

/**
 * Retrieves the specific preview with the given ID.
 * @param id The ID of the preview.
 * @return {*} Returns a promise with the preview. Failures to get are treated as errors.
 */
export const getPreview = id => getEntity(`${pathManage}/previews/${id}`);

/**
 * Creates the given preview.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param preview The preview to add.
 * @return {*} Returns a promise with the created preview. Failures to create are treated as errors.
 */
export const createPreview = preview => postEntity(`${pathManage}/previews`, null, preview);

/**
 * Updates the given preview. The preview should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param preview The preview to update.
 * @return {*} Returns a promise with the updated preview. Failures to create are treated as errors.
 */
export const updatePreview = preview => putEntity(`${pathManage}/previews`, null, preview);

/**
 * Deletes the preview with the given ID.
 * @param id The ID of the preview.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deletePreview = id => deleteEntity(`${pathManage}/previews/${id}`);

/**
 * Gets the raw previews in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getPreviews = (page, query) =>
  getPage(`${pathManage}/previews`, [['page', page], ...query]);

/**
 * Retrieves the specific preview with the link fragment.
 * @param linkFragment The link fragment to follow.
 * @return {*} Returns a promise with the preview. Failures to get are treated as errors.
 */
export const getPreviewPublic = linkFragment =>
  getEntity(`${pathManage}/previews/public/${linkFragment}`);

/**
 * Gets all previews as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allPreviews = () => getResolution(`${pathManage}/resolve/previews`);

/**
 * Gets previews for the given IDs.
 * @param ids The IDs to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolvePreviews = ids =>
  postResolution(`${pathManage}/resolve/previews`, null, ids || []);
