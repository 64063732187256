import { useEffect, useState } from 'react';
import { getPartner, getPartnerSites } from '../api/partners';
import { getEdits } from '../api/managed';
import { getCampaign } from '../api/campaigns';
import { getCustomer } from '../api/customers';
import { getHighlight } from '../api/highlights';
import { getOffer } from '../api/offers';
import { getResource } from '../api/resources';
import { getArchiveCounts, getDashboard } from '../api/dashboard';
import {
  getListingCampaignRelated,
  getListingOfferInventory,
  getListingOfferPreviews,
  getListingPartnerRelated
} from '../api/listing';
import { getCampaignStats, getOfferStats } from '../api/stats';
import { getLastEventOf } from '../api/events';
import { getPreview } from '../api/previews';

/**
 * Uses a remote value that can be fetched by an asynchronous function returning  a promise on the values.
 * @param {function():Promise<*>} get The getter retrieving the item from a passed ID as a promise.
 * @return {function():[*, function(*):void, boolean, Error, *, function():void]} Returns the value, the
 * setter, the loading state, the error, the originally loaded value and a reload handle.
 */
export const useRemote = get => () => {
  // Hook provides value, loading status and error value.
  const [originalValue, setOriginalValue] = useState(null);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reloadToken, setReloadToken] = useState(null);

  // Use change in ID. If ID is present and loadable, dispatch a request to the API.
  useEffect(() => {
    (async () => {
      try {
        // Start loading and mark it.
        setError(null);
        setLoading(true);
        const received = await get();
        setOriginalValue(JSON.parse(JSON.stringify(received)));
        setValue(received);
      } catch (error) {
        // On error, mark in state.
        setError(error);
      } finally {
        // Always finish loading.
        setLoading(false);
      }
    })();
  }, [reloadToken]);

  // Return partner, then loading status and error value.
  // TODO Better pattern for reloading?
  return [value, setValue, loading, error, originalValue, () => setReloadToken(Date.now())];
};

/**
 * Uses a remote value that can be fetched by an asynchronous function taking the ID and returning
 * a promise on the value.
 * @param {function(id:*):Promise<*>} get The getter retrieving the item from a passed ID as a promise.
 * @return {function(id:*):[*, function(*):void, boolean, Error, *]} Returns the value, the
 * setter, the loading state, the error and the originally loaded value.
 */
export const useRemoteValue = get => id => {
  // Hook provides value, loading status and error value.
  const [originalValue, setOriginalValue] = useState(null);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reloadToken, setReloadToken] = useState(null);

  // Use change in ID. If ID is present and loadable, dispatch a request to the API.
  useEffect(() => {
    (async () => {
      // Check if valid and ID given.
      if (id) {
        try {
          // Start loading and mark it.
          setError(null);
          setLoading(true);
          const received = await get(id);
          setOriginalValue(JSON.parse(JSON.stringify(received)));
          setValue(received);
        } catch (error) {
          // On error, mark in state.
          setError(error);
        } finally {
          // Always finish loading.
          setLoading(false);
        }
      } else {
        // Reset on no ID passed.
        setOriginalValue(null);
        setValue(null);
      }
    })();
  }, [id, reloadToken]);

  // Return partner, then loading status and error value.
  return [value, setValue, loading, error, originalValue, () => setReloadToken(Date.now())];
};

/**
 * Uses the dashboard for displaying.
 * @type {function():[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useDashboard = useRemote(getDashboard);

/**
 * Uses the last event of a user for displaying.
 * @type {function(user:*):[*, function(*):void, boolean, Error, *]}
 */
export const useLastEventOf = useRemoteValue(getLastEventOf);

/**
 * Uses the archive counts for displaying.
 * @type {function():[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useArchiveCounts = useRemote(getArchiveCounts);

/**
 * Uses listing related resolution.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 */
export const useListingCampaignRelated = useRemoteValue(getListingCampaignRelated);

/**
 * Uses listing related resolution.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 */
export const useListingPartnerRelated = useRemoteValue(getListingPartnerRelated);

/**
 * Uses listing previews resolution.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 */
export const useListingOfferPreviews = useRemoteValue(getListingOfferPreviews);

/**
 * Uses listing inventory resolution.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 */
export const useListingOfferInventory = useRemoteValue(getListingOfferInventory);

/**
 * Uses a campaign for displaying or editing for the given ID.
 * @param id The ID of the campaign to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useCampaign = useRemoteValue(getCampaign);

/**
 * Uses a customer for displaying or editing for the given ID.
 * @param id The ID of the customer to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useCustomer = useRemoteValue(getCustomer);

/**
 * Uses a highlight for displaying or editing for the given ID.
 * @param id The ID of the highlight to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useHighlight = useRemoteValue(getHighlight);

/**
 * Uses an offer for displaying or editing for the given ID.
 * @param id The ID of the offer to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useOffer = useRemoteValue(getOffer);

/**
 * Uses a partner for displaying or editing for the given ID.
 * @param id The ID of the partner to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and if the currently assigned value
 * was changed.
 */
export const usePartner = useRemoteValue(getPartner);

/**
 Uses the partner sites of the partner with the given ID.
 @param id The ID of the campaign to retrieve.
 * @type {function(*): [*,(function(*): void),boolean,Error,*]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const usePartnerSites = useRemoteValue(getPartnerSites);

/**
 * Uses a preview for displaying or editing for the given ID.
 * @param id The ID of the preview to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const usePreview = useRemoteValue(getPreview);

/**
 * Uses a resource for displaying or editing for the given ID.
 * @param id The ID of the resource to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useResource = useRemoteValue(getResource);

/**
 * Uses the edits of the entity with the given ID.
 * @param id The ID of the entity to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useEdits = useRemoteValue(getEdits);

/**
 * Uses the campaign statistics of the campaign with the given ID.
 * @param id The ID of the campaign to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useCampaignStats = useRemoteValue(getCampaignStats);

/**
 * Uses the offer statistics of the offer with the given ID.
 * @param id The ID of the offer to retrieve.
 * @type {function(id:*):[*, function(*):void, boolean, Error, *]}
 * Returns the value, the setter, the loading state, the error and the originally loaded value.
 */
export const useOfferStats = useRemoteValue(getOfferStats);
