/**
 * True if an object that is not null or an array.
 * @param value The value to check.
 * @return {boolean}
 */
const canMerge = value => 'object' === typeof value && !Array.isArray(value) && value !== null;

/**
 * Recursively merges the object. Does not take care of circular references and is only meant to
 * provide concatenation of nested data objects.
 * @param a The first object, values that can be merged will be overridden.
 * @param b The object that provides extra values to merge.
 * @return {*}
 */
const merge = (a, b) => {
  // Can only merge objects with objects. Ignore arrays.
  if (!canMerge(a) || !canMerge(b)) return b;

  // Start with new empty object.
  const result = {};

  // Add all values from 'a' that do not need to be merged.
  for (const [k, v] of Object.entries(a)) {
    if (!Object.hasOwnProperty.call(b, k)) {
      result[k] = v;
    }
  }

  // Add all value from 'b' that may be merged or are new.
  for (const [k, v] of Object.entries(b)) {
    result[k] = Object.hasOwnProperty.call(a, k) ? merge(a[k], v) : v;
  }

  // Return the created object with all merged values.
  return result;
};

/**
 * Returns a set-state like function that amend the current state.
 * @param {function(existing:*):void} setState The current state.
 * @return {function(changes:*): void} Returns a function that amends the existing object.
 */
export const setAmended = setState => changes => setState(existing => merge(existing, changes));
