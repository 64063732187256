import React, { useId } from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.scss';

const ToggleSwitch = ({ labelOff, labelOn, onUpdateValue, value }) => {
  const id = useId();
  return (
    <div className='ToggleSwitch'>
      {labelOff && <div className='ToggleSwitch__Label ToggleSwitch__Label--Off'>{labelOff}</div>}
      <button
        id={id}
        className={`ToggleSwitch__Toggle ToggleSwitch__Toggle--${value ? 'On' : 'Off'}`}
        onClick={() => onUpdateValue(!value)}
      >
        <div className='ToggleSwitch__Knob' />
      </button>
      {labelOn && (
        <label htmlFor={id} className='ToggleSwitch__Label ToggleSwitch__Label--On'>
          {labelOn}
        </label>
      )}
    </div>
  );
};

ToggleSwitch.propTypes = {
  labelOff: PropTypes.string,
  labelOn: PropTypes.string,
  onUpdateValue: PropTypes.func.isRequired,
  value: PropTypes.bool
};

export default ToggleSwitch;
