import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getHighlights } from '../../api/highlights';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const highlightsLocation = 'highlights';

/**
 * Requests that the given page of the given query is made present.
 */
export const highlightsRequestPage = pagingRequestPage(highlightsLocation, getHighlights);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectHighlights = pagingSelectItems(highlightsLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectHighlightsMode = pagingSelectMode(highlightsLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectHighlightsError = pagingSelectError(highlightsLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectHighlightsQuery = pagingSelectQuery(highlightsLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectHighlightsTotal = pagingSelectTotal(highlightsLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectHighlightsTotalItems = pagingSelectTotalItems(highlightsLocation);

/**
 * Reducer for highlights listing.
 */
export const highlightsReducer = pagingReducer(highlightsLocation);
