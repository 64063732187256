import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Close } from '../../images/icons';
import Colors from '../../definitions/colors';
import './Overlay.scss';

const Overlay = ({ visible, onClose, children, showCloseButton, big }) => {
  const [closing, setClosing] = useState(false);
  useEffect(() => setClosing(false), [visible]);

  useEffect(() => {
    if (closing) {
      const to = setTimeout(onClose, 500);
      return () => clearTimeout(to);
    }
  }, [closing]);
  return (
    <div className={`Overlay ${visible && !closing ? 'Overlay--In' : 'Overlay--Out'}`}>
      <div
        role='dialog'
        className={`Overlay__Window
        ${big ? 'Overlay__Window--Big' : ''}
        ${visible && !closing ? 'Overlay__Window--In' : 'Overlay__Window--Out'}
        `}
      >
        {showCloseButton && (
          <button className='Overlay__Close' title='Schließen' onClick={() => setClosing(true)}>
            <Close size={23} fill={Colors.Orange} />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

Overlay.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  showCloseButton: PropTypes.bool,
  big: PropTypes.bool
};

Overlay.defaultProps = {
  showCloseButton: true
};

export default Overlay;
