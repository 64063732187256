import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './reducers';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { cmsStore } from '../components/App';

/**
 * Initialize logger with collapsed mode and printing diffs.
 */
const logger = createLogger({ collapsed: true, diff: true });

/**
 * Persistence configuration.
 * @type {{storage, key: string}}
 */
const persistence = { storage, key: 'cms' };

export const createCmsStore = () =>
  createStore(persistReducer(persistence, rootReducer), applyMiddleware(thunk, logger));

export const createCmsPersistor = () => persistStore(cmsStore);
