import { notifyError, notifySuccess } from '../../helpers/notifications';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { setAmended } from '../../util/setAmended';

// TODO Modeling here: should we return objects? Objects better if some parts are not needed, arrays easier for renaming.

/**
 * Given the resolver and the ID, returns the name presented by the resolved element or the ID as a fallback.
 * @param {function(keys:*[]):[*, boolean, Error]} useResolve The resolver, useCampaignsBy etc.
 * @param {*} id The ID to resolve.
 * @return {{name: *, loading: *, error: *}} Returns the name, loading state and error.
 */
export const useName = (useResolve, id) => {
  const [values, loading, error] = useResolve(id ? [id] : null);
  const name = values?.find(value => id === value.id)?.name || id;
  return { name, loading, error };
};

// TODO: Common error?

/**
 *
 * @param {function(id:*):[*, function(*):void, boolean, Error, *]} useEntity Entity loading hook.
 * @param {*} id The ID to resolve.
 * @param {{messageFail:string=}=} options The options.
 * @return {{id:*,entity:*,loading:boolean,error:Error,reload:function():void}}
 */
export const useEntityView = (useEntity, id, options) => {
  // Read options or set defaults.
  const messageFail = options?.messageFail || 'Das Objekt konnte nicht geladen werden.';

  // Use entity as given by the getter.
  const [entity, , loading, error, , reload] = useEntity(id);

  // Notify errors if present.
  useEffect(() => {
    if (error) notifyError(messageFail);
  }, [error]);

  // Map outputs.
  return { id, entity, loading, error, reload };
};

/**
 *
 * @param {function(id:*):[*, function(*):void, boolean, Error, *]} useEntity Entity loading hook.
 * @param {*} id The ID to resolve.
 * @param {{messageFail:string=,init:*=,template?:string,templateTransform?:function(*):*}=} options The options.
 * @return {{id:*,isNew:boolean,entity:*,setEntity:function(entity:*):void,amendEntity:function(changes:*):void,loading:boolean,error:Error,original:*,changed:boolean,reload:function():void}}
 */
export const useEntityEdit = (useEntity, id, options) => {
  // Read options or set defaults.
  const messageFail = options?.messageFail || 'Das Objekt konnte nicht geladen werden.';

  // Use entity as given by the getter, memorize if new.
  const isNew = !id;
  const [entity, setEntity, loading, error, original, reload] = useEntity(id);

  // Use given template if defined.
  const [template] = useEntity(options?.template);

  // Connect initializing new entities.
  useEffect(() => {
    // Not new, nothing to do.
    if (!isNew) return;

    // Check initialization mode.
    if (options?.template) {
      // Init by template, set when loaded. Optionally transform.
      if (template)
        setEntity(
          typeof options?.templateTransform === 'function'
            ? options?.templateTransform(template)
            : template
        );
    } else if (options?.init) {
      // Require to be function.
      if (typeof options.init !== 'function')
        throw new Error('Invalid parameter, init must be a function');

      // Init by object.
      setEntity(options?.init());
    }
  }, [isNew, template, options?.init]);

  // Notify load failures.
  useEffect(() => {
    if (error) notifyError(messageFail || 'Das Objekt konnte nicht geladen werden.');
  }, [error]);

  // Derive some properties.
  const amendEntity = setAmended(setEntity);
  const changed = JSON.stringify(original) !== JSON.stringify(entity);

  return {
    id,
    isNew,
    entity,
    setEntity,
    amendEntity,
    loading,
    error,
    original,
    changed,
    reload
  };
};

// TODO: Loading indicator when saving.

/**
 * Performs a remote action with some confirmation, success and failure messages, as well as navigation.
 * @param {(function(...): Promise<void>)} action The action to invoke. Receives the arguments of the returned function.
 * @param messageFail The message to display on failure, if any.
 * @param navigateSuccess The navigation to perform on success, if any.
 * @param messageConfirm The message to display when confirmation is needed, if any.
 * @param messageSuccess The message to display on success, if any.
 * @param navigateFail The navigation to perform on failure, if any.
 * @return {(function(...): Promise<void>)}
 */
export const remoteAction = ({
  action,
  navigateSuccess,
  navigateFail,
  messageConfirm,
  messageSuccess,
  messageFail
}) => {
  // Get history.
  const navigate = useNavigate();

  return async (...args) => {
    // If confirm required, confirm with message, otherwise return.
    if (messageConfirm && !confirm(messageConfirm)) return;

    try {
      // Invoke action.
      const result = await action(...args);

      // Mark success if given. Apply function to result if given as function.
      if (typeof messageSuccess === 'function') notifySuccess(messageSuccess(result));
      else if (typeof messageSuccess === 'string') notifySuccess(messageSuccess);

      // Navigate if given. Apply function to result if given as function.
      if (typeof navigateSuccess === 'function') navigate(navigateSuccess(result));
      else if (typeof navigateSuccess === 'string') navigate(navigateSuccess);
    } catch (error) {
      // Mark failure if given. Apply function to error if given as function.
      if (typeof messageFail === 'function') notifyError(messageFail(error));
      else if (typeof messageFail === 'string') notifyError(messageFail);

      // Navigate if given. Apply function to error if given as function.
      if (typeof navigateFail === 'function') navigate(navigateFail(error));
      else if (typeof navigateFail === 'string') navigate(navigateFail);
    }
  };
};
