export const Paths = {
  /* General */
  Login: () => `/login`,
  Dashboard: () => `/dashboard`,
  ValueDashboard: () => `/value-dashboard`,

  /* Partners */
  Partners: () => `/partners`,
  PartnerCreate: () => `/partners/create`,
  PartnerDetails: id => `/partners/${id || ':id'}`,
  PartnerEdit: id => `/partners/${id || ':id'}/edit`,

  /* Campaigns */
  Campaigns: () => `/campaigns`,
  CampaignsArchive: () => `/campaigns/archive`,
  CampaignCreate: () => `/campaigns/create`,
  CampaignDetails: id => `/campaigns/${id || ':id'}`,
  CampaignStatistics: id => `/campaigns/${id || ':id'}/statistics`,
  CampaignEdit: id => `/campaigns/${id || ':id'}/edit`,

  /* Offers */
  OfferCreate: campaignId =>
    `/campaigns/offers/create${campaignId ? `?useCampaign=${campaignId}` : ''}`,
  OfferDetails: id => `/campaigns/offers/${id || ':id'}`,
  OfferStatistics: id => `/campaigns/offers/${id || ':id'}/statistics`,
  OfferEdit: id => `/campaigns/offers/${id || ':id'}/edit`,
  OfferDuplicate: offerId => `/campaigns/offers/create${offerId ? `?useTemplate=${offerId}` : ''}`,
  OfferPreviewCMS: alias => `/preview/${alias || ':alias'}`,
  OfferPreviewPortal: alias => `${process.env.REACT_APP_PORTAL}/preview/${alias || ':alias'}`,

  /* Highlights */
  Highlights: () => `/highlights`,
  HighlightCreate: () => `/highlights/create`,
  HighlightDetails: id => `/highlights/${id || ':id'}`,
  HighlightEdit: id => `/highlights/${id || ':id'}/edit`,

  /* Customers */
  Customers: () => `/customers`,
  CustomerDetails: id => `/customers/${id || ':id'}`,
  CustomerEdit: id => `/customers/${id || ':id'}/edit`,

  /* Resources */
  Resources: () => `/resources`,
  ResourceEdit: id => `/resources/${id || ':id'}/edit`
};

export const makeAbsolute = path => `${process.env.REACT_APP_CMS.replace(/\/$/g, '')}${path}`;
