import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import './ImageUpload.scss';
import { useDropzone } from 'react-dropzone';
import { notifyError } from '../../../helpers/notifications';
import CircleSpinner from '../CircleSpinner';
import { uploadFile } from '../../../api/common';

const ImageUpload = ({ circle, disabled, height, label, onUploadedImage, width }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const onDropAccepted = useCallback(async acceptedFiles => {
    const image = acceptedFiles[0];
    setUploading(true);
    try {
      const result = await uploadFile(image);
      setUploadedFile(result);
    } catch (error) {
      notifyError('Beim Hochladen der Datei ist ein Fehler aufgetreten.');
    } finally {
      setUploading(false);
    }
  }, []);

  const onDropRejected = useCallback(e => {
    e?.length > 1
      ? notifyError('Sie können nur eine Datei gleichzeitig hochladen.')
      : notifyError('Es sind nur JPEG- oder PNG-Dateien erlaubt.');
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: {
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg']
    },
    disabled: uploading,
    maxFiles: 1
  });

  // Handle back uploaded file after short timeout, then reset
  useEffect(() => {
    if (uploadedFile) {
      const to = setTimeout(() => {
        onUploadedImage(uploadedFile);
        setUploadedFile(null);
      }, 1000);
      return () => clearTimeout(to);
    }
  }, [uploadedFile]);

  return (
    <div
      className={`ImageUpload
        ${disabled ? 'ImageUpload--Disabled' : ''}
        ${circle ? 'ImageUpload--Circle' : ''}
        ${isDragActive ? 'ImageUpload--Dragging' : ''}
        ${uploading || uploadedFile ? 'ImageUpload--Uploading' : ''}
      `}
      style={{ height, width }}
      {...getRootProps({ role: 'button', tabIndex: 0 })}
    >
      <input {...getInputProps()} />
      <Upload fill={isDragActive ? Colors.White : Colors.Orange} size={20} />
      <div className='ImageUpload__Label'>{label}</div>
      <div
        className={`ImageUpload__Uploading ${
          uploading || uploadedFile ? 'ImageUpload__Uploading--Visible' : ''
        }`}
      >
        <CircleSpinner size={height <= 100 ? height * 0.7 : 70} complete={uploadedFile !== null} />
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onUploadedImage: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};

export default ImageUpload;
