/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const uiLocation = 'ui';

/**
 * Initial state of the UI reducer.
 * @type {Readonly<{openedCampaigns: []}>}
 */
export const initialState = Object.freeze({
  /**
   * Array of campaign ids that are currently unfolded
   */
  openedCampaigns: []
});

/**
 * Opens or closes a campaign
 * @param campaignId The campaign id to toggle.
 * @param open The toggle state as a boolean value.
 * @return {{payload: {campaignId, open}, type: string}}
 */
export const setCampaignOpen = (campaignId, open) => ({
  type: 'ui/campaign/TOGGLE',
  payload: { campaignId, open }
});

/**
 * Selects the current opening status of a specific campaign id
 * @param campaignId The campaign id to look out for.
 * @return {(state)=>boolean}
 */
export const selectCampaignOpen = campaignId => state =>
  state[uiLocation].openedCampaigns.includes(campaignId);

export const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'ui/campaign/TOGGLE': {
      const newOpenedCampaigns = [...state.openedCampaigns];
      if (payload.open) {
        // Add campaign id if it does not exist yet
        if (!newOpenedCampaigns.includes(payload.campaignId)) {
          newOpenedCampaigns.push(payload.campaignId);
        }
      } else {
        // Remove campaign id if it still exists
        const index = newOpenedCampaigns.indexOf(payload.campaignId);
        if (index >= 0) {
          newOpenedCampaigns.splice(index, 1);
        }
      }
      return {
        ...state,
        openedCampaigns: newOpenedCampaigns
      };
    }
    default:
      return state;
  }
};
