import React from 'react';
import PropTypes from 'prop-types';
import { displayFixedDecimalNumber, displayPercentage } from '../../helpers/formatters';
import './Trend.scss';
import Colors from '../../definitions/colors';

export const Trend = ({
  value,
  valueBefore,
  fractionalDigits = 3,
  sensitivity = 0.1,
  percentage = false
}) => {
  const sign = Math.sign(Math.round((value - valueBefore) / sensitivity));
  const signColor = [Colors.Orange, Colors.Black, Colors.Green][1 + sign];
  const signText = [<>&#8600;</>, <>&#8594;</>, <>&#8599;</>][1 + sign];
  return (
    <span className='Trend' style={{ color: signColor }}>
      <span className='Trend__Sign'>{signText}</span>
      {percentage ? (
        <span className='Trend__Text'>{displayPercentage(value, fractionalDigits)}</span>
      ) : (
        <span className='Trend__Text'>{displayFixedDecimalNumber(value, fractionalDigits)}</span>
      )}
    </span>
  );
};

Trend.propTypes = {
  value: PropTypes.number.isRequired,
  valueBefore: PropTypes.number.isRequired,
  fractionalDigits: PropTypes.number,
  sensitivity: PropTypes.number,
  percentage: PropTypes.bool
};
