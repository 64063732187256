import React, { useEffect, useRef } from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import './Highlights.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Toolbar from '../modules/Toolbar';
import { Plus } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import PageTitle from '../../helpers/pageTitle';
import {
  highlightsRequestPage,
  selectHighlights,
  selectHighlightsMode,
  selectHighlightsTotal,
  selectHighlightsTotalItems
} from '../../redux/reducers/highlights';
import HighlightPanel from '../modules/HighlightPanel';
import { Mode } from '../../redux/reducers/mode';
import { useScrollTo } from '../../hooks/useScrollTo';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import Pagination from '../atoms/Pagination';
import { remoteAction } from './common';
import { swapHighlightOrder } from '../../api/highlights';
import SearchBar from '../atoms/SearchBar';
import ItemCount from '../atoms/ItemCount';
import { useSessionState } from '../../hooks/useSessionState';

const Highlights = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useSessionState('Highlights/page', 0);
  const [search, setSearch] = useSessionState('Highlights/search', '');

  // Select state values
  const items = useSelector(selectHighlights(page));
  const mode = useSelector(selectHighlightsMode(page));
  // const error = useSelector(selectHighlightsError(page));
  const total = useSelector(selectHighlightsTotal);
  const totalItems = useSelector(selectHighlightsTotalItems);
  const reload = () => dispatch(highlightsRequestPage(page, [search && ['search', search]]));

  // Scroll to table upon page flip
  const scrollAnchor = useRef(null);
  useScrollTo(scrollAnchor, page);

  // Do not exceed page limit
  usePaginationGuard(page, total, setPage);

  // Make page current with given index and query.
  useEffect(() => {
    reload();
  }, [page, search]);

  const reorder = remoteAction({
    action: async (src, dst) => {
      await swapHighlightOrder(src, dst);
      reload();
    },
    messageSuccess: 'Highlight verschoben.',
    messageFail: 'Highlight konnte nicht verschoben werden.'
  });

  return (
    <main className='Highlights'>
      <PageTitle title={'Story-Highlights'} />
      <LoadingOverlay show={mode !== Mode.Idle} />
      <Container>
        <Toolbar
          title='Story-Highlights'
          left={[
            <SearchBar key={1} term={search} onUpdateTerm={setSearch} />,
            <ItemCount key={2} count={totalItems} />
          ]}
          right={[
            <Button
              key={1}
              title='Neues Highlight'
              icon={Plus}
              iconSize={15}
              to={Paths.HighlightCreate()}
            />
          ]}
        />
        {items &&
          items.map((highlight, index) => (
            <HighlightPanel
              key={highlight.id}
              highlight={highlight}
              onMoveUp={index > 0 ? () => reorder(highlight.id, items[index - 1].id) : undefined}
              onMoveDown={
                index < items.length - 1
                  ? () => reorder(highlight.id, items[index + 1].id)
                  : undefined
              }
            />
          ))}
        <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
      </Container>
    </main>
  );
};

export default Highlights;
