import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import './DropArea.scss';

const DropArea = ({ index, width, height, onDrop }) => {
  const [{ isOver, isDragging, canDrop }, drop] = useDrop(
    () => ({
      accept: 'Image',
      drop: item => onDrop(item.currentIndex, index),
      canDrop: item => item.currentIndex !== index && item.currentIndex !== index - 1,
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDragging: monitor.getItemType() === 'Image'
      })
    }),
    [index, onDrop]
  );
  return (
    <div
      className={`DropArea
      ${isDragging && !isOver ? 'DropArea--Visible' : ''}
      ${isOver && canDrop ? 'DropArea--Ready' : ''}
      ${!isDragging && !isOver ? 'DropArea--Standby' : ''}
      `}
      style={{ height }}
    >
      <div
        className='DropArea__Drop'
        style={{ width: width + 20, left: -0.5 * width }}
        ref={drop}
      />
      <div className='DropArea__Indicator' />
    </div>
  );
};

DropArea.propTypes = {
  index: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired
};

export default DropArea;
