import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import CircleIcon from '../CircleIcon';
import { Down } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import './Dropdown.scss';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { keySelect } from '../../../util/handlers';

const Dropdown = ({
  nullOptionLabel,
  onUpdateValue,
  options,
  showLabel,
  title,
  tooltip,
  value
}) => {
  const dropdown = useRef(null);
  const [visible, setVisible] = useState(false);
  useClickOutside(dropdown, () => setVisible(false));

  const currentText =
    options?.find(option => option.value === value)?.label ||
    `< ${nullOptionLabel || 'Bitte wählen '} >`;

  const pickValue = value => {
    onUpdateValue(value);
    setVisible(false);
  };

  const optionItem = (itemValue, itemLabel) => {
    const selectItem = () => pickValue(itemValue);
    return (
      <button
        key={itemValue}
        tabIndex={visible ? 0 : -1}
        onClick={selectItem}
        onKeyDown={keySelect(selectItem)}
        className={`Dropdown__Option
          ${itemValue === value ? 'Dropdown__Option--Current' : ''}
          ${itemValue === null ? 'Dropdown__Option--Null' : ''}
        `}
      >
        {itemLabel}
      </button>
    );
  };

  const toggleVisible = () => setVisible(!visible);

  return (
    <div className={`Dropdown ${visible ? 'Dropdown--Open' : ''}`} ref={dropdown}>
      <div
        className='Dropdown__CurrentWrapper'
        onClick={toggleVisible}
        onKeyDown={keySelect(toggleVisible)}
        tabIndex={0}
        role='button'
        aria-expanded={visible}
      >
        {showLabel && (
          <div className='Dropdown__Label'>
            <span>{title}</span>
            {tooltip && <Tooltip content={tooltip} />}
          </div>
        )}
        <div
          className={`Dropdown__CurrentText ${value === null ? 'Dropdown__CurrentText--Null' : ''}`}
        >
          {currentText}
        </div>
        <div className='Dropdown__Caret'>
          <CircleIcon icon={<Down size={14} fill={Colors.White} />} size={28} offsetY={1} />
        </div>
      </div>
      <div
        className={`Dropdown__List ${visible ? 'Dropdown__List--Open' : ''}`}
        role='presentation'
        tabIndex={-1}
      >
        {nullOptionLabel && optionItem(null, `< ${nullOptionLabel} >`)}
        {options && options.map(option => optionItem(option.value, option.label))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  nullOptionLabel: PropTypes.string,
  onUpdateValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.any
};

export default Dropdown;
