import { msalGetToken } from '../msal';

const bodyJson = body => (body === undefined || body === null ? body : JSON.stringify(body));

const bodyJsonHeader = body =>
  body === undefined || body === null ? {} : { 'Content-Type': 'application/json' };

const optionsCommon = Object.freeze({
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow'
});

/**
 * Handler returns the JSON content and serializes the body as JSON. If the response cannot be
 * parsed to JSON, returns null.
 * @param url The URL to request.
 * @param method The HTTP method.
 * @param body The body or null or undefined.
 * @param headers The extra headers.
 * @return {Promise<any>} Returns a promise to the data or null if unable to parse as JSON.
 */
export const jsonOptional = (url, method, body, headers) =>
  fetch(url.toString(), {
    method,
    body: bodyJson(body),
    headers: {
      Accept: 'application/json',
      ...bodyJsonHeader(body),
      ...headers
    },
    ...optionsCommon
  }).then(async response => {
    if (response.ok) return await response.json().catch(() => null);
    else throw new Error(await response.text().catch(() => response.statusText));
  });

/**
 * Handler returns the JSON content and serializes the body as JSON.
 * @param url The URL to request.
 * @param method The HTTP method.
 * @param body The body or null or undefined.
 * @param headers The extra headers.
 * @return {Promise<any>} Returns a promise to the data.
 */
export const json = (url, method, body, headers) =>
  fetch(url.toString(), {
    method,
    body: bodyJson(body),
    headers: {
      Accept: 'application/json',
      ...bodyJsonHeader(body),
      ...headers
    },
    ...optionsCommon
  }).then(async response => {
    if (response.ok) return await response.json();
    else throw new Error(await response.text().catch(() => response.statusText));
  });

/**
 * Handler uploads the given file to the backend and returns the deserialized JSON response.
 * @param url The URL to send the file to
 * @param method The HTTP method.
 * @param body The FormData object with the content to upload
 * @param headers The extra headers.
 * @returns {Promise<any>} Returns a promise to the data.
 */
export const upload = (url, method, body, headers) =>
  fetch(url.toString(), {
    method,
    body,
    headers: {
      Accept: 'application/json',
      ...headers
    },
    ...optionsCommon
  }).then(async response => {
    if (response.ok) return await response.json();
    else throw new Error(await response.text().catch(() => response.statusText));
  });

/**
 * Handler returns the JSON content and the page information and serializes the body as JSON.
 * @param url The URL to request.
 * @param method The HTTP method.
 * @param body The body or null or undefined.
 * @param headers The extra headers.
 * @return {Promise<{data: *, pagesCount: *, resultsCount: *, pagesAt: *}>}
 */
export const jsonPage = (url, method, body, headers) =>
  fetch(url.toString(), {
    method,
    body: bodyJson(body),
    headers: {
      Accept: 'application/json',
      ...bodyJsonHeader(body),
      ...headers
    },
    ...optionsCommon
  }).then(async response => {
    if (response.ok)
      // Data OK, return content.
      return {
        data: await response.json(),
        pagesAt: Number(response.headers.get('X-Pages-At')),
        pagesCount: Number(response.headers.get('X-Pages-Count')),
        resultsCount: Number(response.headers.get('X-Results-Count'))
      };
    if (404 === response.status)
      // Data not found, return empty object.
      return {
        data: [],
        pagesAt: 0,
        pagesCount: 0,
        resultsCount: 0
      };
    else throw new Error(await response.text().catch(() => response.statusText));
  });

/**
 * API base address.
 */
export const apiBase = process.env.REACT_APP_API;

/**
 * Infix for manage operations.
 */
export const pathManage = process.env.REACT_APP_API_PATH_MANAGE ?? 'manage';

/**
 * Infix for event operations.
 */
export const pathEvents = process.env.REACT_APP_API_PATH_EVENTS ?? 'events';

/**
 * Infix for analyze operations.
 */
export const pathAnalyze = process.env.REACT_APP_API_PATH_ANALYZE ?? 'analyze';

/**
 * API subscription key.
 */
const apiKey = process.env.REACT_APP_API_KEY;

/**
 * Creates an API method.
 * @param {function(url:*, method:string, body:*, headers:*):*} handler The handler, usually json.
 * @param {string} method The HTTP method.
 * @return {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const apiMethod = (handler, method) => (path, query, body, headers) => {
  // Make URL and append subscription key.
  const url = new URL(`${apiBase}/${path.startsWith('/') ? path.substring(1) : path}`);

  // If query is given, append pairs.
  if (query) {
    for (const [k, v] of Array.isArray(query) ? query : Object.entries(query)) {
      url.searchParams.append(k, `${v}`);
    }
  }

  // Run with handler after token.
  return msalGetToken().then(token => {
    // Fast-track no authentication present.
    if (!token) return null;

    // Delegated fetch.
    return handler(url, method, body, {
      // Always add subscription key.
      'Ocp-Apim-Subscription-Key': apiKey,

      // Add bearer token if given.
      ...(token && { Authorization: `Bearer ${token}` }),

      // Add user headers, overriding any headers already assigned.
      ...headers
    });
  });
};

/**
 * Fetch with API settings. This is a preliminary implementation testing new structuring of APIs.
 * @param {RequestInfo|string} info The request info or URL or API path.
 * @param {(RequestInit&{query?:object})?} init The init object. May also contain
 *  query. Any required header that is not present will be added.
 */
export const apiFetch = (info, init) => {
  // Make URL from source field or source string.
  const url =
    typeof info === 'string'
      ? new URL(!info.startsWith('https://') ? `${apiBase}/${info}` : info)
      : new URL(info.url?.startsWith('https://') === false ? `${apiBase}/${info.url}` : info.url);

  // Append query if given in init.
  if (typeof init?.query === 'object') {
    for (const [k, v] of Array.isArray(init.query) ? init.query : Object.entries(init.query)) {
      if (k === undefined || k === null) continue;
      if (v === undefined || v === null) continue;
      url.searchParams.append(k, `${v}`);
    }
    delete init.query;
  }

  // Replace original URL after optionally composing query parameters.
  if (typeof info === 'string') info = url.toString();
  else info.url = url.toString();

  // Default init object states. Assert init is object and init's headers is an object.
  if (typeof init !== 'object' || init === null) init = {};
  if (typeof init.headers !== 'object' || init.headers === null) init.headers = {};

  // Default flags.
  if (typeof init.method !== 'string') init.method = 'GET';
  if (typeof init.cache !== 'string') init.cache = 'no-cache';
  if (typeof init.credentials !== 'string') init.credentials = 'same-origin';
  if (typeof init.redirect !== 'string') init.redirect = 'follow';

  // Default headers.
  if (typeof init.headers['Ocp-Apim-Subscription-Key'] !== 'string')
    init.headers['Ocp-Apim-Subscription-Key'] = apiKey;
  if (typeof init.headers['Accept'] !== 'string') init.headers['Accept'] = 'application/json';
  if (typeof init.headers['Content-Type'] !== 'string')
    init.headers['Content-Type'] = 'application/json';

  // Get current token.
  return msalGetToken().then(token => {
    // Fast-track no authentication present.
    if (!token) return null;

    // Assign token from MSAL.
    init.headers['Authorization'] = `Bearer ${token}`;

    // Delegated fetch.
    return fetch(info, init);
  });
};

/**
 * Response with an error text.
 */
class ErrorResponse extends Error {
  /**
   * Initializes the error.
   * @param {string|null} text The response text or null.
   * @param status The status number.
   * @param statusText The status text.
   */
  constructor(text, status, statusText) {
    super();
    this.text = text;
    this.status = status;
    this.statusText = statusText;
  }

  toString() {
    return this.text ?? this.statusText;
  }
}

/**
 * Completes the response for it's JSON content.
 * @param {Response} response The incoming response.
 * @return {Promise<*>} Returns the JSON content if possible.
 */
export const completeJson = async response => {
  // Response is OK. Return JSON content.
  if (response.ok) return await response.json();

  // Response is not OK. Throw with optional text.
  throw new ErrorResponse(
    await response.text().catch(() => null),
    response.status,
    response.statusText
  );
};

/**
 * Completes the response for it's JSON content.
 * @param {Response} response The incoming response.
 * @return {Promise<{data: *, pagesAt: number, pagesCount: number, resultsCount: number}>} Returns the JSON content if possible.
 */
export const completeJsonPage = async response => {
  // Response is OK. Return JSON content and page info.
  if (response.ok) {
    return {
      data: await response.json(),
      pagesAt: Number(response.headers.get('X-Pages-At')),
      pagesCount: Number(response.headers.get('X-Pages-Count')),
      resultsCount: Number(response.headers.get('X-Results-Count'))
    };
  }

  // Response is not OK. Throw with optional text.
  throw new ErrorResponse(
    await response.text().catch(() => null),
    response.status,
    response.statusText
  );
};

/**
 * Returns a GET method API accessor that works on paging data.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const getPage = apiMethod(jsonPage, 'GET');

/**
 * Returns a GET method API accessor for entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const getEntity = apiMethod(json, 'GET');

/**
 * Returns a POST method API accessor for entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const postEntity = apiMethod(json, 'POST');

/**
 * Returns a PUT method API accessor for entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const putEntity = apiMethod(json, 'PUT');

/**
 * Returns a DELETE method API accessor for entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const deleteEntity = apiMethod(jsonOptional, 'DELETE');

/**
 * Returns a GET method API accessor for listings. For now functionally not different from
 * getting entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const getListing = apiMethod(json, 'GET');

/**
 * Returns a GET method API accessor for events. For now functionally not different from
 * getting entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const getEvent = apiMethod(json, 'GET');

/**
 * Returns a GET method API accessor for stats. For now functionally not different from
 * getting entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const getStats = apiMethod(json, 'GET');

/**
 * Returns a GET method API accessor for resolutions. For now functionally not different from
 * getting entities.
 * @type {function(path:string, query:*=, body:*=, headers:*=): *}
 */
export const getResolution = apiMethod(json, 'GET');

/**
 * Returns a POST method API accessor for direct calls. For now functionally not different from
 * posting entities.
 * @type {function(*, *=, *=, *=): *}
 */
export const postCall = apiMethod(json, 'POST');

/**
 * Returns a POST method API accessor for resolutions. For now functionally not different from
 * posting entities.
 * @type {function(*, *=, *=, *=): *}
 */
export const postResolution = apiMethod(json, 'POST');

// TODO: Fix pattern.
export const uploadFile = file => {
  // Make URL and append subscription key.
  const url = new URL(`${apiBase}/${pathManage}/media`);

  // Create form data for POST request
  const data = new FormData();
  data.append('file', file);

  // Run with handler.
  return msalGetToken().then(token => {
    // Fast-track no authentication present.
    if (!token) return;

    // Delegated upload.
    return upload(url, 'POST', data, {
      'Ocp-Apim-Subscription-Key': apiKey,

      // Add bearer token if given.
      ...(token && { Authorization: `Bearer ${token}` })
    });
  });
};
