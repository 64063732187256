import {
  deleteEntity,
  getEntity,
  getPage,
  getResolution,
  pathManage,
  postEntity,
  postResolution,
  putEntity
} from './common';

/**
 * Retrieves the specific customer with the given ID.
 * @param id The ID of the customer.
 * @return {*} Returns a promise with the customer. Failures to get are treated as errors.
 */
export const getCustomer = id => getEntity(`${pathManage}/customers/${id}`);

/**
 * Creates the given customer.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param customer The customer to add.
 * @return {*} Returns a promise with the created customer. Failures to create are treated as errors.
 */
export const createCustomer = customer => postEntity(`${pathManage}/customers`, null, customer);

/**
 * Updates the given customer. The customer should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param customer The customer to update.
 * @return {*} Returns a promise with the updated customer. Failures to create are treated as errors.
 */
export const updateCustomer = customer => putEntity(`${pathManage}/customers`, null, customer);

/**
 * Deletes the customer with the given ID.
 * @param id The ID of the customer.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deleteCustomer = id => deleteEntity(`${pathManage}/customers/${id}`);

/**
 * Gets the raw customers in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getCustomers = (page, query) =>
  getPage(`${pathManage}/customers`, [['page', page], ...query]);

/**
 * Gets all interests as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allInterests = () => getResolution(`${pathManage}/resolve/interests`);

/**
 * Gets interests for the given names.
 * @param names The names to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolveInterests = names =>
  postResolution(`${pathManage}/resolve/interests`, null, names || []);
