import React, { useEffect, useRef } from 'react';
import Container from '../layout/Container';
import './Resources.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Pagination from '../atoms/Pagination';
import { useScrollTo } from '../../hooks/useScrollTo';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import Toolbar from '../modules/Toolbar';
import ItemCount from '../atoms/ItemCount';
import SearchBar from '../atoms/SearchBar';
import Table from '../atoms/table/Table';
import TableHead, { fromApiSort } from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';
import PageTitle from '../../helpers/pageTitle';
import { useResourceTarget } from '../../hooks/useResourceTarget';
import {
  resourcesRequestPage,
  selectResources,
  selectResourcesMode,
  selectResourcesTotal,
  selectResourcesTotalItems
} from '../../redux/reducers/resources';
import RadioButtonBar from '../atoms/input/RadioButtonBar';
import TextRenderer from '../layout/TextRenderer';
import AutoEllipsis from '../layout/AutoEllipsis';
import { Edit } from '../../images/icons';
import IconButton from '../atoms/IconButton';
import Colors from '../../definitions/colors';
import { Paths } from '../../definitions/paths';
import { useSessionState } from '../../hooks/useSessionState';

const Resources = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useSessionState('Resources/page', 0);
  const [search, setSearch] = useSessionState('Resources/search', '');
  const [order, setOrder] = useSessionState('Resources/order', null);
  const [target, setTarget] = useSessionState('Resources/target', null);

  // Select state values
  const items = useSelector(selectResources(page));
  const mode = useSelector(selectResourcesMode(page));
  // const error = useSelector(selectResourcesError(page));
  const total = useSelector(selectResourcesTotal);
  const totalItems = useSelector(selectResourcesTotalItems);

  // Scroll to table upon page flip
  const scrollAnchor = useRef(null);
  useScrollTo(scrollAnchor, page);

  // Do not exceed page limit
  usePaginationGuard(page, total, setPage);

  // Make page current with given index and query.
  useEffect(() => {
    dispatch(
      resourcesRequestPage(page, [
        search && ['search', search],
        order && ['order', order],
        target !== null && ['target', target]
      ])
    );
  }, [page, search, order, target]);

  return (
    <main className='Resources'>
      <PageTitle title={'Textbausteine'} />
      <LoadingOverlay show={mode === 'INITIALIZING'} />
      <Container>
        <Toolbar
          title='Textbausteine'
          left={[
            <RadioButtonBar
              key={1}
              value={target}
              onUpdateValue={setTarget}
              options={[
                { value: null, label: 'Alle' },
                { value: 1, label: 'Kundenportal' },
                { value: 2, label: 'evm-App' },
                { value: 4, label: 'Website' },
                { value: 8, label: 'Partner-App' }
              ]}
            />
          ]}
          right={[
            <SearchBar key={1} term={search} onUpdateTerm={setSearch} />,
            <ItemCount key={2} count={totalItems} />
          ]}
        />
        <div className='Partners__TableWrapper' ref={scrollAnchor}>
          <Table
            headings={[
              <TableHead
                key={1}
                label='Identifier'
                width={25}
                currentSorting={fromApiSort('identifier', order)}
                onSortAsc={() => setOrder('+identifier')}
                onSortDesc={() => setOrder('-identifier')}
                onSortReset={() => setOrder(null) /* default */}
              />,
              <TableHead
                key={2}
                label='Text'
                currentSorting={fromApiSort('content', order)}
                onSortAsc={() => setOrder('+content')}
                onSortDesc={() => setOrder('-content')}
                onSortReset={() => setOrder(null) /* default */}
              />,
              <TableHead key={3} label='Ändern' align='CENTER' width={6} slim />,
              <TableHead key={4} label='System' align='RIGHT' width={15} />
            ]}
          >
            {items &&
              items.map((resource, index) => {
                const { portal, app, website, partnerApp } = useResourceTarget(resource.target);
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <div className='Resources__Identifier'>
                        {resource.identifier.replaceAll('.', '.\u200B')}
                      </div>
                      <div className='Resources__Label'>{resource.label}</div>
                    </TableCell>
                    <TableCell>
                      <AutoEllipsis concealColor={index % 2 === 0 ? 'GRAY' : 'WHITE'}>
                        <TextRenderer content={resource.content} />
                      </AutoEllipsis>
                    </TableCell>
                    <TableCell align='CENTER' slim>
                      <IconButton
                        title='Bearbeiten'
                        showLabel={false}
                        to={Paths.ResourceEdit(resource.id)}
                        iconCircle={{ icon: <Edit size={14} fill={Colors.White} />, size: 28 }}
                      />
                    </TableCell>
                    <TableCell align='RIGHT'>
                      {(portal && 'Portal') ||
                        (app && 'App') ||
                        (website && 'Website') ||
                        (partnerApp && 'Partner-App')}
                    </TableCell>
                  </TableRow>
                );
              })}
          </Table>
        </div>
        <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
      </Container>
    </main>
  );
};

export default Resources;
