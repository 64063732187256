import React, { useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { uploadFile } from '../../../api/common';
import { ButtonColors } from '../../../definitions/colors';
import { de } from '../../../tinymce/langs/de';
import PropTypes from 'prop-types';
import tinymce from 'tinymce/tinymce';
import contentUiCss from '!!raw-loader!../../../tinymce/skins/evm/content.min.css';
import '../../../tinymce/skins/evm/skin.min.css';
import './RichtextEditor.scss';

import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';

tinymce.addI18n('de', de);

const RichtextEditor = ({ allowImages, allowLinks, onUpdateValue, value }) => {
  const editorConfiguration = useMemo(() => {
    const uploadImage = async (blobInfo, success, failure) => {
      try {
        const result = await uploadFile(blobInfo.blob());
        success(result.location);
      } catch (error) {
        failure(error);
      }
    };

    const makeFormatOption = Color => ({
      title: Color.Name,
      selector: 'a',
      classes: 'WysiwygButton',
      styles: { backgroundColor: Color.HexCode }
    });

    return {
      // General options
      skin: false,
      content_css: false,
      content_style: contentUiCss,
      menubar: false,
      toolbar: [
        'undo redo | styleselect | removeformat | bold italic underline | bullist numlist | link image'
      ],
      plugins: ['autoresize', allowImages && 'image', allowLinks && 'link', 'lists'].join(' '),
      language: 'de',
      inline: true,
      min_height: 500,
      // Styling
      style_formats: [
        { title: 'Absatzformat' },
        { title: 'Überschrift 1', format: 'h1' },
        { title: 'Überschrift 2', format: 'h2' },
        { title: 'Überschrift 3', format: 'h3' },
        { title: 'Überschrift 4', format: 'h4' },
        { title: 'Fließtext', format: 'p' },
        { title: 'Buttons', items: ButtonColors.map(makeFormatOption) }
      ],
      // Image options
      images_upload_handler: uploadImage,
      image_dimensions: false,
      object_resizing: false,
      // Link options
      default_link_target: '_blank',
      link_context_toolbar: true,
      target_list: false,
      link_title: false
    };
  }, [allowImages, allowLinks]);

  return (
    <div className='RichtextEditor'>
      <Editor value={value} onEditorChange={onUpdateValue} init={editorConfiguration} />
    </div>
  );
};

RichtextEditor.propTypes = {
  allowImages: PropTypes.bool,
  allowLinks: PropTypes.bool,
  onUpdateValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default RichtextEditor;
