import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getActivations } from '../../api/activations';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const activationsLocation = 'activations';

/**
 * Requests that the given page of the given query is made present.
 */
export const activationsRequestPage = pagingRequestPage(activationsLocation, getActivations);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectActivations = pagingSelectItems(activationsLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectActivationsMode = pagingSelectMode(activationsLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectActivationsError = pagingSelectError(activationsLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectActivationsQuery = pagingSelectQuery(activationsLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectActivationsTotal = pagingSelectTotal(activationsLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectActivationsTotalItems = pagingSelectTotalItems(activationsLocation);

/**
 * Reducer for activations listing.
 */
export const activationsReducer = pagingReducer(activationsLocation);
