import { pathManage, getPage } from './common';

/**
 * Gets the raw customer activations in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getActivations = (page, query) =>
  !query?.find(([key]) => 'by' === key)
    ? getPage(`${pathManage}/activations`, [['page', page], ...query])
    : getPage(`${pathManage}/activations-with-by`, [['page', page], ...query]);
