import React, { useId } from 'react';
import PropTypes from 'prop-types';
import './Textbox.scss';
import Tooltip from '../Tooltip';

const Textbox = ({
  inputId,
  disabled,
  inputClass,
  instantValidator,
  isTextarea,
  name,
  onUpdateValue,
  placeholder,
  readonly,
  required,
  showLabel,
  title,
  tooltip,
  type,
  value
}) => {
  const newId = useId();
  const usedId = inputId ?? newId;
  const validation = instantValidator ? instantValidator(value, !required) : true;
  return (
    <div
      className={`Textbox
      ${readonly ? 'Textbox--Readonly' : ''}
      ${disabled ? 'Textbox--Disabled' : ''}
      `}
    >
      {showLabel && (
        <div className='Textbox__Label'>
          <label htmlFor={usedId}>{title}</label>
          {tooltip && <Tooltip content={tooltip} />}
        </div>
      )}
      {!isTextarea ? (
        <input
          id={usedId}
          className={`Textbox__Input ${inputClass}`}
          onChange={event => (readonly ? undefined : onUpdateValue(event.currentTarget.value))}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          title={title}
          type={type}
          value={value ?? ''}
        />
      ) : (
        <textarea
          id={usedId}
          className={`Textbox__Input Textbox__Input--Textarea ${inputClass}`}
          onChange={event => (readonly ? undefined : onUpdateValue(event.currentTarget.value))}
          disabled={readonly || disabled}
          name={name}
          placeholder={placeholder}
          title={title}
          value={value ?? ''}
        />
      )}
      <div
        className={`Textbox__Validation ${
          validation !== true ? 'Textbox__Validation--Visible' : ''
        }`}
      >
        {validation}
      </div>
    </div>
  );
};

Textbox.propTypes = {
  inputId: PropTypes.string,
  disabled: PropTypes.bool,
  inputClass: PropTypes.string,
  instantValidator: PropTypes.func,
  isTextarea: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onUpdateValue: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'url', 'number']).isRequired,
  value: PropTypes.string
};

Textbox.defaultProps = {
  showLabel: true,
  type: 'text',
  value: ''
};

export default Textbox;
