import { ViewRoute } from './ViewRoute';
import Campaigns from '../views/Campaigns';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import React from 'react';

export const ViewCampaignsArchive = () => (
  <ViewRoute
    loggedInComponent={<Campaigns archive />}
    loggedOutComponent={<Redirect to={Paths.Login()} />}
  />
);
