import { useEffect, useLayoutEffect } from 'react';
import { withAbortSignal } from '../util/abort';

/**
 * Uses an effect. The effect handler receives an abort signal that is invoked when the effect is
 * unloaded.
 * @param {function(abortSignal:AbortSignal):((function():void)|Promise<*>|void)} effect The effect
 * function, receiving the generated abort signal.
 * @param deps The dependency array.
 */
export const useAbortEffect = (effect, deps) =>
  useEffect(typeof effect !== 'function' ? effect : withAbortSignal(effect), deps);

/**
 * Uses a layout effect. The effect handler receives an abort signal that is invoked when the effect
 * is unloaded.
 * @param {function(abortSignal:AbortSignal):((function():void)|Promise<*>|void)} effect The effect
 * function, receiving the generated abort signal.
 * @param deps The dependency array.
 */
export const useAbortLayoutEffect = (effect, deps) =>
  useLayoutEffect(typeof effect !== 'function' ? effect : withAbortSignal(effect), deps);
