import React from 'react';
import PropTypes from 'prop-types';
import './OfferPanel.scss';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../definitions/paths';
import IconButton from '../atoms/IconButton';
import { Archive, Down, Edit, Eye, NoEye, Up } from '../../images/icons';
import Colors from '../../definitions/colors';
import CircleIcon from '../atoms/CircleIcon';
import { displayDate } from '../../helpers/formatters';
import moment from 'moment';
import { isPreRelease, isReleased } from '../../helpers/entities';
import { keySelect } from '../../util/handlers';
import { classes } from '../../util/styles';

const OfferPanel = ({
  alternate,
  editable,
  offer,
  onArchive,
  onMoveDown,
  onMoveUp,
  showStatus,
  withoutCampaign
}) => {
  const navigate = useNavigate();
  const partnerName = offer.partnerUsedName || offer.partner?.name;

  // Determine period from validity.
  const inFuture = offer?.validFrom && moment().isSameOrBefore(moment(offer.validFrom));
  const inPast = offer?.validUntil && moment(offer.validUntil).isSameOrBefore(moment());
  const inPreview = offer?.visibleFrom && moment().isSameOrAfter(moment(offer.visibleFrom));

  let status = null;
  if (isPreRelease(offer))
    status = (
      <>
        <CircleIcon icon={<Edit size={15} fill={Colors.White} />} size={28} />
        <div className='OfferPanel__StatusLabel OfferPanel__StatusLabel--Inactive'>Entwurf</div>
      </>
    );
  else if (isReleased(offer) && offer?.isActive)
    status = (
      <>
        <CircleIcon icon={<Eye size={20} fill={Colors.White} />} size={28} />
        <div className='OfferPanel__StatusLabel'>
          Aktiv
          {offer?.validFrom && ` ab ${displayDate(offer?.validFrom)}`}
          {offer?.validUntil && ` bis ${displayDate(offer?.validUntil)}`}
        </div>
      </>
    );
  else if (isReleased(offer) && !offer?.isActive && inFuture && !inPreview)
    status = (
      <>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <div className='OfferPanel__StatusLabel OfferPanel__StatusLabel--Inactive'>
          {offer?.visibleFrom && (
            <>
              Vorschau ab {displayDate(offer?.visibleFrom)}
              <br />
            </>
          )}
          Aktiv
          {offer?.validFrom && ` ab ${displayDate(offer?.validFrom)}`}
          {offer?.validUntil && ` bis ${displayDate(offer?.validUntil)}`}
        </div>
      </>
    );
  else if (isReleased(offer) && !offer?.isActive && inFuture && inPreview)
    status = (
      <>
        <CircleIcon icon={<Eye size={20} fill={Colors.White} />} size={28} />
        <div className='OfferPanel__StatusLabel'>
          Vorschau, aktiv
          {offer?.validFrom && ` ab ${displayDate(offer?.validFrom)}`}
          {offer?.validUntil && ` bis ${displayDate(offer?.validUntil)}`}
        </div>
      </>
    );
  else if (isReleased(offer) && !offer?.isActive && inPast)
    status = (
      <>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} offsetY={-1} size={28} />
        <div className='OfferPanel__StatusLabel OfferPanel__StatusLabel--Inactive'>
          War aktiv
          {offer?.validUntil && ` bis ${displayDate(offer?.validUntil)}`}
        </div>
      </>
    );

  const actions = (
    <div className='OfferPanel__Actions'>
      {editable && (
        <>
          {onArchive && (
            <div className='OfferPanel__ActionArchive'>
              <IconButton
                title='Archivieren'
                iconCircle={{
                  icon: <Archive size={16} fill={Colors.White} />,
                  size: 28
                }}
                onClick={onArchive}
              />
            </div>
          )}
          <div className='OfferPanel__ActionMoveUp'>
            <IconButton
              title='Nach oben bewegen'
              iconCircle={{
                icon: <Up size={14} fill={Colors.White} />,
                size: 28,
                offsetY: -1
              }}
              disabled={!onMoveUp}
              onClick={onMoveUp}
            />
          </div>
          <div className='OfferPanel__ActionMoveDown'>
            <IconButton
              title='Nach unten bewegen'
              iconCircle={{
                icon: <Down size={14} fill={Colors.White} />,
                size: 28,
                offsetY: 1
              }}
              disabled={!onMoveDown}
              size={28}
              onClick={onMoveDown}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <div
      className={classes(
        'OfferPanel',
        withoutCampaign && 'OfferPanel--WithoutCampaign',
        alternate && 'OfferPanel--Alternate'
      )}
    >
      <div className='OfferPanel__Title'>
        {partnerName && <div className='OfferPanel__Partner'>{partnerName}:</div>}
        <span
          onClick={() => navigate(Paths.OfferDetails(offer.id))}
          onKeyDown={keySelect(() => navigate(Paths.OfferDetails(offer.id)))}
          title='Details anzeigen'
          role='button'
          tabIndex={0}
          className={offer.title ? 'OfferPanel__TitleText' : 'OfferPanel__TitleText--Unnamed'}
        >
          {offer.title ?? 'Unbenannter Vorteil'}
        </span>
      </div>
      {showStatus && <div className='OfferPanel__Status'>{status}</div>}
      {actions}
    </div>
  );
};

OfferPanel.propTypes = {
  alternate: PropTypes.bool,
  editable: PropTypes.bool,
  offer: PropTypes.object,
  onArchive: PropTypes.func,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  showStatus: PropTypes.bool,
  withoutCampaign: PropTypes.bool
};

OfferPanel.defaultProps = {
  editable: true,
  showStatus: true
};

export default OfferPanel;
