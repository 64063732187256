import { useState } from 'react';
import { useAbortEffect } from './useAbortEffect';

/**
 * Uses a name resolver for an ID.
 * @param {function(id:string,signal:*):Promise<string>} resolver The resolver, takes ID and abort signal.
 * @param {string|null} id ID if available.
 * @returns {string}
 */
export const useResolveName = (resolver, id) => {
  const [name, setName] = useState(null);

  useAbortEffect(
    signal => {
      // Reset if no ID is given.
      if (!id) {
        setName(null);
        return;
      }

      // Update async with loading.
      (async () => {
        // Perform loading.
        const result = await resolver(id, signal);

        // Set to loaded name.
        setName(result);
      })().catch(() => setName(null));
    },
    [id]
  );

  return name;
};
