import { useSelector } from 'react-redux';
import { selectLoggedIn } from '../../redux/reducers/account';
import Menu from '../modules/NavBar';
import PropTypes from 'prop-types';
import React from 'react';

export const ViewRoute = ({
  noMenu = false,
  loggedInComponent = null,
  loggedOutComponent = null,
  children = null
}) => {
  // Check login status to pick the element to render.
  const loggedIn = useSelector(selectLoggedIn);

  // Evaluate if needed.
  const loggedInValue =
    (loggedIn &&
      (typeof loggedInComponent === 'function' ? loggedInComponent() : loggedInComponent)) ||
    null;
  const loggedOutValue =
    (!loggedIn &&
      (typeof loggedOutComponent === 'function' ? loggedOutComponent() : loggedOutComponent)) ||
    null;

  // Return the components that are not null in the given case.
  return (
    <>
      {noMenu ? null : <Menu />}
      {loggedInValue}
      {loggedOutValue}
      {children}
    </>
  );
};

ViewRoute.propTypes = {
  noMenu: PropTypes.bool,
  loggedInComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  loggedOutComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.node,
  edits: PropTypes.array
};
