import { filterKeys } from '../../util/objects';
import { Mode } from './mode';

/**
 * Initial state of the paging reducer.
 * @type {{total: null, pages: {}, query: null}}
 */
export const initialState = Object.freeze({
  /**
   * Pages by their page number.
   */
  pages: {},

  /**
   * Query that drives the current state.
   */
  query: null,

  /**
   * The total number of pages. Can be null if not initialized yet.
   */
  total: null,

  /**
   * Total number of results. Can be null if not initialized yet.
   */
  totalItems: null
});

/**
 * Used to signal reset of the state with a query.
 * @param namespace The namespace of the actual paged object.
 */
const pagingActivate = namespace => query => ({
  type: `${namespace}/ACTIVATE`,
  payload: { query }
});

/**
 * Used to signal that a single page update or initialization is beginning.
 * @param namespace The namespace of the actual paged object.
 */
const pagingPageBegin = namespace => (page, update) => ({
  type: `${namespace}/PAGE_BEGIN`,
  payload: { page, update }
});

/**
 * Used to signal that a page update or initialize is complete.
 * @param namespace The namespace of the actual paged object.
 */
const pagingPageGot = namespace => (page, items, count, countItems) => ({
  type: `${namespace}/PAGE_GOT`,
  payload: { page, items, count, countItems }
});

/**
 * Used to signal failure on a single page.
 * @param namespace The namespace of the actual paged object.
 */
const pagingPageFailed = namespace => (page, error) => ({
  type: `${namespace}/PAGE_FAILED`,
  payload: { page, error }
});

/**
 * Checks if the queries are equal.
 * @param left The left query.
 * @param right The right query.
 * @return {boolean}
 */
const queryEqual = (left, right) => JSON.stringify(left) === JSON.stringify(right);

/**
 * Requests that the given page of the given query is made present. This can keep the current state
 * when only items are selected and the page is already present. This action will always defensively
 * fetch a page, even if data is present. This action will not start two fetches.
 *
 * This action will clear the data and page count when another query is given than the last used.
 *
 * This action returns a promise and resolves it with either true when the entire operation finished
 * or with false when the operation failed at fetch. All actions will always be dispatched.
 * @param namespace namespace of the actual paged object.
 * @param fetchPage The async fetch page operation, takes page and query and returns data, pages at, pages count and results count.
 */
export const pagingRequestPage = (namespace, fetchPage) => {
  const activate = pagingActivate(namespace);
  const pageBegin = pagingPageBegin(namespace);
  const pageGot = pagingPageGot(namespace);
  const pageFailed = pagingPageFailed(namespace);

  /**
   * @param page The page to make present.
   * @param query The query, passed to the fetch operation..
   */
  return (page, query) => async (dispatch, getState) => {
    // Filter empty parameters.
    query = query.filter(Boolean);

    // Get state to compare against inputs.
    const state = getState()[namespace];

    // Get current data state and the query.
    const data = state.pages[page];
    const active = state.query;

    // Check if the query is shallow equal, deep equality is not needed because the query is usually
    // translated into URL search parameters. Also check if the data is like true.
    const sameQuery = queryEqual(active, query);
    const dataPresent = !!data;

    // Active query is equal to the current query.
    if (!sameQuery) {
      dispatch(activate(query));
    }

    // Data is present and currently doing something. Do not start new.
    if (sameQuery && data && data.mode !== Mode.Idle && data.error === null) {
      return;
    }

    // Begin loading. Assume updating if the query did not change and there is data present.
    dispatch(pageBegin(page, sameQuery && dataPresent));

    try {
      // Get from remote with the desired page and query.
      const { data, pagesAt, pagesCount, resultsCount } = await fetchPage(page, query);

      // Assert data consistency.
      // eslint-disable-next-line no-console
      console.assert(page === pagesAt, 'Got %i, expected %d', pagesAt, page);

      // Dispatch received with the values from the backend.
      dispatch(pageGot(page, data, pagesCount, resultsCount));

      // Page request OK.
      return true;
    } catch (error) {
      // Failed with this error, propagate to page state.
      dispatch(pageFailed(page, error));

      // Page request failed, communicate.
      return false;
    }
  };
};

/**
 * Selects the items of the current page.
 * @param namespace namespace of the actual paged object.
 */
export const pagingSelectItems = namespace => page => state =>
  (state[namespace].pages[page] || { items: null }).items;

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param namespace namespace of the actual paged object.
 */
export const pagingSelectMode = namespace => page => state =>
  (state[namespace].pages[page] || { mode: null }).mode;

/**
 * Selects the error status of the page.
 * @param namespace namespace of the actual paged object.
 */
export const pagingSelectError = namespace => page => state =>
  (state[namespace].pages[page] || { error: null }).error;

/**
 * Selects the currently activated query.
 * @param namespace namespace of the actual paged object.
 */
export const pagingSelectQuery = namespace => state => state[namespace].query;

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param namespace namespace of the actual paged object.
 */
export const pagingSelectTotal = namespace => state => state[namespace].total;

/**
 * Selects the current items count. This selector may return `null` if no count is available yet.
 * @param namespace namespace of the actual paged object.
 */
export const pagingSelectTotalItems = namespace => state => state[namespace].totalItems;

/**
 * Reducer for paginating data retrieval.
 */
export const pagingReducer = namespace => {
  const typeActivate = `${namespace}/ACTIVATE`;
  const typePageBegin = `${namespace}/PAGE_BEGIN`;
  const typePageGot = `${namespace}/PAGE_GOT`;
  const typePageFailed = `${namespace}/PAGE_FAILED`;

  return (state = initialState, { type, payload }) => {
    switch (type) {
      // Activate query, this removes all pages.
      case typeActivate:
        return {
          // Reset and update query.
          pages: {},
          query: payload.query,
          total: null,
          totalItems: null
        };

      // Begin page, this leaves all other pages and sets the mode. Keeps the current items if updating.
      case typePageBegin:
        return {
          // Keep query and total.
          ...state,

          // Update page values.
          pages: {
            // Leave other pages untouched.
            ...state.pages,

            // Update given page.
            [payload.page]: {
              // If updating, keep current values, otherwise reset.
              items: payload.update ? state.pages[payload.page].items : [],

              // If updating, set mode to updating, otherwise set to initializing.
              mode: payload.update ? Mode.Updating : Mode.Initializing,

              // Drop error.
              error: null
            }
          }
        };

      // Handle page result. This cleans up exceeding pages and updates the items and the mode.
      case typePageGot:
        return {
          // Keep state.
          ...state,

          // Update totals.
          total: payload.count,
          totalItems: payload.countItems,

          // Update page values.
          pages: {
            // Leave other pages untouched.
            ...filterKeys(state.pages, page => page < payload.count),

            // Update given page.
            [payload.page]: {
              items: payload.items,
              mode: Mode.Idle,
              error: null
            }
          }
        };

      // Handle page failed. This sets the mode and error and cleans the items.
      case typePageFailed:
        // page, error
        return {
          // Keep state.
          ...state,

          // Update page value.
          pages: {
            // Leave other pages untouched.
            ...state.pages,

            // Set mode to idle, reset items, and mark error.
            [payload.page]: {
              items: [],
              mode: Mode.Idle,
              error: payload.error
            }
          }
        };

      default:
        return state;
    }
  };
};
