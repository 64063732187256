import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PageTitle = ({ title, cmsContext = true }) => (
  <Helmet defaultTitle={null} titleTemplate={`%s – evm Entdecker-Bonus${cmsContext ? ' CMS' : ''}`}>
    <title>{title}</title>
  </Helmet>
);
PageTitle.propTypes = {
  title: PropTypes.node,
  cmsContext: PropTypes.bool
};
PageTitle.defaultProps = {
  cmsContext: true
};
export default PageTitle;
