import React from 'react';
import PropTypes from 'prop-types';
import './RadioButtonBar.scss';

const RadioButtonBar = ({ disabled, onUpdateValue, options, value }) => {
  return (
    <div className={`RadioButtonBar ${disabled ? 'RadioButtonBar--Disabled' : ''}`}>
      {options.map(option => (
        <div
          key={option.value}
          className={`RadioButtonBar__Option
             ${option.value === value ? 'RadioButtonBar__Option--Selected' : ''}
             `}
          onClick={() => onUpdateValue(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

RadioButtonBar.propTypes = {
  disabled: PropTypes.bool,
  onUpdateValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired
    })
  ),
  value: PropTypes.any
};

export default RadioButtonBar;
