import React from 'react';

import './LoadingStrip.scss';
import PropTypes from 'prop-types';

/**
 * Displays a small rectangle indicating per-field loading.
 */
export const LoadingStrip = ({ inverted }) => (
  <div className={`LoadingStrip ${inverted ? 'LoadingStrip--Inverted' : ''}`}>
    <div className={`LoadingStrip__Marquee ${inverted ? 'LoadingStrip__Marquee--Inverted' : ''}`} />
  </div>
);

LoadingStrip.propTypes = {
  inverted: PropTypes.bool
};
