/**
 * Creates a key selector wrapper for the function that runs on Enter and Space.
 * @param fn The function to wrap. Undefined will be returned as is.
 * @return {(function(*): (*|undefined))|*} Returns a new function or undefined.
 */
export function keySelect(fn) {
  if (!fn) return fn;
  /**
   * @param {KeyboardEvent|MouseEvent} event
   */
  return event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      return fn();
    }
  };
}
