import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../definitions/colors';
import Chart from 'react-apexcharts';
import de from 'apexcharts/dist/locales/de.json';
import './Chart.scss';

const DateBarChart = ({ empty, series }) => {
  const chartOptions = {
    chart: {
      locales: [de],
      defaultLocale: 'de',
      animations: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      fontFamily: 'Ubuntu, sans-serif',
      foreColor: '#212529',
      selection: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        format: 'dd. MMMM yyyy'
      },
      style: {
        fontSize: '16px'
      }
    },
    colors: [Colors.Orange],
    xaxis: {
      type: 'datetime',
      labels: {
        showDuplicates: false,
        format: 'dd. MMMM',
        style: {
          fontSize: '16px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '16px'
        }
      }
    }
  };

  return (
    <div className='Chart'>
      <Chart options={chartOptions} series={series} type='bar' width='100%' height={350} />
      {!empty ? null : (
        <div className='Chart__EmptyOverlay'>
          <span>Keine Daten</span>
        </div>
      )}
    </div>
  );
};

DateBarChart.propTypes = {
  empty: PropTypes.bool,
  series: PropTypes.array.isRequired
};

export default DateBarChart;
