import React from 'react';
import PropTypes from 'prop-types';
import './SingleBarChart.scss';

const SingleBarChart = ({ background, value, max }) => {
  return (
    <div className='SingleBarChart'>
      <div className={`SingleBarChart__BarContainer SingleBarChart__BarContainer--${background}`}>
        <div className='SingleBarChart__Bar' style={{ width: `${(value / max) * 100}%` }} />
      </div>
      <div className='SingleBarChart__Value'>{new Intl.NumberFormat('de-DE').format(value)}</div>
    </div>
  );
};

SingleBarChart.propTypes = {
  background: PropTypes.oneOf(['WHITE', 'GRAY']).isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default SingleBarChart;
