import React, { useMemo, useState } from 'react';
import Container from '../layout/Container';
import './Dashboard.scss';
import DashboardTile from '../modules/DashboardTile';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { Paths } from '../../definitions/paths';
import DashboardTileItem from '../atoms/DashboardTileItem';
import Toolbar from '../modules/Toolbar';
import PageTitle from '../../helpers/pageTitle';
import { useDashboard } from '../../hooks/useRemoteValue';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../redux/reducers/account';
import { useAbortEffect } from '../../hooks/useAbortEffect';
import moment from 'moment';
import { analyzeValuesSummary } from '../../api/customerValue';
import { formatChange } from '../../helpers/formatters';
import { LoadingStrip } from '../atoms/LoadingStrip';
import { useMsal } from '@azure/msal-react';

const splitsCount = (limit, splits) => {
  if (typeof splits !== 'object' || splits === null) return null;
  return Object.entries(splits)
    .filter(([key]) => key !== 'mean')
    .filter(([key]) => Number(key) >= limit)
    .reduce((prev, [, value]) => prev + value, 0);
};

const Dashboard = () => {
  // Use authentication token and account.
  const { instance } = useMsal();
  const accountId = useSelector(selectAccount);
  const account = typeof accountId === 'string' ? instance.getAccountByHomeId(accountId) : null;

  // Use dashboard value and loading status.
  const [dashboard, , loading] = useDashboard();

  const [valueSummaryBefore, setValueSummaryBefore] = useState(null);
  const [valueSummary, setValueSummary] = useState(null);
  const [valueSummaryLoading, setValueSummaryLoading] = useState(false);
  useAbortEffect(signal => {
    const timeNow = moment();
    const timeMid = moment().subtract(7, 'days');

    (async () => {
      setValueSummaryLoading(true);
      const [first, second] = await Promise.all([
        analyzeValuesSummary(timeMid.toISOString(), signal),
        analyzeValuesSummary(timeNow.toISOString(), signal)
      ]);
      setValueSummaryBefore(first);
      setValueSummary(second);
    })()
      .catch(() => undefined)
      .finally(() => setValueSummaryLoading(false));
  }, []);

  const change = useMemo(() => {
    if (!valueSummaryBefore?.splits || !valueSummary?.splits) return null;

    const now = splitsCount(0.2, valueSummary.splits);
    const before = splitsCount(0.2, valueSummaryBefore.splits);
    return now - before;
  }, [valueSummary, valueSummaryBefore]);

  return (
    <main className='Dashboard'>
      <PageTitle title={'Dashboard'} />
      <LoadingOverlay show={loading} />
      <Container>
        <Toolbar title={`Willkommen, ${account?.name}!`} />
        <div className='Dashboard__Layout'>
          <div className='Dashboard__Column Dashboard__Column--Wider'>
            <DashboardTile
              linkTo={Paths.Campaigns()}
              value={dashboard?.activeCampaigns?.length || 0}
              mainLabel='Kampagnen'
              subLabel='sind zurzeit aktiv'
            >
              {!dashboard?.activeCampaigns
                ? null
                : dashboard.activeCampaigns.map(({ id, title }, index) => (
                    <DashboardTileItem
                      key={id}
                      alternate={index % 2 === 1}
                      label={title}
                      statisticsUrl={Paths.CampaignStatistics(id)}
                      detailsUrl={Paths.CampaignDetails(id)}
                    />
                  ))}
            </DashboardTile>
          </div>
          <div className='Dashboard__Column Dashboard__Column--Wider'>
            <DashboardTile
              linkTo={Paths.Campaigns()}
              value={dashboard?.activeOffers?.length || 0}
              mainLabel='Vorteile'
              subLabel='sind zurzeit aktiv'
            >
              {!dashboard?.activeOffers
                ? null
                : dashboard.activeOffers.map(({ id, title }, index) => (
                    <DashboardTileItem
                      key={id}
                      alternate={index % 2 === 1}
                      label={title}
                      statisticsUrl={Paths.OfferStatistics(id)}
                      detailsUrl={Paths.OfferDetails(id)}
                    />
                  ))}
            </DashboardTile>
          </div>
          <div className='Dashboard__Column Dashboard__Column'>
            <DashboardTile
              linkTo={Paths.Campaigns()}
              value={dashboard?.activationCount || 0}
              mainLabel='Einlösungen'
              subLabel='in den letzten 7 Tagen'
            />
            <DashboardTile
              linkTo={Paths.Customers()}
              value={dashboard?.newCustomersCount || 0}
              mainLabel='neue Kunden'
              subLabel='in den letzten 7 Tagen'
            />
            <DashboardTile
              linkTo={Paths.ValueDashboard()}
              value={valueSummaryLoading ? <LoadingStrip inverted /> : formatChange(change)}
              mainLabel='neue Entdecker'
              subLabel='in den letzten 7 Tagen'
              tooltip='Dieser Wert gibt an, wie sich die Anzahl der Nutzer mit einem Entdeckerwert größer als 20% seit der vorherigen Woche entwickelt hat.'
            />
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Dashboard;
