import React from 'react';
import PropTypes from 'prop-types';
import './ItemCount.scss';

const ItemCount = ({ count }) => {
  return count ? (
    <div className='ItemCount'>
      {new Intl.NumberFormat('de-DE').format(count)} {count === 1 ? 'Eintrag' : 'Einträge'}
    </div>
  ) : null;
};

ItemCount.propTypes = {
  count: PropTypes.number
};

export default ItemCount;
