import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../definitions/colors';
import './CircleIcon.scss';

const CircleIcon = ({ color = Colors.Orange, icon, offsetX = 0, offsetY = 0, size = 40 }) => {
  return (
    <div
      className='CircleIcon'
      style={{
        ...(color && { backgroundColor: color }),
        height: `${size}px`,
        paddingTop: `${offsetY > 0 ? offsetY * 2 : 0}px`,
        paddingBottom: `${offsetY < 0 ? offsetY * -2 : 0}px`,
        paddingLeft: `${offsetX > 0 ? offsetX * 2 : 0}px`,
        paddingRight: `${offsetX < 0 ? offsetX * -2 : 0}px`,
        width: `${size}px`
      }}
    >
      {icon}
    </div>
  );
};

export const CircleIconPropTypes = {
  color: PropTypes.string,
  icon: PropTypes.element.isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  size: PropTypes.number
};

export const CircleIconDefaultProps = {
  color: Colors.Orange,
  offsetX: 0,
  offsetY: 0,
  size: 40
};

CircleIcon.propTypes = CircleIconPropTypes;

export default CircleIcon;
