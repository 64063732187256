import React from 'react';
import PropTypes from 'prop-types';
import CircleIcon from './CircleIcon';
import { Close, Tick } from '../../images/icons';
import Colors from '../../definitions/colors';
import './SubstitutionIndicator.scss';

const SubstitutionIndicator = ({ description, identifier, used }) => {
  return (
    <div className={`SubstitutionIndicator ${used ? 'SubstitutionIndicator--Used' : ''}`}>
      {used ? (
        <CircleIcon icon={<Tick size={16} fill={Colors.White} />} size={28} color={Colors.Green} />
      ) : (
        <CircleIcon
          icon={<Close size={14} fill={Colors.White} />}
          size={28}
          color={Colors.Orange}
        />
      )}
      <div className='SubstitutionIndicator__Text'>
        <span className='SubstitutionIndicator__Identifier'>{`{${identifier}}`}</span>
        {` – ${description}`}
      </div>
    </div>
  );
};

SubstitutionIndicator.propTypes = {
  description: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  used: PropTypes.bool
};

export default SubstitutionIndicator;
