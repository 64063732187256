import React from 'react';
import PropTypes from 'prop-types';
import './PreviewLink.scss';
import { Paths, makeAbsolute } from '../../definitions/paths';
import { displayBy, displayDate } from '../../helpers/formatters';
import { Close, Duplicate, Eye } from '../../images/icons';
import Colors from '../../definitions/colors';
import IconButton from './IconButton';
import { notifySuccess } from '../../helpers/notifications';
import CopyToClipboard from 'react-copy-to-clipboard';

const previewLinkEnvironment = (title, url) => (
  <div className='PreviewLink__Environment'>
    <div className='PreviewLink__EnvironmentName'>{title}:</div>
    <IconButton
      title='Vorschau öffnen'
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      iconCircle={{
        icon: <Eye size={20} fill={Colors.White} />,
        size: 28
      }}
    />
    <CopyToClipboard
      text={url}
      onCopy={() => notifySuccess('Der Vorschaulink wurde in die Zwischenablage kopiert.')}
    >
      <IconButton
        title='Vorschaulink kopieren'
        iconCircle={{
          icon: <Duplicate size={14} fill={Colors.White} />,
          size: 28
        }}
      />
    </CopyToClipboard>
  </div>
);

const PreviewLink = ({ link, onDelete }) => {
  const { linkFragment, createdBy, createdAt, id } = link;
  return (
    <div className='PreviewLink'>
      <div className='PreviewLink__Alias'>{linkFragment}</div>
      {previewLinkEnvironment('Übersicht', makeAbsolute(Paths.OfferPreviewCMS(linkFragment)))}
      {previewLinkEnvironment('Kundenportal', Paths.OfferPreviewPortal(linkFragment))}
      <div className='PreviewLink__Data'>
        Erstellt von {displayBy(createdBy)} am {displayDate(createdAt)}
      </div>
      <IconButton
        title='Entfernen'
        onClick={() => onDelete(id)}
        iconCircle={{ icon: <Close size={12} fill={Colors.White} />, size: 28 }}
      />
    </div>
  );
};

PreviewLink.propTypes = {
  link: PropTypes.shape({
    linkFragment: PropTypes.string.isRequired,
    createdBy: PropTypes.object,
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  onDelete: PropTypes.func
};

export default PreviewLink;
