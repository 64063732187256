export const useResourceTarget = target => {
  const Targets = {
    PORTAL: 1,
    APP: 2,
    WEBSITE: 4,
    PARTNERAPP: 8
  };
  return {
    /* eslint-disable no-bitwise */
    portal: (target & Targets.PORTAL) === Targets.PORTAL,
    app: (target & Targets.APP) === Targets.APP,
    website: (target & Targets.WEBSITE) === Targets.WEBSITE,
    partnerApp: (target & Targets.PARTNERAPP) === Targets.PARTNERAPP
  };
};
