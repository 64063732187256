import React from 'react';
import PropTypes from 'prop-types';
import './LoadingOverlay.scss';

const LoadingOverlay = ({ show, clear }) => {
  return (
    <div
      className={`LoadingOverlay ${show ? 'LoadingOverlay--Visible' : ''} ${
        clear ? 'LoadingOverlay--Clear' : ''
      }`}
    >
      <div className='LoadingOverlay__ProgressBar' />
    </div>
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
  clear: PropTypes.bool
};

export default LoadingOverlay;
