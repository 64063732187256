import React, { useEffect, useRef } from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import './Campaigns.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Toolbar from '../modules/Toolbar';
import { Plus } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import PropTypes from 'prop-types';
import TextLink from '../atoms/TextLink';
import CampaignPanel from '../modules/CampaignPanel';
import PageTitle from '../../helpers/pageTitle';
import { remoteAction } from './common';
import { swapCampaignOrder, updateCampaignStage } from '../../api/campaigns';
import { isNotArchived, stageArchived } from '../../helpers/entities';
import {
  selectListingCampaignsOffers,
  selectListingCampaignsOffersMode,
  selectListingCampaignsOffersTotal,
  selectListingCampaignsOffersTotalItems,
  listingCampaignsOffersRequestPage
} from '../../redux/reducers/listingCampaignsOffers';

import { useScrollTo } from '../../hooks/useScrollTo';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import ItemCount from '../atoms/ItemCount';
import Pagination from '../atoms/Pagination';
import { useArchiveCounts } from '../../hooks/useRemoteValue';
import { Mode } from '../../redux/reducers/mode';
import { displayArchival } from '../../helpers/formatters';
import SearchBar from '../atoms/SearchBar';
import { useSessionState } from '../../hooks/useSessionState';

/**
 * Returns true if the campaign is the loose offers campaign. Checks if the ID is GUID default empty.
 * @param campaign The campaign to check.
 */
const isLooseOffersCampaign = campaign => campaign?.id === '00000000-0000-0000-0000-000000000000';

const Campaigns = ({ archive }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useSessionState('Campaigns/page', 0);
  const [search, setSearch] = useSessionState('Campaigns/search', '');

  // Select state values
  const campaignsOffers = useSelector(selectListingCampaignsOffers(page));
  const mode = useSelector(selectListingCampaignsOffersMode(page));
  // const error = useSelector(selectPartnersError(page));
  const total = useSelector(selectListingCampaignsOffersTotal);
  const totalItems = useSelector(selectListingCampaignsOffersTotalItems);
  const reload = () =>
    dispatch(
      listingCampaignsOffersRequestPage(page, [
        search && ['search', search],
        ['archived', !!archive]
      ])
    );

  // Use archive counts and loading status.
  const [archiveCounts, , loadingArchiveCounts] = useArchiveCounts();

  // Compose to display loading.
  const loading = mode !== Mode.Idle || loadingArchiveCounts;

  // Scroll to table upon page flip
  const scrollAnchor = useRef(null);
  useScrollTo(scrollAnchor, page);

  // Do not exceed page limit
  usePaginationGuard(page, total, setPage);

  // Make page current with given index and query.
  useEffect(() => {
    reload();
  }, [page, search, archive]);

  /* Todo: consider "archive" prop to fetch archived offers instead */

  const reorder = remoteAction({
    action: async (src, dst) => {
      await swapCampaignOrder(src, dst);
      reload();
    },
    messageSuccess: 'Kampagne verschoben.',
    messageFail: 'Kampagne konnte nicht verschoben werden.'
  });

  const archiveCampaign = remoteAction({
    action: async id => {
      await updateCampaignStage(id, stageArchived);
      reload();
    },
    messageSuccess: 'Kampagne archiviert.',
    messageFail: 'Fehler beim Archivieren.'
  });

  const toolbar = archive ? (
    <Toolbar title='Archiv' backButton={{ label: 'Kampagnen & Vorteile', to: Paths.Campaigns() }} />
  ) : (
    <Toolbar
      title='Kampagnen & Vorteile'
      left={[
        <SearchBar key={1} term={search} onUpdateTerm={setSearch} />,
        <ItemCount key={2} count={totalItems} />
      ]}
      right={[
        <Button
          key={1}
          title='Neue Kampagne'
          icon={Plus}
          iconSize={15}
          to={Paths.CampaignCreate()}
        />
      ]}
    />
  );
  return (
    <main className='Campaigns'>
      <PageTitle title={archive ? 'Archivierte Kampagnen & Vorteile' : 'Kampagnen & Vorteile'} />
      <LoadingOverlay show={loading} />
      <Container>
        {toolbar}
        {!campaignsOffers
          ? null
          : campaignsOffers.map((campaign, index) =>
              isLooseOffersCampaign(campaign) ? (
                <CampaignPanel
                  key={campaign.id}
                  standaloneOffers={campaign.offers}
                  reloadContainer={reload}
                />
              ) : (
                <CampaignPanel
                  key={campaign.id}
                  campaign={campaign}
                  onArchive={
                    isNotArchived(campaign) ? () => archiveCampaign(campaign.id) : undefined
                  }
                  onMoveUp={
                    index > 0
                      ? () => reorder(campaign.id, campaignsOffers[index - 1].id)
                      : undefined
                  }
                  onMoveDown={
                    index < campaignsOffers.length - 1
                      ? () => reorder(campaign.id, campaignsOffers[index + 1].id)
                      : undefined
                  }
                  reloadContainer={reload}
                />
              )
            )}
        {!archive && (
          <div className='Campaigns__Archive'>
            {displayArchival(archiveCounts)}
            <br />
            <TextLink title='Zum Archiv' to={Paths.CampaignsArchive()} />
          </div>
        )}
        <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
      </Container>
    </main>
  );
};

Campaigns.propTypes = {
  archive: PropTypes.bool
};

export default Campaigns;
