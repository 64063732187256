import React from 'react';
import PropTypes from 'prop-types';
import { Archive, Down, Eye, NoEye, Plus, Right, Up } from '../../images/icons';
import Colors from '../../definitions/colors';
import './CampaignPanel.scss';
import AnimateHeight from 'react-animate-height';
import CircleIcon from '../atoms/CircleIcon';
import IconButton from '../atoms/IconButton';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../definitions/paths';
import OfferPanel from './OfferPanel';
import Button from '../atoms/Button';
import { swapOfferOrder, updateOfferStage } from '../../api/offers';
import { useDispatch, useSelector } from 'react-redux';
import { remoteAction } from '../views/common';
import { isNotArchived, stageArchived } from '../../helpers/entities';
import { selectCampaignOpen, setCampaignOpen } from '../../redux/reducers/ui';
import { keySelect } from '../../util/handlers';

const CampaignPanel = ({
  campaign,
  editable,
  onArchive,
  onMoveUp,
  onMoveDown,
  showStatus,
  standaloneOffers,
  reloadContainer
}) => {
  const navigate = useNavigate();
  const campaignId = campaign?.id || 'standalone';
  const open = useSelector(selectCampaignOpen(campaignId));
  const dispatch = useDispatch();
  const toggleOpen = () => dispatch(setCampaignOpen(campaignId, !open));
  const archiveCampaign = event => {
    // Do not interpret button click as a toggleOpen action
    event.stopPropagation();
    onArchive();
  };
  const moveUpCampaign = event => {
    // Do not interpret button click as a toggleOpen action
    event.stopPropagation();
    onMoveUp();
  };
  const moveDownCampaign = event => {
    // Do not interpret button click as a toggleOpen action
    event.stopPropagation();
    onMoveDown();
  };

  // Navigate to offers for this panel.
  const offers = campaign?.offers || standaloneOffers || [];

  const reorder = remoteAction({
    action: async (src, dst) => {
      await swapOfferOrder(src, dst);
      reloadContainer && reloadContainer();
    },
    messageSuccess: 'Vorteil verschoben.',
    messageFail: 'Vorteil konnte nicht verschoben werden.'
  });

  const archiveOffer = remoteAction({
    action: async id => {
      await updateOfferStage(id, stageArchived);
      reloadContainer();
    },
    messageSuccess: 'Vorteil archiviert.',
    messageFail: 'Fehler beim Archivieren.'
  });

  const status = (
    <div className='CampaignPanel__Status'>
      {campaign?.isActive ? (
        <>
          <CircleIcon icon={<Eye size={20} fill={Colors.White} />} size={28} />
          <div className='CampaignPanel__StatusLabel'>Aktuell sichtbar</div>
        </>
      ) : (
        <>
          <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
          <div className='CampaignPanel__StatusLabel CampaignPanel__StatusLabel--Inactive'>
            Aktuell nicht sichtbar
          </div>
        </>
      )}
    </div>
  );

  const actions = (
    <div className='CampaignPanel__Actions'>
      {onArchive && (
        <div className='CampaignPanel__ActionArchive'>
          <IconButton
            title='Archivieren'
            iconCircle={{
              icon: <Archive size={16} fill={Colors.White} />,
              size: 28
            }}
            onClick={archiveCampaign}
          />
        </div>
      )}
      <div className='CampaignPanel__ActionMoveUp'>
        <IconButton
          title='Nach oben bewegen'
          iconCircle={{
            icon: <Up size={14} fill={Colors.White} />,
            size: 28,
            offsetY: -1
          }}
          disabled={!onMoveUp}
          onClick={moveUpCampaign}
        />
      </div>
      <div className='CampaignPanel__ActionMoveDown'>
        <IconButton
          title='Nach unten bewegen'
          iconCircle={{
            icon: <Down size={14} fill={Colors.White} />,
            size: 28,
            offsetY: 1
          }}
          disabled={!onMoveDown}
          size={28}
          onClick={moveDownCampaign}
        />
      </div>
    </div>
  );

  return (
    <div className={`CampaignPanel ${open ? 'CampaignPanel--Open' : ''}`}>
      <div className='CampaignPanel__Head' onClick={toggleOpen}>
        <div
          className='CampaignPanel__FoldingIndicator'
          onClick={toggleOpen}
          onKeyDown={keySelect(toggleOpen)}
          role='button'
          tabIndex={0}
        >
          <Right size={8} fill={Colors.Orange} label={open ? 'Aufgeklappt' : 'Zugeklappt'} />
        </div>

        {campaign ? (
          <div
            className='CampaignPanel__Title'
            onClick={() => navigate(Paths.CampaignDetails(campaign.id))}
            onKeyDown={keySelect(() => navigate(Paths.CampaignDetails(campaign.id)))}
            title='Details anzeigen'
            role='button'
            tabIndex={0}
          >
            <span className='CampaignPanel__TitlePrefix'>Kampagne: </span>
            <span className='CampaignPanel__Link'>{campaign.title}</span>
          </div>
        ) : (
          <div className='CampaignPanel__Title CampaignPanel__Title--Passive'>
            <span>Vorteile ohne Kampagne</span>
          </div>
        )}

        <div className='CampaignPanel__Space' />

        {campaign && showStatus && status}
        {campaign && editable && actions}
      </div>

      <AnimateHeight className='CampaignPanel__Content' height={open ? 'auto' : 0}>
        <>
          {offers.map((offer, index) => (
            <OfferPanel
              offer={offer}
              key={offer.id}
              editable={editable}
              onArchive={isNotArchived(offer) ? () => archiveOffer(offer.id) : undefined}
              onMoveUp={index > 0 ? () => reorder(offer.id, offers[index - 1].id) : undefined}
              onMoveDown={
                index < offers.length - 1
                  ? () => reorder(offer.id, offers[index + 1].id)
                  : undefined
              }
            />
          ))}
          {editable && (
            <div className='CampaignPanel__NewOffer'>
              <Button title='Neuer Vorteil' icon={Plus} to={Paths.OfferCreate(campaign?.id)} />
            </div>
          )}
        </>
      </AnimateHeight>
    </div>
  );
};

CampaignPanel.propTypes = {
  campaign: PropTypes.object,
  editable: PropTypes.bool,
  onArchive: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  showStatus: PropTypes.bool,
  standaloneOffers: PropTypes.arrayOf(PropTypes.object),
  reloadContainer: PropTypes.func
};

CampaignPanel.defaultProps = {
  editable: true,
  showStatus: true
};

export default CampaignPanel;
