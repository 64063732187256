import React from 'react';
import PropTypes from 'prop-types';

const IconPropTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  label: PropTypes.string
};

export const Left = ({ size, fill, label = 'Zurück' }) => (
  <svg version='1.1' width={size} viewBox='0 0 11 20' aria-label={label}>
    <path
      fill={fill}
      d='M10,20c0.4,0,0.7-0.2,0.8-0.6c0.1-0.3,0.1-0.7-0.2-1l-8.4-8.4l8.4-8.4c0.3-0.4,0.3-0.9,0-1.3S9.7,0,9.4,0.4
	L0.3,9.4c-0.4,0.4-0.4,0.9,0,1.3l9.1,9.1C9.5,19.9,9.8,20,10,20z'
    />
  </svg>
);
Left.propTypes = IconPropTypes;

export const Right = ({ size, fill, label = 'Vor' }) => (
  <svg version='1.1' width={size} viewBox='0 0 11 20' aria-label={label}>
    <path
      fill={fill}
      d='M1,20c-0.4,0-0.7-0.2-0.8-0.6s-0.1-0.7,0.2-1l8.4-8.4L0.4,1.6C0,1.3,0,0.7,0.4,0.4s0.9-0.4,1.3,0l9.1,9.1
	c0.4,0.4,0.4,0.9,0,1.3l-9.1,9.1C1.5,19.9,1.2,20,1,20z'
    />
  </svg>
);
Right.propTypes = IconPropTypes;

export const Search = ({ size, fill, label = 'Suchen' }) => (
  <svg version='1.1' width={size} viewBox='0 0 15.1 15.3' aria-label={label}>
    <path
      fill={fill}
      d='M0.6,15.3C0.3,15.3,0,15,0,14.6c0-0.1,0.1-0.4,0.1-0.5l3.7-3.7c0.3-0.3,0.6-0.3,0.9,0c0.3,0.3,0.3,0.6,0,0.9l0,0
    L1,15C1,15.1,0.8,15.3,0.6,15.3z'
    />
    <path
      fill={fill}
      d='M9.9,10.8c-3.1,0-5.5-2.4-5.5-5.5S6.9,0,9.8,0s5.3,2.4,5.3,5.3c0,1.4-0.5,2.8-1.5,3.8l0,0
    C12.7,10.2,11.3,10.8,9.9,10.8z M9.9,1.3c-2.3,0-4.2,1.9-4.2,4.1s1.9,4.1,4.1,4.1c1.1,0,2.2-0.4,2.9-1.3l0,0c1.7-1.7,1.7-4.2,0-5.9
    C12,1.7,10.9,1.3,9.9,1.3z'
    />
    <path
      fill={fill}
      d='M8,5.9c-0.4,0-0.6-0.3-0.6-0.6c0-0.1,0.1-0.4,0.1-0.5l1.9-1.9c0.3-0.3,0.6-0.3,0.9,0c0.3,0.3,0.3,0.6,0,0.9
    l-1.9,2C8.4,5.9,8.1,5.9,8,5.9z'
    />
  </svg>
);
Search.propTypes = IconPropTypes;

export const Up = ({ size, fill, label = 'Nach Oben' }) => (
  <svg version='1.1' width={size} viewBox='0 0 14 8' aria-label={label}>
    <path
      fill={fill}
      id='Path'
      d='M14,7.3c0,0.3-0.2,0.5-0.4,0.6c-0.2,0.1-0.5,0-0.7-0.1L7,1.8L1.1,7.7c-0.2,0.2-0.6,0.2-0.9,0C0,7.4,0,7.1,0.2,6.8
	l6.3-6.3c0.2-0.2,0.7-0.2,0.9,0l6.3,6.3C14,6.9,14,7.1,14,7.3z'
    />
  </svg>
);
Up.propTypes = IconPropTypes;

export const Down = ({ size, fill, label = 'Nach Unten' }) => (
  <svg version='1.1' width={size} viewBox='0 0 14 8' aria-label={label}>
    <path
      fill={fill}
      id='Path'
      d='M14,0.9c0-0.3-0.2-0.5-0.4-0.6s-0.5,0-0.7,0.1L7,6.4L1.1,0.5c-0.2-0.2-0.6-0.2-0.9,0S0,1.1,0.2,1.4l6.3,6.3
	c0.2,0.2,0.7,0.2,0.9,0l6.3-6.3C14,1.2,14,1.1,14,0.9z'
    />
  </svg>
);
Down.propTypes = IconPropTypes;

export const Percent = ({ size, fill, label = 'Prozent' }) => (
  <svg version='1.1' width={size} viewBox='0 0 36 36' aria-label={label}>
    <path
      fill={fill}
      d='M2.1,35.5c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.3L32.8,1c0.6-0.6,1.6-0.6,2.3,0
	c0.6,0.6,0.6,1.6,0,2.3L3.2,35C2.9,35.3,2.5,35.5,2.1,35.5'
    />
    <path
      fill={fill}
      d='M7.9,3.7c-2.3,0-4.2,1.9-4.2,4.2S5.6,12,7.9,12S12,10.2,12,7.9S10.2,3.7,7.9,3.7 M7.9,15.2
	c-4.1,0-7.4-3.3-7.4-7.4s3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4S11.9,15.2,7.9,15.2'
    />
    <path
      fill={fill}
      d='M28.1,24c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2S30.4,24,28.1,24
	 M28.1,35.5c-4.1,0-7.4-3.3-7.4-7.4c0-4.1,3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4C35.5,32.2,32.2,35.5,28.1,35.5'
    />
  </svg>
);
Percent.propTypes = IconPropTypes;

export const Calendar = ({ size, fill, label = 'Datum' }) => (
  <svg version='1.1' width={size} viewBox='0 0 40 37' aria-label={label}>
    <path
      fill={fill}
      d='M37.5,4.9h-4.8V3.3c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6v1.6H10.5V3.3c0-0.9-0.7-1.6-1.6-1.6
	C8,1.7,7.3,2.4,7.3,3.3v1.6H2.5c-0.9,0-1.6,0.7-1.6,1.6v28.6c0,0.9,0.7,1.6,1.6,1.6h22.2c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6
	H4.1V17.6h31.8v17.5c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V6.5C39.1,5.6,38.4,4.9,37.5,4.9z M4.1,14.4V8.1h3.2v1.6
	c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V8.1h19.1v1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V8.1h3.2v6.3L4.1,14.4z'
    />
    <path
      fill={fill}
      d='M13.7,20.8h-3.2c-0.9,0-1.6,0.7-1.6,1.6S9.6,24,10.5,24h3.2c0.9,0,1.6-0.7,1.6-1.6S14.6,20.8,13.7,20.8z'
    />
    <path
      fill={fill}
      d='M13.7,27.2h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S14.6,27.2,13.7,27.2z'
    />
    <path
      fill={fill}
      d='M29.5,20.8h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S30.4,20.8,29.5,20.8z'
    />
    <path
      fill={fill}
      d='M29.5,27.2h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S30.4,27.2,29.5,27.2z'
    />
    <path
      fill={fill}
      d='M21.6,27.2h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S22.5,27.2,21.6,27.2
	L21.6,27.2z'
    />
    <path
      fill={fill}
      d='M21.6,20.8h-3.2c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h3.2c0.9,0,1.6-0.7,1.6-1.6S22.5,20.8,21.6,20.8
	L21.6,20.8z'
    />
  </svg>
);
Calendar.propTypes = IconPropTypes;

export const Close = ({ size, fill, label = 'Schließen' }) => (
  <svg version='1.1' width={size} viewBox='0 0 24 24' aria-label={label}>
    <path
      fill={fill}
      d='M13.8,12l9.4-9.4c0.3-0.3,0.5-0.8,0.4-1.3c-0.1-0.4-0.5-0.8-0.9-0.9s-0.9,0-1.3,0.4L12,10.2L2.6,0.8
	C2.3,0.5,1.8,0.3,1.4,0.5S0.6,0.9,0.5,1.4s0,0.9,0.4,1.3l9.4,9.4l-9.4,9.4c-0.4,0.4-0.5,0.9-0.3,1.4c0.2,0.5,0.7,0.8,1.2,0.8
	c0.3,0,0.7-0.1,0.9-0.4l9.4-9.4l9.4,9.4c0.3,0.3,0.8,0.5,1.3,0.4c0.4-0.1,0.8-0.5,0.9-0.9c0.1-0.4,0-0.9-0.4-1.3L13.8,12z'
    />
  </svg>
);
Close.propTypes = IconPropTypes;

export const User = ({ size, fill, label = 'Benutzer' }) => (
  <svg version='1.1' width={size} viewBox='0 0 18 24' aria-label={label}>
    <path
      fill={fill}
      d='M9,12.5c-2.5,0-4.8-1.5-5.7-3.8s-0.4-5,1.3-6.8s4.4-2.3,6.8-1.3s3.8,3.2,3.8,5.7l0,0c0,1.6-0.7,3.2-1.8,4.4
	C12.2,11.9,10.6,12.5,9,12.5L9,12.5z M9,1.9C7.2,1.9,5.6,3,4.9,4.6S4.6,8.2,5.9,9.4c1.3,1.3,3.1,1.6,4.8,1c1.6-0.7,2.7-2.3,2.7-4
	l0,0C13.4,3.9,11.4,1.9,9,1.9z'
    />
    <path
      fill={fill}
      d='M9.6,23.7H1.1c-0.5,0-0.9-0.3-0.9-0.8l0-0.2v-5.6c0-1.6,1.3-3,3-3h11.7c1.6,0,3,1.3,3,3v5.5
	c0,0.5-0.4,0.9-0.9,0.9S16,23.1,16,22.6v-5.5c0-0.6-0.5-1.2-1.2-1.2H3.2c-0.6,0-1.2,0.5-1.2,1.2v4.8h7.6c0.5,0,0.9,0.4,0.9,0.9
	C10.5,23.3,10.1,23.7,9.6,23.7L9.6,23.7z'
    />
  </svg>
);
User.propTypes = IconPropTypes;

export const Plus = ({ size, fill, label = 'Hinzufügen' }) => (
  <svg version='1.1' width={size} viewBox='0 0 16 16' aria-label={label}>
    <path
      fill={fill}
      d='M15.2,7.4H8.6V0.8c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6v6.6H0.8C0.4,7.4,0.2,7.6,0.2,8
	c0,0.4,0.3,0.6,0.6,0.6h6.6v6.6c0,0.4,0.3,0.6,0.6,0.6c0.4,0,0.6-0.3,0.6-0.6V8.6h6.6c0.4,0,0.6-0.3,0.6-0.6
	C15.8,7.6,15.6,7.4,15.2,7.4z'
    />
  </svg>
);
Plus.propTypes = IconPropTypes;

export const Info = ({ size, fill, label = 'Details' }) => (
  <svg version='1.1' width={size} viewBox='0 0 6.7 19.8' aria-label={label}>
    <path
      fill={fill}
      d='M3.3,6.7C2,6.7,0.8,5.9,0.3,4.6S0,1.9,1,1s2.4-1.2,3.6-0.7S6.7,2,6.7,3.3C6.7,5.2,5.2,6.7,3.3,6.7
	L3.3,6.7z M3.3,1.3c-0.8,0-1.6,0.5-1.9,1.3S1.3,4.2,1.9,4.8s1.5,0.8,2.3,0.4s1.3-1.1,1.3-1.9C5.4,2.2,4.5,1.3,3.3,1.3z'
    />
    <path
      fill={fill}
      d='M5.9,19.8H0.7c-0.4,0-0.6-0.3-0.6-0.6v-10c0-0.4,0.3-0.6,0.6-0.6h5.2c0.4,0,0.6,0.3,0.6,0.6v5.4
	c0,0.4-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6V9.8H1.4v8.7h4.5c0.4,0,0.6,0.3,0.6,0.6S6.3,19.8,5.9,19.8L5.9,19.8z'
    />
  </svg>
);
Info.propTypes = IconPropTypes;

export const Chart = ({ size, fill, label = 'Grafik' }) => (
  <svg version='1.1' width={size} viewBox='0 0 16.5 15.9' aria-label={label}>
    <path
      fill={fill}
      d='M4.5,12.7c-0.4,0-0.6-0.3-0.6-0.6V8.3c0-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v3.8
	C5.1,12.4,4.8,12.7,4.5,12.7z'
    />
    <path
      fill={fill}
      d='M8.3,12.7c-0.4,0-0.6-0.3-0.6-0.6V4.5c0-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v7.6
	C8.9,12.4,8.6,12.7,8.3,12.7z'
    />
    <path
      fill={fill}
      d='M12.1,12.7c-0.4,0-0.6-0.3-0.6-0.6V0.6c0-0.4,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v11.5
	C12.7,12.4,12.4,12.7,12.1,12.7z'
    />
    <path
      fill={fill}
      d='M15.9,15.9H0.6c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h15.3c0.4,0,0.6,0.3,0.6,0.6
	S16.3,15.9,15.9,15.9z'
    />
  </svg>
);
Chart.propTypes = IconPropTypes;

export const Minus = ({ size, fill, label = 'Entfernen' }) => (
  <svg version='1.1' width={size} viewBox='0 0 16 2' aria-label={label}>
    <path
      fill={fill}
      d='M8.6,1.6h6.6c0.4,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H8.6H7.4H0.8C0.4,0.4,0.2,0.6,0.2,1
	s0.3,0.6,0.6,0.6h6.6H8.6z'
    />
  </svg>
);
Minus.propTypes = IconPropTypes;

export const Tick = ({ size, fill, label = 'Ok' }) => (
  <svg version='1.1' width={size} viewBox='0 0 16 12' aria-label={label}>
    <path
      fill={fill}
      d='M5.3,11.3c-0.2,0-0.3-0.1-0.4-0.2L0.2,6.5C0,6.3,0,6.1,0,5.8c0.1-0.2,0.2-0.4,0.5-0.5s0.5,0,0.6,0.2l4.2,4.2
	l8.9-8.9c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0.1,0.4,0.2,0.5,0.5s0,0.5-0.2,0.6l-9.3,9.3C5.6,11.2,5.5,11.3,5.3,11.3z'
    />
  </svg>
);
Tick.propTypes = IconPropTypes;

export const Help = ({ size, fill, label = 'Informationen' }) => (
  <svg version='1.1' width={size} viewBox='0 0 10.3 16.6' aria-label={label}>
    <path
      fill={fill}
      d='M5.1,16.6c-0.4,0-0.6-0.3-0.6-0.6v-0.8c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6v0.8
	C5.8,16.3,5.5,16.6,5.1,16.6z'
    />
    <path
      fill={fill}
      d='M5.1,12.8c-0.4,0-0.6-0.3-0.6-0.6V9.7C4.5,9.3,4.8,9,5.1,9c1.6,0,3-0.9,3.6-2.4C9.3,5.2,9,3.5,7.9,2.4
	S5.1,1,3.7,1.6s-2.4,2-2.4,3.6c0,0.4-0.3,0.6-0.6,0.6S0,5.5,0,5.2c0-2.8,2.2-5,4.9-5.2s5.1,2,5.3,4.7c0.2,2.8-1.8,5.2-4.5,5.5v1.9
	c0,0.2-0.1,0.3-0.2,0.5C5.5,12.7,5.3,12.8,5.1,12.8z'
    />
  </svg>
);
Help.propTypes = IconPropTypes;

export const Edit = ({ size, fill, label = 'Bearbeiten' }) => (
  <svg version='1.1' width={size} viewBox='0 0 17.2 17.2' aria-label={label}>
    <path
      fill={fill}
      d='M7.3,13.7c-0.2,0-0.4-0.1-0.5-0.2l-3.1-3c-0.3-0.3-0.3-0.7,0-1l9.2-9.2c0.3-0.3,0.7-0.3,1,0L17,3.3
	c0.3,0.3,0.3,0.7,0,1c-0.3,0.3-0.7,0.3-1,0l-2.5-2.5L5.3,9.9l2,2l4.6-4.6c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1l-5.1,5.1
	C7.7,13.6,7.5,13.7,7.3,13.7z'
    />
    <path
      fill={fill}
      d='M0.7,17.2c-0.2,0-0.4-0.1-0.6-0.3C0,16.7,0,16.5,0,16.3l1.5-4.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5,0,0.7,0.2
	l3.1,3.1C6,14.6,6.1,14.9,6,15.1c-0.1,0.2-0.2,0.4-0.5,0.5l-4.6,1.5C0.9,17.2,0.8,17.2,0.7,17.2z M2.6,13.3l-0.7,2.1L4,14.7
	L2.6,13.3z'
    />
  </svg>
);
Edit.propTypes = IconPropTypes;

export const Eye = ({ size, fill, label = 'Sichtbar' }) => (
  <svg version='1.1' width={size} viewBox='0 0 20.6 12.3' aria-label={label}>
    <path
      fill={fill}
      d='M10.4,3.8C9.1,3.8,8,4.9,8,6.2c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4C12.7,4.9,11.7,3.8,10.4,3.8
	 M10.4,9.8c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6S14,4.2,14,6.2S12.4,9.8,10.4,9.8'
    />
    <path
      fill={fill}
      className='st0'
      d='M10.4,12.3c-5.5,0-9.9-5.5-10.1-5.8L0,6.2l0.3-0.4C0.5,5.5,4.8,0,10.4,0s9.9,5.5,10.1,5.8
	c0.2,0.3,0.2,0.7-0.1,0.9c-0.3,0.2-0.7,0.2-0.9-0.1c0-0.1-4.2-5.3-9.1-5.3c-4.1,0-7.7,3.7-8.7,4.9C2.7,7.4,6.3,11,10.4,11
	c1.8,0,3.8-0.7,5.8-2.2C16.4,8.7,16.8,8.7,17,9c0.2,0.3,0.1,0.7-0.1,0.9C14.7,11.5,12.5,12.3,10.4,12.3'
    />
  </svg>
);
Eye.propTypes = IconPropTypes;

export const NoEye = ({ size, fill, label = 'Nicht sichtbar' }) => (
  <svg version='1.1' width={size} viewBox='0 0 20.6 14.1' aria-label={label}>
    <path
      fill={fill}
      d='M1.6,7c1.1-1.2,4.6-4.9,8.7-4.9c1.1,0,2.2,0.3,3.2,0.7L12.4,4c-0.6-0.4-1.3-0.6-2-0.6
	C8.4,3.4,6.7,5,6.7,7c0,0.8,0.2,1.5,0.6,2l-1.5,1.5C3.8,9.4,2.3,7.8,1.6,7 M12.7,7c0,1.3-1.1,2.4-2.4,2.4C9.9,9.4,9.5,9.3,9.2,9
	l3.2-3.2C12.6,6.2,12.7,6.6,12.7,7 M8,7c0-1.3,1.1-2.4,2.4-2.4c0.4,0,0.8,0.1,1.1,0.3L8.3,8.1C8.1,7.8,8,7.4,8,7 M10.4,10.7
	c2,0,3.6-1.6,3.6-3.6c0-0.8-0.3-1.5-0.7-2.1l3.8-3.8c0.2-0.2,0.2-0.7,0-0.9c-0.2-0.2-0.7-0.2-0.9,0l-1.7,1.7c-1.3-0.6-2.7-1-4.2-1
	c-5.5,0-9.9,5.5-10.1,5.8L0,7l0.3,0.4c0.1,0.1,1.9,2.4,4.6,4.1L3.4,13c-0.2,0.2-0.2,0.7,0,0.9c0.1,0.1,0.3,0.2,0.5,0.2
	c0.2,0,0.3-0.1,0.4-0.2L8.3,10C8.9,10.4,9.6,10.7,10.4,10.7'
    />
    <path
      fill={fill}
      d='M20.3,6.6c0-0.1-1.2-1.6-3.2-3.1c-0.3-0.2-0.7-0.2-0.9,0.1c-0.2,0.3-0.2,0.7,0.1,0.9
	c1.2,1,2.1,2,2.6,2.5c-1.1,1.2-4.6,4.9-8.6,4.9c-0.6,0-1.2-0.1-1.8-0.2c-0.3-0.1-0.7,0.1-0.8,0.5c-0.1,0.3,0.1,0.7,0.5,0.8
	c0.7,0.2,1.4,0.3,2.1,0.3c5.5,0,9.8-5.6,10-5.8L20.6,7L20.3,6.6z'
    />
  </svg>
);
NoEye.propTypes = IconPropTypes;

export const Archive = ({ size, fill, label = 'Archiv' }) => (
  <svg version='1.1' width={size} viewBox='0 0 15.2 13.1' aria-label={label}>
    <path
      fill={fill}
      style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
      d='M1.3,3.5h12.6V1.3H1.3V3.5z M14.5,0H0.6C0.3,0,0,0.3,0,0.6v3.5c0,0.4,0.3,0.6,0.6,0.6h0.5v7.7
	c0,0.4,0.3,0.6,0.6,0.6h7c0.4,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.6-0.6-0.6H2.4V4.8h10.4v7.7c0,0.4,0.3,0.6,0.6,0.6
	c0.4,0,0.6-0.3,0.6-0.6V4.8h0.5c0.4,0,0.6-0.3,0.6-0.6V0.6C15.2,0.3,14.9,0,14.5,0L14.5,0z'
    />
    <path
      fill={fill}
      style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
      d='M8.8,7.2H6.4C6,7.2,5.7,6.9,5.7,6.6C5.7,6.2,6,6,6.4,6h2.4c0.4,0,0.6,0.3,0.6,0.6
	C9.4,6.9,9.1,7.2,8.8,7.2'
    />
  </svg>
);
Archive.propTypes = IconPropTypes;

export const Qr = ({ size, fill, label = 'QR-Code' }) => (
  <svg version='1.1' width={size} viewBox='0 0 18 18' aria-label={label}>
    <path
      fill={fill}
      d='M5.1,18H2.3C1,18,0,17,0,15.7v-2.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v2.8
	c0,0.4,0.3,0.7,0.7,0.7h2.8c0.4,0,0.8,0.4,0.8,0.8S5.6,18,5.1,18'
    />
    <path
      fill={fill}
      d='M15.7,18h-2.8c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h2.8c0.4,0,0.7-0.3,0.7-0.7v-2.8
	c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v2.8C18,17,17,18,15.7,18'
    />
    <path
      fill={fill}
      d='M17.2,5.9c-0.4,0-0.8-0.4-0.8-0.8V2.3c0-0.4-0.3-0.7-0.7-0.7h-2.8c-0.4,0-0.8-0.4-0.8-0.8
	c0-0.4,0.4-0.8,0.8-0.8h2.8C17,0,18,1,18,2.3v2.8C18,5.6,17.6,5.9,17.2,5.9'
    />
    <path
      fill={fill}
      d='M0.8,5.9C0.4,5.9,0,5.6,0,5.1V2.3C0,1,1,0,2.3,0h2.8c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8
	H2.3c-0.4,0-0.7,0.3-0.7,0.7v2.8C1.6,5.6,1.2,5.9,0.8,5.9'
    />
    <path
      fill={fill}
      d='M7.3,6.5c-0.5,0-0.9,0.4-0.9,0.9v3.3c0,0.5,0.4,0.9,0.9,0.9h3.3c0.5,0,0.9-0.4,0.9-0.9V7.3
	c0-0.5-0.4-0.9-0.9-0.9H7.3z M10.6,13.1H7.3c-1.3,0-2.4-1.1-2.4-2.4V7.3C4.9,6,6,4.9,7.3,4.9h3.3c1.3,0,2.4,1.1,2.4,2.4v3.3
	C13.1,12,12,13.1,10.6,13.1L10.6,13.1z'
    />
  </svg>
);
Qr.propTypes = IconPropTypes;

export const Euro = ({ size, fill, label = 'Einkauf' }) => (
  <svg version='1.1' width={size} viewBox='0 0 14 15.6' aria-label={label}>
    <path
      fill={fill}
      d='M13.1,13.6c-1.7,0.9-3.8,1-5.6,0.2C5.7,13,4.4,11.4,3.8,9.6h5.4c0.4,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.6-0.6-0.6H3.6
	c0-0.2-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0.1-0.5h6.7C10.7,7.3,11,7,11,6.6S10.7,6,10.4,6H3.8c0.5-1.9,1.9-3.4,3.7-4.2
	C9.3,1,11.3,1.1,13.1,2c0.3,0.2,0.7,0,0.9-0.3c0.2-0.3,0-0.7-0.3-0.9C11.5-0.2,9-0.3,6.8,0.7S3,3.7,2.5,6H0.6C0.3,6,0,6.3,0,6.6
	s0.3,0.6,0.6,0.6h1.7c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5H0.6C0.3,8.3,0,8.6,0,8.9c0,0.4,0.3,0.6,0.6,0.6h1.9
	c0.5,2.3,2.1,4.3,4.3,5.3c2.2,1,4.7,0.9,6.8-0.2c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.1-0.5-0.3-0.6S13.3,13.4,13.1,13.6L13.1,13.6z'
    />
  </svg>
);
Euro.propTypes = IconPropTypes;

export const Article = ({ size, fill, label = 'Artikel' }) => (
  <svg version='1.1' width={size} viewBox='0 0 17 18.7' aria-label={label}>
    <path
      fill={fill}
      d='M16.1,18.7c-0.5,0-0.8-0.4-0.8-0.9V1.7H1.7V17h7.7c0.5,0,0.8,0.4,0.8,0.9s-0.4,0.9-0.8,0.9H0.9
	c-0.5,0-0.9-0.4-0.9-0.9v-17C0,0.4,0.4,0,0.9,0h15.3C16.6,0,17,0.4,17,0.9v17C17,18.3,16.6,18.7,16.1,18.7z'
    />
    <path
      fill={fill}
      d='M12.3,6.3H4.7c-0.5,0-0.8-0.4-0.8-0.9c0-0.5,0.4-0.8,0.8-0.8h7.6c0.5,0,0.9,0.4,0.9,0.8
	C13.1,5.9,12.8,6.3,12.3,6.3z'
    />
    <path
      fill={fill}
      d='M12.3,10.2H4.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.9,0.8-0.9h7.6c0.5,0,0.9,0.4,0.9,0.9
	C13.1,9.8,12.8,10.2,12.3,10.2z'
    />
    <path
      fill={fill}
      d='M12.3,14.1H4.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.9,0.8-0.9h7.6c0.5,0,0.9,0.4,0.9,0.9
	C13.1,13.7,12.8,14.1,12.3,14.1z'
    />
  </svg>
);
Article.propTypes = IconPropTypes;

export const Upload = ({ size, fill, label = 'Hochladen' }) => (
  <svg version='1.1' width={size} viewBox='0 0 16 17' aria-label={label}>
    <path
      fill={fill}
      d='M14.2,17H1.8c-1,0-1.8-0.8-1.8-1.8v-1.9c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v1.9c0,0.1,0,0.2,0.1,0.3
	s0.2,0.1,0.3,0.1h12.3c0.1,0,0.2,0,0.3-0.1s0.1-0.2,0.1-0.3v-1.9c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v1.9
	C16,16.2,15.2,17,14.2,17z'
    />
    <path
      fill={fill}
      d='M8,0L8,0C7.8,0,7.6,0.1,7.5,0.2L4.3,3.3C4,3.6,4,4,4.3,4.3c0.3,0.3,0.7,0.3,1,0L8,1.7l2.6,2.6
	c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.7,0-1L8.5,0.2C8.4,0.1,8.2,0,8,0z'
    />
    <path
      fill={fill}
      d='M8,4.3L8,4.3C7.6,4.3,7.3,4.6,7.3,5v7.3C7.3,12.7,7.6,13,8,13l0,0c0.4,0,0.7-0.3,0.7-0.7V5
	C8.7,4.6,8.4,4.3,8,4.3L8,4.3z'
    />
  </svg>
);
Upload.propTypes = IconPropTypes;

export const Gas = ({ size, fill, label = 'Gas' }) => (
  <svg version='1.1' width={size} viewBox='0 0 14 19.1' aria-label={label}>
    <path
      fill={fill}
      d='M6.2,0.1C6,0,5.7,0,5.4,0.1C5.2,0.3,5.1,0.6,5.2,0.9C5.3,1,6.6,4.2,2.6,7.1C1,8.3,0.1,10.2,0,12.2
	c0,3.9,3.1,7,7,7c0.1,0,0.2,0,0.3-0.1c1.6-0.2,2.9-1.4,3.3-2.9c0.2-0.5,1-3.2-2.5-7.4C7.9,8.4,7.6,8.4,7.3,8.5S6.9,8.9,7,9.2
	c0,0,0.3,1.6-2,2.5c-1,0.5-1.8,1.4-2.1,2.5c-0.2,0.6-0.1,1.3,0,2c-1-1.1-1.6-2.5-1.6-4c0.1-1.6,0.8-3,2-4c2.1-1.3,3.4-3.5,3.4-6
	c6.7,5.5,6,9.8,6,9.9c0,0,0,0.1,0,0.1c0,0.6-0.1,1.1-0.3,1.7c-0.1,0.2,0,0.5,0.1,0.6s0.4,0.2,0.6,0.2c0.2-0.1,0.4-0.2,0.5-0.4
	c0.2-0.6,0.3-1.3,0.3-2C14.1,11.5,14.6,6.3,6.2,0.1z M4.1,14.5c0.2-0.7,0.7-1.3,1.4-1.6c1.1-0.4,2-1.2,2.5-2.2c2.2,3.2,1.4,5,1.4,5
	c0,0,0,0.1,0,0.1c-0.4,1.4-1.8,2.3-3.3,2S3.7,15.9,4.1,14.5L4.1,14.5z'
    />
  </svg>
);
Gas.propTypes = IconPropTypes;

export const Water = ({ size, fill, label = 'Wasser' }) => (
  <svg version='1.1' width={size} viewBox='0 0 9.1 13.7' aria-label={label}>
    <path
      fill={fill}
      d='M4.6,13.7c-2.2,0-4.1-1.6-4.5-3.8c-0.3-1.9,0.7-3.5,1.5-5c0.2-0.4,0.5-0.8,0.7-1.2l1.8-3.5C4.2,0,4.5-0.1,4.8,0
	S5.1,0.4,5,0.7L3.3,4.1C3,4.5,2.8,5,2.5,5.4C1.7,6.8,0.8,8.2,1.1,9.7c0.3,1.9,2.1,3.2,4,2.9c0.8-0.1,1.5-0.5,2-1.1
	c0.7-0.7,1-1.6,1-2.5C8,8.2,7.7,7.5,7.3,6.8C7.2,6.6,7.1,6.5,7,6.3L5.9,4.2C5.8,4,5.9,3.7,6.1,3.5s0.5,0,0.7,0.2l1.1,2.1
	C8,6,8.1,6.1,8.2,6.3C8.7,7.1,9,8,9.1,8.9c0,1.2-0.4,2.4-1.3,3.2C7,13.1,5.8,13.6,4.6,13.7z'
    />
    <path
      fill={fill}
      d='M4.4,11.2c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0.1-0.3,0.1-0.4l1.4-1.4c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7L4.7,11
	C4.7,11.1,4.5,11.2,4.4,11.2z'
    />
  </svg>
);
Water.propTypes = IconPropTypes;

export const Electricity = ({ size, fill, label = 'Elektrizität' }) => (
  <svg version='1.1' width={size} viewBox='0 0 17.3 17.3' aria-label={label}>
    <path
      fill={fill}
      d='M6.4,6.5L4.6,8.3c-0.2,0.2-0.2,0.7,0,0.9s0.7,0.2,0.9,0l1.8-1.8C7.5,7.2,7.5,7,7.5,6.7C7.4,6.5,7.3,6.3,7,6.3
	C6.8,6.2,6.6,6.3,6.4,6.5L6.4,6.5z'
    />
    <path
      fill={fill}
      d='M17.1,4.2c-0.2-0.2-0.7-0.2-0.9,0l-3.6,3.6L9.5,4.7l3.6-3.6c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0
	L8.6,3.8l-2-2c-0.3-0.2-0.6-0.2-0.9,0L2.9,4.5c-2.6,2.5-2.7,6.6-0.4,9.3l-2.3,2.3C0,16.4,0,16.6,0,16.8s0.2,0.4,0.5,0.5
	s0.5,0,0.6-0.2l2.3-2.3c1.2,1,2.8,1.6,4.4,1.6c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.6-0.4,0.5-0.7c0-0.3-0.4-0.6-0.7-0.6
	c-2.4,0.3-4.8-0.9-5.8-3.1S2.1,7.2,3.8,5.5l2.3-2.3l2,2l0,0l0,0l6,6l-1.4,1.4c-0.2,0.2-0.2,0.6,0,0.9c0.2,0.2,0.6,0.2,0.9,0l1.9-1.9
	c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.3-0.2-0.5l-2-2l3.6-3.6C17.4,4.9,17.4,4.5,17.1,4.2z'
    />
  </svg>
);
Electricity.propTypes = IconPropTypes;

export const CheckboxTick = ({ size, fill, label = 'Auswahl' }) => (
  <svg version='1.1' width={size} viewBox='0 0 17 13.3' aria-label={label}>
    <path
      fill={fill}
      d='M5.8,13.3L0.4,7.9c-0.6-0.6-0.6-1.5,0-2.1C1,5.2,2,5.2,2.6,5.8l3.3,3.3l8.6-8.6c0.6-0.6,1.5-0.6,2.1,0
	c0.6,0.6,0.6,1.5,0,2.1L5.8,13.3z'
    />
  </svg>
);
CheckboxTick.propTypes = IconPropTypes;

export const Duplicate = ({ size, fill, label = 'Duplizieren' }) => (
  <svg version='1.1' width={size} viewBox='0 0 11.8 11.8' aria-label={label}>
    <path
      fill={fill}
      d='M8,3.8L8,3.8V9v2.3c0,0.3,0.2,0.5,0.5,0.5S9,11.6,9,11.3V9h2.3c0.3,0,0.5-0.2,0.5-0.5v-8c0-0.3-0.2-0.5-0.5-0.5h-8
	C3,0,2.8,0.2,2.8,0.5v2.3H0.5C0.2,2.8,0,3,0,3.3v8c0,0.3,0.2,0.5,0.5,0.5H5c0.3,0,0.5-0.2,0.5-0.5S5.2,10.8,5,10.8H1v-7h1.8L8,3.8z
	 M8,3.8 M10.8,8H9V3.3C9,3,8.8,2.8,8.5,2.8H3.8V1h7V8z'
    />
  </svg>
);
Duplicate.propTypes = IconPropTypes;

export const Form = ({ size, fill, label = 'Formular' }) => (
  <svg version='1.1' width={size} viewBox='0 0 24 20' aria-label={label}>
    <path
      fill={fill}
      d='M8,8.8H1.5c-0.7,0-1.2-0.6-1.2-1.2v-5c0-0.7,0.6-1.2,1.2-1.2H14c0.4,0,0.8,0.3,0.8,0.8S14.4,2.8,14,2.8H1.8v4.5
	H8c0.4,0,0.8,0.3,0.8,0.8S8.4,8.8,8,8.8z'
    />
    <path
      fill={fill}
      d='M18.5,19.8h-17c-0.7,0-1.2-0.6-1.2-1.2v-5c0-0.7,0.6-1.2,1.2-1.2H5c0.4,0,0.8,0.3,0.8,0.8S5.4,13.8,5,13.8H1.8
	v4.5h16.5V14c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.5C19.8,19.2,19.2,19.8,18.5,19.8z'
    />
    <path
      fill={fill}
      d='M14.2,13.5c-0.2,0-0.4-0.1-0.5-0.2l-3-3c-0.3-0.3-0.3-0.7,0-1l9-9c0.3-0.3,0.7-0.3,1,0l3,3c0.3,0.3,0.3,0.7,0,1
	c-0.3,0.3-0.7,0.3-1,0l-2.5-2.5l-8,8l2,2l4.5-4.5c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1l-5,5C14.6,13.4,14.4,13.5,14.2,13.5z'
    />
    <path
      fill={fill}
      d='M7.7,17c-0.2,0-0.4-0.1-0.6-0.3C7,16.5,7,16.3,7,16.1l1.5-4.5c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5,0,0.7,0.2
	l3,3c0.2,0.2,0.2,0.4,0.2,0.7s-0.2,0.4-0.5,0.5L7.9,17C7.9,17,7.8,17,7.7,17z M9.5,13.1l-0.7,2.1l2.1-0.7L9.5,13.1z'
    />
  </svg>
);
Form.propTypes = IconPropTypes;
