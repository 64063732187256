import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import './MultiPicker.scss';

const MultiPicker = ({ disabled, options, pickedKeys, onUpdatePickedKeys }) => {
  const toggleOption = name => {
    if (pickedKeys.includes(name)) {
      const previous = pickedKeys;
      const current = pickedKeys.filter(key => name !== key);
      onUpdatePickedKeys(current, previous);
    } else {
      const previous = pickedKeys;
      const current = [...pickedKeys, name];
      onUpdatePickedKeys(current, previous);
    }
  };
  const makePickerElement = useCallback(
    option => {
      const isSelected = pickedKeys.includes(option.key);
      const buttonVariant = isSelected
        ? option.variantSelected ?? 'ORANGE_FILL'
        : option.variantDeselected ?? 'ORANGE_OUTLINE';
      return (
        <div key={option.key} className='MultiPicker__Item'>
          <Button
            title={option.label}
            onClick={() => toggleOption(option.key)}
            variant={buttonVariant}
          />
        </div>
      );
    },
    [pickedKeys]
  );

  const makeDisplayElement = useCallback(
    option => (
      <div className='MultiPicker__Item MultiPicker__Display' key={option.key}>
        <Button title={option.label} />
      </div>
    ),
    []
  );

  if (!options) {
    return null;
  }

  return (
    <div className={disabled ? 'MultiPicker MultiPicker--disabled' : 'MultiPicker'}>
      {onUpdatePickedKeys
        ? options.map(option => makePickerElement(option))
        : options
            .filter(option => pickedKeys.indexOf(option.key) >= 0)
            .map(option => makeDisplayElement(option))}
    </div>
  );
};

MultiPicker.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      variantSelected: PropTypes.string,
      variantDeselected: PropTypes.string
    })
  ),
  pickedKeys: PropTypes.arrayOf(PropTypes.any),
  onUpdatePickedKeys: PropTypes.func
};

export default MultiPicker;
