import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getPreviews } from '../../api/previews';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const previewsLocation = 'previews';

/**
 * Requests that the given page of the given query is made present.
 */
export const previewsRequestPage = pagingRequestPage(previewsLocation, getPreviews);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectPreviews = pagingSelectItems(previewsLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectPreviewsMode = pagingSelectMode(previewsLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectPreviewsError = pagingSelectError(previewsLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectPreviewsQuery = pagingSelectQuery(previewsLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectPreviewsTotal = pagingSelectTotal(previewsLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectPreviewsTotalItems = pagingSelectTotalItems(previewsLocation);

/**
 * Reducer for previews listing.
 */
export const previewsReducer = pagingReducer(previewsLocation);
