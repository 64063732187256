import React from 'react';
import PropTypes from 'prop-types';
import './FormRow.scss';

const FormRow = ({ children, spaceBetween, lastItemFixed }) => {
  return (
    <div
      className={`FormRow ${spaceBetween ? 'FormRow--Spaced' : ''} ${
        lastItemFixed ? 'FormRow--LastFixed' : ''
      }`}
    >
      {children?.length > 1 ? (
        children.map((elem, index) => (
          <div key={index} className='FormRow__Element'>
            {elem}
          </div>
        ))
      ) : (
        <div className='FormRow__Element'>{children}</div>
      )}
    </div>
  );
};

FormRow.propTypes = {
  children: PropTypes.array,
  spaceBetween: PropTypes.bool,
  lastItemFixed: PropTypes.bool
};

export default FormRow;
