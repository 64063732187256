/**
 * Filters the object's entries via their keys.
 * @param object The object.
 * @param predicate The predicate. Takes the key as the first argument, then index and the array of entries.
 * @return Returns the filtered object or null or undefined if given that initially.
 */
export const filterKeys = (object, predicate) => {
  // Handle known values.
  if (object === undefined) return undefined;
  if (object === null) return null;

  // Filter via entries.
  return Object.fromEntries(
    Object.entries(object).filter(([key], index, array) => predicate(key, index, array))
  );
};

/**
 * Filters the object's entries via their values.
 * @param object The object.
 * @param predicate The predicate. Takes the value as the first argument, then index and the array of entries.
 * @return Returns the filtered object or null or undefined if given that initially.
 */
export const filterValues = (object, predicate) => {
  // Handle known values.
  if (object === undefined) return undefined;
  if (object === null) return null;

  // Filter via entries.
  return Object.fromEntries(
    Object.entries(object).filter(([, value], index, array) => predicate(value, index, array))
  );
};
