import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Tick } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import './ResourceEdit.scss';
import PageTitle from '../../helpers/pageTitle';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { remoteAction, useEntityEdit } from './common';
import { updateResource } from '../../api/resources';
import DisplayGroup from '../atoms/DisplayGroup';
import Textbox from '../atoms/input/Textbox';
import RichtextEditor from '../atoms/input/RichtextEditor';
import SubstitutionIndicator from '../atoms/SubstitutionIndicator';
import { useResource } from '../../hooks/useRemoteValue';
import useIdParam from '../../hooks/useIdParam';

const init = () => ({});
const ResourceEdit = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Use partner of ID. Connect amend function that merges the incoming changes. Get changed status.
  const { entity, loading, amendEntity, changed } = useEntityEdit(useResource, id, {
    init
  });

  // Content and setter. Amends content.
  const content = entity?.content?.value || '';
  const contentType = entity?.content?.type || 'text/plain';
  const setContent = value => amendEntity({ content: { type: contentType, value } });

  // Submits the create or the change.
  const submit = remoteAction({
    action: async () => updateResource(entity),
    navigateSuccess: () => Paths.Resources(),
    messageSuccess: 'Änderungen gespeichert.',
    messageFail: 'Fehler beim Ändern.'
  });

  const inContent = substitution => content.includes(`{${substitution}}`);

  return (
    <main className='ResourceEdit'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Textbaustein bearbeiten: ${entity?.identifier}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title='Textbaustein bearbeiten'
            backButton={{ to: Paths.Resources(), label: 'Textbausteine' }}
            right={[
              <Button
                key={1}
                title='Abbrechen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                to={Paths.Resources()}
              />,
              <Button
                key={2}
                title='Änderungen speichern'
                icon={Tick}
                iconSize={17}
                disabled={!changed}
                onClick={submit}
              />
            ]}
          />
          <DisplayGroup label='Identifier'>
            {entity?.identifier}
            <div className='ResourceEdit__Label'>{entity?.label}</div>
          </DisplayGroup>
          {entity?.content?.type !== 'text/plain' ? null : (
            <DisplayGroup label='Text'>
              <Textbox
                onUpdateValue={setContent}
                name='text'
                title='Text festlegen'
                value={content}
                required
                showLabel={false}
              />
            </DisplayGroup>
          )}
          {entity?.content?.type !== 'text/html' ? null : (
            <DisplayGroup label='Inhalt'>
              <RichtextEditor
                onUpdateValue={setContent}
                value={content}
                allowImages={false}
                allowLinks={false}
              />
            </DisplayGroup>
          )}
          {entity?.availableSubstitutions?.length > 0 && (
            <DisplayGroup
              label='Verfügbare Ersetzungen'
              tooltip='Die hier aufgeführten Platzhalter können im vorliegenden Text verwendet werden. Diese werden bei der Ausgabe im Frontend dann entsprechend ersetzt.'
            >
              {entity.availableSubstitutions.map((substitution, index) => (
                <div key={index} className='ResourceEdit__Substitution'>
                  <SubstitutionIndicator
                    identifier={substitution.identifier}
                    description={substitution.description}
                    used={inContent(substitution.identifier)}
                  />
                </div>
              ))}
            </DisplayGroup>
          )}
        </Container>
      )}
    </main>
  );
};

export default ResourceEdit;
