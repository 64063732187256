import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import './NavBarItem.scss';
import PropTypes from 'prop-types';

const NavBarItem = ({ label, to }) => {
  const match = useMatch({
    path: to
  });

  return (
    <li className={`NavBarItem ${match && 'NavBarItem--Current'}`}>
      <Link to={to} className='NavBarItem__Link' title={label}>
        <span>{label}</span>
      </Link>
    </li>
  );
};

NavBarItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default NavBarItem;
