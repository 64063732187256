import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import './IconButton.scss';
import { Link, useNavigate } from 'react-router-dom';
import CircleIcon, { CircleIconPropTypes } from './CircleIcon';
import { keySelect } from '../../util/handlers';

const IconButton = ({
  disabled,
  iconCircle,
  labelPosition = 'RIGHT',
  onClick,
  showLabel,
  title,
  to,
  link
}) => {
  // Use navigate function.
  const navigate = useNavigate();

  // Wrap click or navigate handler.
  const handleAction = useCallback(
    event => {
      if (onClick) onClick(event);
      else navigate(to);
    },
    [onClick, navigate, to]
  );

  // Select element type.
  const Element = useMemo(() => (link ? Link : 'button'), [link]);

  return (
    <Element
      onClick={handleAction}
      onKeyDown={keySelect(handleAction)}
      to={to}
      disabled={disabled}
      title={title}
      className={`IconButton IconButton--LabelPosition-${labelPosition}`}
    >
      <CircleIcon
        icon={iconCircle.icon}
        size={iconCircle.size}
        color={null}
        offsetX={iconCircle.offsetX}
        offsetY={iconCircle.offsetY}
      />
      {showLabel && <div className='IconButton__Label'>{title}</div>}
    </Element>
  );
};

IconButton.propTypes = {
  iconCircle: PropTypes.shape(CircleIconPropTypes).isRequired,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['LEFT', 'RIGHT']),
  onClick: PropTypes.func,
  showLabel: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.any,
  link: PropTypes.bool
};

export default IconButton;
