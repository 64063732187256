import { useLayoutEffect, useState } from 'react';

/**
 *
 * @param {Element|Node|Window} element
 * @return {{x: number, width: number, y: number, height: number}}
 */
export const useClientRect = element => {
  // Get initial values.
  const initialX = element === window ? 0 : element?.clientLeft;
  const initialY = element === window ? 0 : element?.clientTop;
  const initialWidth = element === window ? window.innerWidth : element?.clientWidth;
  const initialHeight = element === window ? window.innerHeight : element?.clientHeight;

  // Make states.
  const [x, setX] = useState(initialX);
  const [y, setY] = useState(initialY);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  // Connect when layout is performed.
  useLayoutEffect(() => {
    // No input, nothing to do.
    if (!element) return;

    // Element is element type.
    if (element instanceof Element) {
      const handler = () => {
        const rect = element.getBoundingClientRect();
        setX(rect.left);
        setY(rect.top);
        setWidth(rect.width);
        setHeight(rect.height);
      };
      const observer = new ResizeObserver(handler);
      observer.observe(element);
      handler();
      return () => {
        observer.unobserve(element);
        observer.disconnect();
      };
    }

    // Element is window exactly.
    if (element === window) {
      const handler = () => {
        setX(0);
        setY(0);
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      };
      window.addEventListener('resize', handler);
      handler();
      return () => {
        window.removeEventListener('resize', handler);
      };
    }

    // Element is any node.
    const handler = () => {
      setX(element.clientLeft);
      setY(element.clientTop);
      setWidth(element.clientWidth);
      setHeight(element.clientHeight);
    };
    element.addEventListener('resize', handler);
    handler();
    return () => {
      element.removeEventListener('resize', handler);
    };
  }, [element]);

  return { x, y, width, height };
};
