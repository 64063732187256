import React, { createElement, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import './Button.scss';
import Colors from '../../definitions/colors';
import { classes } from '../../util/styles';
import { keySelect } from '../../util/handlers';

const whiteFilledVariants = ['ORANGE_FILL', 'WHITE_OUTLINE'];

const Button = ({
  autoWidth,
  disabled,
  title,
  onClick,
  to,
  target,
  icon,
  iconSize,
  variant,
  link
}) => {
  // Use navigate function.
  const navigate = useNavigate();

  // Compose icon props.
  const iconProps = useMemo(
    () => ({
      size: iconSize,
      fill: whiteFilledVariants.includes(variant) ? Colors.White : Colors.Orange
    }),
    [iconSize, variant]
  );

  // Wrap click or navigate handler.
  const handleAction = useCallback(() => {
    if (onClick) onClick();
    else if (target === '_blank') window.open(to, target);
    else navigate(to);
  }, [onClick, navigate, to]);

  // Select element type.
  const Element = useMemo(() => (link ? Link : 'button'), [link]);

  // Return the created element.
  return (
    <Element
      onClick={handleAction}
      onKeyDown={keySelect(handleAction)}
      to={to}
      disabled={disabled}
      className={classes(
        'Button',
        `Button--Style-${variant}`,
        autoWidth && 'Button--AutoWidth',
        icon && 'Button--HasIcon'
      )}
    >
      <span className='Button__Label'>{title}</span>
      {icon ? createElement(icon, iconProps) : null}
    </Element>
  );
};

Button.propTypes = {
  autoWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(['_self', '_blank']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  to: PropTypes.any,
  variant: PropTypes.oneOf([
    'ORANGE_FILL',
    'ORANGE_OUTLINE',
    'WHITE_FILL',
    'WHITE_OUTLINE',
    'GHOST'
  ]).isRequired,
  link: PropTypes.bool
};

Button.defaultProps = {
  variant: 'ORANGE_FILL',
  iconSize: 20
};

export default Button;
