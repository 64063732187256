import React from 'react';
import Container from '../layout/Container';
import evmLogo from '../../images/logo.svg';
import NavBarItem from '../atoms/NavBarItem';
import './NavBar.scss';
import { useMatch } from 'react-router-dom';
import Tooltip from '../atoms/Tooltip';
import { Paths } from '../../definitions/paths';
import UserMenu from './UserMenu';

const NavBar = () => {
  const authenticated = !useMatch('/login');

  const envLabel = process.env.REACT_APP_ENV || 'Lokal';
  const envTooltip =
    envLabel === 'Live'
      ? 'Sie arbeiten in der Live-Umgebung. Änderungen sind für Kunden sichtbar, arbeiten Sie daher sorgfältig.'
      : 'Sie arbeiten in einer Testumgebung ohne Auswirkungen auf Kunden.';

  return (
    <nav className='NavBar'>
      <Container>
        <div className='NavBar__HeaderContent'>
          <img src={evmLogo} alt='evm Entdecker-Bonus CMS' className='NavBar__Logo' />
          <ul className='NavBar__NavBar'>
            {authenticated && (
              <>
                <NavBarItem label='Dashboard' to={Paths.Dashboard()} />
                <NavBarItem label='Entdecker-Wert' to={Paths.ValueDashboard()} />
                <NavBarItem label='Partner' to={Paths.Partners()} />
                <NavBarItem label='Kampagnen & Vorteile' to={Paths.Campaigns()} />
                <NavBarItem label='Highlights' to={Paths.Highlights()} />
                <NavBarItem label='Kunden' to={Paths.Customers()} />
              </>
            )}
          </ul>
          {authenticated && (
            <div className='NavBar__User'>
              <UserMenu />
            </div>
          )}
          <div className={`NavBar__Environment NavBar__Environment--${envLabel}`}>
            <span className='NavBar__EnvironmentLabel'>{envLabel}</span>
            <Tooltip content={envTooltip} inverted={envLabel === 'Live'} />
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default NavBar;
