import React, { useEffect, useRef } from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import './Partners.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Pagination from '../atoms/Pagination';
import { useScrollTo } from '../../hooks/useScrollTo';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import Toolbar from '../modules/Toolbar';
import { Plus } from '../../images/icons';
import ItemCount from '../atoms/ItemCount';
import SearchBar from '../atoms/SearchBar';
import Table from '../atoms/table/Table';
import TableHead, { fromApiSort } from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';
import TextLink from '../atoms/TextLink';
import { displayPartnerContact, displayPartnerCounts } from '../../helpers/formatters';
import { Paths } from '../../definitions/paths';
import PageTitle from '../../helpers/pageTitle';
import {
  listingPartnersRequestPage,
  selectListingPartners,
  selectListingPartnersMode,
  selectListingPartnersTotal,
  selectListingPartnersTotalItems
} from '../../redux/reducers/listingPartners';
import { useSessionState } from '../../hooks/useSessionState';

const Partners = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useSessionState('Partners/page', 0);
  const [search, setSearch] = useSessionState('Partners/search', '');
  const [order, setOrder] = useSessionState('Partners/order', null);

  // Select state values
  const items = useSelector(selectListingPartners(page));
  const mode = useSelector(selectListingPartnersMode(page));
  // const error = useSelector(selectPartnersError(page));
  const total = useSelector(selectListingPartnersTotal);
  const totalItems = useSelector(selectListingPartnersTotalItems);

  // Scroll to table upon page flip
  const scrollAnchor = useRef(null);
  useScrollTo(scrollAnchor, page);

  // Do not exceed page limit
  usePaginationGuard(page, total, setPage);

  // Make page current with given index and query.
  useEffect(() => {
    dispatch(
      listingPartnersRequestPage(page, [search && ['search', search], order && ['order', order]])
    );
  }, [page, search, order]);

  return (
    <main className='Partners'>
      <PageTitle title={'Partner'} />
      <LoadingOverlay show={mode === 'INITIALIZING'} />
      <Container>
        <Toolbar
          title='Partner'
          left={[
            <SearchBar key={1} term={search} onUpdateTerm={setSearch} />,
            <ItemCount key={2} count={totalItems} />
          ]}
          right={[
            <Button
              key={1}
              title='Neuer Partner'
              icon={Plus}
              iconSize={15}
              to={Paths.PartnerCreate()}
            />
          ]}
        />
        <div className='Partners__TableWrapper' ref={scrollAnchor}>
          <Table
            headings={[
              <TableHead
                key={1}
                label='Name'
                width={30}
                currentSorting={fromApiSort('name', order)}
                onSortAsc={() => setOrder('+name')}
                onSortDesc={() => setOrder('-name')}
                onSortReset={() => setOrder(null) /* default */}
              />,
              <TableHead
                key={2}
                label='Ansprechpartner'
                currentSorting={fromApiSort('contact.name', order)}
                onSortAsc={() => setOrder('+contact.name')}
                onSortDesc={() => setOrder('-contact.name')}
                onSortReset={() => setOrder(null) /* default */}
              />,
              <TableHead
                key={3}
                label='Vorteile'
                width={30}
                currentSorting={fromApiSort('countOffers', order)}
                onSortAsc={() => setOrder('+countOffers')}
                onSortDesc={() => setOrder('-countOffers')}
                onSortReset={() => setOrder(null) /* default */}
              />
            ]}
          >
            {items &&
              items.map((partner, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextLink title={partner.name} to={Paths.PartnerDetails(partner.id)} />
                  </TableCell>
                  <TableCell>{displayPartnerContact(partner)}</TableCell>
                  <TableCell>
                    {!partner?.countOffers && !partner?.countOffersActive ? (
                      <span className='Partners__NoOffers'>Noch keine Vorteile.</span>
                    ) : (
                      <TextLink
                        title={displayPartnerCounts(partner.countOffers, partner.countOffersActive)}
                        href={Paths.PartnerDetails(partner.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </div>
        <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
      </Container>
    </main>
  );
};

export default Partners;
