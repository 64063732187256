/**
 * Converts a style color to an array of RGBA values in 0 to 1.
 * @param color The color name.
 * @return {[number,number,number,number]|undefined}
 */
export const styleToRgba = color => {
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, 1, 1);
  ctx.fillStyle = '#000';
  ctx.fillStyle = color;
  const computed = ctx.fillStyle;
  ctx.fillStyle = '#fff';
  ctx.fillStyle = color;
  if (computed !== ctx.fillStyle) {
    return; // invalid color
  }
  ctx.fillRect(0, 0, 1, 1);
  const result = [...ctx.getImageData(0, 0, 1, 1).data];
  result[0] /= 255;
  result[1] /= 255;
  result[2] /= 255;
  result[3] /= 255;
  return result;
};

/**
 * Interpolates a to b via t.
 * @param {number} t
 * @param {[number,number,number,number]} a
 * @param {[number,number,number,number]} b
 * @return {[number,number,number,number]}
 */
export const rgbaLerp = (t, a, b) => [
  (1 - t) * a[0] + t * b[0],
  (1 - t) * a[1] + t * b[1],
  (1 - t) * a[2] + t * b[2],
  (1 - t) * a[3] + t * b[3]
];

/**
 * Cubic spline.
 * @param t T value.
 * @param a Start point.
 * @param b Control point.
 * @param c End point.
 * @returns {[number,number,number,number]}
 */
export const rgbaBiLerp = (t, a, b, c) => rgbaLerp(t, rgbaLerp(t, a, b), rgbaLerp(t, b, c));

/**
 * Quartic spline.
 * @param t T value.
 * @param a Start point.
 * @param b First control point.
 * @param c Second control point.
 * @param d End point.
 * @returns {[number,number,number,number]}
 */
export const rgbaTriLerp = (t, a, b, c, d) =>
  rgbaLerp(
    t,
    rgbaLerp(t, rgbaLerp(t, a, b), rgbaLerp(t, b, c)),
    rgbaLerp(t, rgbaLerp(t, b, c), rgbaLerp(t, c, d))
  );

/**
 * Segmented linear interpolation.
 * @param t T value.
 * @param a Start point.
 * @param b Mid point.
 * @param c End point.
 * @returns {[number,number,number,number]}
 */
export const rgbaSegBiLerp = (t, a, b, c) =>
  t < 1 / 2 ? rgbaLerp(t * 2, a, b) : rgbaLerp(t * 2 - 1, b, c);

/**
 * Segmented linear interpolation.
 * @param t T value.
 * @param a Start point.
 * @param b Mid point from start.
 * @param c Mid point from end.
 * @param d End point.
 * @returns {[number,number,number,number]}
 */
export const rgbaSegTriLerp = (t, a, b, c, d) => {
  if (t < 1 / 3) {
    return rgbaLerp(t * 3, a, b);
  } else if (t < 2 / 3) {
    return rgbaLerp(t * 3 - 1, b, c);
  } else {
    return rgbaLerp(t * 3 - 2, c, d);
  }
};

/**
 * Converts the RGBA array to a style-applicable CSS string.
 * @param {[number,number,number,number]} color The color.
 * @return {string} Returns an RGBA string.
 */
export const rgbaToStyle = ([r, g, b, a]) =>
  `rgba(${(r * 255).toFixed()},${(g * 255).toFixed()},${(b * 255).toFixed()},${a})`;
