import TextLink from '../components/atoms/TextLink';
import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { customerContracts } from './parsers';
import { identityIssuer } from '../config';

moment.locale('de');

export const sanitizeWebUrl = url => {
  return /^https?:\/\/(.)*/.test(url) ? url : `https://${url}`;
};

export const buildFormAliasUrl = (url, alias) => {
  return url.replace('{code}', alias);
};

export const sanitizePhoneUrl = phone => {
  return `tel:${phone.replace(/[^0-9]/g, '')}`;
};

export const displayTimestamp = timestamp => {
  return moment(timestamp).format('DD.MM.YYYY, HH:mm:ss');
};

export const displayDate = timestamp => {
  return moment(timestamp).format('DD.MM.YYYY');
};

export const displayTimeSpan = (timestamp, relative = false) => {
  return moment.duration(moment(timestamp).diff(moment())).locale('de').humanize(relative);
};

export const displayTime = timestamp => {
  return moment(timestamp).format('HH:mm');
};

export const displayDateBoundaries = (from, until) => {
  const format = 'DD.MM.YYYY';
  return (
    (from && until && `${moment(from).format(format)} bis ${moment(until).format(format)}`) ||
    (from && !until && `Ab ${moment(from).format(format)}`) ||
    (!from && until && `Bis ${moment(until).format(format)}`) ||
    null
  );
};

export const displayAddress = address => {
  if (!address) return null;

  return (
    <>
      {address
        .split(/\r\n|\r|\n/)
        .map(part => part.trim())
        .map((part, i) => (
          <Fragment key={i}>
            {part}
            <br />
          </Fragment>
        ))}
    </>
  );
};

// Helper function to format the contact person
export const displayPartnerContact = partner =>
  !partner?.contact ? null : (
    <>
      {partner.contact.name ? (
        <>
          {partner.contact.name} <br />
        </>
      ) : null}
      {partner.contact.email ? (
        <>
          <TextLink href={`mailto:${partner.contact.email}`} title={partner.contact.email} />
          <br />
        </>
      ) : null}
      {partner.contact.phone ? (
        <TextLink href={sanitizePhoneUrl(partner.contact.phone)} title={partner.contact.phone} />
      ) : null}
    </>
  );

export const displayPartnerCounts = (total, active) => {
  // Get count strings with pluralization.
  const textTotal = (total && (1 === total ? '1 Vorteil' : `${total} Vorteile`)) || '';
  const textActive = (active && `, davon ${active} aktiv`) || '';
  return textTotal + textActive || 'Noch keine Vorteile';
};

export const displayBy = by => {
  // Nothing given, fallback mark as unknown. // TODO: Styling.
  if (!by) return 'Unbekannt';

  // Both present, display as name, link as mail-to.
  if (null !== by.name && null !== by.email)
    return <TextLink href={`mailto:${by.email}`} title={by.name} />;

  // Name only, render directly.
  if (null !== by.name) return <>{by.name}</>;

  // Email only, display as itself and link as mail-to.
  if (null !== by.email) return <TextLink href={`mailto:${by.email}`} title={by.email} />;

  // If identity issued by customer ID service, use login name.
  if (identityIssuer === by.issuer) {
    const [login] = by.identifier.split('/');
    return (
      <>
        Geändert von <i>{login}</i>
      </>
    );
  }

  // Cannot render.
  return 'Unbekannt';
};

// TODO: Sensible solution, maybe with scope (on offer, on partner, on highlight).

const pathSkipped = path => {
  return 'Id' === path || 'Deleted' === path;
};
export const pathName = path => {
  switch (path) {
    case 'Categories':
      return 'Kategorien';
    case 'InternalCategories':
      return 'Interne Kategorien';
    case 'Campaign':
      return 'Kampagne';
    case 'Address':
      return 'Adresse';
    case 'VisibleFrom':
      return 'Sichtbarkeit';
    case 'ValidFrom':
      return 'Startzeit';
    case 'ValidUntil':
      return 'Endzeit';
    case 'Title':
      return 'Titel';
    case 'Definition':
      return 'Vorteilsdefinition';
    case 'InfoPhone':
      return 'Telefonnummer';
    case 'InfoWebsite':
      return 'Websieite';
    case 'Description':
      return 'Bezeichnung';
    case 'DescriptionLong':
      return 'Langbeschreibung';
    case 'Images':
      return 'Bilder';
    case 'PartnerUsedName':
      return 'Anzeigename Partner';
    case 'Partner':
      return 'Partner';
    case 'Order':
      return 'Anordnung';
    case 'Priority':
      return 'Priorisierung';
    case 'Offers':
      return 'Vorteile';
    case 'TextDisplay':
      return 'Textdarstellung';
    case 'Interests':
      return 'Interessen';
    case 'Stage':
      return 'Freigabestufe';
    case 'Excerpt':
      return 'Kurzbeschreibung';
    case 'Header':
      return 'Titelbild';
    case 'HouseholdChildren':
      return 'Anzahl Kinder';
    case 'HouseholdPeople':
      return 'Anzahl Personen';
    case 'HouseholdHasChildren':
      return 'Kinder im Haushalt';
    case 'Telephone':
      return 'Telefonnummer';
    case 'CustomerId':
      return 'Kundennummer';
    case 'DateOfBirth':
      return 'Geburtstag';
    case 'FirstName':
      return 'Vorname';
    case 'LastName':
      return 'Nachname';
    case 'OnboardingStart':
      return 'Onboarding begonnen';
    case 'OnboardingComplete':
      return 'Onboarding abgeschlossen';
    case 'Salutation':
      return 'Anrede';
    case 'Deleted':
      return 'Gelöscht';
    case 'For':
      return 'Für';
    case 'Bookmarked':
      return 'Gemerkte Vorteile';

    default:
      return path;
  }
};
export const displayEditFields = changes => {
  if (!changes) return <small>Keine Änderung</small>; // TODO: Styling.

  const [p0, p1, p2, ...pRest] = changes.reduce(
    (r, c) => (pathSkipped(c.path) || r.includes(c.path) ? r : [...r, c.path]),
    []
  );

  if (0 < pRest?.length)
    return `${pathName(p0)}, ${pathName(p1)}, ${pathName(p2)} und mehr geändert`;
  if (p2) return `${pathName(p0)}, ${pathName(p1)} und ${pathName(p2)} geändert`;
  if (p1) return `${pathName(p0)} und ${pathName(p1)} geändert`;
  if (p0) return `${pathName(p0)} geändert`;

  return <small>Keine Änderung</small>;
};

/**
 * Formats the date of birth for a customer if accessible.
 * @param customer The customer to print for.
 * @return {null|string}
 */
export const displayCustomerBirthday = customer =>
  !customer?.dateOfBirth ? null : moment(customer.dateOfBirth).format('DD.MM.YYYY');

/**
 * Displays the name of the customer if accessible.
 * @param customer The customer to print for.
 * @return {string}
 */
export const displayCustomerName = customer =>
  !customer ? 'Anonymer Kunde' : `${customer.firstName} ${customer.lastName}`;

/**
 * Displays the contract types as a sensible concatenated string.
 * @param types The types to map.
 * @return {string|*} Returns the concatenated string.
 */
export const contractTypeLabel = types => {
  const gas = types.includes('G');
  const water = types.includes('W');
  const energy = types.includes('E');
  const [p0, p1, ...pRest] = [gas && 'Gas', water && 'Wasser', energy && 'Strom'].filter(Boolean);

  if (0 < pRest?.length) return [p0, p1, ...pRest].join(', ');
  if (p1) return `${p0} und ${p1}`;
  return p0;
};

/**
 * Displays the customer IDs if parsable.
 * @param customer The customer to parse for.
 * @return {string|null} Returns the formatted string or null if unable to format.
 */
export const displayCustomerIds = customer => {
  const ids = customerContracts(customer);
  if (!ids) return null;
  return ids
    .map(({ id, types }) => {
      const typeLabel = contractTypeLabel(types);
      return typeLabel ? `${id} (${typeLabel})` : id;
    })
    .join(', ');
};

/**
 * Displays the archival count footer for campaigns/offers.
 * @param archiveCounts The object to print for.
 * @return {string} Returns the footer text.
 */
export const displayArchival = archiveCounts => {
  // Determine count names.
  const campaigns =
    archiveCounts?.countCampaigns &&
    (1 === archiveCounts.countCampaigns
      ? '1 Kampagne'
      : `${archiveCounts.countCampaigns} Kampagnen`);
  const offers =
    archiveCounts?.countOffers &&
    (1 === archiveCounts.countOffers ? '1 Vorteil' : `${archiveCounts.countOffers} Vorteile`);

  // Determine suffix from both amounts.
  const suffix =
    1 < (archiveCounts?.countCampaigns || 0) + (archiveCounts?.countOffers || 0)
      ? ' wurden archiviert'
      : ' wurde archiviert.';

  // If nothing in archive, use special case.
  if (!campaigns && !offers) return 'Nichts im Archiv.';

  // Join parts.
  return [campaigns, offers].filter(Boolean).join(' und ') + suffix;
};

export const displayPriority = entity => {
  if (!entity?.priority) return 'Keine Priorisierung';
  if (100 <= entity.priority) return 'Vorne anzeigen';
  if (0 < entity.priority) return 'Vor anderen anzeigen';
  if (entity.priority <= -100) return 'Hinten anzeigen';
  if (entity.priority < 0) return 'Hinter anderen anzeigen';
  return null;
};

export const displayFractional = (
  number,
  fractionalDigits,
  classNameSign = 'Fractional__sign',
  classNameFull = 'Fractional__full',
  classNameFrac = 'Fractional__frac',
  classNameRound = 'Fractional__round'
) => {
  if (typeof number !== 'number') return null;
  const sign = Math.sign(number);
  const [full, frac] = number.toFixed(fractionalDigits).split('.');
  const round = !frac || +frac === 0;
  return (
    <>
      {sign >= 0 ? null : <span className={classNameSign}>-</span>}
      <span className={round ? classNameRound : classNameFull}>{full}</span>
      {round ? null : <span className={classNameFrac}>.{frac}</span>}
    </>
  );
};

export const displayFixedDecimalNumber = (number, fractionalDigits) => {
  if (typeof number !== 'number') return null;
  return new Intl.NumberFormat('de', {
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits
  }).format(number);
};

export const displayPercentage = number => {
  if (typeof number !== 'number') return null;
  return Math.round(number * 100).toString() + '%';
};

export const displayPercentagePrecise = number => {
  if (typeof number !== 'number') return null;
  return (Math.round(number * 10000) / 100).toString() + '%';
};

export const formatChange = number => (number > 0 ? `+${number}` : `${number}`);

export const naturalize = number =>
  [
    'eine',
    'zwei',
    'drei',
    'vier',
    'fünf',
    'sechs',
    'sieben',
    'acht',
    'neun',
    'zehn',
    'elf',
    'zwölf'
  ][number - 1];
