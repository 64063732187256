import React, { useRef, useState } from 'react';
import './UserMenu.scss';
import { useClickOutside } from '../../hooks/useClickOutside';
import TextLink from '../atoms/TextLink';
import UserMenuItem from '../atoms/UserMenuItem';
import { Paths } from '../../definitions/paths';
import { notifySuccess, notifyError } from '../../helpers/notifications';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../redux/reducers/account';
import copyToClipboard from 'copy-to-clipboard';
import { msalClearLogin, msalGetToken } from '../../msal';
import { useMsal } from '@azure/msal-react';

const UserMenu = () => {
  const menu = useRef(null);
  const [visible, setVisible] = useState(false);

  useClickOutside(menu, () => setVisible(false));

  // Use current account.
  const { instance } = useMsal();
  const accountId = useSelector(selectAccount);
  const account = typeof accountId === 'string' ? instance.getAccountByHomeId(accountId) : null;

  // Use logout. Connect to perform login by invoking and notifying success.
  const performLogout = async () => {
    await msalClearLogin();
    setVisible(false);
    notifySuccess('Sie wurden abgemeldet. Auf Wiedersehen!');
  };

  // Gets the token. Copies it to the clipboard and notifies success or failure.
  const copyApiToken = async () => {
    try {
      const token = await msalGetToken();
      copyToClipboard(`Bearer ${token}`);
      notifySuccess('Token wurde kopiert.');
    } catch (e) {
      notifyError('Token konnte nicht kopiert werden.');
    }
  };

  return (
    <div ref={menu} className='UserMenu'>
      Angemeldet als{' '}
      <TextLink
        onClick={() => setVisible(!visible)}
        title={account?.name || 'Anonym'}
        inheritSize
      />
      <div className={`UserMenu__Dropdown ${visible ? 'UserMenu__Dropdown--Visible' : ''}`}>
        <UserMenuItem
          to={Paths.Resources()}
          title='Textbausteine'
          onClick={() => setVisible(false)}
        />
        {/* TODO Without to-link, this displays as a blocky button.  */}
        <UserMenuItem to={'#'} title='Abmelden' onClick={performLogout} />
        <UserMenuItem alternate to={'#'} title='API Token kopieren' onClick={copyApiToken} />
      </div>
    </div>
  );
};

export default UserMenu;
