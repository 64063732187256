import HeatChart from './HeatChart';
import Colors from '../../definitions/colors';
import PropTypes from 'prop-types';
import React from 'react';

const getHour = o => (o.hour < 10 ? `0${o.hour}:00` : `${o.hour}:00`);
const getDay = o =>
  ['Sonntag', 'Montag', 'Dienstag', 'Mitwoch', 'Donnerstag', 'Freitag', 'Samstag'][o.dayOfWeek - 1];
const allDays = [...Array(7).keys()].map(day => getDay({ dayOfWeek: day + 1 }));
const allHours = [...Array(24).keys()].map(hour => getHour({ hour }));
const getValue = o => o.count;

const DayHourHeatChart = ({ empty, data }) => (
  <HeatChart
    empty={empty}
    xs={allHours}
    ys={allDays}
    xOf={getHour}
    yOf={getDay}
    data={data}
    valueOf={getValue}
    height={350}
    primary={Colors.Orange}
  />
);

DayHourHeatChart.propTypes = {
  empty: PropTypes.bool,
  data: PropTypes.array.isRequired
};

export default DayHourHeatChart;
