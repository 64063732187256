import { campaignsLocation, campaignsReducer } from './campaigns';
import { combineReducers } from 'redux';
import { customersLocation, customersReducer } from './customers';
import { highlightsLocation, highlightsReducer } from './highlights';
import { offersLocation, offersReducer } from './offers';
import { partnersLocation, partnersReducer } from './partners';
import { resourcesLocation, resourcesReducer } from './resources';
import { listingPartnersLocation, listingPartnersReducer } from './listingPartners';
import {
  listingCampaignsOffersLocation,
  listingCampaignsOffersReducer
} from './listingCampaignsOffers';
import { uiLocation, uiReducer } from './ui';
import { activationsLocation, activationsReducer } from './activations';
import { listingCustomersLocation, listingCustomersReducer } from './listingCustomers';
import { accountLocation, accountReducer } from './account';
import { previewsLocation, previewsReducer } from './previews';

/**
 * Combines all necessary reducers to run the CMS.
 */
export const rootReducer = combineReducers({
  [accountLocation]: accountReducer,
  [uiLocation]: uiReducer,
  [campaignsLocation]: campaignsReducer,
  [activationsLocation]: activationsReducer,
  [customersLocation]: customersReducer,
  [highlightsLocation]: highlightsReducer,
  [offersLocation]: offersReducer,
  [partnersLocation]: partnersReducer,
  [previewsLocation]: previewsReducer,
  [resourcesLocation]: resourcesReducer,
  [listingCampaignsOffersLocation]: listingCampaignsOffersReducer,
  [listingPartnersLocation]: listingPartnersReducer,
  [listingCustomersLocation]: listingCustomersReducer
});
