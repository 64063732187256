import React from 'react';
import PropTypes from 'prop-types';
import './SingleImage.scss';
import IconButton from '../IconButton';
import { Close } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import { useDrag } from 'react-dnd';

const SingleImage = ({ circle, cover, index, height, location, onRemove, width }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'Image',
    item: { currentIndex: index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }));
  return (
    <div
      ref={drag}
      className={`SingleImage ${isDragging ? 'SingleImage--Dragging' : ''} ${
        circle ? 'SingleImage--Circle' : ''
      }`}
    >
      <img
        src={location}
        alt={location}
        style={{ width, height }}
        className={`SingleImage__Image ${
          cover ? 'SingleImage__Image--Cover' : 'SingleImage__Image--Contain'
        }`}
      />
      <div className='SingleImage__Remove'>
        <IconButton
          title='Entfernen'
          onClick={onRemove}
          iconCircle={{ icon: <Close size={12} fill={Colors.White} />, size: 28 }}
        />
      </div>
    </div>
  );
};

SingleImage.propTypes = {
  circle: PropTypes.bool,
  cover: PropTypes.bool,
  index: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};

export default SingleImage;
