/**
 * Location of account data in the state.
 * @type {string}
 */
export const accountLocation = 'account';

/**
 * Initial state of the account reducer.
 */
export const initialState = Object.freeze({
  account: null,
  reason: null
});

/**
 * Sets the selected account
 * @param {string|null} account The account ID to use.
 * @param {string} reason The reason, optional.
 * @return {{type: 'account/ASSIGN',  payload: {account:string|null, reason:string}}}
 */
export const accountAssign = (account, reason) => ({
  type: 'account/ASSIGN',
  payload: { account, reason }
});

/**
 * Selects the account.
 * @param {any} state
 * @return {string|null}
 */
export const selectAccount = state => state[accountLocation].account;

/**
 * Selects true if account is selected.
 * @param {any} state
 * @return {boolean}
 */
export const selectLoggedIn = state => !!state[accountLocation].account;

/**
 * Reducer for account actions.
 */
export const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'account/ASSIGN':
      return {
        ...state,
        account: payload.account,
        reason: payload.reason
      };
    default:
      return state;
  }
};
