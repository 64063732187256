import { ViewRoute } from './ViewRoute';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import Login from '../views/Login';
import React from 'react';

export const ViewLogin = () => (
  <ViewRoute
    loggedInComponent={<Redirect to={Paths.Dashboard()} />}
    loggedOutComponent={<Login />}
  />
);
