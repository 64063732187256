import {
  deleteEntity,
  getEntity,
  getPage,
  getResolution,
  pathManage,
  postCall,
  postEntity,
  postResolution,
  putEntity
} from './common';

/**
 * Retrieves the specific highlight with the given ID.
 * @param id The ID of the highlight.
 * @return {*} Returns a promise with the highlight. Failures to get are treated as errors.
 */
export const getHighlight = id => getEntity(`${pathManage}/highlights/${id}`);

/**
 * Creates the given highlight.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param highlight The highlight to add.
 * @return {*} Returns a promise with the created highlight. Failures to create are treated as errors.
 */
export const createHighlight = highlight => postEntity(`${pathManage}/highlights`, null, highlight);

/**
 * Updates the given highlight. The highlight should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param highlight The highlight to update.
 * @return {*} Returns a promise with the updated highlight. Failures to create are treated as errors.
 */
export const updateHighlight = highlight => putEntity(`${pathManage}/highlights`, null, highlight);

/**
 * Updates the stage of the highlight.
 * @param id The ID of the highlight.
 * @param stage The new stage value.
 * @return {*} Returns a promise with the updated object. Failures to update are treated as errors.
 */
export const updateHighlightStage = (id, stage) =>
  putEntity(`${pathManage}/highlights/${id}/stage`, [['stage', stage]], null);

/**
 * Swaps the order of the highlight with the order of the other given highlight.
 * @param src The ID of the highlight.
 * @param dst The ID of the highlight to swap the order with.
 * @return {*} Returns a promise with the updated object. Failures to update are treated as errors.
 */
export const swapHighlightOrder = (src, dst) =>
  postCall(`${pathManage}/highlights/${src}/swap-order`, [['with', dst]], null);

/**
 * Deletes the highlight with the given ID.
 * @param id The ID of the highlight.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deleteHighlight = id => deleteEntity(`highlights/${id}`);

/**
 * Gets the raw highlights in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getHighlights = (page, query) =>
  getPage(`${pathManage}/highlights`, [['page', page], ...query]);

/**
 * Gets all highlights as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allHighlights = () => getResolution(`${pathManage}/resolve/highlights`);

/**
 * Gets highlights for the given IDs.
 * @param ids The IDs to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolveHighlights = ids =>
  postResolution(`${pathManage}/resolve/highlights`, null, ids || []);
