import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../atoms/IconButton';
import { Left } from '../../images/icons';
import Colors from '../../definitions/colors';
import './Toolbar.scss';

const Toolbar = ({ left, right, backButton, title }) => {
  const wrapInGroups = elements => {
    if (!elements || elements.length < 1) {
      return null;
    }
    return elements.map((elem, index) => (
      <div key={index} className={`Toolbar__Group ${index === 0 ? 'Toolbar__Group--First' : ''}`}>
        {elem}
      </div>
    ));
  };
  return (
    <div className='Toolbar'>
      {backButton && (
        <div className='Toolbar__Back'>
          <IconButton
            title={backButton.label}
            showLabel
            labelPosition='RIGHT'
            to={backButton.to}
            iconCircle={{ icon: <Left size={11} fill={Colors.White} />, size: 40 }}
          />
        </div>
      )}
      {title && left && <h1 className='Toolbar__Title'>{title}</h1>}
      <div className='Toolbar__Horizontal'>
        <div className='Toolbar__Left'>
          {left ? (
            wrapInGroups(left)
          ) : (
            <h1 className='Toolbar__Title Toolbar__Title--Inline'>{title}</h1>
          )}
        </div>
        <div className='Toolbar__Right'>{wrapInGroups(right)}</div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  left: PropTypes.arrayOf(PropTypes.element),
  right: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string,
  backButton: PropTypes.shape({
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};

export default Toolbar;
