import React from 'react';
import PropTypes from 'prop-types';
import './DashboardTile.scss';
import { Link } from 'react-router-dom';
import Tooltip from '../atoms/Tooltip';

const DashboardTile = ({
  children,
  linkTo,
  mainLabel,
  subLabel,
  value,
  valueFormatter,
  tooltip
}) => {
  return (
    <div className={`DashboardTile ${children ? 'DashboardTile--WithDetails' : ''}`}>
      <Link to={linkTo} className='DashboardTile__Tile'>
        <div style={{ position: 'relative' }}>
          {tooltip && (
            <Tooltip
              size={20}
              inverted={true}
              className='DashboardTile__Tooltip'
              content={tooltip}
            />
          )}
        </div>
        <h1 className='DashboardTile__Value'>{valueFormatter ? valueFormatter(value) : value}</h1>
        <h2 className='DashboardTile__MainLabel'>{mainLabel}</h2>
        {subLabel && <div className='DashboardTile__SubLabel'>{subLabel}</div>}
      </Link>
      {children && (
        <div className='DashboardTile__DetailsWrapper'>
          <div className='DashboardTile__Details'>{children}</div>
        </div>
      )}
    </div>
  );
};

DashboardTile.propTypes = {
  children: PropTypes.any,
  linkTo: PropTypes.string.isRequired,
  mainLabel: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.any]).isRequired,
  valueFormatter: PropTypes.func,
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

DashboardTile.defaultProps = {
  valueFormatter: value =>
    typeof value === 'number' ? new Intl.NumberFormat('de-DE').format(value) : value
};

export default DashboardTile;
