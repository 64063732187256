import React from 'react';
import PropTypes from 'prop-types';
import { Eye } from '../../images/icons';
import Colors from '../../definitions/colors';
import CircleIcon from '../atoms/CircleIcon';
import './ImagePreview.scss';

const ImagePreview = ({ circle, cover, height, images, width }) => {
  const presentImages = images.filter(Boolean);
  const SingleImage = image => (
    <a
      href={image.location}
      title='Bild anzeigen'
      target='_blank'
      className={`ImagePreview__ImageLink ${circle ? 'ImagePreview__ImageLink--Circle' : ''}`}
      rel='noreferrer'
      key={image.location}
    >
      <img
        src={image.location}
        alt={image.location}
        className={`ImagePreview__Image ${
          cover ? 'ImagePreview__Image--Cover' : 'ImagePreview__Image--Contain'
        }`}
        style={{ width, height }}
      />
      <div className='ImagePreview__Circle'>
        <CircleIcon icon={<Eye size={20} fill={Colors.Orange} />} size={40} color={Colors.White} />
      </div>
    </a>
  );

  return (
    <div className='ImagePreview'>
      {presentImages ? (
        <div className='ImagePreview__Wrapper'>
          {presentImages.map(image => SingleImage(image))}
        </div>
      ) : (
        <div className='ImagePreview__NoImage'>Kein Bild hinterlegt</div>
      )}
    </div>
  );
};

ImagePreview.propTypes = {
  circle: PropTypes.bool,
  cover: PropTypes.bool,
  height: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      alias: PropTypes.string
    })
  ),
  width: PropTypes.number
};

export default ImagePreview;
