import { ViewRoute } from './ViewRoute';
import HighlightEdit from '../views/HighlightEdit';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import React from 'react';

export const ViewHighlightCreate = () => (
  <ViewRoute
    loggedInComponent={<HighlightEdit />}
    loggedOutComponent={<Redirect to={Paths.Login()} />}
  />
);
