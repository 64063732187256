import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Paths } from '../../definitions/paths';
import { ViewOfferPreview } from './ViewOfferPreview';
import { ViewLogin } from './ViewLogin';
import { ViewDashboard } from './ViewDashboard';
import { ViewValueDashboard } from './ViewValueDashboard';
import { ViewPartners } from './ViewPartners';
import { ViewPartnerCreate } from './ViewPartnerCreate';
import { ViewPartnerEdit } from './ViewPartnerEdit';
import { ViewPartnerDetails } from './ViewPartnerDetails';
import { ViewCampaigns } from './ViewCampaigns';
import { ViewCampaignsArchive } from './ViewCampaignsArchive';
import { ViewCampaignCreate } from './ViewCampaignCreate';
import { ViewCampaignEdit } from './ViewCampaignEdit';
import { ViewCampaignDetails } from './ViewCampaignDetails';
import { ViewCampaignStatistics } from './ViewCampaignStatistics';
import { ViewOfferCreate } from './ViewOfferCreate';
import { ViewOfferEdit } from './ViewOfferEdit';
import { ViewOfferDetails } from './ViewOfferDetails';
import { ViewOfferStatistics } from './ViewOfferStatistics';
import { ViewHighlights } from './ViewHighlights';
import { ViewHighlightCreate } from './ViewHighlightCreate';
import { ViewHighlightEdit } from './ViewHighlightEdit';
import { ViewHighlightDetails } from './ViewHighlightDetails';
import { ViewCustomers } from './ViewCustomers';
import { ViewCustomerDetails } from './ViewCustomerDetails';
import { ViewCustomerEdit } from './ViewCustomerEdit';
import { ViewResources } from './ViewResources';
import { ViewResourceEdit } from './ViewResourceEdit';
import { ViewFallback } from './ViewFallback';

const ViewSwitch = () => {
  const [lastPath, setLastPath] = useState('');
  const location = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    if (location.pathname !== lastPath) {
      setLastPath(location.pathname);
      window.scrollTo({ top: 0, left: 0 });
    }
  }, [location.pathname, lastPath, setLastPath]);

  return (
    <Routes>
      {/* General */}
      <Route path={Paths.Login()} element={<ViewLogin />} />
      <Route path={Paths.Dashboard()} element={<ViewDashboard />} />
      <Route path={Paths.ValueDashboard()} element={<ViewValueDashboard />} />

      {/* Partners */}
      <Route exact path={Paths.Partners()} element={<ViewPartners />} />
      <Route path={Paths.PartnerCreate()} element={<ViewPartnerCreate />} />
      <Route path={Paths.PartnerEdit()} element={<ViewPartnerEdit />} />
      <Route exact path={Paths.PartnerDetails()} element={<ViewPartnerDetails />} />

      {/* Campaigns */}
      <Route exact path={Paths.Campaigns()} element={<ViewCampaigns />} />
      <Route path={Paths.CampaignsArchive()} element={<ViewCampaignsArchive />} />
      <Route path={Paths.CampaignCreate()} element={<ViewCampaignCreate />} />
      <Route path={Paths.CampaignEdit()} element={<ViewCampaignEdit />} />
      <Route exact path={Paths.CampaignDetails()} element={<ViewCampaignDetails />} />
      <Route path={Paths.CampaignStatistics()} element={<ViewCampaignStatistics />} />

      {/* Offers */}
      <Route path={Paths.OfferCreate()} element={<ViewOfferCreate />} />
      <Route path={Paths.OfferEdit()} element={<ViewOfferEdit />} />
      <Route exact path={Paths.OfferDetails()} element={<ViewOfferDetails />} />
      <Route path={Paths.OfferStatistics()} element={<ViewOfferStatistics />} />
      <Route path={Paths.OfferPreviewCMS()} element={<ViewOfferPreview />} />

      {/* Highlights */}
      <Route exact path={Paths.Highlights()} element={<ViewHighlights />} />
      <Route path={Paths.HighlightCreate()} element={<ViewHighlightCreate />} />
      <Route path={Paths.HighlightEdit()} element={<ViewHighlightEdit />} />
      <Route exact path={Paths.HighlightDetails()} element={<ViewHighlightDetails />} />

      {/* Customers */}
      <Route exact path={Paths.Customers()} element={<ViewCustomers />} />
      <Route exact path={Paths.CustomerDetails()} element={<ViewCustomerDetails />} />
      <Route path={Paths.CustomerEdit()} element={<ViewCustomerEdit />} />

      {/* Resources */}
      <Route exact path={Paths.Resources()} element={<ViewResources />} />
      <Route path={Paths.ResourceEdit()} element={<ViewResourceEdit />} />

      {/* Default */}
      <Route path='*' element={<ViewFallback />} />
    </Routes>
  );
};

export default ViewSwitch;
