import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Edit } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './PartnerDetails.scss';
import DisplayGroup from '../atoms/DisplayGroup';
import TextRenderer from '../layout/TextRenderer';
import { displayPartnerContact } from '../../helpers/formatters';
import EditsProtocol from '../modules/EditsProtocol';
import CampaignPanel from '../modules/CampaignPanel';
import PageTitle from '../../helpers/pageTitle';
import ImagePreview from '../modules/ImagePreview';
import { useEdits, useListingPartnerRelated, usePartner } from '../../hooks/useRemoteValue';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { deletePartner } from '../../api/partners';
import { remoteAction, useEntityView } from './common';
import { isArchived, isNotArchived } from '../../helpers/entities';
import { SitePanel } from '../modules/SitePanel';

const PartnerDetails = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Use partner of ID.
  const { entity, loadingEntity } = useEntityView(usePartner, id);
  const [related, , loadingRelated] = useListingPartnerRelated(id);
  const [edits, , loadingEdits] = useEdits(id);

  // Loading state is combination of either partner or related campaigns/offers loading.
  const loading = loadingEntity || loadingRelated || loadingEdits;

  // Sites of the partner.
  const sites = entity?.sites;

  // Categorize campaigns and offers by stage.
  const campaigns = related?.campaigns?.filter(isNotArchived) || [];
  const campaignsArchived = related?.campaigns?.filter(isArchived) || [];
  const standaloneOffers = related?.standaloneOffers?.filter(isNotArchived) || [];
  const standaloneOffersArchived = related?.standaloneOffers?.filter(isArchived) || [];

  // Deletes the currently viewed partner.
  const handleDelete = remoteAction({
    action: async () => deletePartner(id),
    messageConfirm: 'Bitte bestätigen Sie das Löschen des Partners.',
    messageSuccess: 'Partner gelöscht.',
    navigateSuccess: Paths.Partners(),
    messageFail: 'Fehler beim Löschen.'
  });

  // Determine visibilities for displaying only present elements.
  const hasCampaigns = 0 < campaigns?.length;
  const hasStandaloneOffers = 0 < standaloneOffers?.length;
  const hasCampaignsArchived = 0 < campaignsArchived?.length;
  const hasStandaloneOffersArchived = 0 < standaloneOffersArchived?.length;
  const hasAny = hasCampaigns || hasStandaloneOffers;
  const hasAnyArchived = hasCampaignsArchived || hasStandaloneOffersArchived;

  return (
    <main className='PartnerDetails'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Partner: ${entity?.name}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title={entity.name}
            backButton={{ to: Paths.Partners(), label: 'Partner' }}
            right={[
              <Button
                key={1}
                title='Löschen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                onClick={handleDelete}
              />,
              <Button
                key={2}
                title='Bearbeiten'
                icon={Edit}
                iconSize={17}
                to={Paths.PartnerEdit(entity.id)}
              />
            ]}
          />
          <DisplayGroup label='Name'>{entity.name}</DisplayGroup>
          <DisplayGroup label='Logo'>
            <ImagePreview images={[entity.logo]} width={320} height={180} />
          </DisplayGroup>
          <DisplayGroup label='Beschreibung'>
            <TextRenderer content={entity.description} />
          </DisplayGroup>

          {!sites?.length ? null : (
            <DisplayGroup label='Zweigstellen'>
              {sites.map((site, i) => (
                <SitePanel key={i} name={site.name} address={site.address} />
              ))}
            </DisplayGroup>
          )}

          <DisplayGroup label='Ansprechpartner'>{displayPartnerContact(entity)}</DisplayGroup>
          {!hasAny ? null : (
            <DisplayGroup label='Aktuelle Vorteile'>
              <div className='PartnerDetails__CampaignsWrapper'>
                {!hasCampaigns
                  ? null
                  : campaigns.map(campaign => (
                      <CampaignPanel
                        key={campaign.id}
                        campaign={campaign}
                        editable={false}
                        showStatus={false}
                      />
                    ))}
                {!hasStandaloneOffers ? null : (
                  <CampaignPanel editable={false} standaloneOffers={standaloneOffers} />
                )}
              </div>
            </DisplayGroup>
          )}

          {!hasAnyArchived ? null : (
            <DisplayGroup label='Archivierte Vorteile'>
              <div className='PartnerDetails__CampaignsWrapper'>
                {!hasCampaignsArchived
                  ? null
                  : campaignsArchived.map(campaign => (
                      <CampaignPanel
                        key={campaign.id}
                        campaign={campaign}
                        editable={false}
                        showStatus={false}
                      />
                    ))}
                {!hasStandaloneOffersArchived ? null : (
                  <CampaignPanel editable={false} standaloneOffers={standaloneOffersArchived} />
                )}
              </div>
            </DisplayGroup>
          )}

          <EditsProtocol edits={edits} />
        </Container>
      )}
    </main>
  );
};

export default PartnerDetails;
