import { useEffect } from 'react';

export function useScrollTo(ref, monitor) {
  useEffect(
    () => {
      if (ref?.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
    Array.isArray(monitor) ? monitor : [monitor]
  );
}
