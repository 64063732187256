import {
  apiFetch,
  completeJson,
  deleteEntity,
  getEntity,
  getPage,
  getResolution,
  pathManage,
  postCall,
  postEntity,
  postResolution,
  putEntity
} from './common';

/**
 * Retrieves the specific campaign with the given ID.
 * @param id The ID of the campaign.
 * @return {*} Returns a promise with the campaign. Failures to get are treated as errors.
 */
export const getCampaign = id => getEntity(`${pathManage}/campaigns/${id}`);

/**
 * Creates the given campaign.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param campaign The campaign to add.
 * @return {*} Returns a promise with the created campaign. Failures to create are treated as errors.
 */
export const createCampaign = campaign => postEntity(`${pathManage}/campaigns`, null, campaign);

/**
 * Updates the given campaign. The campaign should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param campaign The campaign to update.
 * @return {*} Returns a promise with the updated campaign. Failures to create are treated as errors.
 */
export const updateCampaign = campaign => putEntity(`${pathManage}/campaigns`, null, campaign);

/**
 * Updates the stage of the campaign.
 * @param id The ID of the campaign.
 * @param stage The new stage value.
 * @return {*} Returns a promise with the updated object. Failures to update are treated as errors.
 */
export const updateCampaignStage = (id, stage) =>
  putEntity(`${pathManage}/campaigns/${id}/stage`, [['stage', stage]], null);

/**
 * Swaps the order of the campaign with the order of the other given campaign.
 * @param src The ID of the campaign.
 * @param dst The ID of the campaign to swap the order with.
 * @return {*} Returns a promise with the updated object. Failures to update are treated as errors.
 */
export const swapCampaignOrder = (src, dst) =>
  postCall(`${pathManage}/campaigns/${src}/swap-order`, [['with', dst]], null);

/**
 * Deletes the campaign with the given ID.
 * @param id The ID of the campaign.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deleteCampaign = id => deleteEntity(`${pathManage}/campaigns/${id}`);

/**
 * Gets the raw campaigns in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getCampaigns = (page, query) =>
  getPage('${pathManage}/campaigns', [['page', page], ...query]);

/**
 * Gets all campaigns as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allCampaigns = () => getResolution(`${pathManage}/resolve/campaigns`);

/**
 * Gets campaigns for the given IDs.
 * @param ids The IDs to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolveCampaigns = ids =>
  postResolution(`${pathManage}/resolve/campaigns`, null, ids || []);

/**
 * Resolves a single campaign name.
 * @param id The ID of the campaign to resolve for.
 * @param signal Abort signal.
 * @returns {Promise<string|null>} Returns the name or null.
 */
export const resolveCampaignName = (id, signal) => {
  if (typeof id !== 'string') return null;
  const body = JSON.stringify([id]);
  return apiFetch(`${pathManage}/resolve/campaigns`, { method: 'POST', signal, body })
    .then(response => completeJson(response))
    .then(elements => elements?.[0]?.name || null)
    .catch(() => null);
};
