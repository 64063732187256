const Colors = {
  Black: '#212529',
  Gray: '#3c434b',
  GrayLight: '#e0e0e0',
  Green: '#74b841',
  Orange: '#f39100',
  White: '#ffffff'
};

export default Colors;

export const ButtonColors = [
  { Name: 'Dynamik-Blau', HexCode: '#0064b4' },
  { Name: 'Dynamik-Blau (light)', HexCode: '#6291e0' },
  { Name: 'Dynamik-Blau (dark)', HexCode: '#143c7f' },
  { Name: 'Energie-Rot', HexCode: '#f8522a' },
  { Name: 'Energie-Rot (light)', HexCode: '#ff8557' },
  { Name: 'Energie-Rot (dark)', HexCode: '#bd1400' },
  { Name: 'Frische-Türkis', HexCode: '#1ebeb2' },
  { Name: 'Frische-Türkis (light)', HexCode: '#66f1e4' },
  { Name: 'Frische-Türkis (dark)', HexCode: '#008d83' },
  { Name: 'Sattgrün', HexCode: '#74b841' },
  { Name: 'Sattgrün (light)', HexCode: '#a6eb71' },
  { Name: 'Sattgrün (dark)', HexCode: '#42880a' },
  { Name: 'Sonnengelb', HexCode: '#f8be2a' },
  { Name: 'Sonnengelb (light)', HexCode: '#fff060' },
  { Name: 'Sonnengelb (dark)', HexCode: '#c18e00' },
  { Name: 'Wärme-Orange', HexCode: '#f39100' },
  { Name: 'Wärme-Orange (light)', HexCode: '#ffc245' },
  { Name: 'Wärme-Orange (dark)', HexCode: '#ba6300' }
];
