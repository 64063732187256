import React from 'react';
import PropTypes from 'prop-types';
import './TableRow.scss';

const TableRow = ({ alternate, children }) => {
  return (
    <tr className={`TableRow ${alternate ? 'TableRow--Alternate' : ''}`}>
      {children &&
        (children.length > 1
          ? children.map((cell, index) => React.cloneElement(cell, { alternate, key: index }))
          : React.cloneElement(children, { alternate }))}
    </tr>
  );
};

TableRow.propTypes = {
  alternate: PropTypes.bool,
  children: PropTypes.any
};

export default TableRow;
