import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

/**
 * React plugin for Application insights.
 * @see https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-react-js#basic-usage
 * @type {ReactPlugin}
 */
const reactPlugin = new ReactPlugin();

/**
 * Application insights instance.
 * @type {ApplicationInsights}
 */
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_CMS_AI_CONNECTION_STRING,
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    enableAjaxPerfTracking: true,
    enableAjaxErrorStatusText: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    disableFetchTracking: false,
    distributedTracingMode: DistributedTracingModes.W3C,
    extensions: [reactPlugin]
  }
});

/**
 * Message of Resize Observer exception that is of no value.
 * @type {string}
 */
const exceptionResizeObserver = 'ResizeObserver loop completed with undelivered notifications.';

/**
 * Message of Resize Observer exception that is of no value. This one is used in e.g. Safari.
 * @type {string}
 */
const exceptionResizeObserverAlt = 'ResizeObserver loop limit exceeded';

/**
 * Adds the standard exception filters. Invoke after App Insights are loaded.
 * @param target The target insights to add the initializers to.
 */
export const addExceptionFilters = target => {
  // Add exception filter.
  target.addTelemetryInitializer(({ baseType, baseData }) => {
    // Not exception, telemetry is OK.
    if (baseType !== 'ExceptionData') return true;

    // Exception include Resize Observer loop error in any kind, skip it.
    if (baseData?.exceptions?.find(({ message }) => message?.includes(exceptionResizeObserver)))
      return false;
    if (baseData?.exceptions?.find(({ message }) => message?.includes(exceptionResizeObserverAlt)))
      return false;

    // Not matched, telemetry is OK.
    return true;
  });
};
