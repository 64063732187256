import PropTypes from 'prop-types';
import React, { createElement, useEffect, useMemo, useRef, useState } from 'react';
import Colors from '../../definitions/colors';
import { useClientRect } from '../../hooks/useClientRect';
import CircleIcon from './CircleIcon.jsx';
import './Tooltip.scss';
import { Help } from '../../images/icons';

const Tooltip = ({
  icon,
  iconSize,
  inverted,
  size,
  content,
  className,
  style,
  iconClassName,
  iconStyle,
  contentClassName,
  contentStyle
}) => {
  const [visible, setVisible] = useState(false);
  const finalIconSize = iconSize || size * 0.4;
  const [displayOnLeft, setDisplayOnLeft] = useState(false);

  const iconElement = createElement(icon, {
    size: finalIconSize,
    fill: inverted ? Colors.Orange : Colors.White
  });

  const refIcon = useRef();
  const refContent = useRef();

  // Get relevant dimensions.
  const { width: windowWidth } = useClientRect(window);
  const { x: iconX, width: iconWidth } = useClientRect(refIcon.current);
  const { width: contentWidth } = useClientRect(refContent.current);

  // Combine dimensions.
  const combinedDimensions = useMemo(() => {
    return iconX + iconWidth + 8 + contentWidth;
  }, [windowWidth, iconX, iconWidth, contentWidth]);

  // Update left-ness of display.
  useEffect(() => setDisplayOnLeft(combinedDimensions > windowWidth), [combinedDimensions]);

  return (
    <div
      className={`Tooltip ${className}`}
      style={style}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div ref={refIcon} className={`Tooltip__Icon ${iconClassName}`} style={iconStyle}>
        <CircleIcon
          offsetX={0}
          offsetY={0}
          icon={iconElement}
          size={size}
          color={inverted ? Colors.White : Colors.Orange}
        />
      </div>
      <div
        ref={refContent}
        className={`Tooltip__Content ${
          visible ? 'Tooltip__Content--Visible' : ''
        } ${contentClassName}`}
        style={
          displayOnLeft ? { ...contentStyle, right: size + 8 } : { ...contentStyle, left: size + 8 }
        }
      >
        {content}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.elementType.isRequired,
  iconSize: PropTypes.number,
  inverted: PropTypes.bool,
  size: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  extraWidth: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object
};

Tooltip.defaultProps = {
  size: 14,
  icon: Help
};

export default Tooltip;
