import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Tick } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './HighlightEdit.scss';
import PageTitle from '../../helpers/pageTitle';
import DisplayGroup from '../atoms/DisplayGroup';
import SingleImageManager from '../modules/SingleImageManager';
import Textbox from '../atoms/input/Textbox';
import FormRow from '../atoms/FormRow';
import RadioButtons from '../atoms/input/RadioButtons';
import Dropdown from '../atoms/input/Dropdown';
import { remoteAction, useEntityEdit } from './common';
import { useHighlight } from '../../hooks/useRemoteValue';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { createHighlight, updateHighlight } from '../../api/highlights';
import { useCampaignsAll, useOffersAll } from '../../hooks/useResolve';

const init = () => ({});

const HighlightEdit = () => {
  const id = useIdParam();

  // Use highlight of ID. Connect amend function that merges the incoming changes. Get changed status.
  const { isNew, entity, setEntity, loading, amendEntity, changed } = useEntityEdit(
    useHighlight,
    id,
    { init }
  );

  // Functional definition for offer highlight property access.
  const accessOffer = {
    // On is offer, changing type resets target.
    on: 'offer',
    setOn: value => setEntity({ ...entity, on: { [value]: {} } }),

    // Common accessors.
    targetTitle: entity?.on?.offer?.target?.title,

    // Target mapped by it's id.
    onOffer: entity?.on?.offer?.target?.id,
    setOnOffer: value => setEntity({ ...entity, on: { offer: { target: { id: value } } } }),

    // Campaign uncommitted.
    setOnCampaign: () => undefined
  };

  // Functional definition for campaign highlight property access.
  const accessCampaign = {
    // On is campaign, changing type resets target.
    on: 'campaign',
    setOn: value => setEntity({ ...entity, on: { [value]: {} } }),

    // Common accessors.
    targetTitle: entity?.on?.campaign?.target?.title,

    // Offer uncommitted.
    setOnOffer: () => undefined,

    // Target mapped by it's id.
    onCampaign: entity?.on?.campaign?.target?.id,
    setOnCampaign: value => setEntity({ ...entity, on: { campaign: { target: { id: value } } } })
  };

  // Type based property accessors.
  const { on, setOn, targetTitle, onOffer, setOnOffer, onCampaign, setOnCampaign } =
    (entity?.on?.campaign && accessCampaign) || accessOffer;

  // Basic property accessors.
  const preview = entity?.preview;
  const setPreview = value => amendEntity({ preview: value });

  const previewTitle = entity?.previewTitle;
  const setPreviewTitle = value => amendEntity({ previewTitle: value });

  const portrait = entity?.portrait;
  const setPortrait = value => amendEntity({ portrait: value });

  const badge = entity?.badge;
  const setBadge = value => amendEntity({ badge: value });

  const title = entity?.title;
  const setTitle = value => amendEntity({ title: value });

  const excerpt = entity?.excerpt;
  const setExcerpt = value => amendEntity({ excerpt: value });

  // Use resolution values.
  const [campaigns] = useCampaignsAll();
  const [offers] = useOffersAll();

  // Submits the create or the change.
  const submit = isNew
    ? remoteAction({
        action: async () => createHighlight(entity),
        navigateSuccess: ({ id }) => Paths.HighlightDetails(id),
        messageSuccess: 'Highlight erstellt.',
        messageFail: 'Fehler beim Erstellen.'
      })
    : remoteAction({
        action: async () => updateHighlight(entity),
        navigateSuccess: ({ id }) => Paths.HighlightDetails(id),
        messageSuccess: 'Änderungen gespeichert.',
        messageFail: 'Fehler beim Ändern.'
      });

  return (
    <main className='HighlightEdit'>
      <LoadingOverlay show={loading} />
      <PageTitle title={isNew ? 'Highlight erstellen' : `Highlight bearbeiten: ${targetTitle}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title={isNew ? 'Highlight erstellen' : 'Highlight bearbeiten'}
            backButton={{
              to: isNew ? Paths.Highlights() : Paths.HighlightDetails(entity?.id),
              label: isNew ? 'Highlights' : `Highlight: ${targetTitle}`
            }}
            right={[
              <Button
                key={1}
                title='Abbrechen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                to={isNew ? Paths.Highlights() : Paths.HighlightDetails(entity?.id)}
              />,
              <Button
                key={2}
                title={isNew ? 'Highlight speichern' : 'Änderungen speichern'}
                icon={Tick}
                iconSize={17}
                disabled={!changed}
                onClick={submit}
              />
            ]}
          />
          <DisplayGroup
            label='Verknüpfter Inhalt*'
            tooltip='Wählen Sie aus, auf welche Kampagne oder welchen Vorteil dieses Story-Highlight verlinken soll.'
          >
            <FormRow spaceBetween={false}>
              <RadioButtons
                value={on}
                onUpdateValue={setOn}
                options={[
                  { value: 'campaign', label: 'Kampagne' },
                  { value: 'offer', label: 'Vorteil' }
                ]}
              />
              {on === 'campaign' ? (
                <Dropdown
                  title='Kampagne auswählen*'
                  onUpdateValue={setOnCampaign}
                  value={onCampaign}
                  showLabel
                  options={campaigns?.map(campaign => ({
                    value: campaign.id,
                    label: campaign.name
                  }))}
                />
              ) : (
                <Dropdown
                  title='Vorteil auswählen*'
                  onUpdateValue={setOnOffer}
                  value={onOffer}
                  showLabel
                  options={offers?.map(offer => ({
                    value: offer.id,
                    label: offer.name
                  }))}
                />
              )}
            </FormRow>
          </DisplayGroup>
          <DisplayGroup
            label='Vorschaubild*'
            tooltip='Das Vorschaubild wird als kleiner Kreis dargestellt, bevor die Story geöffnet wird. Sollte möglichst quadratisch sein.'
          >
            <SingleImageManager
              image={preview}
              onUpdateImage={setPreview}
              width={120}
              height={120}
              circle
              cover
            />
          </DisplayGroup>
          <DisplayGroup
            label='Vorschautitel*'
            tooltip='Der Vorschautitel wird unterhalb des kreisförmigen Vorschaubilds eingeblendet und sollte aufgrund des begrenzten Platzes möglichst kurz sein.'
          >
            <Textbox
              value={previewTitle}
              onUpdateValue={setPreviewTitle}
              name='previewTitle'
              title='Vorschautitel'
              showLabel={false}
              required
            />
          </DisplayGroup>
          <DisplayGroup
            label='Story-Bild*'
            tooltip='Das Story-Bild wird vollformatig in der Story angezeigt, darüber wird der Text gelegt. Das Bild sollte ungefähr das Seitenverhältnis 9:16 haben.'
          >
            <SingleImageManager
              image={portrait}
              onUpdateImage={setPortrait}
              width={135}
              height={240}
              cover
            />
          </DisplayGroup>
          <DisplayGroup
            label='Story-Titel*'
            tooltip='Der Story-Titel liegt transparent über dem Story-Bild und sollte die Aufmerksamkeit auf sich ziehen.'
          >
            <Textbox
              value={title}
              onUpdateValue={setTitle}
              name='title'
              title='Story-Titel'
              showLabel={false}
              required
            />
          </DisplayGroup>
          <DisplayGroup
            label='Story-Beschreibung*'
            tooltip='Die Story-Beschreibung wird als kurzer Fließtext unterhalb des Titels eingeblendet.'
          >
            <Textbox
              value={excerpt}
              onUpdateValue={setExcerpt}
              name='excerpt'
              title='Story-Beschreibung'
              showLabel={false}
              required
            />
          </DisplayGroup>
          <DisplayGroup
            label='Badge festlegen'
            tooltip='Bei Vorteilen wird standardmäßig die Gültigkeit angezeigt, Kampagnen haben üblicherweise kein Badge. In beiden Fällen kann das Badge hier manuell festgelegt werden. Sollte nicht länger als ca. 15 Zeichen sein.'
          >
            <div className='HighlightEdit__Badge'>
              <Textbox
                value={badge}
                onUpdateValue={setBadge}
                name='badge'
                title='Badge festlegen'
                showLabel={false}
                required
              />
            </div>
          </DisplayGroup>
        </Container>
      )}
    </main>
  );
};

export default HighlightEdit;
