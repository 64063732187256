import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';
import { CheckboxTick } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import Tooltip from '../Tooltip';
import { keySelect } from '../../../util/handlers';

const Checkbox = ({ disabled, label, onUpdateValue, value, tooltip }) => {
  const toggle = useCallback(() => onUpdateValue?.(!value), [onUpdateValue, value]);
  return (
    <div
      className={`Checkbox
      ${disabled ? 'Checkbox--Disabled' : ''}
      ${!onUpdateValue ? 'Checkbox--OnlyDisplay' : ''}
      ${value ? 'Checkbox--Ticked' : ''}
      `}
      role='button'
      tabIndex={0}
      onClick={toggle}
      onKeyDown={keySelect(toggle)}
    >
      <div className='Checkbox__Indicator'>
        <div className='Checkbox__Tick'>
          <CheckboxTick fill={disabled ? Colors.Gray : Colors.Orange} size={17} />
        </div>
      </div>
      {label && <div className='Checkbox__Label'>{label}</div>}
      {tooltip && <Tooltip content={tooltip} />}
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onUpdateValue: PropTypes.func,
  value: PropTypes.bool.isRequired,
  tooltip: PropTypes.string
};

export default Checkbox;
