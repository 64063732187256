import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Tick } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './CustomerEdit.scss';
import PageTitle from '../../helpers/pageTitle';
import DisplayGroup from '../atoms/DisplayGroup';
import Textbox from '../atoms/input/Textbox';
import { validateDate, validateEmail, validatePhone } from '../../helpers/validators';
import FormRow from '../atoms/FormRow';
import NumericPicker from '../atoms/input/NumericPicker';
import Dropdown from '../atoms/input/Dropdown';
import MultiPicker from '../atoms/input/MultiPicker';
import { remoteAction, useEntityEdit } from './common';
import { useCustomer } from '../../hooks/useRemoteValue';
import { useInterestsAll } from '../../hooks/useResolve';
import { createCustomer, updateCustomer } from '../../api/customers';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { displayCustomerBirthday, displayCustomerName } from '../../helpers/formatters';

const init = () => ({});

const CustomerEdit = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Use customer of ID. Connect amend function that merges the incoming changes. Get changed status.
  const {
    isNew,
    entity: customer,
    loading,
    amendEntity,
    changed
  } = useEntityEdit(useCustomer, id, { init });

  // Other data.
  const [allInterests] = useInterestsAll();

  // Property accessors.
  const salutationReadonly = true;
  const salutation = customer?.salutation;
  const setSalutation = value => amendEntity({ salutation: value });

  const firstNameReadonly = true;
  const firstName = customer?.firstName;
  const setFirstName = value => amendEntity({ firstName: value });

  const lastNameReadonly = true;
  const lastName = customer?.lastName;
  const setLastName = value => amendEntity({ lastName: value });

  const emailReadonly = true;
  const email = customer?.email;
  const setEmail = value => amendEntity({ email: value });

  const telephoneReadonly = true;
  const telephone = customer?.telephone;
  const setTelephone = value => amendEntity({ telephone: value });

  const dateOfBirthReadonly = true;
  const dateOfBirth = customer?.dateOfBirth;
  const setDateOfBirth = value => amendEntity({ dateOfBirth: value });

  const householdPeopleReadonly = true;
  const householdPeople = customer?.householdPeople;
  const setHouseholdPeople = value => amendEntity({ householdPeople: value });

  const householdChildrenReadonly = true;
  const householdChildren = customer?.householdChildren;
  const setHouseholdChildren = value => amendEntity({ householdChildren: value });

  const householdHasChildrenReadonly = true;
  const householdHasChildren = customer?.householdHasChildren;
  const setHouseholdHasChildren = value =>
    amendEntity({
      householdHasChildren: value,
      householdChildren: value ? householdChildren : null
    });

  // Make internal category options and keys for multi-picker. When setting from keys, map back.
  const interestsReadonly = true;
  const interestsOptions = allInterests.map(({ name, label }) => ({
    key: name,
    label
  }));
  const interestsPickedKeys = customer?.interests?.map(interest => interest.name) || [];
  const setInterestsPickedKeys = value =>
    amendEntity({
      interests: allInterests.filter(interest => value.includes(interest.name))
    });

  // Submits the create or the change.
  const submit = isNew
    ? remoteAction({
        action: async () => createCustomer(customer),
        navigateSuccess: ({ id }) => Paths.CustomerDetails(id),
        messageSuccess: 'Kunde erstellt.',
        messageFail: 'Fehler beim Erstellen.'
      })
    : remoteAction({
        action: async () => updateCustomer(customer),
        navigateSuccess: ({ id }) => Paths.CustomerDetails(id),
        messageSuccess: 'Änderungen gespeichert.',
        messageFail: 'Fehler beim Ändern.'
      });

  return (
    <main className='CustomerEdit'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Kunden bearbeiten: ${customer?.linkedContract?.displayName}`} />
      {!customer ? null : (
        <Container>
          <Toolbar
            title='Kunden bearbeiten'
            backButton={{
              to: Paths.CustomerDetails(id),
              label: displayCustomerName(customer)
            }}
            right={[
              <Button
                key={1}
                title='Abbrechen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                to={Paths.CustomerDetails(id)}
              />,
              <Button
                key={2}
                title='Änderungen speichern'
                icon={Tick}
                iconSize={17}
                disabled={!changed}
                onClick={submit}
              />
            ]}
          />
          <DisplayGroup
            label='Stammdaten'
            tooltip='Diese Stammdaten werden aus dem verknüpften evm-Vertrag aus Lima ausgelesen. Sie können nicht bearbeitet werden, aber leere Felder können einmalig ergänzt werden. Sie werden dann in Lima gespeichert.'
          >
            <FormRow>
              <Textbox
                value={salutation}
                onUpdateValue={setSalutation}
                name='salutation'
                title='Anrede'
                instantValidator={() => true}
                readonly={salutationReadonly}
              />
              <Textbox
                value={firstName}
                onUpdateValue={setFirstName}
                name='firstName'
                title='Vorname'
                instantValidator={name => '' !== name}
                readonly={firstNameReadonly}
              />
              <Textbox
                value={lastName}
                onUpdateValue={setLastName}
                name='lastName'
                title='Nachname'
                instantValidator={name => '' !== name}
                readonly={lastNameReadonly}
              />
            </FormRow>
            <br />
            <FormRow>
              {/* TODO This view is not reachable by direct means anyays, fix when needed. */}
              <Textbox
                value={displayCustomerBirthday({ dateOfBirth })}
                onUpdateValue={setDateOfBirth}
                name='dateOfBirth'
                title='Geburtsdatum'
                instantValidator={date => validateDate(date, true)}
                readonly={dateOfBirthReadonly}
              />
              <Textbox
                value={email}
                onUpdateValue={setEmail}
                name='email'
                title='E-Mail-Adresse'
                instantValidator={date => validateEmail(date, true)}
                readonly={emailReadonly}
              />
              <Textbox
                value={telephone}
                onUpdateValue={setTelephone}
                name='telephone'
                title='Telefonnummer'
                instantValidator={date => validatePhone(date, true)}
                readonly={telephoneReadonly}
              />
            </FormRow>
          </DisplayGroup>
          <DisplayGroup label='Haushalt & Familie'>
            <FormRow>
              <NumericPicker
                title='Im Haushalt lebende Personen'
                showLabel
                min={1}
                value={householdPeople}
                valueDisplay={value => `${value} Person${value !== 1 ? 'en' : ''}`}
                onUpdateValue={setHouseholdPeople}
                readonly={householdPeopleReadonly}
              />
              <Dropdown
                title='Kinder im Haushalt'
                value={householdHasChildren}
                showLabel
                onUpdateValue={setHouseholdHasChildren}
                options={[
                  { value: true, label: 'Ja' },
                  { value: false, label: 'Nein' }
                ]}
                readonly={householdHasChildrenReadonly}
              />
              <NumericPicker
                title='Im Haushalt lebende Kinder'
                showLabel
                min={1}
                disabled={!householdHasChildren}
                value={householdChildren}
                valueDisplay={value => `${value || 0} Kind${value !== 1 ? 'er' : ''}`}
                onUpdateValue={setHouseholdChildren}
                readonly={householdChildrenReadonly}
              />
            </FormRow>
          </DisplayGroup>
          <DisplayGroup label='Interessen'>
            <MultiPicker
              options={interestsOptions}
              pickedKeys={interestsPickedKeys}
              onUpdatePickedKeys={setInterestsPickedKeys}
              readonly={interestsReadonly}
            />
          </DisplayGroup>
        </Container>
      )}
    </main>
  );
};

export default CustomerEdit;
