import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';
import './DisplayGroup.scss';

const DisplayGroup = ({ htmlFor, children, label, tooltip }) => {
  return (
    <div className='DisplayGroup'>
      <div className='DisplayGroup__LabelWrapper'>
        <label htmlFor={htmlFor} className='DisplayGroup__Label'>
          {label}
        </label>
        {tooltip && <Tooltip content={tooltip} size={28} />}
      </div>
      <div className='DisplayGroup__ContentWrapper'>{children}</div>
    </div>
  );
};

DisplayGroup.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.any,
  label: PropTypes.string,
  tooltip: PropTypes.string
};

export default DisplayGroup;
