import { getListing, pathManage } from './common';

/**
 * Retrieves the dashboard listing.
 * @return {*} Returns a promise with the dashboard value. Failures to get are treated as errors.
 */
export const getDashboard = () => getListing(`${pathManage}/listing/dashboard`);

/**
 * Retrieves the archive counts.
 * @return {*} Returns a promise with the archive counts. Failures to get are treated as errors.
 */
export const getArchiveCounts = () => getListing(`${pathManage}/listing/archive-counts`);
