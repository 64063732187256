import React, { useCallback } from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Plus, Tick } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './PartnerEdit.scss';
import DisplayGroup from '../atoms/DisplayGroup';
import Textbox from '../atoms/input/Textbox';
import FormRow from '../atoms/FormRow';
import { validateEmail, validatePhone } from '../../helpers/validators';
import RichtextEditor from '../atoms/input/RichtextEditor';
import SingleImageManager from '../modules/SingleImageManager';
import PageTitle from '../../helpers/pageTitle';
import { usePartner } from '../../hooks/useRemoteValue';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { createPartner, updatePartner } from '../../api/partners';
import { remoteAction, useEntityEdit } from './common';
import { SitePanel } from '../modules/SitePanel';

const init = () => ({ sites: [] });

const PartnerEdit = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Use partner of ID. Connect amend function that merges the incoming changes. Get changed status.
  const { isNew, entity, setEntity, loading, amendEntity, changed } = useEntityEdit(
    usePartner,
    id,
    {
      init
    }
  );

  // Name and setter.
  const [name, setName] = [entity?.name || '', value => amendEntity({ name: value })];

  // Description and setter. Amends description.
  const description = entity?.description?.value || '';
  const setDescription = value => amendEntity({ description: { type: 'text/html', value } });

  // Basic property accessors.
  const logo = entity?.logo;
  const setLogo = value => amendEntity({ logo: value });

  const contactName = entity?.contact?.name;
  const setContactName = value => amendEntity({ contact: { name: value } });

  const contactEmail = entity?.contact?.email;
  const setContactEmail = value => amendEntity({ contact: { email: value } });

  const contactPhone = entity?.contact?.phone;
  const setContactPhone = value => amendEntity({ contact: { phone: value } });

  // Update entity delete site by ID.
  const deleteSite = useCallback(
    index =>
      setEntity(entity => ({
        ...entity,
        sites: [...entity.sites.slice(0, index), ...entity.sites.slice(index + 1)]
      })),
    [setEntity]
  );

  // Update entity add empty site.
  const addSite = useCallback(
    () =>
      setEntity(entity => ({
        ...entity,
        sites: [...entity.sites, {}]
      })),
    [setEntity]
  );

  // Update entity set site name by ID.
  const updateSiteName = useCallback(
    index => value =>
      setEntity(entity => {
        const newSites = [...entity.sites];
        newSites[index] = { ...newSites[index], name: value };
        return { ...entity, sites: newSites };
      }),
    [setEntity]
  );

  // Update entity set site address by ID.
  const updateSiteAddress = useCallback(
    index => value =>
      setEntity(entity => {
        const newSites = [...entity.sites];
        newSites[index] = { ...newSites[index], address: value };
        return { ...entity, sites: newSites };
      }),
    [setEntity]
  );

  // Submits the create or the change.
  const submit = isNew
    ? remoteAction({
        action: async () => createPartner(entity),
        navigateSuccess: ({ id }) => Paths.PartnerDetails(id),
        messageSuccess: 'Partner erstellt.',
        messageFail: 'Fehler beim Erstellen.'
      })
    : remoteAction({
        action: async () => updatePartner(entity),
        navigateSuccess: ({ id }) => Paths.PartnerDetails(id),
        messageSuccess: 'Änderungen gespeichert.',
        messageFail: 'Fehler beim Ändern.'
      });

  return (
    <main className='PartnerEdit'>
      <LoadingOverlay show={loading} />
      <PageTitle title={isNew ? 'Partner erstellen' : `Partner bearbeiten: ${entity?.name}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title={isNew ? 'Partner erstellen' : 'Partner bearbeiten'}
            backButton={{
              to: isNew ? Paths.Partners() : Paths.PartnerDetails(entity.id),
              label: isNew ? 'Partner' : entity.name
            }}
            right={[
              <Button
                key={1}
                title='Abbrechen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                to={isNew ? Paths.Partners() : Paths.PartnerDetails(entity.id)}
              />,
              <Button
                key={2}
                title={isNew ? 'Partner speichern' : 'Änderungen speichern'}
                icon={Tick}
                iconSize={17}
                disabled={!changed}
                onClick={submit}
              />
            ]}
          />

          <DisplayGroup
            label='Name*'
            htmlFor='name'
            tooltip='Der Name wird häufig in Verbindung mit den zugeordneten Vorteilen eingeblendet.'
          >
            <Textbox
              inputId='name'
              title='Name'
              name='name'
              value={name}
              onUpdateValue={setName}
              required
              showLabel={false}
            />
          </DisplayGroup>
          <DisplayGroup
            label='Logo'
            tooltip='Laden Sie hier das optionale Logo hoch, das innerhalb von Vorteilen angezeigt werden kann. Empfohlen wird eine vektorbasierte SVG-Grafik. Das Logo sollte einen transparenten Hintergrund ohne zusätzlichen Weißraum haben.'
          >
            <SingleImageManager image={logo} onUpdateImage={setLogo} width={320} height={180} />
          </DisplayGroup>
          <DisplayGroup
            label='Beschreibung*'
            tooltip='Beschreiben Sie den Partner und seine Leistungen kurz und prägnant.'
          >
            <RichtextEditor onUpdateValue={setDescription} value={description} />
          </DisplayGroup>

          <DisplayGroup
            label='Zweigstellen'
            tooltip='Zweigstellen werden in der Ortssuche berücksichtigt.'
          >
            {!entity?.sites?.length ? (
              <Button icon={Plus} title='Zweigstelle hinzufügen' onClick={addSite} />
            ) : (
              <>
                {entity.sites.map((site, i) => (
                  <SitePanel
                    edit={true}
                    key={i}
                    name={site.name}
                    address={site.address}
                    setName={updateSiteName(i)}
                    setAddress={updateSiteAddress(i)}
                    onDelete={() => deleteSite(i)}
                  />
                ))}
                <Button icon={Plus} title='Zweigstelle hinzufügen' onClick={addSite} />
              </>
            )}
          </DisplayGroup>

          <DisplayGroup
            label='Ansprechpartner'
            tooltip='Diese Angaben sind ausschließlich intern sichtbar und werden Kund*innen nicht angezeigt.'
          >
            <FormRow>
              <Textbox
                value={contactName}
                onUpdateValue={setContactName}
                name='contactName'
                title='Name'
              />
              <Textbox
                value={contactEmail}
                onUpdateValue={setContactEmail}
                name='contactEmail'
                title='E-Mail-Adresse'
                instantValidator={validateEmail}
              />
              <Textbox
                value={contactPhone}
                onUpdateValue={setContactPhone}
                name='contactPhone'
                title='Telefonnummer'
                instantValidator={validatePhone}
              />
            </FormRow>
          </DisplayGroup>
        </Container>
      )}
    </main>
  );
};
export default PartnerEdit;
