import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { accountAssign, selectAccount } from '../redux/reducers/account';
import { cmsStore } from '../components/App';

/**
 * The client ID. This is associated to the registered App.
 */
const msalClientId = process.env.REACT_APP_MSAL_CLIENT_ID;

/**
 * The tenant ID, given by the AD.
 */
const msalTenant = process.env.REACT_APP_MSAL_TENANT;

/**
 * The used redirect URI. Sent to the registered App as where to go after login is complete.
 */
const msalRedirect = process.env.REACT_APP_MSAL_REDIRECT;

/**
 * MSAL public client configuration.
 * @type {Readonly<{cache: {cacheLocation: string, storeAuthStateInCookie: boolean}, auth: {redirectUri, clientId, authority: string}}>}
 */
export const msalConfiguration = Object.freeze({
  auth: {
    // Client ID from App registration.
    clientId: msalClientId,

    // Authority is login@MS plus the tenant ID, i.e., the 247GRAD AD tenant ID.
    authority: `https://login.microsoftonline.com/${msalTenant}`,

    // This URL is what is sent to the App registration as the desired redirect ID. It *must* be entered in the
    // registration, otherwise the App registration will not deliver the token.
    redirectUri: msalRedirect
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: 'localStorage',
    // Set this to "true" if you are having issues on IE11 or Edge
    storeAuthStateInCookie: false
  }
});

export const msalAppScope = `${msalClientId}/.default`;

/**
 * Configuration for MSAL connection.
 * @type {PublicClientApplication}
 */
export const msalInstance = new PublicClientApplication(msalConfiguration);

/**
 * Gets a MSAL token with the currently selected account. Performs automatic account clearing if
 * an interaction is required.
 * @returns {Promise<string|null>} Returns a promise to the token or null.
 */
export const msalGetToken = async () => {
  // Get account from store. Use to get qctual account by home ID.
  const accountId = selectAccount(cmsStore.getState());

  // Verify type, update if mismatch found.
  if (typeof accountId !== 'string') {
    cmsStore.dispatch(accountAssign(null, 'Legacy account stored'));
    return null;
  }

  // Get actual account.
  const account = msalInstance.getAccountByHomeId(accountId);

  try {
    // No account selected, immediately return.
    if (!account) return null;

    // Get token silently.
    const result = await msalInstance.acquireTokenSilent({
      account,
      scopes: [msalAppScope]
    });

    // Retrieved the token, get the token value.
    return result ? result.accessToken : null;
  } catch (error) {
    // Check if token was invalidated.
    if (error instanceof InteractionRequiredAuthError) {
      cmsStore.dispatch(accountAssign(null, 'Interaction required for account'));
    }
    // Any error, return null.
    return null;
  }
};

/**
 * Unselects the currently selected account in the main CMS store.
 */
export const msalClearLogin = () => {
  cmsStore.dispatch(accountAssign(null, 'Login cleared by user'));
};

/**
 * Opens the login.
 * @returns {Promise<void>}
 */
export const msalOpenLogin = () => {
  return msalInstance.loginRedirect({
    scopes: [msalAppScope]
  });
};
