import { ViewRoute } from './ViewRoute';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import React from 'react';

export const ViewFallback = () => (
  <ViewRoute>
    <Redirect to={Paths.Login()} />
  </ViewRoute>
);
