import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';
import Button from './Button';

const Pagination = ({ currentPage, numPages, onPagePick }) => {
  if (!numPages || numPages <= 1) {
    return null;
  }

  const canGoBack = currentPage > 0;
  const onGoBack = () => {
    if (canGoBack) {
      onPagePick(currentPage - 1);
    }
  };
  const canGoNext = currentPage < numPages - 1;
  const onGoNext = () => {
    if (canGoNext) {
      onPagePick(currentPage + 1);
    }
  };

  /* PAGINATION LOGIC:
    -> Always show first page
    -> Always show last page
    -> Always show current page +/- 1 page
    -> Omit all other pages by "..." ellipsis
   */
  const pagesToShow = [...new Array(numPages)]
    .map((_, index) => index)
    .filter((_, index) => {
      return index === 0 || index === numPages - 1 || Math.abs(index - currentPage) <= 1;
    });

  return (
    <div className='Pagination'>
      <Button title='Zurück' onClick={onGoBack} disabled={!canGoBack} autoWidth />
      <div className='Pagination__NumberedPages'>
        {pagesToShow.map((page, index) => {
          const ellipsis = index > 0 && page - 1 > pagesToShow[index - 1];
          return (
            <span key={index}>
              {ellipsis && <span className='Pagination__Ellipsis'>...</span>}
              <button
                className={`Pagination__Page ${
                  page === currentPage ? 'Pagination__Page--Current' : ''
                }`}
                onClick={() => onPagePick(page)}
              >
                {page + 1}
              </button>
            </span>
          );
        })}
      </div>
      <Button title='Weiter' onClick={onGoNext} disabled={!canGoNext} autoWidth />
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  onPagePick: PropTypes.func
};

export default Pagination;
