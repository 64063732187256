import React, { useEffect } from 'react';
import ViewSwitch from './structures/ViewSwitch';
import withBrowserRouter from './wrappers/withBrowserRouter';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import withDragAndDropContext from './wrappers/withDragAndDropContext';
import { MsalProvider } from '@azure/msal-react';
import { PersistGate } from 'redux-persist/integration/react';
import { accountAssign } from '../redux/reducers/account';
import { msalInstance } from '../msal';
import { createCmsPersistor, createCmsStore } from '../redux/store';

/**
 * Global Redux store.
 */
export const cmsStore = createCmsStore();

/**
 * Global State persistor.
 */
export const cmsPersistor = createCmsPersistor();

const AppContent = () => {
  // Use initial loading overlay.
  useEffect(() => {
    const to = setTimeout(() => {
      const overlay = document.getElementsByClassName('ROOT__Spinner')[0];
      overlay.style.opacity = 0;
      overlay.style.visibility = 'hidden';
    }, 400);
    return () => clearTimeout(to);
  }, []);

  // Return primary app content.
  return (
    <div className='App'>
      <ToastContainer
        position='bottom-right'
        newestOnTop={true}
        autoClose={3000}
        transition={Slide}
      />
      <div>
        <ViewSwitch />
      </div>
    </div>
  );
};

const App = () => {
  // Listen to redirect completion. Dispatch as custom event.
  msalInstance.handleRedirectPromise().then(
    result => {
      if (result?.account)
        cmsStore.dispatch(accountAssign(result.account.homeAccountId, 'Logged in via redirect'));
      else accountAssign(null, `No account result from redirect`);
    },
    error => {
      cmsStore.dispatch(accountAssign(null, `Error while handling redirect (${error.toString()})`));
    }
  );

  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={cmsStore}>
        <PersistGate loading={null} persistor={cmsPersistor}>
          <AppContent />
        </PersistGate>
      </Provider>
    </MsalProvider>
  );
};

export default withBrowserRouter(withDragAndDropContext(App));
