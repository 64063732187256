import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const withDragAndDropContext = BaseComponent => {
  const WithDragAndDropContext = props => {
    return (
      <DndProvider backend={HTML5Backend}>
        <BaseComponent {...props} />
      </DndProvider>
    );
  };
  WithDragAndDropContext.displayName = 'withDragAndDropContext';
  return WithDragAndDropContext;
};

export default withDragAndDropContext;
