/**
 * Returns the customer principal.
 * @param customer The customer object.
 * @return {string|null} The principal or null.
 */
export const customerPrincipal = customer => {
  // No for identifier, nothing to process.
  if (!customer?.for?.identifier) return null;

  // Find separator, if not present, nothing to process.
  const separator = customer.for.identifier.indexOf('/');
  if (separator < 0) return null;

  // Parse substring after separator.
  // Split items at semicolon, take all elements of length two and name them.
  return customer.for.identifier.substring(0, separator);
};

/**
 * Parses the customer IDs and contract types from the customer object if present and valid.
 * @param customer
 * @return {{id: string, type: string}[]|null}
 */
export const customerContracts = customer => {
  // No for identifier, nothing to process.
  if (!customer?.for?.identifier) return null;

  // Find separator, if not present, nothing to process.
  const separator = customer.for.identifier.indexOf('/');
  if (separator < 0) return null;

  // Parse substring after separator.
  // Split items at semicolon, take all elements of length two and name them.
  return customer.for.identifier
    .substring(separator + 1)
    .split(';')
    .map(pair => pair.split('$'))
    .filter(pair => 2 === pair.length)
    .map(([id, types]) => ({ id, types }));
};
