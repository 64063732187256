import { apiFetch, completeJson, pathAnalyze } from './common';

/**
 * Gets the customer value for the given identifier at the given point in time.
 * @param identifier The identifier of the customer to compute for.
 * @param at The ISO datetime for which to compute the value.
 * @param {AbortSignal?} signal Abort signal for fetch.
 * @return {Promise<{value: number}>} Returns a promise with the value. Failures are completed as an
 * object with value set to null.
 */
export const analyzeValue = (identifier, at, signal) => {
  // Check input shape.
  if (typeof identifier !== 'string') return null;

  // Fetch and process.
  return apiFetch(`${pathAnalyze}/value`, { signal, query: { identifier, at } })
    .then(response => completeJson(response))
    .catch(() => ({ value: null }));
};

/**
 * Gets the customer values summary (global) at the given point in time.
 * @param at The ISO datetime for which to compute the value.
 * @param {AbortSignal?} signal Abort signal for fetch.
 * @return {Promise<{mean: number, splits: object}>} Returns a promise with the values. Failures are
 * completed as an object with value set to null.
 */
export const analyzeValuesSummary = (at, signal) => {
  // Fetch and process.
  return apiFetch(`${pathAnalyze}/values/summary`, { signal, query: { at } })
    .then(response => completeJson(response))
    .catch(() => ({ value: null }));
};
