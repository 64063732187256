import React from 'react';
import PropTypes from 'prop-types';
import SingleImage from '../atoms/imageManager/SingleImage';
import './MultiImageManager.scss';
import DropArea from '../atoms/imageManager/DropArea';
import ImageUpload from '../atoms/imageManager/ImageUpload';

const MultiImageManager = ({ circle, cover, height, images, limit, onUpdateImages, width }) => {
  const deleteImage = index => {
    const newImages = [...images];
    newImages.splice(index, 1);
    onUpdateImages(newImages);
  };

  const moveImage = (fromIndex, toIndex) => {
    const finalToIndex = toIndex > fromIndex ? toIndex - 1 : toIndex;
    const newImages = [...images];
    newImages.splice(finalToIndex, 0, newImages.splice(fromIndex, 1)[0]);
    onUpdateImages(newImages);
  };

  const appendImage = image => {
    const newImages = [...images, image];
    onUpdateImages(newImages);
  };

  const canAppend = !limit || limit > images?.length;

  return (
    <div className='MultiImageManager'>
      {images &&
        images.map((image, index) => [
          <DropArea
            key={index + 'drop'}
            onDrop={moveImage}
            index={index}
            width={width}
            height={height}
          />,
          <SingleImage
            key={index + image.location}
            index={index}
            location={image.location}
            {...{ circle, cover, height, width }}
            onRemove={() => deleteImage(index)}
          />
        ])}
      <DropArea onDrop={moveImage} index={images.length} width={width} height={height} />
      {canAppend && (
        <ImageUpload
          label='Bild hinzufügen'
          {...{ circle, height, width }}
          onUploadedImage={appendImage}
        />
      )}
    </div>
  );
};

MultiImageManager.propTypes = {
  circle: PropTypes.bool,
  cover: PropTypes.bool,
  height: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      alias: PropTypes.string
    })
  ),
  limit: PropTypes.number,
  onUpdateImages: PropTypes.func.isRequired,
  width: PropTypes.number
};
export default MultiImageManager;
