import React from 'react';
import PropTypes from 'prop-types';
import './ContractTile.scss';
import CircleIcon from './CircleIcon';
import { Electricity, Gas, Water } from '../../images/icons';
import Colors from '../../definitions/colors';
import { contractTypeLabel } from '../../helpers/formatters';

const ContractTile = ({ address, customerId, energyTypes, highlighted, name }) => {
  const iconColor = highlighted ? Colors.Orange : Colors.White;
  const circleColor = highlighted ? Colors.White : Colors.Orange;

  const gas = energyTypes.includes('G');
  const water = energyTypes.includes('W');
  const energy = energyTypes.includes('E');
  const iconGas = !gas ? null : (
    <CircleIcon icon={<Gas size={14} fill={iconColor} />} size={28} color={circleColor} />
  );
  const iconWater = !water ? null : (
    <CircleIcon icon={<Water size={12} fill={iconColor} />} size={28} color={circleColor} />
  );
  const iconEnergy = !energy ? null : (
    <CircleIcon icon={<Electricity size={16} fill={iconColor} />} size={28} color={circleColor} />
  );

  return (
    <div className={`ContractTile ${highlighted ? 'ContractTile--Highlight' : ''}`}>
      <div className='ContractTile__Type'>
        {iconGas}
        {iconWater}
        {iconEnergy}
        <span>{contractTypeLabel(energyTypes)}</span>
      </div>
      <div className='ContractTile__CustomerId'>{customerId}</div>
      <p className='ContractTile__Data'>
        {name}
        <br />
        {address}
      </p>
    </div>
  );
};

ContractTile.propTypes = {
  address: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  energyTypes: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  name: PropTypes.string.isRequired
};

export default ContractTile;
