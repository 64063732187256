import { useState } from 'react';

/**
 * Uses a permanent state that is persisted in local storage.
 * @template T
 * @param {string} key Key to use in local storage. Replace with useId when available.
 * @param {T|function(): T} init The init value or function.
 * @returns {[T, function(T): void, function(): void, function(): void]} State and set state, then
 * refresh from store and remove from store.
 */
export const useSessionState = (key, init) => {
  // Actual state.
  const [current, setCurrent] = useState(() => {
    // Get existing value from key. If present, deserialize and return.
    const existing = sessionStorage.getItem(key);
    if (existing !== null) {
      return JSON.parse(existing);
    }

    // Not present, create or use passed value. Assign as string to storage and return.
    const replace = typeof init === 'function' ? init() : init;
    sessionStorage.setItem(key, JSON.stringify(replace));
    return replace;
  });

  // Return transformed state.
  return [
    current,
    replace => {
      // Update actual storage and local storage.
      setCurrent(replace);
      sessionStorage.setItem(key, JSON.stringify(replace));
    },
    () => {
      // Get value and carry if assigned.
      const existing = sessionStorage.getItem(key);
      if (existing !== null) {
        setCurrent(JSON.parse(existing));
      }
    },
    () => {
      // Remove value assigned to key.
      sessionStorage.removeItem(key);
    }
  ];
};
