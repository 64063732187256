import { ViewRoute } from './ViewRoute';
import CampaignStatistics from '../views/CampaignStatistics';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import React from 'react';

export const ViewCampaignStatistics = () => (
  <ViewRoute
    loggedInComponent={<CampaignStatistics />}
    loggedOutComponent={<Redirect to={Paths.Login()} />}
  />
);
