import React, { useCallback } from 'react';
import { createOffer, updateOffer } from '../../api/offers';
import Colors from '../../definitions/colors';
import { Paths } from '../../definitions/paths';
import { buildFormAliasUrl } from '../../helpers/formatters';
import PageTitle from '../../helpers/pageTitle';
import useIdParam from '../../hooks/useIdParam';
import useQueryVar from '../../hooks/useQueryVars';
import { useOffer, usePartnerSites } from '../../hooks/useRemoteValue';
import {
  useCampaignsAll,
  useInterestsAll,
  useInternalCategoriesAll,
  usePartnersAll,
  useShopsAll
} from '../../hooks/useResolve';
import { Article, Close, Euro, Form, Qr, Tick } from '../../images/icons';
import Button from '../atoms/Button';
import CircleIcon from '../atoms/CircleIcon';
import DisplayGroup from '../atoms/DisplayGroup';
import FormRow from '../atoms/FormRow';
import DatePicker from '../atoms/input/DatePicker';
import Dropdown from '../atoms/input/Dropdown';
import MultiPicker from '../atoms/input/MultiPicker';
import NumericPicker from '../atoms/input/NumericPicker';
import RadioButtons from '../atoms/input/RadioButtons';
import RichtextEditor from '../atoms/input/RichtextEditor';
import Textbox from '../atoms/input/Textbox';
import LoadingOverlay from '../atoms/LoadingOverlay';
import OfferTypePicker from '../atoms/OfferTypePicker';
import Container from '../layout/Container';
import MultiImageManager from '../modules/MultiImageManager';
import Toolbar from '../modules/Toolbar';
import { remoteAction, useEntityEdit } from './common';
import './OfferEdit.scss';
import SubstitutionIndicator from '../atoms/SubstitutionIndicator';
import Checkbox from '../atoms/input/Checkbox';

const initialDefinitions = Object.freeze({
  article: {
    content: null,
    buttonWebsiteUsedName: null
  },
  shopify: {
    condition: null,
    limit: null,
    shop: null,
    productLimit: null,
    product: null,
    voucherCode: null,
    buttonWebsiteUsedName: null,
    buttonRedeemUsedName: null
  },
  qrCode: {
    condition: null,
    limit: null,
    buttonWebsiteUsedName: null,
    buttonRedeemUsedName: null
  },
  form: {
    condition: null,
    limit: null,
    buttonWebsiteUsedName: null,
    buttonRedeemUsedName: null,
    target: null
  }
});

const shopifyBackreference = process.env.REACT_APP_SHOPIFY_TRYOUT_NOTE_NAME;

const priorityOptions = [
  { value: 'highest', label: 'Vorne anzeigen' },
  { value: 'higher', label: 'Vor anderen anzeigen' },
  { value: 'none', label: 'Keine Priorisierung' },
  { value: 'lower', label: 'Hinter anderen anzeigen' },
  { value: 'lowest', label: 'Hinten anzeigen' }
];

const priorityValueToOption = value =>
  (!value && 'none') ||
  (100 <= value && 'highest') ||
  (value <= -100 && 'lowest') ||
  (0 < value && 'higher') ||
  (value < 0 && 'lower') ||
  'none';

const priorityOptionToValue = option =>
  (option === 'highest' && 100) ||
  (option === 'higher' && 50) ||
  (option === 'lower' && -50) ||
  (option === 'lowest' && -100) ||
  null;

/**
 * Copies the offer. Adjusts the title and the stage.
 * @param entity The entity to copy.
 * @returns {*&{stage: number, title: string}} Returns a copy of the entity with the appropriate fields changed.
 */
const toDuplicatedOffer = entity => ({
  ...entity,
  title: `${entity.title} (dupliziert)`,
  stage: 0
});

/**
 * Special site used in site selector giving the option to make available at all sites.
 * @type {{name: string, id: string}}
 */
const siteAll = {
  id: 'ALL',
  name: 'In allen Zweigstellen'
};

const OfferEdit = () => {
  const id = useIdParam();
  const initialCampaignId = useQueryVar('useCampaign');
  const templateId = useQueryVar('useTemplate');

  // Use highlight of ID. Connect amend function that merges the incoming changes. Get changed status.
  const { isNew, entity, setEntity, loading, amendEntity, changed } = useEntityEdit(useOffer, id, {
    template: templateId,
    templateTransform: toDuplicatedOffer,
    init: useCallback(
      () => ({
        definition: { qrCode: { ...initialDefinitions.qrCode } },
        campaign: initialCampaignId ? { id: initialCampaignId } : null
      }),
      [initialCampaignId]
    )
  });

  // Other data. TODO: There's also some loading status here. Should we notify on that?
  const [allCategories] = useInterestsAll();
  const [allInternalCategories] = useInternalCategoriesAll();
  const [shops] = useShopsAll();
  const [campaigns] = useCampaignsAll();
  const [partners] = usePartnersAll();
  const [availableSites] = usePartnerSites(entity?.partner?.id);

  // Functional definition for QR-code property access.
  const accessQrCode = {
    // Type is QR code.
    type: 'qrCode',

    // Common for limited offers.
    applyLimit: entity?.definition?.qrCode?.limit !== null,
    setApplyLimit: value => amendEntity({ definition: { qrCode: { limit: value ? 1 : null } } }),
    limit: entity?.definition?.qrCode?.limit,
    setLimit: value => amendEntity({ definition: { qrCode: { limit: value } } }),

    // QR-code button definition mapping.
    buttonWebsiteUsedName: entity?.definition?.qrCode?.buttonWebsiteUsedName,
    setButtonWebsiteUsedName: value =>
      amendEntity({ definition: { qrCode: { buttonWebsiteUsedName: value } } }),
    buttonRedeemUsedName: entity?.definition?.qrCode?.buttonRedeemUsedName,
    setButtonRedeemUsedName: value =>
      amendEntity({ definition: { qrCode: { buttonRedeemUsedName: value } } }),

    // Condition maps to QR-code's field. Assigns with type markdown.
    condition: entity?.definition?.qrCode?.condition?.value || '',
    setCondition: value =>
      amendEntity({ definition: { qrCode: { condition: { type: 'text/html', value } } } }),

    // Target for web form will not be changed.
    setTarget: () => undefined,

    // Shopify properties are not changed.
    setShopifyShop: () => undefined,
    setShopifyProduct: () => undefined,
    setShopifyVoucherCode: () => undefined,
    setShopifyProductLimit: () => undefined,
    setShopifyInventoryChecked: () => undefined,

    // Article properties are not changed.
    setArticleContent: () => undefined,
    setArticleTeaser: () => undefined
  };

  // Functional definition for shopify property access.
  const accessShopify = {
    // Type is shopify.
    type: 'shopify',

    // Common for limited offers.
    applyLimit: entity?.definition?.shopify?.limit !== null,
    setApplyLimit: value => amendEntity({ definition: { shopify: { limit: value ? 1 : null } } }),
    limit: entity?.definition?.shopify?.limit,
    setLimit: value => amendEntity({ definition: { shopify: { limit: value } } }),

    // Shopify button definition mapping.
    buttonWebsiteUsedName: entity?.definition?.shopify?.buttonWebsiteUsedName,
    setButtonWebsiteUsedName: value =>
      amendEntity({ definition: { shopify: { buttonWebsiteUsedName: value } } }),
    buttonRedeemUsedName: entity?.definition?.shopify?.buttonRedeemUsedName,
    setButtonRedeemUsedName: value =>
      amendEntity({ definition: { shopify: { buttonRedeemUsedName: value } } }),

    // Condition will not be changed.
    setCondition: () => undefined,

    // Target for web form will not be changed.
    setTarget: () => undefined,

    // Special shopify data
    shopifyShop: entity?.definition?.shopify?.shop,
    setShopifyShop: value => amendEntity({ definition: { shopify: { shop: value } } }),
    shopifyProduct: entity?.definition?.shopify?.product,
    setShopifyProduct: value => amendEntity({ definition: { shopify: { product: value } } }),
    shopifyVoucherCode: entity?.definition?.shopify?.voucherCode,
    setShopifyVoucherCode: value =>
      amendEntity({ definition: { shopify: { voucherCode: value } } }),
    shopifyProductLimit: entity?.definition?.shopify?.productLimit,
    setShopifyProductLimit: value =>
      amendEntity({ definition: { shopify: { productLimit: value } } }),
    shopifyInventoryChecked: entity?.definition?.shopify?.inventoryChecked,
    setShopifyInventoryChecked: value =>
      amendEntity({ definition: { shopify: { inventoryChecked: value } } }),

    // Article properties are not changed.
    setArticleContent: () => undefined,
    setArticleTeaser: () => undefined
  };

  // Functional definition for article property access.
  const accessArticle = {
    // Type is article, changing type resets definition.
    type: 'article',

    // Common for limited offers. Unused here.
    setApplyLimit: () => undefined,
    setLimit: () => undefined,

    // Article has no redeem button.
    buttonWebsiteUsedName: entity?.definition?.article?.buttonWebsiteUsedName,
    setButtonWebsiteUsedName: value =>
      amendEntity({ definition: { article: { buttonWebsiteUsedName: value } } }),
    setButtonRedeemUsedName: () => undefined,

    // Condition will not be changed.
    setCondition: () => undefined,

    // Target for web form will not be changed.
    setTarget: () => undefined,

    // Shopify properties are not changed.
    setShopifyShop: () => undefined,
    setShopifyProduct: () => undefined,
    setShopifyVoucherCode: () => undefined,
    setShopifyProductLimit: () => undefined,
    setShopifyInventoryChecked: () => undefined,

    // Article content maps to definition's field. Assigns with type markdown.
    articleContent: entity?.definition?.article?.content?.value || '',
    setArticleContent: value =>
      amendEntity({ definition: { article: { content: { type: 'text/html', value } } } }),

    // Article teaser maps to definition's field. Assigns with type markdown.
    articleTeaser: entity?.definition?.article?.teaser?.value || '',
    setArticleTeaser: value =>
      amendEntity({
        definition: { article: { teaser: value ? { type: 'text/html', value } : null } }
      })
  };

  // Functional definition for form property access.
  const accessForm = {
    // Type is form.
    type: 'form',

    // Common for limited offers.
    applyLimit: entity?.definition?.form?.limit !== null,
    setApplyLimit: value => amendEntity({ definition: { form: { limit: value ? 1 : null } } }),
    limit: entity?.definition?.form?.limit,
    setLimit: value => amendEntity({ definition: { form: { limit: value } } }),

    // Form button definition mapping.
    buttonWebsiteUsedName: entity?.definition?.form?.buttonWebsiteUsedName,
    setButtonWebsiteUsedName: value =>
      amendEntity({ definition: { form: { buttonWebsiteUsedName: value } } }),
    buttonRedeemUsedName: entity?.definition?.form?.buttonRedeemUsedName,
    setButtonRedeemUsedName: value =>
      amendEntity({ definition: { form: { buttonRedeemUsedName: value } } }),

    // Condition maps to form's field. Assigns with type markdown.
    condition: entity?.definition?.form?.condition?.value || '',
    setCondition: value =>
      amendEntity({ definition: { form: { condition: { type: 'text/html', value } } } }),

    // Target for web form access.
    target: entity?.definition?.form?.target,
    setTarget: value => amendEntity({ definition: { form: { target: value } } }),

    // Shopify properties are not changed.
    setShopifyShop: () => undefined,
    setShopifyProduct: () => undefined,
    setShopifyVoucherCode: () => undefined,
    setShopifyProductLimit: () => undefined,
    setShopifyInventoryChecked: () => undefined,

    // Article properties are not changed.
    setArticleContent: () => undefined,
    setArticleTeaser: () => undefined
  };

  // Functional definition for pre-load property access.
  const accessNoOp = {
    // Type is form.
    type: 'noop',
    applyLimit: undefined,
    limit: undefined,
    buttonWebsiteUsedName: undefined,
    buttonRedeemUsedName: undefined,
    condition: undefined,
    target: undefined,
    setApplyLimit: () => undefined,
    setLimit: () => undefined,
    setButtonWebsiteUsedName: () => undefined,
    setButtonRedeemUsedName: () => undefined,
    setCondition: () => undefined,
    setTarget: () => undefined,
    setShopifyShop: () => undefined,
    setShopifyProduct: () => undefined,
    setShopifyVoucherCode: () => undefined,
    setShopifyProductLimit: () => undefined,
    setShopifyInventoryChecked: () => undefined,
    setArticleContent: () => undefined,
    setArticleTeaser: () => undefined
  };

  // Determine types.
  const isQrCode = !!entity?.definition?.qrCode;
  const isShopify = !!entity?.definition?.shopify;
  const isArticle = !!entity?.definition?.article;
  const isForm = !!entity?.definition?.form;

  // Type based property accessors.
  const {
    type,
    applyLimit,
    setApplyLimit,
    limit,
    setLimit,
    buttonWebsiteUsedName,
    setButtonWebsiteUsedName,
    buttonRedeemUsedName,
    setButtonRedeemUsedName,
    condition,
    setCondition,
    target,
    setTarget,
    shopifyShop,
    setShopifyShop,
    shopifyProduct,
    setShopifyProduct,
    shopifyVoucherCode,
    setShopifyVoucherCode,
    shopifyProductLimit,
    setShopifyProductLimit,
    shopifyInventoryChecked,
    setShopifyInventoryChecked,
    articleContent,
    setArticleContent,
    articleTeaser,
    setArticleTeaser
  } =
    (isQrCode && accessQrCode) ||
    (isShopify && accessShopify) ||
    (isArticle && accessArticle) ||
    (isForm && accessForm) ||
    accessNoOp;

  const setType = newType => {
    if (type === newType) return;
    setEntity({ ...entity, definition: { [newType]: { ...initialDefinitions[newType] } } });
  };

  // Basic property accessors.
  const campaignId = entity?.campaign?.id;
  const setCampaignId = value => setEntity({ ...entity, campaign: value ? { id: value } : null });

  const pickedPriority = priorityValueToOption(entity?.priority);
  const setPriority = value => amendEntity({ priority: priorityOptionToValue(value) });

  // Make category options and keys for multi-picker. When setting from keys, map back.
  const categoryOptions = allCategories.map(({ name, label }) => ({ key: name, label }));
  const categoryPickedKeys = entity?.categories?.map(category => category.name) || [];
  const setCategoryPickedKeys = value =>
    amendEntity({
      categories: allCategories.filter(category => value.includes(category.name))
    });

  // Make internal category options and keys for multi-picker. When setting from keys, map back.
  const internalCategoryOptions = allInternalCategories.map(({ name, label }) => ({
    key: name,
    label
  }));
  const internalCategoryPickedKeys =
    entity?.internalCategories?.map(internalCategory => internalCategory.name) || [];
  const setInternalCategoryPickedKeys = value =>
    amendEntity({
      internalCategories: allInternalCategories.filter(internalCategory =>
        value.includes(internalCategory.name)
      )
    });

  const validFrom = entity?.validFrom;
  const setValidFrom = value => amendEntity({ validFrom: value });

  const validUntil = entity?.validUntil;
  const setValidUntil = value => amendEntity({ validUntil: value });

  const visibleFrom = entity?.visibleFrom;
  const setVisibleFrom = value => amendEntity({ visibleFrom: value });

  const partnerId = entity?.partner?.id;
  const setPartnerId = value => setEntity({ ...entity, partner: value ? { id: value } : null });

  const sites = entity?.sites;
  const setSites = value => setEntity({ ...entity, sites: value });

  const geoUnrestricted = entity?.geoUnrestricted;
  const setGeoUnrestricted = value => setEntity({ ...entity, geoUnrestricted: value });

  const partnerUsedName = entity?.partnerUsedName || '';
  const setPartnerUsedName = value => amendEntity({ partnerUsedName: value ? value : null });

  const title = entity?.title;
  const setTitle = value => amendEntity({ title: value });

  const excerpt = entity?.excerpt;
  const setExcerpt = value => amendEntity({ excerpt: value });

  const description = entity?.description;
  const setDescription = value => amendEntity({ description: value });

  const descriptionLong = entity?.descriptionLong?.value || '';
  const setDescriptionLong = value =>
    amendEntity({ descriptionLong: { type: 'text/html', value } });

  const infoWebsite = entity?.infoWebsite;
  const setInfoWebsite = value => amendEntity({ infoWebsite: value });

  const infoPhone = entity?.infoPhone;
  const setInfoPhone = value => amendEntity({ infoPhone: value });

  const images = entity?.images || [];
  const setImages = value => amendEntity({ images: value });

  const address = entity?.address;
  const setAddress = value => setEntity({ ...entity, address: value ? value : null });

  const pickerAvailableSites = !availableSites?.length ? [siteAll] : [siteAll, ...availableSites];
  const pickerSites = !sites?.length ? [siteAll.id] : sites;
  const setPickerSites = (value, previous) => {
    // Only on first swap over to having the 'ALL' site, clear the actual sites. Otherwise set.
    if (!previous.includes(siteAll.id) && value.includes(siteAll.id)) setSites([]);
    else setSites(value.filter(id => id !== siteAll.id));
  };

  // Todo: Create a proper redeem url to test the shopify data, only without the alias reference
  const shopifyTryOutUrl =
    (shopifyShop &&
      shopifyProduct &&
      `${shopifyShop}/cart/${shopifyProduct}:${shopifyProductLimit > 0 ? shopifyProductLimit : 1}?${
        shopifyVoucherCode ? `discount=${shopifyVoucherCode}&` : ''
      }attributes[${shopifyBackreference}]=0`) ||
    null;

  // Submits the create or the change.
  const submit = isNew
    ? remoteAction({
        action: async () => createOffer(entity),
        navigateSuccess: ({ id }) => Paths.OfferDetails(id),
        messageSuccess: 'Vorteil erstellt.',
        messageFail: 'Fehler beim Erstellen.'
      })
    : remoteAction({
        action: async () => updateOffer(entity),
        navigateSuccess: ({ id }) => Paths.OfferDetails(id),
        messageSuccess: 'Änderungen gespeichert.',
        messageFail: 'Fehler beim Ändern.'
      });

  // Offer type selector (when creating) or indicator (when editing)
  let offerTypeDisplay = null;
  if (!isNew && entity?.definition?.qrCode) {
    offerTypeDisplay = (
      <div className='OfferEdit__Type'>
        <CircleIcon icon={<Qr size={14} fill={Colors.White} />} size={28} />
        <span className='OfferEdit__TypeLabel'>QR-Code</span>
        <span className='OfferEdit__TypeLabelAddition'>
          – Die Vorteilsart kann nicht nachträglich geändert werden.
        </span>
      </div>
    );
  } else if (!isNew && entity?.definition?.shopify) {
    offerTypeDisplay = (
      <div className='OfferEdit__Type'>
        <CircleIcon icon={<Euro size={12} fill={Colors.White} />} offsetX={-1} size={28} />
        <span className='OfferEdit__TypeLabel'>Shopify-Direktkauf</span>
        <span className='OfferEdit__TypeLabelAddition'>
          – Die Vorteilsart kann nicht nachträglich geändert werden.
        </span>
      </div>
    );
  } else if (!isNew && entity?.definition?.article) {
    offerTypeDisplay = (
      <div className='OfferEdit__Type'>
        <CircleIcon icon={<Article size={14} fill={Colors.White} />} size={28} />
        <span className='OfferEdit__TypeLabel'>Info-Artikel</span>
        <span className='OfferEdit__TypeLabelAddition'>
          – Die Vorteilsart kann nicht nachträglich geändert werden.
        </span>
      </div>
    );
  } else if (!isNew && entity?.definition?.form) {
    offerTypeDisplay = (
      <div className='OfferEdit__Type'>
        <CircleIcon icon={<Form size={16} fill={Colors.White} />} size={28} offsetX={1} />
        <span className='OfferEdit__TypeLabel'>Web-Formular</span>
        <span className='OfferEdit__TypeLabelAddition'>
          – Die Vorteilsart kann nicht nachträglich geändert werden.
        </span>
      </div>
    );
  }

  return (
    <main className='OfferEdit'>
      <LoadingOverlay show={loading} />
      <PageTitle title={isNew ? 'Vorteil erstellen' : `Vorteil bearbeiten: ${entity?.title}`} />
      <Container>
        <Toolbar
          title={isNew ? 'Vorteil erstellen' : 'Vorteil bearbeiten'}
          backButton={{
            to: isNew ? Paths.Campaigns() : Paths.OfferDetails(entity?.id),
            label: isNew || !entity?.title ? 'Kampagnen & Vorteile' : entity?.title
          }}
          right={[
            <Button
              key={1}
              title='Abbrechen'
              icon={Close}
              iconSize={12}
              variant='ORANGE_OUTLINE'
              to={isNew ? Paths.Campaigns() : Paths.OfferDetails(entity?.id)}
            />,
            <Button
              key={2}
              title={isNew ? 'Vorteil speichern' : 'Änderungen speichern'}
              icon={Tick}
              iconSize={17}
              disabled={!changed}
              onClick={submit}
            />
          ]}
        />

        <DisplayGroup
          label='Kampagne'
          tooltip='Der Vorteil kann einer Kampagne zugeordnet sein oder für sich alleine stehen.'
        >
          <Dropdown
            title='Kampagne auswählen'
            onUpdateValue={setCampaignId}
            value={campaignId}
            nullOptionLabel='Keine Kampagne'
            options={campaigns?.map(campaign => ({
              value: campaign.id,
              label: campaign.name
            }))}
          />
        </DisplayGroup>
        <DisplayGroup label='Vorteilsart'>
          {isNew ? <OfferTypePicker onUpdateValue={setType} value={type} /> : offerTypeDisplay}
        </DisplayGroup>
        <DisplayGroup label='Kategorien'>
          <MultiPicker
            options={categoryOptions}
            pickedKeys={categoryPickedKeys}
            onUpdatePickedKeys={setCategoryPickedKeys}
          />
        </DisplayGroup>
        <DisplayGroup label='Interne Kategorien'>
          <MultiPicker
            options={internalCategoryOptions}
            pickedKeys={internalCategoryPickedKeys}
            onUpdatePickedKeys={setInternalCategoryPickedKeys}
          />
        </DisplayGroup>
        <DisplayGroup label='Zeitraum'>
          <FormRow>
            <div>
              <DatePicker
                date={validFrom}
                name='validFrom'
                onUpdateDate={setValidFrom}
                max={validUntil}
                title='Von'
                showLabel
              />
            </div>
            <div>
              <DatePicker
                date={validUntil}
                name='validUntil'
                min={validFrom}
                onUpdateDate={setValidUntil}
                endOfDay
                title='Bis'
                showLabel
              />
            </div>
            <div>
              <DatePicker
                date={visibleFrom}
                name='visibleFrom'
                onUpdateDate={setVisibleFrom}
                max={validFrom || validUntil}
                title='Vorschau ab'
                tooltip='Wählen Sie hier ein Datum, ab welchem der Vorteil unter „Demnächst“ angekündigt werden soll. Die Einlösung ist erst ab dem Zeitraum der Gültigkeit möglich.'
                showLabel
                disabled={!validFrom}
              />
            </div>
          </FormRow>
        </DisplayGroup>
        {(type === 'qrCode' || type === 'shopify' || type === 'form') && (
          <DisplayGroup
            label='Einlösungen*'
            tooltip='Legen Sie fest, ob die Anzahl an Einlösungen pro Kunde limitiert sein soll.'
          >
            <div className='OfferEdit__Limit'>
              <RadioButtons
                onUpdateValue={setApplyLimit}
                value={applyLimit}
                options={[
                  { value: false, label: 'Unbegrenzt' },
                  { value: true, label: 'Bis zu' }
                ]}
              />
              <div className='OfferEdit__LimitPicker'>
                <NumericPicker
                  title='Einlösungen'
                  showLabel={false}
                  value={limit || 1}
                  onUpdateValue={setLimit}
                  min={1}
                  disabled={!applyLimit}
                  valueDisplay={value => `${value} Einlösung${value === 1 ? '' : 'en'} pro Kunde`}
                />
              </div>
            </div>
          </DisplayGroup>
        )}
        <DisplayGroup label='Partner'>
          <FormRow>
            <Dropdown
              title='Partner auswählen'
              onUpdateValue={setPartnerId}
              nullOptionLabel='Kein Partner'
              value={partnerId}
              options={partners?.map(partner => ({
                value: partner.id,
                label: partner.name
              }))}
            />
            <Textbox
              value={partnerUsedName}
              onUpdateValue={setPartnerUsedName}
              name='partnerUsedName'
              title='Anzeige des Partnernamens überschreiben'
              showLabel
              tooltip='Vergeben Sie hier optional einen Alternativtext, der statt des vollständigen Partnernamens angezeigt werden soll.'
            />
          </FormRow>
        </DisplayGroup>

        <DisplayGroup
          label='Ortsbindung'
          tooltip='Legen Sie fest, ob die Anzahl an Einlösungen pro Kunde limitiert sein soll.'
        >
          <div className='OfferEdit__GeoRestriction'>
            <RadioButtons
              onUpdateValue={setGeoUnrestricted}
              value={geoUnrestricted}
              options={[
                { value: false, label: 'Ortsgebunden' },
                { value: true, label: 'Keine Ortsbindung' }
              ]}
            />
          </div>
        </DisplayGroup>

        {!availableSites?.length ? null : (
          <DisplayGroup
            label='Zweigstellen'
            tooltip='Wenn alle Zweigstellen explizit angewählt sind, werden neue Zweigstellen nicht automatisch angefügt'
          >
            {
              <MultiPicker
                disabled={geoUnrestricted}
                options={pickerAvailableSites?.map(site => ({
                  key: site.id,
                  label: (
                    <>
                      <span className='OfferEdit__SiteName'>{site.name}</span>
                      {!site.address ? null : (
                        <span className='OfferEdit__SiteAddress'>{site.address}</span>
                      )}
                    </>
                  ),
                  variantDeselected: site.id === siteAll.id ? 'GHOST' : undefined
                }))}
                pickedKeys={pickerSites}
                onUpdatePickedKeys={setPickerSites}
              />
            }
          </DisplayGroup>
        )}

        <DisplayGroup
          label='Priorisierung'
          tooltip='Die Priorisierung bestimmt, wie demnächst sichtbare Vorteile mit gleicher Startzeit untereinander sortiert werden.'
        >
          <Dropdown
            title='Priorisierung auswählen'
            onUpdateValue={setPriority}
            value={pickedPriority}
            options={priorityOptions}
          />
        </DisplayGroup>

        <h1 className='OfferEdit__Section'>Inhalte</h1>
        <DisplayGroup label='Titel*'>
          <Textbox
            value={title}
            onUpdateValue={setTitle}
            name='title'
            title='Titel'
            required
            showLabel={false}
          />
        </DisplayGroup>
        <DisplayGroup
          label='Kurzbeschreibung*'
          tooltip='Die Kurzbeschreibung wird unterhalb des Titels auf der Vorteilsseite sowie in der Kachelansicht eingeblendet.'
        >
          <Textbox
            onUpdateValue={setExcerpt}
            name='excerpt'
            title='Kurzbeschreibung'
            value={excerpt}
            isTextarea
            required
            showLabel={false}
          />
        </DisplayGroup>
        {(type === 'qrCode' || type === 'shopify' || type === 'form') && (
          <DisplayGroup
            label='Vorteilsbezeichnung*'
            tooltip='Die Bezeichnung sollte in aller Kürze benennen, welche Leistung die Kund*innen erhalten (z. B. „10 % Rabatt“ oder „Gratis-Ticket“).'
          >
            <Textbox
              value={description}
              onUpdateValue={setDescription}
              name='description'
              title='Vorteilsbezeichnung'
              required
              showLabel={false}
            />
          </DisplayGroup>
        )}
        <DisplayGroup label='Buttons'>
          <FormRow>
            <Textbox
              value={buttonWebsiteUsedName}
              onUpdateValue={setButtonWebsiteUsedName}
              name='buttonWebsiteUsedName'
              title='Button auf der Website überschreiben'
              tooltip='Optional können Sie den Standardtext, der im Button auf der öffentlichen Website angezeigt wird, hier überschreiben. Der Button führt je nach Gerät zur evm-App oder zum evm-Kundenportal.'
            />
            {type === 'article' ? (
              <div />
            ) : (
              <Textbox
                value={buttonRedeemUsedName}
                onUpdateValue={setButtonRedeemUsedName}
                name='buttonRedeemUsedName'
                title='Einlösen-Button überschreiben'
                tooltip='Optional können Sie den Standardtext überschreiben, der im Button zur Einlösung des Vorteils angezeigt wird.'
              />
            )}
          </FormRow>
        </DisplayGroup>
        {(type === 'qrCode' || type === 'shopify' || type === 'form') && (
          <DisplayGroup
            label='Langbeschreibung'
            tooltip='Die Langbeschreibung wird im Inhaltsbereich dargestellt und bietet Formatierungsoptionen.'
          >
            <RichtextEditor onUpdateValue={setDescriptionLong} value={descriptionLong} allowLinks />
          </DisplayGroup>
        )}
        {(type === 'qrCode' || type === 'form') && (
          <DisplayGroup
            label='Bedingungen'
            tooltip='Optional können Sie hier als Freitextfeld Bedingungen definieren, die für die Einlösung gelten sollen.'
          >
            <RichtextEditor onUpdateValue={setCondition} value={condition} allowLinks />
          </DisplayGroup>
        )}
        {type === 'shopify' && (
          <>
            <DisplayGroup label='Shopify-Daten'>
              <FormRow>
                <Dropdown
                  title='Shop*'
                  tooltip='Wählen Sie den zu verknüpfenden Shopify-Shop aus.'
                  value={shopifyShop}
                  onUpdateValue={setShopifyShop}
                  options={shops?.map(({ uri, name }) => ({ value: uri, label: name }))}
                  showLabel
                />
                <Textbox
                  value={shopifyProduct}
                  onUpdateValue={setShopifyProduct}
                  name='shopifyProduct'
                  title='ID der Produktvariante'
                  tooltip='Hinterlegen Sie hier die ID der Produktvariante (nicht des Produkts), welche in den Warenkorb gelegt werden soll.'
                  required
                />
                <Textbox
                  value={shopifyVoucherCode}
                  onUpdateValue={setShopifyVoucherCode}
                  name='shopifyVoucherCode'
                  title='Gutscheincode'
                  tooltip='Falls ein Gutscheincode automatisch auf den Warenkorb angewendet werden soll, tragen Sie diesen hier ein.'
                />
                <NumericPicker
                  title='Max. Anzahl'
                  onUpdateValue={setShopifyProductLimit}
                  value={shopifyProductLimit}
                  min={0}
                  tooltip='Falls Sie die maximale Anzahl an Produkten begrenzen wollen, wählen Sie hier die Menge – andernfalls belassen Sie den Wert bei 0.'
                />
              </FormRow>

              <div className='OfferEdit__ShopifyTryOut'>
                <Button
                  title='Warenkorb ausprobieren'
                  disabled={!shopifyTryOutUrl}
                  to={shopifyTryOutUrl}
                  target='_blank'
                />
              </div>
            </DisplayGroup>
            <DisplayGroup
              label='Bestand überprüfen'
              tooltip='Hiermit wird der Lagerbestand der Produktvariante überwacht. Sobald das Produkt ausverkauft ist, wird auch der Vorteil als ausverkauft angezeigt und kann nicht mehr eingelöst werden.'
            >
              <Checkbox
                label='Bestandsprüfung für diesen Vorteil aktivieren'
                value={shopifyInventoryChecked}
                onUpdateValue={setShopifyInventoryChecked}
              />
            </DisplayGroup>
          </>
        )}
        {type === 'form' && (
          <DisplayGroup
            label='Ziel-URL'
            tooltip='Auf diese URL werden Kunden weitergeleitet, wenn sie den Vorteil einlösen wollen.'
          >
            <FormRow lastItemFixed>
              <Textbox
                value={target}
                onUpdateValue={setTarget}
                name='target'
                title='Website'
                showLabel={false}
              />
              <Button
                autoWidth
                title='URL ausprobieren'
                disabled={!target}
                to={buildFormAliasUrl(entity?.definition?.form?.target || '', '0000000000')}
                target='_blank'
              />
            </FormRow>
            <div className='OfferEdit__FormUrlCodeReplacement'>
              <SubstitutionIndicator
                identifier='code'
                description='Dieser Platzhalter wird durch den individuellen Code ersetzt.'
                used={target?.includes('{code}')}
              />
            </div>
          </DisplayGroup>
        )}
        <DisplayGroup
          label='Bilder'
          tooltip='Mindestens ein Bild sollte hochgeladen werden, mehrere Bilder werden als Slider-Galerie dargestellt. Seitenverhältnis am besten 16:9.'
        >
          <MultiImageManager
            images={images}
            onUpdateImages={setImages}
            cover={true}
            width={160}
            height={90}
          />
        </DisplayGroup>

        {type === 'article' && (
          <DisplayGroup
            label='Teaser'
            tooltip='Teaserbeschreibung für Artikel, diese wird nur auf der Webseite verwendet.'
          >
            <RichtextEditor
              onUpdateValue={setArticleTeaser}
              value={articleTeaser}
              allowImages
              allowLinks
            />
          </DisplayGroup>
        )}

        {type === 'article' && (
          <DisplayGroup label='Inhalt des Artikels'>
            <RichtextEditor
              onUpdateValue={setArticleContent}
              value={articleContent}
              allowImages
              allowLinks
            />
          </DisplayGroup>
        )}

        <h1 className='OfferEdit__Section'>Kontaktdaten</h1>
        <DisplayGroup
          label='Adresse'
          tooltip='Falls der Vorteil an einem bestimmten Standort einzulösen ist, können Sie hier die Adresse hinterlegen.'
        >
          <Textbox
            value={address || ''}
            onUpdateValue={setAddress}
            name='address'
            title='Adresse'
            showLabel={false}
            isTextarea
          />
        </DisplayGroup>
        <DisplayGroup
          label='Website'
          tooltip='Falls eine Website-Verlinkung unter dem Vorteil dargestellt werden soll, fügen Sie die URL hier ein.'
        >
          <Textbox
            value={infoWebsite}
            onUpdateValue={setInfoWebsite}
            name='infoWebsite'
            title='Website'
            showLabel={false}
          />
        </DisplayGroup>
        <DisplayGroup
          label='Telefon'
          tooltip='Falls eine Telefonnummer unter dem Vorteil dargestellt werden soll, fügen Sie diese hier ein.'
        >
          <Textbox
            value={infoPhone}
            onUpdateValue={setInfoPhone}
            name='infoPhone'
            title='Telefon'
            showLabel={false}
          />
        </DisplayGroup>
      </Container>
    </main>
  );
};

export default OfferEdit;
