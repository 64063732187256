import React from 'react';
import PropTypes from 'prop-types';
import { Down, Up } from '../../images/icons';
import Colors from '../../definitions/colors';
import './HighlightPanel.scss';
import IconButton from '../atoms/IconButton';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../definitions/paths';
import HighlightStatus from '../atoms/HighlightStatus';
import { keySelect } from '../../util/handlers';

const HighlightPanel = ({ highlight, onMoveUp, onMoveDown }) => {
  const navigate = useNavigate();

  const moveUpHighlight = event => {
    // Do not interpret button click as an open action
    event.preventDefault();
    event.stopPropagation();
    onMoveUp();
  };
  const moveDownHighlight = event => {
    // Do not interpret button click as an open action
    event.preventDefault();
    event.stopPropagation();
    onMoveDown();
  };

  let title = null;
  if (highlight?.on?.isCampaign) {
    title = (
      <>
        <span className='HighlightPanel__TitlePrefix'>Kampagne: </span>
        <span className='HighlightPanel__Link'> {highlight.on.campaign?.target?.title}</span>
      </>
    );
  } else if (highlight?.on?.isOffer) {
    title = (
      <>
        <span className='HighlightPanel__TitlePrefix'>Vorteil: </span>
        <span className='HighlightPanel__Link'>{highlight.on.offer?.target?.title}</span>
      </>
    );
  }

  const actions = (
    <div className='HighlightPanel__Actions'>
      <div className='HighlightPanel__ActionMoveUp'>
        <IconButton
          title='Nach oben bewegen'
          iconCircle={{
            icon: <Up size={14} fill={Colors.White} />,
            size: 28,
            offsetY: -1
          }}
          disabled={!onMoveUp}
          onClick={moveUpHighlight}
        />
      </div>
      <div className='HighlightPanel__ActionMoveDown'>
        <IconButton
          title='Nach unten bewegen'
          iconCircle={{
            icon: <Down size={14} fill={Colors.White} />,
            size: 28,
            offsetY: 1
          }}
          disabled={!onMoveDown}
          size={28}
          onClick={moveDownHighlight}
        />
      </div>
    </div>
  );

  return (
    <div
      className='HighlightPanel'
      onClick={() => navigate(Paths.HighlightDetails(highlight?.id))}
      onKeyDown={keySelect(() => navigate(Paths.HighlightDetails(highlight?.id)))}
      title='Details anzeigen'
      role='button'
      tabIndex={0}
    >
      <div className='HighlightPanel__Head'>
        <div className='HighlightPanel__Title'>{title}</div>
        {highlight && (
          <div className='HighlightPanel__Status'>
            <HighlightStatus highlight={highlight} small />
          </div>
        )}
        {highlight && actions}
      </div>
    </div>
  );
};

HighlightPanel.propTypes = {
  highlight: PropTypes.any,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func
};

export default HighlightPanel;
