import {
  deleteEntity,
  getEntity,
  getPage,
  getResolution,
  pathManage,
  postEntity,
  postResolution,
  putEntity
} from './common';

/**
 * Retrieves the specific partner with the given ID.
 * @param id The ID of the partner.
 * @return {*} Returns a promise with the partner. Failures to get are treated as errors.
 */
export const getPartner = id => getEntity(`${pathManage}/partners/${id}`);

/**
 * Retrieves the sites of a specific partner with the given ID.
 * @param id The ID of the partner.
 * @return {*} Returns a promise with the sites. Failures to get are treated as errors.
 */
export const getPartnerSites = id => getEntity(`${pathManage}/partners/${id}/sites`);

/**
 * Creates the given partner.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param partner The partner to add.
 * @return {*} Returns a promise with the created partner. Failures to create are treated as errors.
 */
export const createPartner = partner => postEntity(`${pathManage}/partners`, null, partner);

/**
 * Updates the given partner. The partner should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param partner The partner to update.
 * @return {*} Returns a promise with the updated partner. Failures to create are treated as errors.
 */
export const updatePartner = partner => putEntity(`${pathManage}/partners`, null, partner);

/**
 * Deletes the partner with the given ID.
 * @param id The ID of the partner.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deletePartner = id => deleteEntity(`${pathManage}/partners/${id}`);

/**
 * Gets the raw partners in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getPartners = (page, query) =>
  getPage(`${pathManage}/partners`, [['page', page], ...query]);

/**
 * Gets all partners as a resolution list with objects giving ID and name.
 * @type {*} Returns a promise on the resolutions.
 */
export const allPartners = () => getResolution(`${pathManage}/resolve/partners`);

/**
 * Gets partners for the given IDs.
 * @param ids The IDs to return.
 * @return {*} Returns a promise on the resolutions.
 */
export const resolvePartners = ids =>
  postResolution(`${pathManage}/resolve/partners`, null, ids || []);

/**
 * Resolves the location of an address.
 * @type {*} Returns a promise on the resolution.
 */
export const resolveAddress = address =>
  postResolution(`${pathManage}/resolve/address`, { address });

/**
 * Resolves the location of an ZIP code.
 * @type {*} Returns a promise on the resolution.
 */
export const resolveZip = zip => postResolution(`${pathManage}/resolve/zip`, { address: zip });
