import React from 'react';
import CircleIcon from './CircleIcon';
import { Edit, Eye, NoEye } from '../../images/icons';
import Colors from '../../definitions/colors';
import PropTypes from 'prop-types';
import './HighlightStatus.scss';
import { isPreRelease, isReleased } from '../../helpers/entities';

const HighlightStatus = ({ highlight, small }) => {
  let status = null;
  if (isPreRelease(highlight))
    status = (
      <>
        <CircleIcon icon={<Edit size={15} fill={Colors.White} />} size={28} />
        <div className='HighlightStatus__Label HighlightStatus__Label--Inactive'>Entwurf</div>
      </>
    );
  else if (isReleased(highlight) && highlight?.isActive)
    status = (
      <>
        <CircleIcon icon={<Eye size={20} fill={Colors.White} />} size={28} />
        <div className='HighlightStatus__Label'>Aktuell sichtbar</div>
      </>
    );
  else if (isReleased(highlight) && !highlight?.isActive)
    status = (
      <>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <div className='HighlightStatus__Label HighlightStatus__Label--Inactive'>
          Aktuell nicht sichtbar
        </div>
      </>
    );

  return <div className={`HighlightStatus ${small ? 'HighlightStatus--Small' : ''}`}>{status}</div>;
};

HighlightStatus.propTypes = {
  highlight: PropTypes.any,
  small: PropTypes.bool
};

export default HighlightStatus;
