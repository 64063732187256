import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getOffers } from '../../api/offers';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const offersLocation = 'offers';

/**
 * Requests that the given page of the given query is made present.
 */
export const offersRequestPage = pagingRequestPage(offersLocation, getOffers);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectOffers = pagingSelectItems(offersLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectOffersMode = pagingSelectMode(offersLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectOffersError = pagingSelectError(offersLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectOffersQuery = pagingSelectQuery(offersLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectOffersTotal = pagingSelectTotal(offersLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectOffersTotalItems = pagingSelectTotalItems(offersLocation);

/**
 * Reducer for offers listing.
 */
export const offersReducer = pagingReducer(offersLocation);
