import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Colors from '../../definitions/colors';
import Chart from 'react-apexcharts';
import de from 'apexcharts/dist/locales/de.json';
import './Chart.scss';

const chartOptions = {
  chart: {
    locales: [de],
    defaultLocale: 'de',
    animations: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    fontFamily: 'Ubuntu, sans-serif',
    foreColor: '#212529',
    selection: {
      enabled: false
    },
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    style: {
      fontSize: '16px'
    }
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  },
  yaxis: {
    labels: {
      minWidth: 160,
      maxWidth: 160,
      style: {
        fontSize: '16px'
      }
    }
  }
};

const HeatChart = ({
  empty,
  data,
  xOf,
  yOf,
  valueOf,
  xs,
  ys,
  primary = Colors.Orange,
  height = 350
}) => {
  const series = useMemo(() => {
    const initXs = () => (xs ? Object.fromEntries(xs.map(x => [x, 0])) : {});
    const initYs = () => (ys ? Object.fromEntries(ys.map(y => [y, initXs()])) : {});

    const result = initYs();
    for (const d of data) {
      const x = xOf(d);
      const y = yOf(d);
      const target = result[y] || (result[y] = initXs());
      target[x] = valueOf(d);
    }

    return Object.entries(result).map(([key, value]) => ({
      name: key,
      color: primary,
      data: Object.entries(value).map(([key, value]) => ({ x: key, y: value }))
    }));
  }, [data, xOf, yOf, valueOf, primary]);

  return (
    <div className='Chart'>
      <Chart options={chartOptions} series={series} type='heatmap' width='100%' height={height} />
      {!empty ? null : (
        <div className='Chart__EmptyOverlay'>
          <span>Keine Daten</span>
        </div>
      )}
    </div>
  );
};

HeatChart.propTypes = {
  empty: PropTypes.bool,
  data: PropTypes.array.isRequired,
  xOf: PropTypes.func.isRequired,
  yOf: PropTypes.func.isRequired,
  valueOf: PropTypes.func.isRequired,
  xs: PropTypes.array,
  ys: PropTypes.array,
  primary: PropTypes.string,
  height: PropTypes.number
};

export default HeatChart;
