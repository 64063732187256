/**
 * Build version if available.
 * @type {string}
 */
export const version = process.env.REACT_APP_VERSION;

/**
 * Identity issuer for customers.
 * @type {string}
 */
export const identityIssuer = process.env.REACT_APP_CUSTOMER_IDENTITY_ISSUER;
