import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Colors from '../../definitions/colors';
import Chart from 'react-apexcharts';
import de from 'apexcharts/dist/locales/de.json';
import { displayPercentagePrecise } from '../../helpers/formatters';
import moment from 'moment/moment';
import './Chart.scss';

const DateLineChart = ({
  empty,
  series,
  yTitle,
  xTitle,
  percentage = false,
  minZero = true,
  maxDetectScale = 2
}) => {
  // Detect adjusted ceiling of series for nicer plotting.
  const maxDetected = useMemo(() => {
    if (maxDetectScale < 0) return undefined;
    const multiplier = Math.pow(10, maxDetectScale);
    let max = undefined;
    for (const items of series)
      for (const item of items.data) {
        const adjusted = Math.ceil(item.y * multiplier) / multiplier;
        if (max === undefined || adjusted > max) max = adjusted;
      }

    return max;
  }, [maxDetectScale, series]);

  // Convert to options.
  const chartOptions = useMemo(
    () => ({
      grid: {
        padding: {
          left: 20,
          right: 60
        }
      },
      chart: {
        locales: [de],
        defaultLocale: 'de',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        fontFamily: 'Ubuntu, sans-serif',
        foreColor: '#212529',
        selection: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: {
          format: 'dd. MMMM yyyy'
        },
        style: {
          fontSize: '16px'
        }
      },
      colors: [Colors.Orange],
      xaxis: {
        type: 'category',
        title: xTitle && {
          text: xTitle,
          style: {
            fontFamily: 'Ubuntu, sans-serif'
          }
        },
        labels: {
          showDuplicates: false,
          formatter: x => moment(x).format('DD. MMMM'),
          style: {
            fontSize: '16px'
          }
        }
      },
      yaxis: {
        title: yTitle && {
          text: yTitle,
          style: {
            fontFamily: 'Ubuntu, sans-serif'
          }
        },
        min: minZero ? 0.0 : undefined,
        max: maxDetected,
        forceNiceScale: true,

        labels: {
          style: {
            fontSize: '16px'
          },
          formatter: percentage ? displayPercentagePrecise : undefined
        }
      }
    }),
    [xTitle, yTitle, percentage, minZero, maxDetected]
  );

  return (
    <div className='Chart'>
      <Chart options={chartOptions} series={series} type='line' width='100%' height={350} />
      {!empty ? null : (
        <div className='Chart__EmptyOverlay'>
          <span>Keine Daten</span>
        </div>
      )}
    </div>
  );
};

DateLineChart.propTypes = {
  empty: PropTypes.bool,
  series: PropTypes.array.isRequired,
  percentage: PropTypes.bool,
  yTitle: PropTypes.string,
  xTitle: PropTypes.string,
  minZero: PropTypes.bool,
  maxDetectScale: PropTypes.number
};

export default DateLineChart;
