import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './EditsProtocol.scss';
import IconButton from '../atoms/IconButton';
import Colors from '../../definitions/colors';
import { Search } from '../../images/icons';
import { displayBy, displayEditFields, displayTimestamp, pathName } from '../../helpers/formatters';
import Overlay from '../atoms/Overlay';
import Table from '../atoms/table/Table';
import TableHead from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';

const EditsProtocol = ({ edits }) => {
  const [currentChanges, setCurrentChanges] = useState(null);
  return (
    <div className='EditsProtocol'>
      <h1 className='EditsProtocol__Title'>Änderungen</h1>
      <table className='EditsProtocol__DataTable'>
        <tbody>
          {edits &&
            edits.map((edit, index) => (
              <tr className='EditsProtocol__DataRow' key={index}>
                <td className='EditsProtocol__DataCell EditsProtocol__DataCell--Timestamp'>
                  {displayTimestamp(edit.time)}
                </td>
                <td className='EditsProtocol__DataCell EditsProtocol__DataCell--Name'>
                  {displayBy(edit.by)}
                </td>
                <td className='EditsProtocol__DataCell EditsProtocol__DataCell--Action'>
                  {displayEditFields(edit.changes)}
                </td>
                <td className='EditsProtocol__DataCell EditsProtocol__DataCell--Details'>
                  <IconButton
                    color={Colors.Orange}
                    iconCircle={{
                      icon: <Search fill={Colors.White} size={15} />,
                      offsetX: -1,
                      size: 28
                    }}
                    title='Details anzeigen'
                    showLabel
                    labelPosition='LEFT'
                    onClick={() => setCurrentChanges(edit.changes)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Overlay visible={!!currentChanges} onClose={() => setCurrentChanges(null)} big>
        <h2 className='EditsProtocol__ChangesTitle'>Details der Änderung</h2>
        <Table
          headings={[
            <TableHead label='Feld' key={1} width={12} />,
            <TableHead label='Alter Wert' key={2} width={44} />,
            <TableHead label='Neuer Wert' key={3} width={44} />
          ]}
        >
          {currentChanges &&
            currentChanges.map((change, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div className='EditsProtocol__Field'>{pathName(change.path)}</div>
                </TableCell>
                <TableCell>
                  <div className='EditsProtocol__RawData'>
                    {JSON.stringify(JSON.parse(change.original), null, 2)}
                  </div>
                </TableCell>
                <TableCell>
                  <div className='EditsProtocol__RawData'>
                    {JSON.stringify(JSON.parse(change.current), null, 2)}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </Overlay>
    </div>
  );
};

EditsProtocol.propTypes = {
  edits: PropTypes.array
};

export default EditsProtocol;
