/**
 * Adds an automatic abort controller that is aborted when the result function is invoked.
 * @param {function(abortSignal:AbortSignal):((function():void)|Promise<*>|void)} fn Block that
 * receives the abort signal.
 * @returns {function(): (function():void)} Returns the cancellation function.
 */
export const withAbortSignal = fn => () => {
  // Create abort signal from controller, create instance exception.
  const abortController = new AbortController();
  const abortSignal = abortController.signal;
  const abortObject = new DOMException('Aborted from clear', 'AbortError');

  // Invoke wrapped function for result.
  const clearOrPromise = fn(abortSignal);

  // Result is a function and thereby an inner clear method.
  if (typeof clearOrPromise === 'function')
    // Attach clear and abort.
    return () => {
      abortController.abort(abortObject);
      clearOrPromise();
    };

  // Result is promise like.
  if (typeof clearOrPromise?.catch === 'function') {
    // Catch specific instance and don't throw.
    clearOrPromise.catch(error => {
      if (error !== abortObject) throw error;
    });

    // Return abort on controller.
    return () => {
      abortController.abort(abortObject);
    };
  }

  // Nothing proper to do with result, just abort on controller.
  return () => {
    abortController.abort(abortObject);
  };
};
