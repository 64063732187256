import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Archive, Chart, Close, Edit, Eye, NoEye, Plus } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './CampaignDetails.scss';
import DisplayGroup from '../atoms/DisplayGroup';
import EditsProtocol from '../modules/EditsProtocol';
import OfferPanel from '../modules/OfferPanel';
import CircleIcon from '../atoms/CircleIcon';
import Colors from '../../definitions/colors';
import ImagePreview from '../modules/ImagePreview';
import PageTitle from '../../helpers/pageTitle';
import { useCampaign, useEdits, useListingCampaignRelated } from '../../hooks/useRemoteValue';
import { deleteCampaign, updateCampaignStage } from '../../api/campaigns';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { remoteAction, useEntityView } from './common';
import { swapOfferOrder } from '../../api/offers';
import {
  isArchived,
  isNotArchived,
  isPreRelease,
  isReleased,
  stageArchived,
  stageDraft,
  stageReleased
} from '../../helpers/entities';
import FormRow from '../atoms/FormRow';
import ToggleSwitch from '../atoms/input/ToggleSwitch';

const CampaignDetails = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  // Use campaign of ID. Use related offers.
  const { entity, loadingEntity, reload } = useEntityView(useCampaign, id);
  const [related, , loadingRelated, , , reloadRelated] = useListingCampaignRelated(id);
  const [edits, , loadingEdits, , , reloadEdits] = useEdits(id);

  // Loading state is combination of associated loading states.
  const loading = loadingEntity || loadingRelated || loadingEdits;

  // Categorize offers by stage.
  const offers = related?.offers?.filter(isNotArchived) || [];
  const offersArchived = related?.offers?.filter(isArchived) || [];

  // Deletes the currently viewed campaign.
  const handleDelete = remoteAction({
    action: async () => deleteCampaign(id),
    navigateSuccess: Paths.Campaigns(),
    messageConfirm: 'Bitte bestätigen Sie das Löschen der Kampagne.',
    messageSuccess: 'Kampagne gelöscht.',
    messageFail: 'Fehler beim Löschen.'
  });

  // Archives or unarchives the currently viewed campaign.
  const handleChangeArchival = remoteAction({
    action: async () => {
      if (isArchived(entity)) {
        await updateCampaignStage(id, stageDraft);
        reload();
        reloadRelated();
        reloadEdits();
      } else {
        await updateCampaignStage(id, stageArchived);
        reload();
        reloadRelated();
        reloadEdits();
      }
    },
    messageSuccess: isArchived(entity) ? 'Kampagne aus dem Archiv geholt.' : 'Kampagne archiviert.',
    messageFail: isArchived(entity)
      ? 'Fehler beim aus dem Archiv holen.'
      : 'Fehler beim Archivieren.'
  });

  // Changes the stage of the campaign.
  const handleChangeMode = remoteAction({
    action: async () => {
      if (isPreRelease(entity)) {
        await updateCampaignStage(id, stageReleased);
        reload();
        reloadEdits();
      } else if (isReleased(entity)) {
        await updateCampaignStage(id, stageDraft);
        reload();
        reloadEdits();
      }
    }
  });

  const reorder = remoteAction({
    action: async (src, dst) => {
      await swapOfferOrder(src, dst);
      reload();
      reloadRelated();
      reloadEdits();
    },
    messageSuccess: 'Vorteil verschoben.',
    messageFail: 'Vorteil konnte nicht verschoben werden.'
  });

  // Determine visibilities for displaying only present elements.
  const hasOffers = 0 < offers?.length;
  const hasOffersArchived = 0 < offersArchived?.length;

  // Determine status as follows. If active, it is released and some offers are visible. If it is not active but
  // released, there's no visible offer. Otherwise default labeling applies.
  let statusComponent;
  if (entity?.isActive)
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<Eye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel'>Diese Kampagne ist aktuell sichtbar.</span>
      </div>
    );
  else if (isReleased(entity))
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel OfferDetails__StatusLabel--Inactive'>
          Diese Kampagne ist aktuell nicht sichtbar, weil kein Vorteil aktiv ist.
        </span>
      </div>
    );
  else if (isArchived(entity))
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel OfferDetails__StatusLabel--Inactive'>
          Diese Kampagne ist archiviert.
        </span>
      </div>
    );
  else
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel OfferDetails__StatusLabel--Inactive'>
          Diese Kampagne ist aktuell nicht sichtbar, weil sie im Entwurfsmodus ist.
        </span>
      </div>
    );

  return (
    <main className='CampaignDetails'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Kampagne: ${entity?.title}`} />

      {!entity ? null : (
        <Container>
          <Toolbar
            title={entity?.title}
            backButton={{ to: Paths.Campaigns(), label: 'Kampagnen & Vorteile' }}
            right={[
              <Button
                key='evaluate'
                title='Auswerten'
                icon={Chart}
                iconSize={18}
                variant='ORANGE_OUTLINE'
                to={Paths.CampaignStatistics(entity?.id)}
              />,
              !isArchived(entity) && (
                <Button
                  key='edit'
                  title='Bearbeiten'
                  icon={Edit}
                  iconSize={17}
                  to={Paths.CampaignEdit(entity?.id)}
                />
              ),
              <Button
                key='archival'
                title={isArchived(entity) ? 'Aus dem Archiv holen' : 'Archivieren'}
                icon={Archive}
                iconSize={18}
                variant='ORANGE_OUTLINE'
                onClick={handleChangeArchival}
              />,
              <Button
                key='delete'
                title='Löschen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                onClick={handleDelete}
              />
            ].filter(Boolean)}
          />
          <DisplayGroup label='Titel'>{entity?.title}</DisplayGroup>
          <DisplayGroup label='Kurzbeschreibung'>{entity?.excerpt}</DisplayGroup>
          <DisplayGroup label='Titelbild'>
            {entity?.header?.location ? (
              <ImagePreview images={[entity.header]} width={320} height={180} cover />
            ) : (
              <div className='CampaignDetails__NoHeader'>Kein Titelbild hinterlegt</div>
            )}
            <div className='CampaignDetails__ColorScheme'>
              Text wird auf diesem Bild in
              <b>{entity?.textDisplay ? ' schwarz ' : ' weiß '}</b>
              dargestellt.
            </div>
          </DisplayGroup>
          <DisplayGroup label='Status'>
            <FormRow spaceBetween>
              {statusComponent}
              {isArchived(entity) ? null : (
                <ToggleSwitch
                  onUpdateValue={handleChangeMode}
                  value={isReleased(entity)}
                  labelOff='Entwurf'
                  labelOn='Aktiv'
                />
              )}
            </FormRow>
          </DisplayGroup>
          {!hasOffers ? null : (
            <DisplayGroup label='Vorteile'>
              <div className='CampaignDetails__OffersWrapper'>
                {offers?.map((offer, index) => (
                  <OfferPanel
                    offer={offer}
                    key={offer.id}
                    onMoveUp={index > 0 ? () => reorder(offer.id, offers[index - 1].id) : undefined}
                    onMoveDown={
                      index < offers.length - 1
                        ? () => reorder(offer.id, offers[index + 1].id)
                        : undefined
                    }
                  />
                ))}
                <div className='CampaignDetails__NewOffer'>
                  <Button title='Neuer Vorteil' icon={Plus} to={Paths.OfferCreate(entity?.id)} />
                </div>
              </div>
            </DisplayGroup>
          )}
          {!hasOffersArchived ? null : (
            <DisplayGroup label='Archivierte Vorteile'>
              <div className='CampaignDetails__OffersWrapper'>
                {offersArchived?.map(offer => (
                  <OfferPanel offer={offer} key={offer.id} editable={false} />
                ))}
              </div>
            </DisplayGroup>
          )}

          <EditsProtocol edits={edits} />
        </Container>
      )}
    </main>
  );
};

export default CampaignDetails;
