import React from 'react';
import PropTypes from 'prop-types';
import './TableCell.scss';

const TableCell = ({ align, alternate, children, slim }) => {
  return (
    <td
      className={`TableCell
        TableCell--${align}
        ${alternate ? 'TableCell--Alternate' : ''}
        ${slim ? 'TableCell--Slim' : ''}
      `}
    >
      {children}
    </td>
  );
};

TableCell.propTypes = {
  align: PropTypes.oneOf(['LEFT', 'CENTER', 'RIGHT']).isRequired,
  alternate: PropTypes.bool,
  children: PropTypes.any,
  slim: PropTypes.bool
};

TableCell.defaultProps = {
  align: 'LEFT'
};

export default TableCell;
