import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCustomer } from '../../api/customers';
import { analyzeValue } from '../../api/customerValue';
import { Paths } from '../../definitions/paths';
import {
  displayCustomerBirthday,
  displayCustomerName,
  displayDate,
  displayPercentage,
  displayTimeSpan,
  sanitizePhoneUrl
} from '../../helpers/formatters';
import { notifyError, notifySuccess } from '../../helpers/notifications';
import PageTitle from '../../helpers/pageTitle';
import { customerContracts } from '../../helpers/parsers';
import useIdParam from '../../hooks/useIdParam';
import { useCustomer, useEdits, useLastEventOf } from '../../hooks/useRemoteValue';
import { useInterestsAll } from '../../hooks/useResolve';
import { Close, Edit } from '../../images/icons';
import Button from '../atoms/Button';
import ContractTile from '../atoms/ContractTile';
import DisplayGroup from '../atoms/DisplayGroup';
import MultiPicker from '../atoms/input/MultiPicker';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { LoadingStrip } from '../atoms/LoadingStrip';
import TextLink from '../atoms/TextLink';
import { Trend } from '../atoms/Trend';
import Container from '../layout/Container';
import EditsProtocol from '../modules/EditsProtocol';
import OfferPanel from '../modules/OfferPanel';
import RedemptionsList from '../modules/RedemptionsList';
import Toolbar from '../modules/Toolbar';
import { useEntityView } from './common';
import './CustomerDetails.scss';
import { ValueDeduction } from './ValueDeduction';
import { useAbortEffect } from '../../hooks/useAbortEffect';
import { useCustomerPrincipal } from '../../hooks/useCustomerPrincipal';
import Tooltip from '../atoms/Tooltip';

const CustomerDetails = () => {
  // Get basic view parameters and objects.
  const navigate = useNavigate();
  const id = useIdParam();

  // Use partner of ID.
  const { entity: customer, loadingEntity, error } = useEntityView(useCustomer, id);
  const [edits, , loadingEdits] = useEdits(id);
  const [lastEvent, , loadingEvent] = useLastEventOf(customer?.for?.identifier);

  // Use principal of customer if given.
  const principal = useCustomerPrincipal(customer);

  // Loading state is combination of associated loading states.
  const loading = loadingEntity || loadingEdits || loadingEvent;

  const [interests] = useInterestsAll();
  const interestsSelected = customer?.interests.map(interest => interest.name);

  const [customerValueBefore, setCustomerValueBefore] = useState(null);
  const [customerValue, setCustomerValue] = useState(null);
  const [customerSummary, setCustomerSummary] = useState(null);
  useAbortEffect(
    signal => {
      if (!principal) return;
      const timeNow = moment();
      const timeMid = moment().subtract(7, 'days');

      (async () => {
        const [first, second] = await Promise.all([
          analyzeValue(principal, timeMid.toISOString(), signal),
          analyzeValue(principal, timeNow.toISOString(), signal)
        ]);
        setCustomerValueBefore(first.value);
        setCustomerValue(second.value);
        setCustomerSummary(second);
      })().catch(() => undefined);
    },
    [principal]
  );

  useEffect(() => {
    if (error) notifyError('Beim Laden des Kunden ist ein Fehler aufgetreten');
  }, [error]);

  // Delete the currently viewed partner.
  const handleDelete = async () => {
    // Confirm that partner should be deleted.
    if (!confirm('Bitte bestätigen Sie das Löschen des Kunden.')) return;

    try {
      // Delete partner. On success, navigate to partners list.
      await deleteCustomer(id);
      notifySuccess('Kunde gelöscht.');
      navigate(Paths.Customers());
    } catch (error) {
      // Mark error.
      notifyError('Fehler beim Löschen.');
    }
  };

  return (
    <main className='CustomerDetails'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Kunde: ${displayCustomerName(customer)}`} />
      {!customer ? null : (
        <Container>
          <Toolbar
            title={displayCustomerName(customer)}
            backButton={{ to: Paths.Customers(), label: 'Kunden' }}
            right={[
              !customer.linkedContract ? null : (
                <Button
                  key={1}
                  title='Bearbeiten'
                  icon={Edit}
                  iconSize={17}
                  to={Paths.CustomerEdit(customer.id)}
                />
              ),
              <Button
                key={2}
                title='Löschen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                onClick={handleDelete}
              />
            ]}
          />
          {!customer.onboardingComplete ? (
            <DisplayGroup label='Hinweis'>
              Dieser Kunde hat das Onboarding noch nicht abgeschlossen und kann den Entdecker-Bonus
              nicht nutzen.
            </DisplayGroup>
          ) : (
            <>
              <DisplayGroup
                label='Stammdaten'
                tooltip='Die Stammdaten repräsentieren die Kundendaten, die aus dem verknüpften Vertrag ausgelesen wurden. Sie können nur im Kundenportal bearbeitet werden. Bislang leere Felder können Kunden dagegen über den Entdecker-Bonus ergänzen.'
              >
                <table className='CustomerDetails__Data'>
                  <tbody>
                    <tr>
                      <td>Entdecker-Wert</td>
                      {typeof customerValue !== 'number' || !customerSummary ? (
                        <td>
                          <LoadingStrip />
                        </td>
                      ) : (
                        <td className='CustomerDetails__Baselined'>
                          <Trend
                            value={customerValue}
                            valueBefore={customerValueBefore}
                            percentage={true}
                            fractionalDigits={0}
                          />
                          <Tooltip
                            content={
                              <span>Vorherige Woche: {displayPercentage(customerValueBefore)}</span>
                            }
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>Anrede</td>
                      <td>
                        {customer.salutation || (
                          <span className='CustomerDetails__Weak'>Keine Angabe</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Vorname</td>
                      <td>
                        {customer.firstName || (
                          <span className='CustomerDetails__Weak'>Keine Angabe</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Nachname</td>
                      <td>
                        {customer.lastName || (
                          <span className='CustomerDetails__Weak'>Keine Angabe</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Geburtstag</td>
                      <td>
                        {displayCustomerBirthday(customer) || (
                          <span className='CustomerDetails__Weak'>Keine Angabe</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>E-Mail</td>
                      <td>
                        {customer.email ? (
                          <TextLink href={`mailto:${customer.email}`} title={customer.email}>
                            {customer.email}
                          </TextLink>
                        ) : (
                          <span className='CustomerDetails__Weak'>Keine Angabe</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Telefon</td>
                      <td>
                        {customer.telephone ? (
                          <TextLink
                            href={sanitizePhoneUrl(customer.telephone)}
                            title={customer.telephone}
                          >
                            {customer.telephone}
                          </TextLink>
                        ) : (
                          <span className='CustomerDetails__Weak'>Keine Angabe</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </DisplayGroup>
              <DisplayGroup label='Haushalt & Familie'>
                {customer.householdPeople <= 1
                  ? 'Lebt alleine im Haushalt'
                  : `Lebt in einem Haushalt mit ${customer.householdPeople} Personen`}
                <br />
                {customer.householdHasChildren
                  ? `Es ${
                      customer.householdChildren === 1
                        ? 'lebt 1 Kind'
                        : `leben ${customer.householdChildren} Kinder`
                    } im gleichen Haushalt.`
                  : 'Es leben keine Kinder im gleichen Haushalt.'}
              </DisplayGroup>
              <DisplayGroup label='Interessen'>
                <MultiPicker
                  options={interests?.map(interest => ({
                    key: interest.name,
                    label: interest.label
                  }))}
                  pickedKeys={interestsSelected}
                />
              </DisplayGroup>
              {!customer.onboardingStart && !lastEvent ? null : (
                <DisplayGroup label='Nutzung'>
                  {!customer.onboardingStart ? null : (
                    <p>
                      Registriert seit{' '}
                      {`${displayDate(customer.onboardingStart)} (${displayTimeSpan(
                        customer.onboardingStart
                      )})`}
                    </p>
                  )}
                  {!lastEvent ? null : (
                    <p>
                      Letzte Nutzung am{' '}
                      {` ${displayDate(lastEvent?.Timestamp)} (${displayTimeSpan(
                        lastEvent?.Timestamp,
                        true
                      )})`}
                    </p>
                  )}
                </DisplayGroup>
              )}
            </>
          )}
          <DisplayGroup label='evm-Verträge'>
            <div className='CustomerDetails__Contracts'>
              {customerContracts(customer)?.map(({ id: customerId, types: energyTypes }, index) => (
                <div key={index} className='CustomerDetails__Contract'>
                  <ContractTile
                    highlighted={customer?.customerId === customerId}
                    customerId={customerId}
                    name={
                      customer?.customerId === customerId
                        ? 'Im Onboarding angewählt'
                        : 'Weiterer Vertrag'
                    }
                    energyTypes={energyTypes}
                    address={''}
                  />
                </div>
              ))}
            </div>
          </DisplayGroup>

          {!customer?.bookmarked?.length ? null : (
            <DisplayGroup
              label='Gemerkte Vorteile'
              tooltip='Diese Vorteile befinden sich aktuell auf der Merkliste des Kunden.'
            >
              {customer?.bookmarked.map((offer, index) => (
                <OfferPanel
                  alternate={index % 2 === 1}
                  offer={offer}
                  key={offer.id}
                  editable={false}
                  showStatus={false}
                  withoutCampaign
                />
              ))}
            </DisplayGroup>
          )}

          {!customerSummary ? null : (
            <>
              <h1 className='CustomerDetails__Section'>
                <span>Entdecker-Wert</span>
                <Tooltip
                  content='Der Entdecker-Wert setzt sich aus diesen Einzelwerten zusammen.'
                  size={28}
                />
              </h1>
              <div className='CustomerDetails__ValueContainer'>
                <ValueDeduction
                  forName={customer?.firstName}
                  rootLabel='Entdecker-Wert'
                  rootNode={customerSummary}
                />
              </div>
            </>
          )}

          <h1 className='CustomerDetails__Section'>Verlauf</h1>
          <RedemptionsList customer={customer.id} />

          <EditsProtocol edits={edits} />
        </Container>
      )}
    </main>
  );
};

export default CustomerDetails;
