export const validateEmail = (value, emptyIsValid = false) => {
  return (
    // eslint-disable-next-line no-empty-character-class
    /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/.test(
      value
    ) ||
    (!value && emptyIsValid) ||
    'Geben Sie eine gültige E-Mail-Adresse ein'
  );
};

export const validateDate = (value, emptyIsValid = false) => {
  return (
    /(^(((0[1-9]|1[0-9]|2[0-8])[.](0[1-9]|1[012]))|((29|30|31)[.](0[13578]|1[02]))|((29|30)[.](0[4,6,9]|11)))[.](19|[2-9][0-9])\d\d$)|(^29[.]02[.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(
      value
    ) ||
    (!value && emptyIsValid) ||
    'Geben Sie ein gültiges Datum ein (TT.MM.JJJJ)'
  );
};

export const validatePhone = (value, emptyIsValid = false) => {
  return (
    /^\+?([0-9]|\/|-|\s)*$/.test(value) ||
    (!value && emptyIsValid) ||
    'Geben Sie eine gültige Telefonnummer ein'
  );
};
