import { useEffect, useState } from 'react';
import { allCampaigns, resolveCampaigns } from '../api/campaigns';
import { allInterests, resolveInterests } from '../api/customers';
import { allHighlights, resolveHighlights } from '../api/highlights';
import {
  allInternalCategories,
  allOffers,
  allShops,
  resolveInternalCategories,
  resolveOffers,
  resolveShops
} from '../api/offers';
import { allPartners, resolvePartners } from '../api/partners';
import { allPreviews, resolvePreviews } from '../api/previews';

/**
 * Uses a resolution list that can be fetched by an asynchronous function returning all possible
 * values.
 * @param {function():Promise<*[]>} get The getter retrieving the resolution.
 * @return {function(): [*[], boolean, Error, function():void]} Returns the values, the loading state, the error and a reload handle.
 */
export const useResolveAll = get => () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reloadToken, setReloadToken] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        // Start loading and mark it.
        setError(null);
        setLoading(true);
        const received = await get();
        setResults(received);
      } catch (error) {
        // On error, mark in state.
        setError(error);
      } finally {
        // Always finish loading.
        setLoading(false);
      }
    })();
  }, [reloadToken]);

  return [results, loading, error, () => setReloadToken(Date.now())];
};

/**
 * Uses a resolution list limited to a set of keys to resolve, fetched by an asynchronous function
 * returning the values for the given keys. The method is fast-tracked if the keys are not true.
 * @param {function( keys:*[]):Promise<*[]>} get The getter retrieving the resolution from the passed keys as
 * a promise.
 * @return {function(keys:*[]): [*[], boolean, Error, function():void]} Returns the values, the loading state, the
 * error and a reload handle.
 */
export const useResolveBy = get => from => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reloadToken, setReloadToken] = useState(null);

  useEffect(() => {
    if (from) {
      (async () => {
        try {
          // Start loading and mark it.
          setError(null);
          setLoading(true);
          const received = await get(from);
          setResults(received);
        } catch (error) {
          // On error, mark in state.
          setError(error);
        } finally {
          // Always finish loading.
          setLoading(false);
        }
      })();
    } else {
      setError(null);
      setResults([]);
    }
  }, [JSON.stringify(from), reloadToken]);

  return [results, loading, error, () => setReloadToken(Date.now())];
};

/**
 * Uses all campaign resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useCampaignsAll = useResolveAll(allCampaigns);

/**
 * Uses campaign resolution by IDs.
 * @param ids The IDs to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useCampaignsBy = useResolveBy(resolveCampaigns);

/**
 * Uses all interest resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useInterestsAll = useResolveAll(allInterests);

/**
 * Uses interest resolution by names.
 * @param names The names to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useInterestsBy = useResolveBy(resolveInterests);

/**
 * Uses all internal category resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useInternalCategoriesAll = useResolveAll(allInternalCategories);

/**
 * Uses internal category resolution by names.
 * @param names The names to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useInternalCategoriesBy = useResolveBy(resolveInternalCategories);

/**
 * Uses all shops resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useShopsAll = useResolveAll(allShops);

/**
 * Uses shops resolution by uris.
 * @param uris The uris to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useShopsBy = useResolveBy(resolveShops);

/**
 * Uses all highlight resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useHighlightsAll = useResolveAll(allHighlights);

/**
 * Uses highlight resolution by IDs.
 * @param ids The IDs to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useHighlightsBy = useResolveBy(resolveHighlights);

/**
 * Uses all offer resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useOffersAll = useResolveAll(allOffers);

/**
 * Uses offer resolution by IDs.
 * @param ids The IDs to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const useOffersBy = useResolveBy(resolveOffers);

/**
 * Uses all partner resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const usePartnersAll = useResolveAll(allPartners);

/**
 * Uses partner resolution by IDs.
 * @param ids The IDs to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const usePartnersBy = useResolveBy(resolvePartners);

/**
 * Uses all preview resolutions for option presentation.
 * @type {function(): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const usePreviewsAll = useResolveAll(allPreviews);

/**
 * Uses preview resolution by IDs.
 * @param ids The IDs to resolve.
 * @type {function(keys:*[]): (*[]|boolean|Error)[]} Returns the value, the loading state and the error.
 */
export const usePreviewsBy = useResolveBy(resolvePreviews);
