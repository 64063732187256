import { useMemo } from 'react';
import { customerPrincipal } from '../helpers/parsers';

/**
 * Uses the principal of the customer.
 * @param customer The customer object.
 * @return {string|null} The principal or null.
 */
export const useCustomerPrincipal = customer =>
  useMemo(() => customerPrincipal(customer), [customer?.for?.identifier]);
