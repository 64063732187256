import React, { useEffect, useRef } from 'react';
import Container from '../layout/Container';
import './Customers.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Pagination from '../atoms/Pagination';
import { useScrollTo } from '../../hooks/useScrollTo';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import Toolbar from '../modules/Toolbar';
import ItemCount from '../atoms/ItemCount';
import SearchBar from '../atoms/SearchBar';
import Table from '../atoms/table/Table';
import TableHead, { fromApiSort } from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';
import TextLink from '../atoms/TextLink';
import { displayCustomerIds, displayCustomerName, displayDate } from '../../helpers/formatters';
import { Paths } from '../../definitions/paths';
import PageTitle from '../../helpers/pageTitle';
import {
  listingCustomersRequestPage,
  selectListingCustomers,
  selectListingCustomersMode,
  selectListingCustomersTotal,
  selectListingCustomersTotalItems
} from '../../redux/reducers/listingCustomers';
import RadioButtonBar from '../atoms/input/RadioButtonBar';
import { Mode } from '../../redux/reducers/mode';
import { useSessionState } from '../../hooks/useSessionState';

const Customers = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useSessionState('Customers/page', 0);
  const [search, setSearch] = useSessionState('Customers/search', '');
  const [completed, setCompleted] = useSessionState('Customers/completed', null);
  const [order, setOrder] = useSessionState('Customers/order', null);
  const [orderCompleted, setOrderCompleted] = useSessionState('Customers/orderCompleted', null);
  const [orderNotCompleted, setOrderNotCompleted] = useSessionState(
    'Customers/orderNotCompleted',
    null
  );

  const orderActual =
    (completed === true && orderCompleted) ||
    (completed === false && orderNotCompleted) ||
    (completed === null && order);
  const setOrderActual =
    (completed === true && setOrderCompleted) ||
    (completed === false && setOrderNotCompleted) ||
    (completed === null && setOrder);

  // Select state values
  const items = useSelector(selectListingCustomers(page));
  const mode = useSelector(selectListingCustomersMode(page));
  // const error = useSelector(selectCustomersError(page));
  const total = useSelector(selectListingCustomersTotal);
  const totalItems = useSelector(selectListingCustomersTotalItems);

  // Scroll to table upon page flip
  const scrollAnchor = useRef(null);
  useScrollTo(scrollAnchor, page);

  // Do not exceed page limit
  usePaginationGuard(page, total, setPage);

  // Make page current with given index and query.
  useEffect(() => {
    dispatch(
      listingCustomersRequestPage(page, [
        search && ['search', search],
        orderActual && ['order', orderActual],
        completed !== null && ['completed', completed]
      ])
    );
  }, [page, search, orderActual, completed]);

  return (
    <main className='Customers'>
      <PageTitle title={'Kunden'} />
      <LoadingOverlay show={mode !== Mode.Idle} />
      <Container>
        <Toolbar
          title='Kunden'
          left={[
            <RadioButtonBar
              key={1}
              value={completed}
              onUpdateValue={setCompleted}
              options={[
                { value: null, label: 'Alle' },
                { value: true, label: 'Registrierte Kunden' },
                { value: false, label: 'Onboarding ausstehend' }
              ]}
            />
          ]}
          right={[
            <SearchBar key={1} term={search} onUpdateTerm={setSearch} />,
            <ItemCount key={2} count={totalItems} />
          ]}
        />
        <div className='Customers__TableWrapper' ref={scrollAnchor}>
          <Table
            headings={[
              <TableHead
                key={1}
                label='Name'
                width={20}
                currentSorting={fromApiSort('lastName', orderActual)}
                onSortAsc={() => setOrderActual('+lastName')}
                onSortDesc={() => setOrderActual('-lastName')}
                onSortReset={() => setOrderActual(null) /* default */}
              />,
              <TableHead
                key={2}
                label='E-Mail-Adresse'
                currentSorting={fromApiSort('email', orderActual)}
                onSortAsc={() => setOrderActual('+email')}
                onSortDesc={() => setOrderActual('-email')}
                onSortReset={() => setOrderActual(null) /* default */}
              />,
              <TableHead key={3} label='Kundennummer(n)' width={22} />,
              <TableHead
                key={4}
                label='Einlösungen'
                width={18}
                currentSorting={fromApiSort('redemptions', orderActual)}
                onSortAsc={() => setOrderActual('+redemptions')}
                onSortDesc={() => setOrderActual('-redemptions')}
                onSortReset={() => setOrderActual(null) /* default */}
              />,
              completed !== false && (
                <TableHead
                  key={5}
                  label='Registrierung'
                  width={16}
                  currentSorting={fromApiSort('onboardingComplete', orderActual)}
                  onSortAsc={() => setOrderActual('+onboardingComplete')}
                  onSortDesc={() => setOrderActual('-onboardingComplete')}
                  onSortReset={() => setOrderActual(null) /* default */}
                />
              ),
              completed === false && (
                <TableHead
                  key={5}
                  label='Registrierung'
                  width={16}
                  currentSorting={fromApiSort('onboardingStart', orderActual)}
                  onSortAsc={() => setOrderActual('+onboardingStart')}
                  onSortDesc={() => setOrderActual('-onboardingStart')}
                  onSortReset={() => setOrderActual(null) /* default */}
                />
              )
            ].filter(Boolean)}
          >
            {items &&
              items.map((customer, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextLink
                      title={displayCustomerName(customer)}
                      to={Paths.CustomerDetails(customer.id)}
                    />
                  </TableCell>
                  <TableCell>
                    {customer.email || (
                      <span className='Customers__Weak'>Keine E-Mail-Adresse</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {displayCustomerIds(customer) || (
                      <span className='Customers__Weak'>Keine Kundennummern</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {!customer.redemptions ? null : (
                      <span>
                        {customer.redemptions} Einlösung{customer.redemptions !== 1 ? 'en' : ''}
                      </span>
                    )}
                    {customer.redemptions ? null : <span className='Customers__Weak'>Keine</span>}
                  </TableCell>
                  <TableCell>
                    {!customer.onboardingComplete ? null : (
                      <span>{displayDate(customer.onboardingComplete)}</span>
                    )}
                    {customer.onboardingComplete || !customer.onboardingStart ? null : (
                      <span>Offen seit {displayDate(customer.onboardingStart)}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </div>
        <Pagination numPages={total} currentPage={page} onPagePick={setPage} />
      </Container>
    </main>
  );
};

export default Customers;
