import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './UserMenuItem.scss';

const UserMenuItem = ({ alternate, onClick, title, to }) => {
  const Element = to ? Link : 'button';
  return (
    <Element
      title={title}
      to={to}
      onClick={onClick}
      className={`UserMenuItem ${alternate ? 'UserMenuItem--Alternate' : ''}`}
    >
      {title}
    </Element>
  );
};

UserMenuItem.propTypes = {
  alternate: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  to: PropTypes.any
};

export default UserMenuItem;
