import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePaginationGuard } from '../../hooks/usePaginationGuard';
import { useScrollTo } from '../../hooks/useScrollTo';
import Table from '../atoms/table/Table';
import TableHead from '../atoms/table/TableHead';
import TableRow from '../atoms/table/TableRow';
import TableCell from '../atoms/table/TableCell';
import TextLink from '../atoms/TextLink';
import { Paths } from '../../definitions/paths';
import { displayTimestamp } from '../../helpers/formatters';
import './RedemptionsList.scss';
import Pagination from '../atoms/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  activationsRequestPage,
  selectActivations,
  selectActivationsTotal
} from '../../redux/reducers/activations';

const RedemptionsList = ({ customer }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  // Select state values
  const items = useSelector(selectActivations(page));
  // const mode = useSelector(selectActivationsMode(page));
  // const error = useSelector(selectActivationsError(page));
  const total = useSelector(selectActivationsTotal);
  // const totalItems = useSelector(selectActivationsTotalItems);

  // Do not exceed page limit
  usePaginationGuard(page, total, setPage);

  // Scroll to table upon page flip
  const scrollAnchor = useRef(null);
  useScrollTo(scrollAnchor, page);

  // Make page current with given index and query.
  useEffect(() => {
    dispatch(activationsRequestPage(page, [['by', customer]]));
  }, [page, customer]);

  return (
    <div className='RedemptionsList'>
      <Table
        headings={[
          <TableHead key={1} label='Kampagne' width={25} />,
          <TableHead key={2} label='Vorteil' />,
          <TableHead key={3} label='Partner' width={25} />,
          <TableHead key={4} label='Einlösung' width={20} />
        ]}
      >
        {items &&
          items.map((activation, index) => (
            <TableRow key={index}>
              <TableCell>
                {activation.offer?.campaign ? (
                  <TextLink
                    title={activation.offer?.campaign?.title}
                    to={Paths.CampaignDetails(activation.offer?.campaign?.id)}
                  />
                ) : (
                  <span className='RedemptionsList__Weak'>Keine Kampagne</span>
                )}
              </TableCell>
              <TableCell>
                <TextLink
                  title={activation.offer?.title}
                  to={Paths.OfferDetails(activation.offer?.id)}
                />
              </TableCell>
              <TableCell>
                {activation.offer?.partner ? (
                  <TextLink
                    title={activation.offer?.partner?.name}
                    to={Paths.PartnerDetails(activation.offer?.partner?.id)}
                  />
                ) : (
                  <span className='RedemptionsList__Weak'>Kein Partner</span>
                )}
              </TableCell>
              <TableCell>{displayTimestamp(activation.at)}</TableCell>
            </TableRow>
          ))}
      </Table>
      <Pagination currentPage={page} numPages={total} onPagePick={setPage} />
    </div>
  );
};

RedemptionsList.propTypes = {
  customer: PropTypes.string.isRequired
};

export default RedemptionsList;
