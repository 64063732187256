import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import de from 'apexcharts/dist/locales/de.json';
import moment from 'moment';
import './Chart.scss';

const DateStackChart = ({ empty, series, hidden = 1, yTitle, xTitle }) => {
  // Update key that can be used to reference the last data-driven update. This is necessary, as the
  // chart implementation cannot initially set series hidden and the update event is fired both on
  // initialization and hiding series.
  const updateKey = useMemo(() => Date.now(), [series, hidden]);
  // const dates = useMemo(() => {
  //   const xs = [];
  //   for (const items of series) {
  //     for (const item of items.data) {
  //       if (!xs.includes(item.x)) xs.push(item.x);
  //     }
  //   }
  //   return xs;
  // }, [series]);

  const chartOptions = useMemo(
    () => ({
      grid: {
        padding: {
          left: 20,
          right: 60
        }
      },
      chart: {
        stacked: true,
        locales: [de],
        defaultLocale: 'de',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        fontFamily: 'Ubuntu, sans-serif',
        foreColor: '#212529',
        selection: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        events: {
          updated: chart => {
            // Only update when key is not the same.
            if (chart.hiddenBy !== updateKey) {
              chart.hiddenBy = updateKey;
              for (let i = 0; i < Math.min(series?.length, hidden); i++)
                chart.hideSeries(series[i].name);
            }
          }
        }
      },
      fill: {
        type: 'solid',
        opacity: 1.0
      },
      stroke: {
        curve: 'smooth'
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        inverseOrder: true,
        x: {
          format: 'dd. MMMM yyyy'
        },
        style: {
          fontSize: '16px'
        }
      },
      xaxis: {
        type: 'category',
        title: xTitle && {
          text: xTitle,
          style: {
            fontFamily: 'Ubuntu, sans-serif'
          }
        },
        labels: {
          showDuplicates: false,
          formatter: x => moment(x).format('DD. MMMM'),
          style: {
            fontSize: '16px'
          }
        }
      },
      yaxis: {
        title: yTitle && {
          text: yTitle,
          style: {
            fontFamily: 'Ubuntu, sans-serif'
          }
        },
        labels: {
          style: {
            fontSize: '16px'
          }
        }
      }
    }),
    [xTitle, yTitle]
  );

  return (
    <div className='Chart'>
      <Chart series={series} options={chartOptions} type='area' width='100%' height={700} />
      {!empty ? null : (
        <div className='Chart__EmptyOverlay'>
          <span>Keine Daten</span>
        </div>
      )}
    </div>
  );
};

DateStackChart.propTypes = {
  empty: PropTypes.bool,
  series: PropTypes.array.isRequired,
  hidden: PropTypes.number,
  yTitle: PropTypes.string,
  xTitle: PropTypes.string
};

export default DateStackChart;
