import {
  pagingReducer,
  pagingRequestPage,
  pagingSelectError,
  pagingSelectItems,
  pagingSelectMode,
  pagingSelectQuery,
  pagingSelectTotal,
  pagingSelectTotalItems
} from './paging';
import { getPartners } from '../../api/partners';

/**
 * Name within the state/name of the reducer.
 * @type {string}
 */
export const partnersLocation = 'partners';

/**
 * Requests that the given page of the given query is made present.
 */
export const partnersRequestPage = pagingRequestPage(partnersLocation, getPartners);

/**
 * Selects the items of the current page.
 * @param page The page to select for.
 */
export const selectPartners = pagingSelectItems(partnersLocation);

/**
 * Selects the mode of the page, i.e., Idle, Initializing, or Updating.
 * @param page The page to select for.
 */
export const selectPartnersMode = pagingSelectMode(partnersLocation);

/**
 * Selects the error status of the page.
 * @param page The page to select for.
 */
export const selectPartnersError = pagingSelectError(partnersLocation);

/**
 * Selects the currently activated query.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectPartnersQuery = pagingSelectQuery(partnersLocation);

/**
 * Selects the current page count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectPartnersTotal = pagingSelectTotal(partnersLocation);

/**
 * Selects the current results count. This selector may return `null` if no count is available yet.
 * @param state The state. Usually not given directly but used in conjunction with `useSelector`.
 */
export const selectPartnersTotalItems = pagingSelectTotalItems(partnersLocation);

/**
 * Reducer for partners listing.
 */
export const partnersReducer = pagingReducer(partnersLocation);
