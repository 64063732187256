import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../atoms/IconButton';
import Colors from '../../definitions/colors';
import { Search } from '../../images/icons';
import './SearchBar.scss';
import Textbox from './input/Textbox';

const SearchBar = ({ term, onUpdateTerm }) => {
  const [currentTerm, setCurrentTerm] = useState(term);
  return (
    <form action='/' onSubmit={e => e.preventDefault()} className='SearchBar'>
      <Textbox
        name='term'
        title='Suchen'
        value={currentTerm}
        showLabel={false}
        onUpdateValue={setCurrentTerm}
        placeholder='Suchen'
        inputClass='SearchBar__Textbox'
      />
      <div className='SearchBar__Submit'>
        <IconButton
          color={Colors.Orange}
          iconCircle={{ icon: <Search fill={Colors.White} size={15} />, offsetX: -1, size: 28 }}
          title='Suchen'
          onClick={() => onUpdateTerm(currentTerm)}
        />
      </div>
    </form>
  );
};

SearchBar.propTypes = {
  term: PropTypes.string.isRequired,
  onUpdateTerm: PropTypes.func.isRequired
};

export default SearchBar;
