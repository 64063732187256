import PropTypes from 'prop-types';
import React from 'react';
import './ValueDeduction.scss';
import { displayPercentage, displayPercentagePrecise } from '../../helpers/formatters';
import { rgbaLerp, rgbaToStyle } from '../../util/color';
import Tooltip from '../atoms/Tooltip';

/**
 * Translation of labels to actual texts.
 */
const valueDeductionTranslation = {
  current: 'Aktuell',
  initiate: 'Vorteil initiiert',
  bookmark: 'Vorteil gemerkt',
  view: 'Vorteil angesehen',
  open: 'Startseite aufgerufen',
  historical: 'Verlauf der Einlösung',
  s1: 'Einlösungen Letzter Monat',
  s1Evm: 'evm',
  s1Querbeet: 'Querbeet',
  s1Partner: 'Partner',
  s2: 'Vor 2.–3.\u00a0Monaten',
  s2Evm: 'evm',
  s2Querbeet: 'Querbeet',
  s2Partner: 'Partner',
  s3: 'Vor 4.–6.\u00a0Monaten',
  s3Evm: 'evm',
  s3Querbeet: 'Querbeet',
  s3Partner: 'Partner',
  s4: 'Vor 7.–12.\u00a0Monaten',
  s4Evm: 'evm',
  s4Querbeet: 'Querbeet',
  s4Partner: 'Partner'
};

const rgbaLow = [251 / 255, 193 / 255, 107 / 255, 1];
const rgbaHigh = [243 / 255, 145 / 255, 0 / 255, 1];

/**
 * Contains a node and maybe more sub-nodes.
 * @param children The content.
 * @returns {JSX.Element}
 * @constructor
 */
const Node = ({ children }) => <div className='ValueDeduction'>{children}</div>;
Node.propTypes = {
  children: PropTypes.node
};

/**
 * Displays the node body. This contains the label and the value.
 * @param children The content.
 * @returns {JSX.Element}
 * @constructor
 */
const NodeBody = ({ children }) => <div className='ValueDeduction__Body'>{children}</div>;
NodeBody.propTypes = {
  children: PropTypes.node
};

/**
 * Displays the weight arrow.
 * @param weight The weight to display.
 * @param color The color.
 * @returns {JSX.Element}
 * @constructor
 */
const NodeWeight = ({ weight, color }) => {
  return (
    <div className='ValueDeduction__Weight'>
      <svg viewBox='0 0 100 100' preserveAspectRatio='none'>
        <path d='M0,50 L15,0 L100,0 L100,100 L15,100 Z' fill={color} />
      </svg>
      <div>
        <span>{weight}×</span>
      </div>
    </div>
  );
};
NodeWeight.propTypes = {
  weight: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

/**
 * Formats values that can but do not have to have fractional digits.
 * @type {Intl.NumberFormat}
 */
const scoreTooltipAvgMeanFormatter = new Intl.NumberFormat('de', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3
});

const mmts = n => (n === 1 ? 'Messung' : 'Messungen');

/**
 * Renders the tooltip content with the meta-info.
 * @param forName The name to display for.
 * @param frequency The original frequency.
 * @param quantile The original quantile.
 * @param qMin Minimum quantile.
 * @param qMax Maximum quantile.
 * @param sampleN Sample size.
 * @param sampleMean Sample mean.
 * @param sampleStd Sample standard deviation.
 * @param sampleMin Sample minimum.
 * @param sampleMax Sample maximum.
 * @returns {JSX.Element}
 * @constructor
 */
const NodeTooltip = ({
  forName,
  frequency,
  quantile,
  qMin,
  qMax,
  sampleN,
  sampleLess,
  sampleEqual,
  sampleGreater,
  sampleMin,
  sampleMean,
  sampleMax
}) => {
  // Render values of the deduction.
  const n = (
    <span>
      <strong>{sampleN || 'Keine'}</strong> {mmts(sampleN)}
      {sampleN ? ', davon:' : ''}
    </span>
  );

  const less = !sampleN ? null : (
    <span>
      <strong>{sampleLess || 'Keine'}</strong> kleiner als {frequency}
    </span>
  );
  const equal = !sampleN ? null : (
    <span>
      <strong>{sampleEqual || 'Keine'}</strong> gleich {frequency}
    </span>
  );
  const greater = !sampleN ? null : (
    <span>
      <strong>{sampleGreater || 'Keine'}</strong> größer als {frequency}
    </span>
  );

  const min = !sampleMin ? null : (
    <span>
      Kleinste Messung: <strong>{sampleMin}</strong>
    </span>
  );

  const mean = !sampleMean ? null : (
    <span>
      Durchschnitt: <strong>{scoreTooltipAvgMeanFormatter.format(sampleMean)}</strong>
    </span>
  );

  const max = !sampleMax ? null : (
    <span>
      Größte Messung: <strong>{sampleMax}</strong>
    </span>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>
        <strong>{frequency}</strong> Mal {forName ? `für ${forName}` : null} eingetreten
      </span>
      <span>
        Quellcentil: <strong>{displayPercentagePrecise(quantile)}</strong>
      </span>
      <span>
        Minimum bei: <strong>{displayPercentagePrecise(qMin)}</strong>
      </span>
      <span>
        Maximum bei: <strong>{displayPercentagePrecise(qMax)}</strong>
      </span>
      <hr />
      {n}
      {less}
      {equal}
      {greater}
      {min || mean || max ? <hr /> : null}
      {min}
      {mean}
      {max}
    </div>
  );
};
NodeTooltip.propTypes = {
  forName: PropTypes.string,
  frequency: PropTypes.number,
  quantile: PropTypes.number,
  qMin: PropTypes.number,
  qMax: PropTypes.number,
  sampleN: PropTypes.number,
  sampleLess: PropTypes.number,
  sampleEqual: PropTypes.number,
  sampleGreater: PropTypes.number,
  sampleMin: PropTypes.number,
  sampleMean: PropTypes.number,
  sampleMax: PropTypes.number
};

/**
 * Displays the node content, i.e., label, value, and frequency.
 * @param text The label text.
 * @param value The value to render.
 * @param color The color.
 * @param tooltip An optional tooltip element.
 * @returns {JSX.Element}
 * @constructor
 */
const NodeContent = ({ text, value, color, tooltip }) => (
  <div className='ValueDeduction__Content' style={{ backgroundColor: color }}>
    <div className='ValueDeduction__Label'>{text}</div>
    <div className='ValueDeduction__Value'>{displayPercentage(value)}</div>
    {!tooltip ? null : (
      <div className='ValueDeduction__Content__Space'>
        <Tooltip inverted className='ValueDeduction__Content__Tooltip' content={tooltip} />
      </div>
    )}
  </div>
);
NodeContent.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  tooltip: PropTypes.node
};

/**
 * Wraps the children of a node.
 * @param children The content.
 * @returns {JSX.Element}
 * @constructor
 */
const NodeChildren = ({ children }) => <div className='ValueDeduction__Children'>{children}</div>;
NodeChildren.propTypes = {
  children: PropTypes.node
};

/**
 * Displays the value deduction.
 * @param forName The name to display for.
 * @param rootLabel The name to use for the root node.
 * @param rootNode The response to display.
 * @return {JSX.Element}
 * @constructor
 */
export const ValueDeduction = ({ forName, rootLabel, rootNode }) => {
  /**
   * Internal recursive component.
   * @return {JSX.Element}
   * @constructor
   */
  const Recursive = ({ label, weight, node }) => {
    const hasWeight = typeof weight === 'number';
    const { $type, value, ...other } = node;

    // Compute shared values.
    const text = valueDeductionTranslation[label] ?? label;
    const color = rgbaToStyle(rgbaLerp(value, rgbaLow, rgbaHigh));

    if ($type === 'WeightedSum') {
      // Convert children.
      const children = Object.entries(other).map(([key, [weight, child]]) => (
        <Recursive key={key} label={key} weight={weight} node={child} />
      ));

      // Return sum node.
      return (
        <Node>
          <NodeBody>
            {!hasWeight ? null : <NodeWeight color={color} weight={weight} />}
            <NodeContent color={color} text={text} value={value} />
          </NodeBody>
          {!children?.length ? null : <NodeChildren>{children}</NodeChildren>}
        </Node>
      );
    } else if ($type === 'Score') {
      const {
        frequency,
        quantile,
        qMin,
        qMax,
        sampleN,
        sampleLess,
        sampleEqual,
        sampleGreater,
        sampleMin,
        sampleMean,
        sampleMax
      } = other;

      // Return score node.
      return (
        <Node>
          <NodeBody weight={weight} backgroundColor={color}>
            {!hasWeight ? null : <NodeWeight color={color} weight={weight} />}
            <NodeContent
              color={color}
              text={text}
              value={value}
              tooltip={
                <NodeTooltip
                  forName={forName}
                  frequency={frequency}
                  quantile={quantile}
                  qMin={qMin}
                  qMax={qMax}
                  sampleN={sampleN}
                  sampleLess={sampleLess}
                  sampleEqual={sampleEqual}
                  sampleGreater={sampleGreater}
                  sampleMin={sampleMin}
                  sampleMean={sampleMean}
                  sampleMax={sampleMax}
                />
              }
            />
          </NodeBody>
        </Node>
      );
    } else {
      // Unknown.
      return null;
    }
  };

  // Props of the recursive element.
  Recursive.propTypes = {
    label: PropTypes.string.isRequired,
    weight: PropTypes.number,
    node: PropTypes.object.isRequired
  };

  // Check if there's a result to display.
  if (typeof rootNode.value !== 'number') {
    // Display no value.
    return <span>Zur Zeit keine Angabe möglich</span>;
  }

  // Return the root recursive element.
  return <Recursive label={rootLabel} node={rootNode} />;
};

// Prop types for value deduction.
ValueDeduction.propTypes = {
  forName: PropTypes.string,
  rootLabel: PropTypes.string.isRequired,
  rootNode: PropTypes.object.isRequired
};
