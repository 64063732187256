import React from 'react';
import PropTypes from 'prop-types';
import './Container.scss';

const Container = ({ children, flexible }) => {
  return <div className={`Container ${flexible ? 'Container--Flexible' : ''}`}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.any,
  flexible: PropTypes.bool
};

export default Container;
