import { ViewRoute } from './ViewRoute';
import CampaignDetails from '../views/CampaignDetails';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import React from 'react';

export const ViewCampaignDetails = () => (
  <ViewRoute
    loggedInComponent={<CampaignDetails />}
    loggedOutComponent={<Redirect to={Paths.Login()} />}
  />
);
