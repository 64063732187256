import { ViewRoute } from './ViewRoute';
import ResourceEdit from '../views/ResourceEdit';
import { Navigate as Redirect } from 'react-router';
import { Paths } from '../../definitions/paths';
import React from 'react';

export const ViewResourceEdit = () => (
  <ViewRoute
    loggedInComponent={<ResourceEdit />}
    loggedOutComponent={<Redirect to={Paths.Login()} />}
  />
);
