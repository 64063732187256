import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Textbox from '../atoms/input/Textbox';
import Overlay from '../atoms/Overlay';
import Button from '../atoms/Button';
import './SitePanel.scss';
import TextLink from '../atoms/TextLink';
import IconButton from '../atoms/IconButton';
import { Minus, Search } from '../../images/icons';
import Colors from '../../definitions/colors';
import { resolveAddress } from '../../api/partners';

const resolveTimeout = 1500;
const showTimeout = 200;

/**
 * Displays a partner site.
 * @param name The name of the site.
 * @param address The address of the site.
 * @return {JSX.Element}
 * @constructor
 */
const SitePanelDisplay = ({ name, address }) => (
  <div className='SitePanel'>
    <span className='SitePanel__Extend'>{name}</span>
    <span className='SitePanel__Standard'>
      <TextLink title={address} href={`https://www.google.de/maps?q=${address}`} target='_blank' />
    </span>
  </div>
);

SitePanelDisplay.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string
};

/**
 * Location selector.
 * @param finish The finish callback.
 * @return {JSX.Element}
 * @constructor
 */
const SiteAddressSelector = ({ finish }) => {
  // The address that was entered in the field.
  const [address, setAddress] = useState('');

  // The curren resolution.
  const [location, setLocation] = useState(null);

  // Bind resolution.
  useEffect(() => {
    // Reset.
    setLocation(null);

    // If criteria do not match, do not resolve.
    if (address.length < 10) return;

    // Resolve after timeout.
    const timeout = setTimeout(() => {
      // Do not immediately show.
      const timeoutShow = setTimeout(() => {
        setLocation(undefined);
      }, showTimeout);

      // Resolve, on result clear showing and set result.
      resolveAddress(address)
        .then(setLocation)
        .finally(() => clearTimeout(timeoutShow));
    }, resolveTimeout);

    // Clear timeout on unmount.
    return () => clearTimeout(timeout);
  }, [address]);

  return (
    <>
      {/* Title. */}
      <h2 className='SitePanel__OverlayTitle'>Adresse suchen</h2>

      {/* Edit panel for address. */}
      <Textbox title='Adresse' value={address} onUpdateValue={setAddress} name='address' />

      {/* Association output. */}
      <div className='SitePanel__OverlayHint'>
        <span
          className={['SitePanel__HintText', !!location && 'SitePanel__HintText--Success']
            .filter(Boolean)
            .join(' ')}
        >
          {location === undefined ? 'Wird geladen...' : null}
          {location === null ? 'Kein Treffer gefunden.' : null}
          {location ? `Adresse gefunden: ${location.normalized}` : null}
        </span>
      </div>

      {/* Complete button. */}
      <div className='SitePanel__OverlayButton'>
        <Button
          title='Bestätigen'
          disabled={!location}
          onClick={() => finish(Boolean(location), location.normalized ?? address)}
        />
      </div>
    </>
  );
};

SiteAddressSelector.propTypes = {
  finish: PropTypes.func
};

/**
 * Displays a partner site for editing.
 * @param name The name of the site.
 * @param address The address of the site.
 * @param setName Name setter.
 * @param setAddress Address setter.
 * @param onDelete Invoked on delete action
 * @param selector A component with the properties `open` for if it is displayed
 * and `finish(accept, address)` to complete and optionally accept the address.
 * @return {JSX.Element}
 * @constructor
 */
const SitePanelEdit = ({ name, address, setName, setAddress, onDelete }) => {
  // True if overlay is open.
  const [selectOpen, setSelectOpen] = useState(false);

  // Completes selection.
  const finishSelect = useCallback((accept, address) => {
    // Close and reset selection overlay.
    setSelectOpen(false);

    // If not accept, skip committing the values.
    if (accept) setAddress(address);
  }, []);

  return (
    <div className='SitePanel'>
      {/* Edit panel for name. */}
      <Textbox title='Name der Zweigstelle' value={name} onUpdateValue={setName} name='name' />

      {/* Edit panel for address. */}
      <Textbox title='Adresse' value={address} readonly name='address' />

      {/* Button opening the paste from URL overlay. */}
      <IconButton
        title='Adresse wählen'
        iconCircle={{ icon: <Search size={16} fill={Colors.White} />, size: 36 }}
        onClick={() => setSelectOpen(true)}
      />

      {/* Button for removing this site. */}
      <IconButton
        title='Entfernen'
        iconCircle={{ icon: <Minus size={16} fill={Colors.White} />, size: 36 }}
        onClick={() => onDelete()}
      />

      {/* Selector overlay. */}
      <Overlay visible={selectOpen} onClose={() => finishSelect(false, null)} big>
        {!selectOpen ? null : <SiteAddressSelector finish={finishSelect} />}
      </Overlay>
    </div>
  );
};

SitePanelEdit.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  setName: PropTypes.func,
  setAddress: PropTypes.func,
  onDelete: PropTypes.func
};
/**
 * Renders a partner site for display or edit.
 * @param edit Display or edit, true if editing.
 * @param name The name of the site.
 * @param address The address of the site.
 * @param setName Name setter.
 * @param setAddress Address setter.
 * @param onDelete Invoked on delete action
 * @return {JSX.Element}
 * @constructor
 */
export const SitePanel = ({ edit, name, address, setName, setAddress, onDelete }) =>
  edit ? (
    <SitePanelEdit
      name={name}
      address={address}
      setName={setName}
      setAddress={setAddress}
      onDelete={onDelete}
    />
  ) : (
    <SitePanelDisplay name={name} address={address} />
  );

SitePanel.propTypes = {
  // Editing.
  edit: PropTypes.bool,
  // Copy of both alternative's prop types.
  ...SitePanelDisplay.propTypes,
  ...SitePanelEdit.propTypes
};
