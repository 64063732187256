/**
 * Update modes.
 * @type {Readonly<{Idle: string, Updating: string, Initializing: string}>}
 */
export const Mode = Object.freeze({
  /**
   * Status is initializing, i.e., loading data without any present data.
   */
  Initializing: 'INITIALIZING',

  /**
   * Status is updating, i.e., defensively refreshing data.
   */
  Updating: 'UPDATING',

  /**
   * Currently no pending operation.
   */
  Idle: 'IDLE'
});
