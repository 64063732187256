import { deleteEntity, getEntity, getPage, pathManage, postEntity, putEntity } from './common';

/**
 * Retrieves the specific resource with the given ID.
 * @param id The ID of the resource.
 * @return {*} Returns a promise with the resource. Failures to get are treated as errors.
 */
export const getResource = id => getEntity(`${pathManage}/resources/${id}`);

/**
 * Creates the given resource.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param resource The resource to add.
 * @return {*} Returns a promise with the created resources. Failures to create are treated as errors.
 */
export const createResource = resource => postEntity(`${pathManage}/resources`, null, resource);

/**
 * Updates the given resource. The resource should have an ID that can be resolved remotely.
 *
 * Navigational references are given as singleton objects with just the ID of the reference to
 * resolve, as specified by the API.
 * @param resource The resource to update.
 * @return {*} Returns a promise with the updated resource. Failures to create are treated as errors.
 */
export const updateResource = resource => putEntity(`${pathManage}/resources`, null, resource);

/**
 * Deletes the resource with the given ID.
 * @param id The ID of the resource.
 * @return {*} Returns a promise. Failures to delete are treated as errors.
 */
export const deleteResource = id => deleteEntity(`${pathManage}/resources/${id}`);

/**
 * Gets the raw resources in paging operation.
 * @param page The page to retrieve.
 * @param query The query to search for.
 * @return {*} Returns a promise on the page. Failures to enumerate are treated as errors.
 */
export const getResources = (page, query) =>
  getPage(`${pathManage}/resources`, [['page', page], ...query]);
