import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Calendar, Close } from '../../../images/icons';
import Colors from '../../../definitions/colors';
import CircleIcon from '../CircleIcon';
import Tooltip from '../Tooltip';
import './DatePicker.scss';
import { ICON_AFTER_POSITION } from 'react-dates/constants';

const DatePicker = ({
  date,
  disabled,
  min,
  max,
  name,
  onUpdateDate,
  endOfDay,
  title,
  tooltip,
  showLabel
}) => {
  moment.locale('de');
  const value = date ? moment(date) : null;
  const [focused, setFocused] = useState(false);

  const dateIsAllowed = day =>
    (!min || moment(min).isSameOrBefore(day, 'day')) &&
    (!max || moment(max).isSameOrAfter(day, 'day'));

  const onChange = newValue => {
    newValue
      ? onUpdateDate(
          newValue
            .set('hour', endOfDay ? 23 : 0)
            .set('minute', endOfDay ? 59 : 0)
            .set('second', endOfDay ? 59 : 0)
            .format()
        )
      : onUpdateDate(null);
  };

  const validation = !value || dateIsAllowed(value) ? true : 'Datum prüfen!';

  return (
    <div className='DatePicker'>
      {showLabel && (
        <div className='DatePicker__Label'>
          <span>{title}</span>
          {tooltip && <Tooltip content={tooltip} />}
        </div>
      )}
      <SingleDatePicker
        disabled={disabled}
        date={value}
        onDateChange={onChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={name}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        placeholder={disabled ? 'Nicht möglich' : 'Kein Datum'}
        showClearDate
        displayFormat='DD.MM.YYYY'
        noBorder
        customInputIcon={
          <CircleIcon icon={<Calendar fill={Colors.White} size={16} />} offsetY={-1} size={28} />
        }
        customCloseIcon={<Close fill={Colors.Orange} size={14} />}
        inputIconPosition={ICON_AFTER_POSITION}
        isOutsideRange={day => !dateIsAllowed(day)}
        isDayHighlighted={day => moment().isSame(day, 'day')}
      />
      <div
        className={`DatePicker__Validation ${
          validation !== true ? 'DatePicker__Validation--Visible' : ''
        }`}
      >
        {validation}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onUpdateDate: PropTypes.func.isRequired,
  endOfDay: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  showLabel: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string
};

export default DatePicker;
