import React, { useEffect } from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import {
  Archive,
  Article,
  Chart,
  Close,
  Edit,
  Euro,
  Eye,
  Form,
  NoEye,
  Qr,
  Plus,
  Duplicate
} from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './OfferDetails.scss';
import DisplayGroup from '../atoms/DisplayGroup';
import EditsProtocol from '../modules/EditsProtocol';
import CircleIcon from '../atoms/CircleIcon';
import Colors from '../../definitions/colors';
import TextLink from '../atoms/TextLink';
import TextRenderer from '../layout/TextRenderer';
import ImagePreview from '../modules/ImagePreview';
import {
  buildFormAliasUrl,
  displayAddress,
  displayDate,
  displayPriority,
  sanitizePhoneUrl,
  sanitizeWebUrl
} from '../../helpers/formatters';
import FormRow from '../atoms/FormRow';
import ToggleSwitch from '../atoms/input/ToggleSwitch';
import MultiPicker from '../atoms/input/MultiPicker';
import PageTitle from '../../helpers/pageTitle';
import { remoteAction, useEntityView, useName } from './common';
import {
  useEdits,
  useListingOfferInventory,
  useListingOfferPreviews,
  useOffer,
  usePartnerSites
} from '../../hooks/useRemoteValue';
import LoadingOverlay from '../atoms/LoadingOverlay';
import {
  useCampaignsBy,
  useInterestsAll,
  useInternalCategoriesAll,
  usePartnersBy
} from '../../hooks/useResolve';
import { deleteOffer, updateOfferStage } from '../../api/offers';
import {
  isArchived,
  isPreRelease,
  isReleased,
  stageArchived,
  stageDraft,
  stageReleased
} from '../../helpers/entities';
import PreviewLink from '../atoms/PreviewLink';
import { createPreview, deletePreview } from '../../api/previews';

// TODO: Temporary or move
const nameOfExternalMGProperty = key =>
  ({
    couponId: 'Externe ID',
    category: 'Externe Kategorie',
    openingTimes: 'Öffnungszeiten',
    email: 'Email',
    validDaysValue: 'Gültige Tage',
    isTwoForOne: 'Zwei für Eins',
    isSingle: 'Single-Angebot',
    isFamily: 'Family-Angebot',
    isFriends: 'Friends-Angebot',
    isDelivery: 'Gültig bei Lieferung',
    isTakeout: 'Gültig bei Abholung',
    flags: 'Spezielle eigenschaften',
    specialties: 'Spezialitäten',
    badge: 'MobileGutscheine Badge'
  }[key]);

// TODO: Temporary or move
const renderExternalMGProperty = (key, value) => {
  if (key === 'couponId') return value.toString();
  if (key === 'category')
    return [
      null,
      'Hotel',
      'Shopping',
      'Wellness',
      'Travel',
      'Leisure',
      'Restaurant',
      'Service Provider',
      'Clothing',
      'Electronic',
      'Fast-food Restaurant',
      'Breakfast',
      'Cafe',
      'Cocktails',
      'Lunch Offer',
      'Fitness'
    ][value];
  if (key === 'openingTimes') return value.toString();
  if (key === 'email') return value ?? 'Nicht hinterlegt';
  if (key === 'email') return value ?? 'Nicht hinterlegt';
  if (key === 'validDaysValue')
    return value.map(item => ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][item]).join(', ');
  if (key === 'isTwoForOne') return value ? 'Ja' : 'Nein';
  if (key === 'isSingle') return value ? 'Ja' : 'Nein';
  if (key === 'isFamily') return value ? 'Ja' : 'Nein';
  if (key === 'isFriends') return value ? 'Ja' : 'Nein';
  if (key === 'isDelivery') return value ? 'Ja' : 'Nein';
  if (key === 'isTakeout') return value ? 'Ja' : 'Nein';
  if (key === 'flags') return value.replaceAll(',', ', ');
  if (key === 'specialties') return value === '' ? undefined : value;
  if (key === 'badge') return value;
  return undefined;
};

const OfferDetails = () => {
  // Select state values. TODO: We could also display shopify name via resolution.
  const id = useIdParam();
  const { entity, loadingEntity, reload } = useEntityView(useOffer, id);
  const { name: campaignName } = useName(useCampaignsBy, entity?.campaign?.id);
  const { name: partnerName } = useName(usePartnersBy, entity?.partner?.id);
  const [edits, , loadingEdits, , , reloadEdits] = useEdits(id);
  const [previews, , loadingPreviews, , , reloadPreviews] = useListingOfferPreviews(id);
  const [inventory, , loadingInventory, , , reloadInventory] = useListingOfferInventory(id);
  const [availableSites, , loadingSites] = usePartnerSites(entity?.partner?.id);

  // Loading state is combination of associated loading states.
  const loading =
    loadingEntity || loadingEdits || loadingPreviews || loadingInventory || loadingSites;

  const [categories] = useInterestsAll();
  const categoriesSelected = entity?.categories?.map(category => category.name);

  const [internalCategories] = useInternalCategoriesAll();
  const internalCategoriesSelected = entity?.internalCategories?.map(category => category.name);

  const buttonWebsiteUsedName =
    entity?.definition?.qrCode?.buttonWebsiteUsedName ||
    entity?.definition?.shopify?.buttonWebsiteUsedName ||
    entity?.definition?.article?.buttonWebsiteUsedName ||
    entity?.definition?.form?.buttonWebsiteUsedName;
  const buttonRedeemUsedName =
    entity?.definition?.qrCode?.buttonRedeemUsedName ||
    entity?.definition?.shopify?.buttonRedeemUsedName ||
    entity?.definition?.form?.buttonRedeemUsedName;

  const sites = entity?.sites;
  const geoUnrestricted = entity?.geoUnrestricted;

  // Deletes the currently viewed offer.
  const handleDelete = remoteAction({
    action: async () => deleteOffer(id),
    navigateSuccess: Paths.Campaigns(),
    messageConfirm: 'Bitte bestätigen Sie das Löschen des Vorteils.',
    messageSuccess: 'Vorteil gelöscht.',
    messageFail: 'Fehler beim Löschen.'
  });

  // Archives or unarchives the currently viewed offer.
  const handleChangeArchival = remoteAction({
    action: async () => {
      if (isArchived(entity)) {
        await updateOfferStage(id, stageDraft);
        reload();
        reloadEdits();
      } else {
        await updateOfferStage(id, stageArchived);
        reload();
        reloadEdits();
      }
    },
    messageSuccess: isArchived(entity) ? 'Vorteil aus dem Archiv geholt.' : 'Vorteil archiviert.',
    messageFail: isArchived(entity)
      ? 'Fehler beim aus dem Archiv holen.'
      : 'Fehler beim Archivieren.'
  });

  // Changes the stage of the offer.
  const handleChangeMode = remoteAction({
    action: async () => {
      if (isPreRelease(entity)) {
        await updateOfferStage(id, stageReleased);
        reload();
        reloadEdits();
      } else if (isReleased(entity)) {
        await updateOfferStage(id, stageDraft);
        reload();
        reloadEdits();
      }
    }
  });

  // TODO: Type discrimination like edit variant.

  // Preview link ops.
  const handleCreatePreview = remoteAction({
    action: async () => {
      await createPreview({ target: { id } });
      reloadPreviews();
    },
    messageSuccess: 'Der Vorschaulink wurde erstellt.',
    messageFail: 'Fehler beim Löschen des Vorschaulinks.'
  });
  const handleDeletePreview = remoteAction({
    action: async id => {
      await deletePreview(id);
      reloadPreviews();
    },
    messageSuccess: 'Der Vorschaulink wurde entfernt und ist nicht länger nutzbar.',
    messageFail: 'Fehler beim Entfernen des Vorschaulinks.'
  });

  // Reload inventory value every minute. Not connected to any edits in our model.
  useEffect(() => {
    const handle = setInterval(reloadInventory, 60 * 1000);
    return () => clearInterval(handle);
  });

  // Render specific properties
  let type = null;
  let conditions = null;
  if (entity?.definition?.qrCode) {
    type = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<Qr size={14} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel'>
          {entity.definition.qrCode.limit
            ? `QR-Code, bis zu ${entity.definition.qrCode.limit} Einlösungen pro Kunde`
            : 'QR-Code, unlimitierte Einlösungen'}
        </span>
      </div>
    );
    conditions = entity.definition.qrCode.condition;
  } else if (entity?.definition?.shopify) {
    const inventoryCheckedLabel = entity.definition.shopify.inventoryChecked ? 'mit' : 'ohne';
    const inventoryValue =
      typeof inventory?.inventory === 'number' ? ` (${inventory.inventory} verfügbar)` : '';
    type = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<Euro size={12} fill={Colors.White} />} offsetX={-1} size={28} />
        <span className='OfferDetails__StatusLabel'>
          {entity.definition.shopify.limit
            ? `Shopify-Direktkauf ${inventoryCheckedLabel} Bestandsprüfung${inventoryValue}, bis zu ${entity.definition.shopify.limit} Einlösungen pro Kunde`
            : `Shopify-Direktkauf ${inventoryCheckedLabel} Bestandsprüfung${inventoryValue}, unlimitierte Einlösungen`}
        </span>
      </div>
    );
    conditions = entity.definition.shopify.condition;
  } else if (entity?.definition?.article) {
    type = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<Article size={14} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel'>Info-Artikel</span>
      </div>
    );
  } else if (entity?.definition?.form) {
    type = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<Form size={16} fill={Colors.White} />} size={28} offsetX={1} />
        <span className='OfferDetails__StatusLabel'>
          {entity.definition.form.limit
            ? `Web-Formular, bis zu ${entity.definition.form.limit} Einlösungen pro Kunde`
            : 'Web-Formular, unlimitierte Einlösungen'}
        </span>
      </div>
    );
    conditions = entity.definition.form.condition;
  } else if (entity?.definition?.externalMG) {
    type = (
      <div className='OfferDetails__Status'>
        {/* TODO: Icon */}
        <CircleIcon icon={<Article size={14} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel'>MobileGutscheine</span>
      </div>
    );
  }

  // Render general complex properties
  let timeframe = 'Dauerhafter Vorteil';
  if (entity?.validFrom && entity?.validUntil) {
    timeframe = `Gültig von ${displayDate(entity.validFrom)} bis ${displayDate(entity.validUntil)}`;
  } else if (entity?.validFrom && !entity?.validUntil) {
    timeframe = `Gültig ab ${displayDate(entity.validFrom)}`;
  } else if (!entity?.validFrom && entity?.validUntil) {
    timeframe = `Gültig bis ${displayDate(entity.validUntil)}`;
  }
  if (entity?.visibleFrom) {
    timeframe = `${timeframe}, Vorschau ab ${displayDate(entity.visibleFrom)}`;
  }

  let statusComponent;
  if (entity?.isActive)
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<Eye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel'>Dieser Vorteil ist aktuell aktiv.</span>
      </div>
    );
  else if (isArchived(entity))
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel OfferDetails__StatusLabel--Inactive'>
          Dieser Vorteil ist archiviert.
        </span>
      </div>
    );
  else
    statusComponent = (
      <div className='OfferDetails__Status'>
        <CircleIcon icon={<NoEye size={20} fill={Colors.White} />} size={28} />
        <span className='OfferDetails__StatusLabel OfferDetails__StatusLabel--Inactive'>
          Dieser Vorteil ist aktuell nicht aktiv.
        </span>
      </div>
    );

  return (
    <main className='OfferDetails'>
      <LoadingOverlay show={loading} />
      <PageTitle title={`Vorteil: ${entity?.title}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title={entity?.title}
            backButton={{ to: Paths.Campaigns(), label: 'Kampagnen & Vorteile' }}
          />
          <Toolbar
            left={[
              <Button
                key='evaluate'
                title='Auswerten'
                icon={Chart}
                iconSize={18}
                variant='ORANGE_OUTLINE'
                to={Paths.OfferStatistics(entity?.id)}
              />,
              !isArchived(entity) && (
                <Button
                  key='edit'
                  title='Bearbeiten'
                  icon={Edit}
                  iconSize={17}
                  to={Paths.OfferEdit(entity?.id)}
                />
              ),
              <Button
                key='duplicate'
                title={'Duplizieren'}
                icon={Duplicate}
                iconSize={18}
                autoWidth={true}
                variant='ORANGE_OUTLINE'
                to={Paths.OfferDuplicate(entity?.id)}
              />
            ].filter(Boolean)}
            right={[
              <Button
                key='archival'
                title={isArchived(entity) ? 'Aus dem Archiv holen' : 'Archivieren'}
                icon={Archive}
                iconSize={18}
                autoWidth={true}
                variant='ORANGE_OUTLINE'
                onClick={handleChangeArchival}
              />,
              <Button
                key='delete'
                title='Löschen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                onClick={handleDelete}
              />
            ].filter(Boolean)}
          />
          <DisplayGroup label='Kampagne'>
            {entity?.campaign ? (
              <TextLink title={campaignName} to={Paths.CampaignDetails(entity.campaign.id)} />
            ) : (
              'Keine Kampagne zugeordnet'
            )}
          </DisplayGroup>
          <DisplayGroup label='Status'>
            <FormRow spaceBetween>
              {statusComponent}
              {isArchived(entity) ? null : (
                <ToggleSwitch
                  onUpdateValue={handleChangeMode}
                  value={isReleased(entity)}
                  labelOff='Entwurf'
                  labelOn='Aktiv'
                />
              )}
            </FormRow>
          </DisplayGroup>
          {type && <DisplayGroup label='Vorteilsart'>{type}</DisplayGroup>}
          <DisplayGroup label='Kategorien'>
            <MultiPicker
              options={categories?.map(category => ({
                key: category.name,
                label: category.label
              }))}
              pickedKeys={categoriesSelected}
            />
          </DisplayGroup>
          <DisplayGroup label='Interne Kategorien'>
            <MultiPicker
              options={internalCategories?.map(category => ({
                key: category.name,
                label: category.label
              }))}
              pickedKeys={internalCategoriesSelected}
            />
          </DisplayGroup>
          <DisplayGroup label='Zeitraum'>{timeframe}</DisplayGroup>
          <DisplayGroup label='Partner'>
            {entity?.partner ? (
              <TextLink title={partnerName} to={Paths.PartnerDetails(entity.partner.id)} />
            ) : (
              'Kein Partner zugeordnet'
            )}
          </DisplayGroup>

          <DisplayGroup label='Ortsbindung'>
            {geoUnrestricted ? 'Keine Ortsbindung' : 'Ortsgebunden'}
          </DisplayGroup>

          {geoUnrestricted || !availableSites?.length ? null : (
            <DisplayGroup label='Zweigstellen'>
              {!sites?.length ? (
                'In allen Zweigstellen verfügbar'
              ) : (
                <MultiPicker
                  options={availableSites?.map(site => ({
                    key: site.id,
                    label: (
                      <>
                        <span className='OfferDetails__SiteName'>{site.name}</span>
                        {!site.address ? null : (
                          <span className='OfferDetails__SiteAddress'>{site.address}</span>
                        )}
                      </>
                    )
                  }))}
                  pickedKeys={sites}
                />
              )}
            </DisplayGroup>
          )}

          <DisplayGroup
            label='Priorisierung'
            tooltip='Die Priorisierung bestimmt, wie demnächst sichtbare Vorteile mit gleicher Startzeit untereinander sortiert werden.'
          >
            {displayPriority(entity)}
          </DisplayGroup>
          <DisplayGroup
            label='Vorschaulinks'
            tooltip='Über Vorschaulinks können die Inhalte dieses Vorteils mit externen Personen abgestimmt werden. Hierfür muss der Vorteil nicht öffentlich sichtbar sein.'
          >
            {previews?.map(link => (
              <PreviewLink key={link.id} link={link} onDelete={handleDeletePreview} />
            ))}
            <div className='OfferDetails__PreviewLinkCreate'>
              <Button
                title='Neuer Vorschaulink'
                icon={Plus}
                iconSize={15}
                onClick={handleCreatePreview}
              />
            </div>
          </DisplayGroup>

          <h1 className='OfferDetails__Section'>Inhalte</h1>
          <DisplayGroup label='Titel'>{entity?.title}</DisplayGroup>
          <DisplayGroup label='Kurzbeschreibung'>{entity?.excerpt}</DisplayGroup>
          {(entity?.definition?.qrCode ||
            entity?.definition?.shopify ||
            entity?.definition?.form ||
            entity?.definition?.externalMG) && (
            <>
              <DisplayGroup label='Vorteilsbezeichnung'>{entity?.description}</DisplayGroup>
            </>
          )}
          {!buttonWebsiteUsedName ? null : (
            <DisplayGroup
              label='Website-Button'
              tooltip='Der Text, mit dem der Standardtext überschreiben wird, der im Button auf der öffentlichen Website angezeigt wird. Der Button führt je nach Gerät zur evm-App oder zum evm-Kundenportal.'
            >
              {buttonWebsiteUsedName}
            </DisplayGroup>
          )}

          {!buttonRedeemUsedName ? null : (
            <DisplayGroup
              label='Einlösen-Button'
              tooltip='Der Text, mit dem der Standardtext überschreiben wird, der im Button zur Einlösung des Vorteils angezeigt wird.'
            >
              {buttonRedeemUsedName}
            </DisplayGroup>
          )}
          {(entity?.definition?.qrCode ||
            entity?.definition?.shopify ||
            entity?.definition?.form ||
            entity?.definition?.externalMG) && (
            <>
              <DisplayGroup label='Langbeschreibung'>
                <TextRenderer content={entity?.descriptionLong} />
              </DisplayGroup>
            </>
          )}
          {entity?.definition?.article?.teaser && (
            <DisplayGroup label='Teaser'>
              <TextRenderer content={entity?.definition?.article?.teaser} />
            </DisplayGroup>
          )}
          {entity?.definition?.article && (
            <DisplayGroup label='Inhalt des Artikels'>
              <TextRenderer content={entity?.definition?.article?.content} />
            </DisplayGroup>
          )}
          {conditions && (
            <DisplayGroup label='Bedingungen'>
              <TextRenderer content={conditions} />
            </DisplayGroup>
          )}
          {entity?.definition?.form && (
            <DisplayGroup
              label='Ziel-URL'
              tooltip='Auf diese URL werden Kunden weitergeleitet, wenn sie den Vorteil einlösen wollen.'
            >
              <TextLink
                href={buildFormAliasUrl(entity?.definition?.form?.target, '0000000000')}
                title={buildFormAliasUrl(entity?.definition?.form?.target, '0000000000')}
                target='_blank'
              />
            </DisplayGroup>
          )}
          <DisplayGroup label='Bilder'>
            <ImagePreview height={90} width={160} images={entity?.images} cover />
          </DisplayGroup>

          {!entity?.definition?.externalMG ? null : (
            <>
              <h1 className='OfferDetails__Section'>MobileGutscheine Eigenschaften</h1>
              {Object.entries(entity.definition.externalMG).map(([key, value]) => {
                const label = nameOfExternalMGProperty(key);
                const children = renderExternalMGProperty(key, value);
                return label === undefined || children === undefined ? null : (
                  <DisplayGroup key={key} label={label}>
                    {children}
                  </DisplayGroup>
                );
              })}
            </>
          )}

          <h1 className='OfferDetails__Section'>Kontaktdaten</h1>
          <DisplayGroup label='Adresse'>
            {entity?.address ? displayAddress(entity?.address) : 'Keine Adresse'}
          </DisplayGroup>
          <DisplayGroup label='Website'>
            {entity?.infoWebsite ? (
              <a
                href={sanitizeWebUrl(entity.infoWebsite)}
                title={entity.infoWebsite}
                target='_blank'
                rel='noopener noreferrer'
              >
                {entity.infoWebsite}
              </a>
            ) : (
              'Keine Website'
            )}
          </DisplayGroup>
          <DisplayGroup label='Telefon'>
            {entity?.infoPhone ? (
              <a href={sanitizePhoneUrl(entity.infoPhone)} title={entity.infoPhone}>
                {entity.infoPhone}
              </a>
            ) : (
              'Keine Telefonnummer'
            )}
          </DisplayGroup>

          <EditsProtocol edits={edits} />
        </Container>
      )}
    </main>
  );
};

export default OfferDetails;
