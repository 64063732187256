import React from 'react';
import Container from '../layout/Container';
import Button from '../atoms/Button';
import Toolbar from '../modules/Toolbar';
import { Close, Tick } from '../../images/icons';
import { Paths } from '../../definitions/paths';
import useIdParam from '../../hooks/useIdParam';
import './CampaignEdit.scss';
import DisplayGroup from '../atoms/DisplayGroup';
import Textbox from '../atoms/input/Textbox';
import Dropdown from '../atoms/input/Dropdown';
import SingleImageManager from '../modules/SingleImageManager';
import PageTitle from '../../helpers/pageTitle';
import { useCampaign } from '../../hooks/useRemoteValue';
import LoadingOverlay from '../atoms/LoadingOverlay';
import { createCampaign, updateCampaign } from '../../api/campaigns';
import { remoteAction, useEntityEdit } from './common';

const init = () => ({});

const CampaignEdit = () => {
  // Get basic view parameters and objects.
  const id = useIdParam();

  const colorOptions = [
    { value: 1, label: 'Schwarz' },
    { value: 0, label: 'Weiß' }
  ];

  // Use campaign of ID. Connect amend function that merges the incoming changes. Get changed status.
  const { isNew, entity, loading, amendEntity, changed } = useEntityEdit(useCampaign, id, { init });

  // State values
  const title = entity?.title;
  const setTitle = value => amendEntity({ title: value });
  const excerpt = entity?.excerpt;
  const setExcerpt = value => amendEntity({ excerpt: value });
  const header = entity?.header;
  const setHeader = value => amendEntity({ header: value });
  const color = entity?.textDisplay;
  const setColor = value => amendEntity({ textDisplay: value });

  // Submits the create or the change.
  const submit = isNew
    ? remoteAction({
        action: async () => createCampaign(entity),
        navigateSuccess: ({ id }) => Paths.CampaignDetails(id),
        messageSuccess: 'Kampagne erstellt.',
        messageFail: 'Fehler beim Erstellen.'
      })
    : remoteAction({
        action: async () => updateCampaign(entity),
        navigateSuccess: ({ id }) => Paths.CampaignDetails(id),
        messageSuccess: 'Änderungen gespeichert.',
        messageFail: 'Fehler beim Ändern.'
      });

  return (
    <main className='CampaignEdit'>
      <LoadingOverlay show={loading} />
      <PageTitle title={isNew ? 'Kampagne erstellen' : `Kampagne bearbeiten: ${entity?.title}`} />
      {!entity ? null : (
        <Container>
          <Toolbar
            title={isNew ? 'Kampagne erstellen' : 'Kampagne bearbeiten'}
            backButton={{
              to: isNew ? Paths.Campaigns() : Paths.CampaignDetails(entity.id),
              label: isNew ? 'Kampagnen & Vorteile' : entity.title
            }}
            right={[
              <Button
                key={1}
                title='Abbrechen'
                icon={Close}
                iconSize={12}
                variant='ORANGE_OUTLINE'
                to={isNew ? Paths.Campaigns() : Paths.CampaignDetails(entity.id)}
              />,
              <Button
                key={2}
                title={isNew ? 'Kampagne speichern' : 'Änderungen speichern'}
                icon={Tick}
                iconSize={17}
                disabled={!changed}
                onClick={submit}
              />
            ]}
          />

          <DisplayGroup
            label='Titel*'
            tooltip='Der Name wird häufig in Verbindung mit den zugeordneten Vorteilen eingeblendet.'
          >
            <Textbox
              title='Titel'
              name='title'
              value={title}
              onUpdateValue={setTitle}
              required
              showLabel={false}
            />
          </DisplayGroup>
          <DisplayGroup
            label='Kurzbeschreibung*'
            tooltip='Die Kurzbeschreibung wird unterhalb des Titels auf der Kampagnenseite eingeblendet.'
          >
            <Textbox
              onUpdateValue={setExcerpt}
              name='excerpt'
              title='Kurzbeschreibung'
              value={excerpt}
              isTextarea
              required
              showLabel={false}
            />
          </DisplayGroup>
          <DisplayGroup
            label='Titelbild'
            tooltip='Laden Sie hier das optionale Titelbild für die Kampagnenseite hoch. Empfohlen wird ein Seitenverhältnis von 16:9.'
          >
            <SingleImageManager
              image={header}
              cover={true}
              onUpdateImage={setHeader}
              width={320}
              height={180}
            />
          </DisplayGroup>
          <DisplayGroup
            label='Textfarbe*'
            tooltip='Legen Sie fest, ob Titel und Kurzbeschreibung auf dem Titelbild in Weiß oder Schwarz angezeigt werden sollen.'
          >
            <Dropdown
              title='Textfarbe'
              value={color}
              onUpdateValue={setColor}
              options={colorOptions}
            />
          </DisplayGroup>
        </Container>
      )}
    </main>
  );
};

export default CampaignEdit;
