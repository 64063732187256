import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../../definitions/colors';
import './TableHead.scss';

/**
 * Converts API sorting given in input into table sorting with the given name being checked.
 * @param name The name without  prefixes.
 * @param input The API sort.
 * @return {'ASC'|'DESC'|null}
 */
export const fromApiSort = (name, input) => {
  if (input === name || input === `+${name}`) return 'ASC';
  else if (input === `-${name}`) return 'DESC';
  else return null;
};

const TableHead = ({
  align,
  currentSorting,
  label,
  onSortAsc,
  onSortDesc,
  onSortReset,
  slim,
  width
}) => {
  const sortable = onSortAsc || onSortDesc || onSortReset;
  const toggleSorting = () => {
    if (!sortable) {
      return;
    }
    switch (currentSorting) {
      case null:
        onSortAsc();
        break;
      case 'ASC':
        onSortDesc();
        break;
      default:
        onSortReset();
    }
  };

  return (
    <th
      className={`
        TableHead
        TableHead--${align}
        ${sortable ? 'TableHead--Sortable' : ''}
        ${slim ? 'TableHead--Slim' : ''}
      `}
      style={{ width: `${width}%` }}
      onClick={toggleSorting}
    >
      <div className='TableHead__Label'>{label}</div>
      {(sortable || currentSorting) && (
        <svg version='1.1' viewBox='0 0 11.9 18.7' className='TableHead__SortIndicator'>
          <path
            fill={currentSorting === 'ASC' ? Colors.Orange : Colors.GrayLight}
            d='M1,7.4c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L5.9,0l5.7,5.7c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0
	L5.9,2.8L1.7,7.1C1.5,7.3,1.3,7.4,1,7.4z'
          />
          <path
            fill={currentSorting === 'DESC' ? Colors.Orange : Colors.GrayLight}
            d='M5.9,18.7l-5.7-5.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4.2,4.2l4.2-4.2c0.4-0.4,1-0.4,1.4,0
	s0.4,1,0,1.4L5.9,18.7z'
          />
        </svg>
      )}
    </th>
  );
};

TableHead.propTypes = {
  align: PropTypes.oneOf(['LEFT', 'CENTER', 'RIGHT']).isRequired,
  currentSorting: PropTypes.oneOf([null, 'ASC', 'DESC']),
  label: PropTypes.string,
  onSortAsc: PropTypes.func,
  onSortDesc: PropTypes.func,
  onSortReset: PropTypes.func,
  slim: PropTypes.bool,
  width: PropTypes.number
};

TableHead.defaultProps = {
  align: 'LEFT'
};

export default TableHead;
