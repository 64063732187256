import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import { Chart, Info } from '../../images/icons';
import Colors from '../../definitions/colors';
import './DashboardTileItem.scss';

const DashboardTileItem = ({ alternate, detailsUrl, label, statisticsUrl, subLabel }) => {
  return (
    <div className={`DashboardTileItem ${alternate ? 'DashboardTileItem--Alternate' : ''}`}>
      <div className='DashboardTileItem__Text'>
        <div className='DashboardTileItem__Label'>{label}</div>
        {subLabel && <div className='DashboardTileItem__SubLabel'>{subLabel}</div>}
      </div>
      <div className='DashboardTileItem__Buttons'>
        {statisticsUrl && (
          <IconButton
            title='Zur Auswertung'
            to={statisticsUrl}
            iconCircle={{ icon: <Chart fill={Colors.White} size={16} />, size: 28 }}
          />
        )}
        {detailsUrl && (
          <IconButton
            title='Details anzeigen'
            to={detailsUrl}
            iconCircle={{ icon: <Info fill={Colors.White} size={6} />, size: 28 }}
          />
        )}
      </div>
    </div>
  );
};

DashboardTileItem.propTypes = {
  alternate: PropTypes.bool,
  detailsUrl: PropTypes.string,
  label: PropTypes.string.isRequired,
  statisticsUrl: PropTypes.string,
  subLabel: PropTypes.string
};

export default DashboardTileItem;
