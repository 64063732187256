import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPreviewPublic } from '../../api/previews';
import Colors from '../../definitions/colors';
import {
  displayAddress,
  displayDate,
  sanitizePhoneUrl,
  sanitizeWebUrl
} from '../../helpers/formatters';
import PageTitle from '../../helpers/pageTitle';
import { Article, Euro, Eye, Qr } from '../../images/icons';
import ebLogo from '../../images/logo-eb.svg';
import evmLogo from '../../images/logo.svg';
import CircleIcon from '../atoms/CircleIcon';
import DisplayGroup from '../atoms/DisplayGroup';
import LoadingOverlay from '../atoms/LoadingOverlay';
import Container from '../layout/Container';
import TextRenderer from '../layout/TextRenderer';
import ImagePreview from '../modules/ImagePreview';
import './OfferPreview.scss';

const OfferPreview = () => {
  const { alias } = useParams();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const loading = !data && !error;

  useEffect(() => {
    getPreviewPublic(alias).then(setData, setError);
  }, [alias]);

  // Render specific properties
  let type = null;
  let conditions = null;
  if (data?.definition?.qrCode) {
    type = (
      <div className='OfferPreview__Type'>
        <CircleIcon icon={<Qr size={14} fill={Colors.White} />} size={28} />
        <span className='OfferPreview__TypeLabel'>
          {data.definition.qrCode.limit
            ? `QR-Code, bis zu ${data.definition.qrCode.limit} Einlösungen pro Kunde`
            : 'QR-Code, unlimitierte Einlösungen'}
        </span>
      </div>
    );
    conditions = data.definition.qrCode.condition;
  } else if (data?.definition?.shopify) {
    type = (
      <div className='OfferPreview__Type'>
        <CircleIcon icon={<Euro size={12} fill={Colors.White} />} offsetX={-1} size={28} />
        <span className='OfferPreview__TypeLabel'>
          {data.definition.shopify.limit
            ? `Shopify-Direktkauf, bis zu ${data.definition.shopify.limit} Einlösungen pro Kunde`
            : 'Shopify-Direktkauf, unlimitierte Einlösungen'}
        </span>
      </div>
    );
    conditions = data.definition.shopify.condition;
  } else if (data?.definition?.article) {
    type = (
      <div className='OfferPreview__Type'>
        <CircleIcon icon={<Article size={14} fill={Colors.White} />} size={28} />
        <span className='OfferPreview__TypeLabel'>Info-Artikel</span>
      </div>
    );
  }

  let timeframe = 'Dauerhafter Vorteil';
  if (data?.validFrom && data?.validUntil) {
    timeframe = `Gültig von ${displayDate(data.validFrom)} bis ${displayDate(data.validUntil)}`;
  } else if (data?.validFrom && !data?.validUntil) {
    timeframe = `Gültig ab ${displayDate(data.validFrom)}`;
  } else if (!data?.validFrom && data?.validUntil) {
    timeframe = `Gültig bis ${displayDate(data.validUntil)}`;
  }
  if (data?.visibleFrom) {
    timeframe = `${timeframe}, Vorschau ab ${displayDate(data.visibleFrom)}`;
  }

  return (
    <main className='OfferPreview'>
      <LoadingOverlay show={loading} />
      <Container flexible>
        {error && (
          <div className='OfferPreview__Error'>
            <PageTitle title={'Vorschaulink nicht gültig'} cmsContext={false} />
            <h2 className='OfferPreview__ErrorTitle'>Hoppla, etwas hat nicht funktioniert.</h2>
            <p className='OfferPreview__ErrorText'>
              Der von Ihnen aufgerufene Vorschaulink scheint nicht mehr gültig zu sein.
              <br />
              Bitte kontaktieren Sie die Person, die Ihnen den Link zur Verfügung gestellt hat, um
              das Problem zu lösen.
            </p>
          </div>
        )}
        {data && (
          <div className='OfferPreview__Data'>
            <PageTitle title={`Vorschau: ${data.title}`} cmsContext={false} />
            <div className='OfferPreview__Header'>
              <img
                src={evmLogo}
                alt='Energieversorgung Mittelrhein'
                className='OfferPreview__Logo'
              />
              <img src={ebLogo} alt='evm Entdecker-Bonus' className='OfferPreview__Logo' />
            </div>
            <div className='OfferPreview__Intro'>
              <CircleIcon
                className='OfferPreview__IntroIcon'
                icon={<Eye fill={Colors.White} size={24} />}
              />
              <div className='OfferPreview__IntroContent'>
                <h3 className='OfferPreview__IntroHeadline'>Sie befinden sich im Vorschaumodus</h3>
                <p className='OfferPreview__IntroText'>
                  Sie sehen eine geschützte Vorschau dieses Vorteils. In dieser Vorschau können Sie
                  alle Inhalte überprüfen, die bei Veröffentlichung angezeigt werden.
                </p>
              </div>
            </div>
            <h1 className='OfferPreview__Title'>{data.title}</h1>
            {type && <DisplayGroup label='Vorteilsart'>{type}</DisplayGroup>}
            <DisplayGroup
              label='Zeitraum'
              tooltip='Bestimmt den Zeitraum, in dem der Vorteil im Entdecker-Bonus angezeigt wird und eine Einlösung möglich ist.'
            >
              {timeframe}
            </DisplayGroup>
            <DisplayGroup
              label='Kurzbeschreibung'
              tooltip='Die Kurzbeschreibung wird sowohl in der Kachelübersicht als auch auf den Detailseiten prominent angezeigt.'
            >
              {data.excerpt}
            </DisplayGroup>
            {(data.definition?.qrCode || data.definition?.shopify) && (
              <>
                <DisplayGroup
                  label='Vorteilsbezeichnung'
                  tooltip='Die Vorteilsbezeichnung ist eine kleine Zusammenfassung des Vorteils, der sich für den Kunden ergibt.'
                >
                  {data.description}
                </DisplayGroup>
              </>
            )}
            <DisplayGroup
              label='Bilder'
              tooltip='Das erste Bild ist das Hauptbild, welches dem Kunden standardmäßig angezeigt wird.'
            >
              <ImagePreview height={170} width={270} images={data.images} cover />
            </DisplayGroup>
            {conditions && (
              <DisplayGroup
                label='Bedingungen'
                tooltip='Bedingungen zur Einlösung dieses Vorteils. Die Bedingungen werden während der Einlösung zur Kontrolle auch in der Partner-App eingeblendet.'
              >
                <TextRenderer content={conditions} />
              </DisplayGroup>
            )}
            {(data.definition?.qrCode || data.definition?.shopify) && (
              <>
                <DisplayGroup
                  label='Langbeschreibung'
                  tooltip='Die Langbeschreibung wird auf der Detailseite des Vorteils angezeigt.'
                >
                  <TextRenderer content={data.descriptionLong} />
                </DisplayGroup>
              </>
            )}
            {data.definition?.article?.teaser && (
              <DisplayGroup
                label='Teaser-Text'
                tooltip='Der Teaser-Text wird nicht-angemeldeten Besuchern auf der Website als Vorschau auf den Artikel angezeigt.'
              >
                <TextRenderer content={data.definition?.article?.teaser} />
              </DisplayGroup>
            )}
            {data.definition?.article && (
              <DisplayGroup
                label='Inhalt des Artikels'
                tooltip='Der Inhalt des Artikels ist nur angemeldeten Kunden zugänglich.'
              >
                <TextRenderer content={data.definition?.article?.content} />
              </DisplayGroup>
            )}
            {!data?.partner ? null : (
              <DisplayGroup label='Partner'>
                <h2 className='OfferPreview__PartnerHeadline'>{data.partner}</h2>
                {data?.partnerDescription && (
                  <div className='OfferPreview__PartnerDescription'>
                    <TextRenderer content={data.partnerDescription} />
                  </div>
                )}
              </DisplayGroup>
            )}
            <DisplayGroup label='Adresse'>
              {data?.address ? displayAddress(data?.address) : 'Keine Adresse'}
            </DisplayGroup>
            <DisplayGroup label='Website'>
              {data?.infoWebsite ? (
                <a
                  href={sanitizeWebUrl(data.infoWebsite)}
                  title={data.infoWebsite}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {data.infoWebsite}
                </a>
              ) : (
                'Keine Website'
              )}
            </DisplayGroup>
            <DisplayGroup label='Telefon'>
              {data?.infoPhone ? (
                <a href={sanitizePhoneUrl(data.infoPhone)} title={data.infoPhone}>
                  {data.infoPhone}
                </a>
              ) : (
                'Keine Telefonnummer'
              )}
            </DisplayGroup>

            <p className='OfferPreview__Footer'>
              Behandeln Sie alle Daten auf dieser Seite streng vertraulich und geben Sie diesen
              Vorschaulink keinesfalls an Kunden oder unbeteiligte Dritte weiter.
            </p>
          </div>
        )}
      </Container>
    </main>
  );
};

export default OfferPreview;
